import { Theme } from "@emotion/react";
import {
  MdHelpOutline,
  MdOutlineCancel,
  MdOutlineCheckCircle,
  MdOutlineMotionPhotosPause,
  MdOutlineSchedule,
} from "react-icons/md";

import { OrderStatus } from "@megaron/crm-contracts";

export const getBackgroundColor = (status: OrderStatus, theme: Theme) => {
  if (status === "fulfilled") {
    return theme.colors.success;
  }

  if (status === "cancelled") {
    return "rgba(0, 0, 0, 0.1)";
  }

  if (status === "awaiting") {
    return theme.colors.primaryLight;
  }

  if (status === "suspended") {
    return theme.colors.warning;
  }

  return theme.colors.primary;
};

export const getTextColor = (status: OrderStatus, theme: Theme) => {
  if (status === "fulfilled") {
    return theme.colors.success;
  }

  if (status === "cancelled") {
    return "black";
  }

  if (status === "awaiting") {
    return theme.colors.primary;
  }

  if (status === "suspended") {
    return theme.colors.warning;
  }

  return theme.colors.primary;
};

export const parseStatusToDisplayData = (status: OrderStatus) => {
  if (status === "fulfilled") {
    return { label: "Zrealizowane", Icon: MdOutlineCheckCircle };
  }

  if (status === "cancelled") {
    return { label: "Anulowane", Icon: MdOutlineCancel };
  }

  if (status === "suspended") {
    return { label: "Wstrzymane", Icon: MdOutlineMotionPhotosPause };
  }

  if (status === "inProgress") {
    return { label: "W realizacji", Icon: MdOutlineSchedule };
  }

  return { label: "Oczekujące", Icon: MdHelpOutline };
};
