import { useContext } from "react";

import { IamAuthContext } from "@megaron/iam-auth-react";
import { useClientManager } from "@megaron/react-clients";

export const useUserRegion = ({ enabled }: { enabled: boolean }) => {
  const regionsQuery = useClientManager("crm").regionsQuery().useQuery({}, { enabled });
  const auth = useContext(IamAuthContext);

  const user = auth.iamUser;

  if (!user || (user && user?.userType !== "megarax") || !regionsQuery.data) {
    return { region: null, isLoaded: false };
  }

  const regions = regionsQuery.data.items;

  const defaultRegion = regions.find((r) => r.ownerEmail === user.email);

  const defaultRegionName = defaultRegion?.name;

  return { region: defaultRegionName, isLoaded: regionsQuery.data !== undefined };
};
