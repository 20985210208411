import { authHttpService, IdTokenPayload } from "@megarax/auth-contracts";
import { addDays, getTime } from "date-fns";
import { loadAuth2WithProps, loadGapiInsideDOM } from "gapi-script";
import React, { useEffect, useState } from "react";

import { useConfig } from "@megaron/dev";
import { getEnvString } from "@megaron/get-env-var";
import { HttpServiceClient } from "@megaron/http-service-client";
import { HandlerMap } from "@megaron/http-service-host";
import { Uuid } from "@megaron/uuid";

export type IamAuthContext = {
  isLoaded: boolean;
  signInWithGoogle: () => Promise<void>;
  signOut: () => Promise<void>;
  iamUser: IdTokenPayload | undefined;
  iamError: AuthResponse["error"];
  getIdToken: () => Promise<string | undefined>;
  getAuthHeader: () => Promise<string | undefined>;
  getGoogleIdToken: () => Promise<string | undefined>;
  getGoogleAccessToken: () => Promise<string | undefined>;
};

export const IamAuthContext = React.createContext<IamAuthContext>({
  isLoaded: false,
  signInWithGoogle: async () => {},
  signOut: async () => {},
  iamUser: undefined,
  iamError: undefined,
  getIdToken: async () => undefined,
  getAuthHeader: async () => undefined,
  getGoogleIdToken: async () => undefined,
  getGoogleAccessToken: async () => undefined,
});

type AuthResponse = Awaited<ReturnType<HandlerMap<typeof authHttpService, {}>["signInWithGoogle"]>>;

const refreshMarginSec = 60;

const isExpired = (expiresAt: number) => {
  const now = Date.now() / 1000;
  return expiresAt - now < refreshMarginSec;
};

export const IamAuthContextProvider: React.FC<{ children?: (ctx: IamAuthContext) => React.ReactNode }> = (props) => {
  const [authResponse, setAuthResponse] = React.useState<AuthResponse>();
  const [googleUser, setGoogleUser] = React.useState<gapi.auth2.GoogleUser>();
  const [isGapiLoaded, setIsGapiLoaded] = useState<boolean>(false);
  const [isGoogleAuthLoaded, setIsGoogleAuthLoaded] = useState<boolean>(false);
  const [isIamLoaded, setIsIamLoaded] = useState<boolean>(false);
  const [auth2, setAuth2] = useState<gapi.auth2.GoogleAuthBase | null>(null);

  const { config } = useConfig();

  useEffect(() => {
    const loadGapi = async () => {
      await loadGapiInsideDOM();
      setIsGapiLoaded(true);
    };
    loadGapi();
  }, []);

  useEffect(() => {
    if (!isGapiLoaded) return;

    (async () => {
      const auth2 = await loadAuth2WithProps(gapi, {
        client_id: getEnvString("NX_PUBLIC_GOOGLE_CLIENT_ID"),
        scope: "profile email openid https://www.googleapis.com/auth/calendar.events",
        ux_mode: "popup",
      });
      setAuth2(auth2);
      if (auth2.isSignedIn.get()) {
        setGoogleUser(auth2.currentUser.get());
      }
      setIsGoogleAuthLoaded(true);
    })();
  }, [isGapiLoaded]);

  const getGoogleIdToken = async () => {
    const response = googleUser?.getAuthResponse();
    if (response && !isExpired(response.expires_at / 1000)) return response.id_token;

    await googleUser?.reloadAuthResponse();
    const token = googleUser?.getAuthResponse().id_token;
    if (!token) throw new Error("Could not get google id token");
    return token;
  };

  const getGoogleAccessToken = async () => {
    let response = googleUser?.getAuthResponse();

    if (response && !isExpired(response.expires_at / 1000)) {
      return response.access_token;
    }

    await googleUser?.reloadAuthResponse();
    response = googleUser?.getAuthResponse();
    const accessToken = response?.access_token;

    if (!accessToken) throw new Error("Could not get Google access token");
    return accessToken;
  };

  const iamSignIn = async () => {
    if (!googleUser) {
      setAuthResponse(undefined);
      return;
    }

    const authlient = HttpServiceClient(config.accountsUrl, authHttpService);
    const result = await authlient.signInWithGoogle({ googleIdToken: await getGoogleIdToken() });
    setAuthResponse(result as any);
    setIsIamLoaded(true);
    return result;
  };

  useEffect(() => {
    iamSignIn();
  }, [googleUser]);

  const signInWithGoogle = async () => {
    if (auth2) {
      const googleUser = await auth2.signIn();
      setGoogleUser(googleUser);
    }
  };

  const signOut = async () => {
    if (auth2) {
      await auth2.signOut();
      setGoogleUser(undefined);
    }
  };

  const getIdToken = async () => {
    const expiresAt = authResponse?.value?.tokenPayload.exp;
    if (!expiresAt || isExpired(expiresAt)) {
      const result = await iamSignIn();
      return result?.value?.idToken ?? undefined;
    }
    return authResponse?.value?.idToken ?? undefined;
  };

  const testUser: IdTokenPayload | undefined = config.testUser.enabled
    ? {
        id: config.testUser.email ?? "",
        email: config.testUser.email ?? "",
        loyaltyId: config.testUser.uuid as Uuid,
        phoneNumber: config.testUser.phoneNumber,
        firstName: "Test",
        lastName: "User",
        groups: config.testUser.groups,
        profilePictureUrl: "",
        roles: config.testUser.roles,
        userType: "megarax",
        attributes: ["userType:megarax", `user:${config.testUser.email}`],
        iat: 1,
        exp: getTime(addDays(new Date(), 100)),
      }
    : undefined;

  const testUserOverride = config.testUser.enabled
    ? { iamUser: testUser, isLoaded: true, iamError: undefined }
    : undefined;

  const ctx: IamAuthContext = {
    isLoaded: googleUser ? isIamLoaded : isGoogleAuthLoaded,
    iamUser: authResponse?.value?.tokenPayload,
    iamError: authResponse?.error,
    ...testUserOverride,
    signInWithGoogle,
    signOut,
    getIdToken,
    getAuthHeader: async () => getIdToken().then((token) => (token ? `Bearer ${token}` : undefined)),
    getGoogleIdToken,
    getGoogleAccessToken,
  };

  return <IamAuthContext.Provider value={ctx}>{props.children?.(ctx)}</IamAuthContext.Provider>;
};
