import { useTheme } from "@emotion/react";

import { OrderStatus } from "@megaron/crm-contracts";
import { useDeviceType } from "@megaron/dash-mq";

import { getBackgroundColor, getTextColor, parseStatusToDisplayData } from "./helpers";

type Props = {
  status: OrderStatus;
};

export const OrderStatusTile: React.FC<Props> = ({ status }) => {
  const theme = useTheme();

  const { isMobile } = useDeviceType();

  const { label, Icon } = parseStatusToDisplayData(status);

  const backgroundColor = getBackgroundColor(status, theme);
  const textColor = getTextColor(status, theme);

  return (
    <div
      css={{
        display: "flex",
        borderRadius: theme.smallBorderRadius,
        border: "1px solid rgba(0, 0, 0, 0.1)",
        width: isMobile ? "100%" : "13rem",
        overflow: "hidden",
      }}
    >
      <div
        css={{
          display: "flex",
          padding: "0.5rem",
          backgroundColor,
          minHeight: "2rem",
          alignSelf: "stretch",
          alignItems: "center",
        }}
      >
        <Icon size={16} color={backgroundColor === textColor ? "white" : theme.colors.primary} />
      </div>
      <div css={{ color: textColor, fontSize: "1rem", fontWeight: 700, padding: "0.5rem 0.75rem" }}>{label}</div>
    </div>
  );
};
