import { useTheme } from "@emotion/react";
import Decimal from "decimal.js";
import { BsFillBoxFill } from "react-icons/bs";
import { IconType } from "react-icons/lib";
import { MdLayers, MdPallet } from "react-icons/md";

import { Dialog, useDialogRoute } from "@megaron/dash-dialog";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { RowCellContent, Table, TableBody, TableHeadColumn } from "@megaron/dash-table";

type ItemPackSize = {
  itemName: string;
  palette: Decimal;
  layer: Decimal;
  box: Decimal;
  piece: Decimal;
};

type Props = {
  itemPackSizes: ItemPackSize[];
};

export const PackSizeButton: React.FC<Props> = ({ itemPackSizes }) => {
  const { isMobile } = useDeviceType();

  const theme = useTheme();

  const tableColumns: TableHeadColumn<string>[] = [
    {},
    ...tableHeadingList.map(
      ({ heading, Icon }) =>
        ({
          isSortable: false,
          label: heading,
          element: Icon ? (
            <div>
              <Icon size={16} color={theme.colors.primary} />
            </div>
          ) : (
            <strong>{heading}</strong>
          ),
        } as const),
    ),
  ];

  const getTableRowCellsContent = (item: ItemPackSize): RowCellContent[] => {
    return [
      {
        element: <span css={{ textWrap: "nowrap" }}>{item.itemName}</span>,
      },
      {
        element: item.palette.toFixed(2),
      },
      {
        element: item.layer.toFixed(2),
      },
      {
        element: item.box.toFixed(2),
      },
      {
        element: item.piece.toFixed(),
      },
    ];
  };

  const packSizeDialog = useDialogRoute("/pack-sizes", ({ onClose }) => (
    <Dialog
      onClose={onClose}
      header="Współczynniki paletowe"
      css={{ width: isMobile ? "100%" : "400px" }}
      placement={isMobile ? "top" : "center"}
    >
      <div css={{ overflow: "auto", margin: "0 -1rem", padding: "0 1rem" }}>
        <Table columns={tableColumns}>
          <TableBody
            rows={[
              ...itemPackSizes.map((item) => ({
                uuid: item.itemName,
                cellsContent: getTableRowCellsContent(item),
                css: {
                  "td:first-child": {
                    padding: "0.75rem",
                  },
                },
              })),
              {
                uuid: "total",
                cellsContent: [
                  {
                    element: "Razem",
                    cellCss: { fontWeight: 700, color: theme.colors.primary },
                  },
                  {
                    element: itemPackSizes
                      .map((item) => item.palette)
                      .reduce((acc, curr) => acc.plus(curr), new Decimal(0))
                      .toFixed(2),
                    cellCss: { fontWeight: 700, color: theme.colors.primary },
                  },
                  {
                    element: itemPackSizes
                      .map((item) => item.layer)
                      .reduce((acc, curr) => acc.plus(curr), new Decimal(0))
                      .toFixed(2),
                    cellCss: { fontWeight: 700, color: theme.colors.primary },
                  },
                  {
                    element: itemPackSizes
                      .map((item) => item.box)
                      .reduce((acc, curr) => acc.plus(curr), new Decimal(0))
                      .toFixed(2),
                    cellCss: { fontWeight: 700, color: theme.colors.primary },
                  },
                  {
                    element: itemPackSizes
                      .map((item) => item.piece)
                      .reduce((acc, curr) => acc.plus(curr), new Decimal(0))
                      .toFixed(),
                    cellCss: { fontWeight: 700, color: theme.colors.primary },
                  },
                ],
                css: {
                  "td:first-child": {
                    padding: "0.75rem",
                  },
                },
              },
            ]}
          />
        </Table>
      </div>
    </Dialog>
  ));

  return (
    <>
      <Button variant="outline" icon={<MdLayers />} onClick={packSizeDialog.open}>
        Współczynniki
      </Button>
      {packSizeDialog.element}
    </>
  );
};

const tableHeadingList: { heading: string; Icon?: IconType }[] = [
  { heading: "Paleta", Icon: MdPallet },
  { heading: "Warstwa", Icon: MdLayers },
  { heading: "Karton", Icon: BsFillBoxFill },
  { heading: "szt." },
];
