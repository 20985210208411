import { useTheme } from "@emotion/react";
import { ReactNode, useCallback, useState } from "react";
import { MdOutlineContactPage, MdOutlineHistory, MdOutlineHub, MdOutlineShoppingCart } from "react-icons/md";
import { useSearchParams } from "react-router-dom-v5-compat";

import { UnsavedChangesAlert } from "./UnsavedChangesAlert";

type Props = {
  customerId: string;
  className?: string;
  showAlertOnTabChange: boolean;
  onTabChange: () => void;
};

export const profileTab = "profil";
export const historyTab = "historia";
export const relationsTab = "relacje";
export const saleTab = "sprzedaz";

type Tab = typeof profileTab | typeof historyTab | typeof relationsTab | typeof saleTab;

export const CustomerTabs: React.FC<Props> = ({ customerId, className, showAlertOnTabChange, onTabChange }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [clickedTab, setClickedTab] = useState<Tab | null>(null);

  const { activeTabIdx, activeTab } = getActiveTab(searchParams);

  const handleTabChange = useCallback(
    (tab: Tab) => {
      if (tab === activeTab) {
        return;
      }

      setSearchParams({ tab }, { replace: true });
    },
    [activeTab, setSearchParams],
  );

  return (
    <div css={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }} className={className}>
      {tabs.map((tab, index) => (
        <Tab
          key={tab.tab}
          icon={tab.icon}
          text={tab.text}
          isActive={index === activeTabIdx}
          isPrevious={index === activeTabIdx - 1}
          isNext={index === activeTabIdx + 1}
          onClick={
            showAlertOnTabChange
              ? () => {
                  setIsAlertVisible(true);
                  setClickedTab(tab.tab);
                }
              : () => handleTabChange(tab.tab)
          }
        />
      ))}
      {isAlertVisible && clickedTab && (
        <UnsavedChangesAlert
          onContinue={() => {
            onTabChange();
            setIsAlertVisible(false);
            handleTabChange(clickedTab);
          }}
          onClose={() => {
            setIsAlertVisible(false);
            setClickedTab(null);
          }}
        />
      )}
    </div>
  );
};

const Tab: React.FC<{
  icon: ReactNode;
  text: string;
  isActive: boolean;
  isPrevious: boolean;
  isNext: boolean;
  onClick: () => void;
}> = ({ icon, text, isActive, isPrevious, isNext, onClick }) => {
  const theme = useTheme();

  return (
    <button
      onClick={onClick}
      css={{
        background: isActive ? theme.colors.primary : theme.colors.background,
        cursor: "pointer",
        transition: "background 0.2s",
        border: "none",
        padding: 0,
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "0.375rem",
          alignItems: "center",
          padding: "0.5rem",
          width: "100%",
          fontWeight: isActive ? 700 : 400,
          color: isActive ? theme.colors.primary : "rgba(255, 255, 255, 0.7)",
          background: isActive ? theme.colors.background : theme.colors.primary,
          borderTopRightRadius: isActive ? theme.smallBorderRadius : 0,
          borderTopLeftRadius: isActive ? theme.smallBorderRadius : 0,
          borderBottomLeftRadius: isNext ? theme.smallBorderRadius : 0,
          borderBottomRightRadius: isPrevious ? theme.smallBorderRadius : 0,
          fontSize: "12px",
          transition: "all 0.2s",
        }}
      >
        {icon}
        <span>{text}</span>
      </div>
    </button>
  );
};

const tabs = [
  { icon: <MdOutlineContactPage size={18} />, text: "Profil", tab: profileTab },
  { icon: <MdOutlineHub size={18} />, text: "Relacje", tab: relationsTab },
  { icon: <MdOutlineShoppingCart size={18} />, text: "Sprzedaż", tab: saleTab },
  { icon: <MdOutlineHistory size={18} />, text: "Historia", tab: historyTab },
] as const;

export const getActiveTab = (searchParams: URLSearchParams) => {
  const searchTab = searchParams.get("tab") as Tab;
  const activeTab = tabs.find((tab) => tab.tab === searchTab);
  const activeTabIdx = tabs.findIndex((tab) => activeTab?.tab === tab.tab);

  return { activeTab: activeTab?.tab || profileTab, activeTabIdx: activeTabIdx === -1 ? 0 : activeTabIdx };
};
