import { Uuid } from "@megarax/common";
import { customerResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { isNotNull } from "@megarax/utils";
import _ from "lodash";
import { useQuery } from "react-query";

import { Failure, Ok } from "@megaron/result";
import { useClientManager, useServiceClient } from "@megaron/react-clients";
import { getDocId, isDocOfType } from "@megaron/docs-contracts";

export const useCustomer = (uuid: Uuid | undefined | null) => {
  const customerProvider = useResourceProviderV2(customerResource);
  const getCustomer = (uuid: Uuid) =>
    customerProvider
      .byUuid(uuid)
      .retrieve()
      .flatMapError((err) => (err === "CustomerNotFound" ? Ok(null) : Failure(err)))
      .then((r) => r.assertOk().value);

  const { data: customer } = useQuery(["customer", uuid], () => getCustomer(uuid!) ?? null, {
    enabled: !!uuid,
  });

  return uuid && customer;
};

export const useCustomers = (uuids: Uuid[]) => {
  const q = useClientManager("docs")
    .retrieveDocs()
    .useQuery({
      docIds: uuids.map((i) => getDocId("customer", i)),
    });

  const dictionary = _(q.data)
    .filter(isNotNull)
    .filter(isDocOfType("customer"))
    .keyBy(({ uuid }) => uuid)
    .value();

  return dictionary;
};
