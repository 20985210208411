import { useQueryClient } from "react-query";

import { useDeviceType } from "@megaron/dash-mq";
import { Page, PageHeader } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useClientManager } from "@megaron/react-clients";

import { DownloadPostSaleDiscountsButton } from "./DownloadPostSaleDiscountsButton";
import { ImportPostSaleDiscountsButton } from "./ImportPostSaleDiscountsButton";
import { PostSaleDiscountsTable } from "./PostSaleDiscountsTable";

export const PostSaleDiscountsPage = () => {
  const { isMobile } = useDeviceType();

  const postSaleDiscountsQuery = useClientManager("crm").postSaleDiscountsQuery().useQuery({});

  const queryClient = useQueryClient();

  return (
    <Page
      css={{
        maxWidth: "1600px",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <PageHeader
        actions={
          <ActionButtons
            css={{ display: isMobile ? "none" : "flex" }}
            onImportSuccess={() => queryClient.invalidateQueries(postSaleDiscountsQuery.key)}
          />
        }
      >
        Rabaty posprzedażowe
      </PageHeader>

      <ActionButtons
        css={{ display: isMobile ? "flex" : "none", marginBottom: "1rem" }}
        onImportSuccess={() => queryClient.invalidateQueries(postSaleDiscountsQuery.key)}
      />

      <QuerySkeleton query={postSaleDiscountsQuery}>
        {(postSaleDiscounts) => (
          <PostSaleDiscountsTable postSaleDiscounts={postSaleDiscounts} isLoading={postSaleDiscountsQuery.isFetching} />
        )}
      </QuerySkeleton>
    </Page>
  );
};

const ActionButtons = ({ onImportSuccess, className }: { onImportSuccess: () => void; className?: string }) => {
  return (
    <div css={{ display: "flex", gap: "0.5rem" }} className={className}>
      <DownloadPostSaleDiscountsButton />

      <ImportPostSaleDiscountsButton onImportSuccess={onImportSuccess} />
    </div>
  );
};
