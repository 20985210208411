import { Serializers } from "@megaron/serializers";

export type RegistrationData = {
  firstName: string;
  lastName: string;
  email: string;
  newsletterConsent: boolean;
};

export const registrationDataSerializer = Serializers.object({
  firstName: Serializers.string,
  lastName: Serializers.string,
  email: Serializers.string,
  postalCode: Serializers.postalCode.nullable(),
  emailConsent: Serializers.boolean,
  phoneCallConsent: Serializers.boolean,
  smsConsent: Serializers.boolean,
});
