import { Uuid } from "@megarax/common";
import { isNotNull } from "@megarax/utils";
import { addMinutes } from "date-fns";

import { CalendarEvent } from "./WeekCalendar";
import { CalendarVisit } from "./useWeekVisits";

export const getVisitCalendarEvents = (
  visits: CalendarVisit[],
  customerMap: { [uuid: Uuid]: { name: string } | undefined | null },
  onVisitClick: (visitUuid: Uuid, customerUuid: Uuid, isEnded: boolean) => void,
): CalendarEvent[] =>
  visits
    .map<CalendarEvent | null>((v) => {
      if (v.startTime === null) {
        if (v.scheduledStartTime === null) return null;
        const endTime = v.scheduledDurationMinutes
          ? addMinutes(v.scheduledStartTime, v.scheduledDurationMinutes)
          : null;

        return {
          color: "#008f4a",
          startTime: v.scheduledStartTime,
          endTime,
          key: v.uuid,
          variant: "outlined",
          title: customerMap[v.customerUuid]?.name ?? "?",
          onClick: () => onVisitClick(v.uuid, v.customerUuid, !!v.endTime),
        };
      }

      return {
        color: "#008f4a",
        endTime: v.endTime,
        key: `visit/${v.uuid}`,
        title: customerMap[v.customerUuid]?.name ?? "?",
        startTime: v.startTime,
        onClick: () => onVisitClick(v.uuid, v.customerUuid, !!v.endTime),
      };
    })
    .filter(isNotNull);
