import { useTheme } from "@emotion/react";
import React from "react";

type DateSelectProps = {
  value: Date | null;
  onChange: (value: Date) => void;
  label?: string;
  className?: string;
  isDisabled?: boolean;
};

export const DateField: React.FunctionComponent<DateSelectProps> = ({
  value,
  onChange,
  label,
  className,
  isDisabled,
}) => {
  const theme = useTheme();

  // Convert Date object to string in 'YYYY-MM-DD' format for input value compatibility
  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are 0-based in JS
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  // Handle date changes, converting back to Date object
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      const [year, month, day] = e.target.value.split("-").map((num) => parseInt(num, 10));
      const selectedDate = new Date(year, month - 1, day); // Months are 0-based in JS
      onChange(selectedDate);
    }
  };

  const inputProps = {
    value: value ? formatDate(value) : "", // Ensure value is in 'YYYY-MM-DD' format
    onChange: handleDateChange,
    css: {
      width: "100%",
      // maxWidth: "500px",
      border: "none",
      padding: "0.5rem 0.75rem",
      fontSize: "1rem",
      borderRadius: "6px",
      backgroundColor: theme.colors.primaryLight,
      ":focus": {
        outline: `1px solid ${theme.colors.primary}`,
      },
    },
    type: "date", // enables the native date picker
    disabled: isDisabled,
  };

  return (
    <div css={{ display: "flex", flexDirection: "column", opacity: isDisabled ? 0.5 : 1 }} className={className}>
      {label && (
        <label
          css={{
            color: theme.colors.primary,
            fontSize: "0.875rem",
            fontFamily: theme.displayFontFamily,
            marginBottom: "0.25rem",
          }}
        >
          {label}
        </label>
      )}
      <input {...inputProps} />
    </div>
  );
};
