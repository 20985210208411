import { AutocompleteTextField, Checkbox, DateField, DecimalField, TextField } from "@megaron/dash-form";
import { Select } from "@megaron/dash-select";
import { currencies, Currency } from "@megaron/invoices-contracts";
import { useServiceClient } from "@megaron/react-clients";

type Props = {
  values: {
    issuer: string;
    issuerTaxId: string;
    invoiceNumber: string;
    issueDate: Date;
    dueDate: Date | null;
    receivedDate: Date;
    total: string;
    currency: Currency;
    isDigital: boolean;
    isPayed: boolean;
  };
  handlers: {
    onIssuerChange: (issuer: string) => void;
    onIssuerTaxIdChange: (issuerTaxId: string) => void;
    onInvoiceNumberChange: (invoiceNumber: string) => void;
    onIssueDateChange: (issueDate: Date) => void;
    onDueDateChange: (dueDate: Date | null) => void;
    onReceivedDateChange: (receivedDate: Date) => void;
    onTotalChange: (total: string) => void;
    onCurrencyChange: (currency: Currency) => void;
    onIsDigitalChange: (isDigital: boolean) => void;
    onPayedChange: (isPayed: boolean) => void;
  };
  className?: string;
};

export const ThreadDetailsForm: React.FC<Props> = ({ values, handlers, className }) => {
  const docs = useServiceClient("docs");

  const { issuer, issuerTaxId, currency, dueDate, invoiceNumber, isDigital, issueDate, receivedDate, total, isPayed } =
    values;

  const {
    onCurrencyChange,
    onDueDateChange,
    onInvoiceNumberChange,
    onIsDigitalChange,
    onIssueDateChange,
    onIssuerChange,
    onIssuerTaxIdChange,
    onReceivedDateChange,
    onTotalChange,
    onPayedChange,
  } = handlers;

  return (
    <form
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "0.625rem",
      }}
      className={className}
    >
      <AutocompleteTextField
        label="Wystawca"
        value={issuer}
        onChange={onIssuerChange}
        getOptions={async (searchText) => {
          const results = await docs.autocomplete({
            text: searchText,
            field: "invoiceIssuer",
            doctype: "invoice-thread",
          });

          return results.value || [];
        }}
        keyName="issuer"
      />
      <TextField label="NIP wystawcy (opcjonalny)" value={issuerTaxId} onChange={onIssuerTaxIdChange} />
      <TextField label="Numer faktury" value={invoiceNumber} onChange={onInvoiceNumberChange} />
      <DateField label="Data wystawienia" css={{ padding: 0 }} value={issueDate} onChange={onIssueDateChange} />
      <div css={{ padding: "0.5rem 0" }}>
        <Checkbox
          value={isPayed}
          onChange={(value) => {
            onPayedChange(value);
            if (value) {
              onDueDateChange(null);
            }
          }}
          label="Faktura została opłacona"
        />
      </div>
      <DateField
        label="Termin płatności"
        css={{ padding: 0 }}
        value={dueDate}
        onChange={onDueDateChange}
        isDisabled={isPayed}
      />
      <DateField
        label="Data otrzymania faktury"
        css={{ padding: 0 }}
        value={receivedDate}
        onChange={onReceivedDateChange}
      />
      <div css={{ display: "flex", gap: "0.625rem" }}>
        <DecimalField
          enableNegativeValues
          label="Kwota netto"
          css={{ width: "100%" }}
          value={total}
          onChange={onTotalChange}
        />
        <Select
          label="Waluta"
          value={currency}
          onChange={(value) => onCurrencyChange(value as Currency)}
          options={currencies.map((currency) => ({ label: currency, value: currency }))}
        />
      </div>
      <div css={{ padding: "0.5rem 0" }}>
        <Checkbox value={isDigital} onChange={onIsDigitalChange} label="Tylko elektroniczna" />
      </div>
    </form>
  );
};
