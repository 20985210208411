import { ResourceNodes, ResourceParamNode } from "@megarax/rest-resource";
import { Serializer, SerializerExtensions, serializerExtensions, ValidationError } from "@megarax/serializers";
import { formatISO, isValid, parseISO } from "date-fns";

import { Failure, Ok } from "@megaron/result";

export type DateString = string & { __brand: "DateString" };

export const validateDateString = (str: string) => {
  const date = new Date(str);
  const formattedDate = formatISO(date, { representation: "date" });
  if (formattedDate !== str) return Failure("InvalidDateString");
  return Ok<DateString>(str as DateString);
};

export const dateStringSerializer: Serializer<DateString> & SerializerExtensions<DateString> = {
  serialize: (dateString) => dateString,
  forceDeserialize: (raw: unknown) => {
    if (typeof raw !== "string") throw new ValidationError("Not a string");
    const result = validateDateString(raw);
    if (result.isFailure) throw new ValidationError("Invalid date string");
    return result.value;
  },
  ...serializerExtensions(),
};

export const DateStringResourceParam = <TNestedActions extends ResourceNodes>(
  paramName: string,
  path: string,
  nested: TNestedActions,
): ResourceParamNode<DateString, TNestedActions> => ({
  children: nested,
  paramName,
  paramPattern: "\\d{4}-[01]\\d-[0-3]\\d",
  paramSerializer: dateStringSerializer,
  path,
});

export const toDateString = (date: Date) => formatISO(date, { representation: "date" }) as DateString;

export function fromDateString(str: DateString): Date;
export function fromDateString(str: DateString | null): Date | null;
export function fromDateString(str: DateString | undefined): Date | undefined;
export function fromDateString(str: DateString | null | undefined): Date | null | undefined;
export function fromDateString(str: DateString | null | undefined) {
  if (str === null) return null;
  if (str === undefined) return undefined;
  return parseISO(str);
}

export const dateStringsInRange = (since: Date | DateString, until: Date | DateString): DateString[] => {
  since = typeof since === "string" ? fromDateString(since) : since;
  until = typeof until === "string" ? fromDateString(until) : until;

  const date = new Date(since);

  const dates: any = [];

  while (date <= until) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates.map(toDateString);
};
