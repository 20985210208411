import { useVisibleTableColumns } from "@megaron/dash-table";
import { visibleCustomersTableColumnsKey, VisibleCustomersTableColumnsKey } from "@megaron/megarax-links";

import { tableHeadingsList } from "./CustomerTable";

const localStorageCustomerVisibleTableColumnsKey = "customersVisibleTableColumns";

export type VisibleCustomersTableColumnsObject = {
  all: { added: string[]; removed: string[] };
  loyalty: { added: string[]; removed: string[] };
  pos: { added: string[]; removed: string[] };
  payers: { added: string[]; removed: string[] };
  distributors: { added: string[]; removed: string[] };
  chains: { added: string[]; removed: string[] };
};

export type VisibleCustomersTableColumnsConfigObject = {
  all: string[];
  loyalty: string[];
  pos: string[];
  payers: string[];
  distributors: string[];
  chains: string[];
};

const defaultLoyaltyVisibleColumns = tableHeadingsList
  .filter((heading) => heading.isDefaultForLoyalty)
  .map((heading) => heading.heading);

const defaultPosVisibleColumns = tableHeadingsList
  .filter((heading) => heading.isDefaultForPos)
  .map((heading) => heading.heading);

const defaultChainVisibleColumns = tableHeadingsList
  .filter((heading) => heading.isDefaultForChain)
  .map((heading) => heading.heading);

const defaultDistributorVisibleColumns = tableHeadingsList
  .filter((heading) => heading.isDefaultForDistributor)
  .map((heading) => heading.heading);

const defaultPayerVisibleColumns = tableHeadingsList
  .filter((heading) => heading.isDefaultForPayer)
  .map((heading) => heading.heading);

const defaultAllCustomersVisibleColumns = tableHeadingsList
  .filter((heading) => heading.isDefaultForAll)
  .map((heading) => heading.heading);

export const defaultCustomersVisibleTableColumns = {
  all: defaultAllCustomersVisibleColumns,
  loyalty: defaultLoyaltyVisibleColumns,
  pos: defaultPosVisibleColumns,
  payers: defaultPayerVisibleColumns,
  distributors: defaultDistributorVisibleColumns,
  chains: defaultChainVisibleColumns,
};

export const useCustomerVisibleTableColumns = () => {
  return useVisibleTableColumns<VisibleCustomersTableColumnsKey>({
    configKeys: [...visibleCustomersTableColumnsKey],
    defaultColumns: defaultCustomersVisibleTableColumns,
    localStorageKey: localStorageCustomerVisibleTableColumnsKey,
    useDefaultConfigOnUpdate: true,
  });
};
