import { Failure, Ok } from "@megaron/result";

import { SerializerExtensions, serializerExtensions } from "../extensions";
import { Serializer } from "../serializer";

export type Email = string & { __brand: "email" };

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const email: Serializer<Email> & SerializerExtensions<Email> = {
  serialize: (email: string) => {
    if (typeof email !== "string") throw new Error("NotAString");

    return email;
  },
  deserialize: (raw: any) => {
    if (typeof raw !== "string") return Failure("NotAString");

    const isValid = isValidEmail(raw);

    if (!isValid) {
      return Failure("InvalidEmail");
    }

    return Ok(raw as Email);
  },
  ...serializerExtensions(),
};
