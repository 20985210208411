import { dateStringSerializer } from "@megaron/date-string";
import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";
import { DayDto, ScheduleVisitDto, Travel } from "../visitSchedule";

const travelSerializer = Serializers.object<Travel>({
  seconds: Serializers.integer,
  meters: Serializers.integer,
});

export const visitScheduleActions = {
  visitScheduleQuery: HttpAction({
    path: "/crm/visitScheduleDays/:userId/date/:date",
    paramsSerializer: Serializers.object({
      userId: Serializers.integer,
      date: dateStringSerializer,
    }),
    valueSerializer: Serializers.object<DayDto>({
      date: Serializers.dateOnly,
      homeLocation: Serializers.object({
        latitude: Serializers.decimal,
        longitude: Serializers.decimal,
      }),
      startTime: Serializers.datetime,
      userId: Serializers.integer,
      removedVisits: Serializers.object({
        uuid: uuidSerializer,
        customerUuid: uuidSerializer,
      }).array(),
      totalDurationSeconds: Serializers.integer,
      totalTravel: travelSerializer,
      travelHome: travelSerializer,
      travelHomeStart: Serializers.datetime,

      visits: Serializers.object<ScheduleVisitDto>({
        uuid: uuidSerializer,
        location: Serializers.object({
          latitude: Serializers.decimal,
          longitude: Serializers.decimal,
        }),
        travelToVisit: travelSerializer,
        customerUuid: uuidSerializer,
        durationMinutes: Serializers.integer,
        startTime: Serializers.datetime,
      }).array(),
    }).nullable(),
  }),
};
