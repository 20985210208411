import { DateStringResourceParam, Uuid, UuidResourceParam, uuidSerializer } from "@megarax/common";
import { BaseResource, IdResourceParam, NestedResourceNode, ResourceActionV2 } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";

import {
  renameRouteInputSerializer,
  saveAndScheduleRouteInputSerializer,
  saveAsPermanentInputSerializer,
  scheduleRouteInputSerializer,
} from "./inputs";
import listNamed from "./list";

export type TradeRouteSimple = {
  uuid: Uuid;
  name: string | null;
  salespersonId: number;
  route: { uuid: Uuid; customerUuid: Uuid; durationMinutes: number }[];
  distanceMeters: number;
  fullDurationMinutes: number;
};

export type ScheduledTradeRoute = {
  uuid: Uuid;
  salespersonId: number;
  route: {
    customerUuid: Uuid;
    uuid: Uuid;
    durationMinutes: number;
  }[];
  distanceMeters: number;
  fullDurationMinutes: number;
  name: string | null;
};

export const tradeRouteSimpleSerializer = MSerializers.object<TradeRouteSimple>({
  uuid: uuidSerializer,
  name: MSerializers.string.nullable(),
  salespersonId: MSerializers.integer,
  route: MSerializers.array(
    MSerializers.object<{ uuid: Uuid; customerUuid: Uuid; durationMinutes: number }>({
      uuid: uuidSerializer,
      customerUuid: uuidSerializer,
      durationMinutes: MSerializers.integer,
    }),
  ),
  distanceMeters: MSerializers.integer,
  fullDurationMinutes: MSerializers.integer,
});

const scheduledTradeRouteSerializer = MSerializers.object<ScheduledTradeRoute>({
  uuid: uuidSerializer,
  salespersonId: MSerializers.integer,
  route: MSerializers.array(
    MSerializers.object<{ uuid: Uuid; customerUuid: Uuid; durationMinutes: number }>({
      uuid: uuidSerializer,
      customerUuid: uuidSerializer,
      durationMinutes: MSerializers.integer,
    }),
  ),
  distanceMeters: MSerializers.integer,
  fullDurationMinutes: MSerializers.integer,
  name: MSerializers.string.nullable(),
});

export const tradeRoutesResource = BaseResource(["crm", "tradeRoutes"], {
  listNamed: listNamed,
  bySalespersonId: IdResourceParam("id", {
    byDate: DateStringResourceParam("date", "date", {
      retrieve: ResourceActionV2({
        name: "retrieve",
        method: "get",
        responseValueSerializer: scheduledTradeRouteSerializer.nullable(),
      }),
    }),
  }),
  byCustomerUuid: UuidResourceParam("customerUuid", "customerUuid", {
    getTradeRoutesCount: ResourceActionV2({
      name: "getTradeRoutesCount",
      method: "get",
      responseValueSerializer: MSerializers.integer,
    }),
  }),
  byUuid: UuidResourceParam("uuid", "uuid", {
    archive: ResourceActionV2({
      name: "archiveTradeRoute",
      method: "put",
      responseErrorSerializer: MSerializers.stringOneOf("TradeRouteDoesNotExist"),
    }),
    saveAsPermanent: ResourceActionV2({
      name: "saveRouteAsPermanent",
      method: "post",
      requestBodySerializer: saveAsPermanentInputSerializer,
      responseErrorSerializer: MSerializers.stringOneOf("TradeRouteAlreadyExists"),
    }),
    schedule: ResourceActionV2({
      name: "scheduleTradeRoute",
      method: "post",
      requestBodySerializer: scheduleRouteInputSerializer,
      responseErrorSerializer: MSerializers.stringOneOf("TradeRouteDoesNotExist"),
    }),
    saveAndSchedule: ResourceActionV2({
      name: "saveAndScheduleTradeRoute",
      method: "post",
      requestBodySerializer: saveAndScheduleRouteInputSerializer,
      responseErrorSerializer: MSerializers.stringOneOf("TradeRouteAlreadyExists"),
    }),
    rename: ResourceActionV2({
      name: "renameTradeRoute",
      method: "post",
      requestBodySerializer: renameRouteInputSerializer,
      responseErrorSerializer: MSerializers.stringOneOf(
        ...["TradeRouteAlreadyExists", "TradeRouteIsNotPermanent", "TradeRouteAlreadyExist"],
      ),
    }),
    retrieve: ResourceActionV2({
      name: "retrieve",
      method: "get",
      responseValueSerializer: tradeRouteSimpleSerializer.nullable(),
    }),
  }),
  maintenance: NestedResourceNode("maintenance", {
    recalculateDistances: ResourceActionV2({
      name: "recalculateDistances",
      method: "post",
    }),
  }),
  customerTradeRouteCountBulkQuery: ResourceActionV2({
    name: "customerTradeRouteCountBulkQuery",
    path: "customerTradeRouteCountBulkQuery",
    querySerializer: MSerializers.object({
      customerUuids: MSerializers.array(uuidSerializer).optional(),
    }),
    responseValueSerializer: MSerializers.array(
      MSerializers.object({
        customerUuid: uuidSerializer,
        fixedTradeRouteCount: MSerializers.integer,
      }),
    ),
  }),
});
