import { useTheme } from "@emotion/react";
import {
  MdApartment,
  MdAttachMoney,
  MdDeleteOutline,
  MdOutlineAdd,
  MdOutlinePersonAdd,
  MdOutlineWarehouse,
  MdStorefront,
} from "react-icons/md";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom-v5-compat";

import { Relation, relationConstraints, RelationType } from "@megaron/crm-contracts";
import { useDialogRoute } from "@megaron/dash-dialog";
import { Button } from "@megaron/dash-form";
import { SelectDialog } from "@megaron/dash-select";
import { Tile } from "@megaron/dash-tile";
import { useToast } from "@megaron/dash-toast";
import { useClientManager, useServiceClient } from "@megaron/react-clients";
import { newUuid, Uuid } from "@megaron/uuid";

type Props = {
  value?: { name: string; relatedCustomerUuid: Uuid; relationUuid: Uuid };
  relationType: RelationType;
  queryKey: string | string[];
  customerUuid: Uuid;
  existingRelation?: Relation[];
};

export const RelationTile: React.FC<Props> = ({ value, relationType, customerUuid, queryKey, existingRelation }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const toast = useToast();
  const docs = useServiceClient("docs");

  const category = relationConstraints[relationType].parentCategory;

  const relationInfo: Record<RelationType, { Icon: React.ComponentType; label: string }> = {
    distributor: { Icon: MdOutlineWarehouse, label: "Dystrybutor" },
    chain: { Icon: MdApartment, label: "Sieć" },
    payer: { Icon: MdAttachMoney, label: "Płatnik" },
    supplyPoint: { Icon: MdStorefront, label: "Punkt sprzedaży" },
    invitedBy: { Icon: MdOutlinePersonAdd, label: "Zaproszony przez" },
  };

  const addRelationMutation = useClientManager("crm").addCustomerRelation().useMutation();
  const removeRelationMutation = useClientManager("crm").removeCustomerRelation().useMutation();

  const existingRelationId = existingRelation?.map((e) => e.parentId);

  const addRelation = (parentId: Uuid, onClose: () => void) => {
    if (parentId !== customerUuid) {
      addRelationMutation.mutate(
        {
          uuid: newUuid(),
          parentId: parentId,
          childId: customerUuid,
          type: relationType,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(queryKey);
            onClose();
          },
          onError: () => toast.error(`Nie udało się dodać relacji`),
        },
      );
    } else {
      toast.error("Nie możesz dodać samego siebie jako relacji.");
    }
  };

  const removeRelation = (relationUuid: Uuid) => {
    removeRelationMutation.mutate(
      {
        relationUuid: relationUuid,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
        },
        onError: () => toast.error(`Nie udało się usunąć relacji`),
      },
    );
  };

  const dialog = useDialogRoute(`/add-relation/${relationType}`, ({ onClose }) => (
    <SelectDialog
      label="Dodaj relacje"
      search={async (searchText) => {
        const results = await docs.searchCustomers({
          categories: [category],
          limit: 100,
          searchText: searchText,
        });

        return results ? results.value?.items.map((result) => ({ label: result.name, value: result.uuid })) ?? [] : [];
      }}
      initiallySelectedValues={existingRelationId}
      variant="single-select"
      onSelect={(option) => option?.value && addRelation(option.value, onClose)}
      isOpen
      isUnselectDisabled
      onClose={onClose}
      noTrigger
    />
  ));

  const Icon = relationInfo[relationType].Icon;

  const TileContent = (
    <Tile
      css={{
        "&:hover": {
          cursor: value?.relatedCustomerUuid ? "pointer" : "default",
        },
      }}
      stripe={
        <div
          css={{
            color: value ? "white" : theme.colors.primary,
            background: value ? theme.colors.primary : "transparent",
            opacity: value ? 1 : 0.1,
            padding: "0.5rem 0.875rem",
            minHeight: 0,
            alignSelf: "stretch",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRight: `1px solid ${value ? theme.colors.primary : "rgba(0, 0, 0)"}`,
          }}
        >
          <Icon />
        </div>
      }
    >
      <div css={{ display: "flex", alignItems: "center", gap: "0.5rem", justifyContent: "space-between" }}>
        <div css={{ display: "flex", flexDirection: "column" }}>
          <span css={{ fontSize: "0.75rem", color: theme.colors.secondaryText }}>
            {relationInfo[relationType].label}
          </span>
          <span
            css={{
              fontSize: "0.875rem",
              color: value ? theme.colors.primary : theme.colors.secondaryText,
              fontWeight: value ? 700 : 400,
              opacity: value ? 1 : 0.5,
            }}
          >
            {value?.name || "Dodaj"}
          </span>
        </div>
        <div css={{ display: "flex", alignItems: "center" }}>
          {relationType !== "invitedBy" && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                value ? removeRelation(value.relationUuid) : dialog.open();
              }}
              icon={value ? <MdDeleteOutline size={16} /> : <MdOutlineAdd size={16} />}
              variant="outline"
              css={{ border: "none", padding: "0.5rem" }}
            />
          )}
        </div>
      </div>
    </Tile>
  );

  return value ? (
    <Link
      to={`/crm/customers/id/${value.relatedCustomerUuid}`}
      css={{
        "&:hover": {
          textDecoration: "none",
        },
      }}
    >
      {TileContent}
    </Link>
  ) : (
    <>
      {TileContent} {dialog.element}
    </>
  );
};
