import { useTheme } from "@emotion/react";
import React from "react";
import tinycolor from "tinycolor2";

import { Spinner } from "@megaron/dash-spinner";

type Props = {
  children?: React.ReactNode;
  onClick?: React.ComponentProps<"button">["onClick"];
  onTouchStart?: React.ComponentProps<"button">["onTouchStart"];
  className?: string;
  variant?: "primary" | "outline" | "text";
  color?: "normal" | "secondary" | "danger" | "inverse";
  icon?: React.ReactNode;
  isLoading?: boolean;
  isDisabled?: boolean;
  size?: "small" | "medium" | "large";
  as?: "button" | "div";
  spinnerColor?: string;
};

export const Button: React.FunctionComponent<Props> = ({ as = "button", ...props }) => {
  const theme = useTheme();

  const foregroundColor = {
    normal: theme.colors.primary,
    danger: theme.colors.danger,
    secondary: theme.colors.primaryLight,
    inverse: theme.colors.background,
  }[props.color ?? "normal"];

  const icon = props.isLoading ? (
    <Spinner color={props.spinnerColor ? props.spinnerColor : foregroundColor} />
  ) : (
    props.icon
  );

  return (
    <ButtonWrapper {...props} as={as}>
      {icon && <div css={{ fontSize: "1.15em", height: "1.15em", display: "flex", alignItems: "center" }}>{icon}</div>}
      {props.children && <span css={{}}>{props.children}</span>}
    </ButtonWrapper>
  );
};

export const IconButton: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  const foregroundColor = {
    normal: theme.colors.primary,
    danger: theme.colors.danger,
    secondary: theme.colors.primary,
    inverse: theme.colors.background,
  }[props.color ?? "normal"];

  return (
    <button
      className={props.className}
      css={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        background: "none",
        color: foregroundColor,
        padding: "0.75em",
        margin: "-0.5em",
        textTransform: "uppercase",
        fontSize: "1.75rem",
        borderRadius: "50%",
        cursor: "pointer",
        transition: "background 0.1s ease-in-out",
        ":active": {
          background: tinycolor(foregroundColor).setAlpha(0.2).toString(),
          transition: "background 0s ease-in-out",
        },
      }}
      onClick={props.onClick}
      onTouchStart={props.onTouchStart}
    >
      {props.children}
    </button>
  );
};

const ButtonWrapper: React.FC<Props> = (props) => {
  const theme = useTheme();

  const foregroundColor = {
    normal: theme.colors.primary,
    danger: theme.colors.danger,
    secondary: theme.colors.primaryLight,
    inverse: theme.colors.background,
  }[props.color ?? "normal"];

  const backgroundColor = {
    normal: theme.colors.background,
    danger: theme.colors.dangerBackground,
    secondary: theme.colors.primary,
    inverse: theme.colors.primary,
  }[props.color ?? "normal"];

  const border = {
    primary: "none",
    outline: `1px solid ${foregroundColor}`,
    text: "none",
  }[props.variant ?? "primary"];

  const background = {
    primary: `radial-gradient(circle at 20%, rgba(255,255,255,0.08) 0%, rgba(255,255,255,0) 100%), ${foregroundColor}`,
    outline: "none",
    text: "none",
  }[props.variant ?? "primary"];

  const color = {
    primary: backgroundColor,
    outline: foregroundColor,
    text: foregroundColor,
  }[props.variant ?? "primary"];

  const fontSize = {
    small: "0.8rem",
    medium: "1rem",
    large: "1.2rem",
  }[props.size ?? "medium"];

  const padding = {
    small: "0.35em 0.8em",
    medium: "0.4em 1em",
    large: "0.5em 1.2em",
  }[props.size ?? "medium"];

  const styles = {
    background,
    opacity: props.isDisabled ? 0.4 : 1,
    border: border,
    color: color,
    padding,
    fontWeight: "400",
    fontSize,
    borderRadius: theme.smallBorderRadius,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: 1.3,
    gap: "0.4rem",
    transition: "all 0.1s ease-in-out",
    whiteSpace: "pre",
    ":disabled": {
      cursor: props.isLoading ? "wait" : "unset",
    },
    ":hover": {
      textDecoration: "underline",
      ":disabled": {
        textDecoration: "none",
      },
    },
  };

  if (props.as === "div") {
    return (
      <div className={props.className} css={styles}>
        {props.children}
      </div>
    );
  }

  return (
    <button
      className={props.className}
      css={styles}
      onClick={props.onClick}
      onTouchStart={props.onTouchStart}
      disabled={props.isDisabled || props.isLoading}
    >
      {props.children}
    </button>
  );
};
