import { useTheme } from "@emotion/react";
import { LoadingBar } from "@megarax/ui-components";
import { GoogleMap, PolygonF, useJsApiLoader } from "@react-google-maps/api";
import React, { useState } from "react";
import { MdList } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { SearchBox } from "@megaron/dash-search";
import { useClientManager } from "@megaron/react-clients";

import { CustomerMarkers } from "./CustomerMarkers";
import { CustomersFilters } from "./CustomersFilters";
import { CustomersGroupFilters } from "./CustomersGroupFilters";
import {
  Bialystok,
  BielskoBiala,
  Bydgoszcz,
  Czestochowa,
  Gdansk,
  JeleniaGora,
  Kielce,
  Koszalin,
  Krakow,
  Lodz,
  Lublin,
  Olsztyn,
  Poznan,
  Poznan2,
  Przemysl,
  Radom,
  Szczecin,
  Warszawa,
  Warszawa2,
  Wroclaw,
  ZielonaGora,
} from "./PolygonPaths";
import { useCustomersFilters } from "./useCustomersFilters";
import { useCustomerVisibleTableColumns } from "./useCustomerVisibleTableColumns";

export const CustomerMapView: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useDeviceType();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env["NX_PUBLIC_GOOGLE_MAPS_API_KEY"]!,
  });
  const theme = useTheme();
  const [map, setMap] = useState<google.maps.Map>();

  const { visibleTableColumns, handleVisibleColumnsChange } = useCustomerVisibleTableColumns();
  const { filters, setFilter } = useCustomersFilters();

  const searchQuery = useClientManager("docs").searchCustomers().useQuery(
    {
      limit: 10000,
      searchText: filters.searchText,
      sort: filters.sort,
      isPro: filters.isPro,
      isAffiliate: filters.isAffiliate,
      tags: filters.tags,
      scannedProducts: filters.scannedProducts,
      regions: filters.regions,
      isUnassigned: filters.isUnassigned,
      categories: filters.categories,
      status: filters.status,
      relation: filters.relation,
      isActive: filters.isActive,
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  return (
    <div
      css={{
        position: "relative",
        height: isMobile ? "calc(100dvh - 26px)" : "100vh",
        maxHeight: "100dvh",
        overflow: "hidden",
        marginBottom: isMobile ? "-26px" : 0,
      }}
    >
      <div
        css={{
          position: "absolute",
          zIndex: 5,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "0.75rem",
          flexWrap: "wrap",
          justifyContent: "right",
          top: 0,
        }}
      >
        <CustomersGroupFilters
          groupFilter={filters.groupFilter}
          onGroupFilterChange={setFilter("groupFilter")}
          visibleTableColumnsConfig={visibleTableColumns}
          onVisibleTableColumnsConfigChange={handleVisibleColumnsChange}
        />
        <div
          css={{
            display: "flex",
            gap: "0.75rem",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "right",
            marginTop: "1rem",
          }}
        >
          <SearchBox onChange={setFilter("searchText")} value={filters.searchText} css={{ width: "100%", flex: "1" }} />
          <Button
            css={{ background: "white", color: theme.colors.primary, padding: "0.325rem 0.625rem" }}
            icon={<MdList />}
            onClick={() => navigate(location.pathname.replace("/map", "") + location.search)}
          />
        </div>
      </div>

      <div css={{ position: "absolute", top: "8.5rem", left: "1rem", zIndex: 5, width: "calc(100% - 2rem)" }}>
        <CustomersFilters
          filters={filters}
          handlers={{
            onSortChange: setFilter("sort"),
            onProChange: setFilter("isPro"),
            onAffiliateChange: setFilter("isAffiliate"),
            onUnassignedChange: setFilter("isUnassigned"),
            onScannedProductsChange: setFilter("scannedProducts"),
            onRegionsChange: setFilter("regions"),
            onTagsChange: setFilter("tags"),
            onStatusChange: setFilter("status"),
            onCategoriesChange: setFilter("categories"),
            onRelationChange: setFilter("relation"),
            onActiveChange: setFilter("isActive"),
          }}
          hideSortFilter
        />
      </div>
      <LoadingBar loading={searchQuery.isFetching} />
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100%", overflow: "hidden" }}
          center={{ lat: 52.0692, lng: 19.4802 }}
          zoom={7}
          options={{
            disableDefaultUI: true,
            streetViewControl: false,
            styles: [
              { elementType: "labels", featureType: "poi.business", stylers: [{ visibility: "off" }] },
              { featureType: "poi", stylers: [{ visibility: "off" }] },
            ],
          }}
          onLoad={(newMap) => {
            setMap(newMap);
          }}
        >
          {!searchQuery.isFetching && searchQuery.data && (
            <CustomerMarkers
              key={JSON.stringify(filters)}
              customers={searchQuery.data.items}
              queryKey={searchQuery.key}
              map={map}
            />
          )}

          <PolygonF path={Szczecin} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Bydgoszcz} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Koszalin} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Gdansk} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Olsztyn} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Lodz} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Warszawa} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Radom} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Lublin} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Czestochowa} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={BielskoBiala} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Krakow} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Wroclaw} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={JeleniaGora} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={ZielonaGora} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Kielce} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Przemysl} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Bialystok} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Poznan} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Warszawa2} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
          <PolygonF path={Poznan2} options={{ fillColor: "#93c47d", fillOpacity: 0 }} visible />
        </GoogleMap>
      )}
    </div>
  );
};
