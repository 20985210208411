import { useTheme } from "@emotion/react";
import { PriceReportDto } from "@megarax/crm-contracts";
import { formatCurrency } from "@megarax/react-utils";
import { formatDistanceToNowStrict } from "date-fns";
import { pl } from "date-fns/locale";
import Decimal from "decimal.js";
import { useState } from "react";

import { Dialog, useDialogRoute } from "@megaron/dash-dialog";
import { Button, DecimalField } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { SectionHeader } from "@megaron/dash-page";
import { SelectDialog } from "@megaron/dash-select";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { RowCellContent, Table, TableBody, TableHeadColumn } from "@megaron/dash-table";
import { useToast } from "@megaron/dash-toast";
import { toDateString } from "@megaron/date-string";
import { useClientManager, useServiceClient } from "@megaron/react-clients";
import { newUuid, Uuid } from "@megaron/uuid";

type Props = {
  customerUuid: Uuid;
  date: Date;
};

export const CustomerReports: React.FC<Props> = ({ customerUuid, date }) => {
  const theme = useTheme();

  const toast = useToast();

  const customerReportsQuery = useClientManager("legacyMegarax")
    .customerReports()
    .useQuery({ customerUuid, date: toDateString(date) });

  const addReportMutation = useClientManager("legacyMegarax").addReport().useMutation();

  const [reportUuid, setReportUuid] = useState(newUuid());

  const submitPriceReport = (input: { subjectUuid: Uuid; netPrice: Decimal }) => {
    addReportMutation.mutate(
      {
        reportUuid,
        customerUuid,
        ...input,
      },
      {
        onSuccess: () => {
          toast.info("Pomyślnie wysłano ankietę cenową.");
          customerReportsQuery.refetch();
          setReportUuid(newUuid());
        },
        onError: () => {
          toast.error("Wystąpił błąd podczas wysyłania ankiety cenowej.");
        },
      },
    );
  };

  const editReportsDialog = useDialogRoute("/ankiety-cenowe", ({ onClose }) => (
    <EditReportsDialog onClose={onClose} onSubmit={submitPriceReport} reports={customerReportsQuery.data} />
  ));

  return (
    <QuerySkeleton query={customerReportsQuery}>
      {(reports) => (
        <div css={{ display: "flex", flexDirection: "column", width: "100%", gap: "1rem" }}>
          <SectionHeader isHr actions={<Button onClick={editReportsDialog.open}>Dodaj</Button>}>
            Ankiety cenowe
          </SectionHeader>
          <span
            css={{
              color: reports && reports.length > 0 ? "black" : theme.colors.secondaryText,
              fontSize: "1rem",
              marginTop: "-0.5rem",
            }}
          >
            {reports && (reports.length ? `Zapisane ceny: ${reports.length}` : `Brak cen`)}
          </span>
          {editReportsDialog.element}
        </div>
      )}
    </QuerySkeleton>
  );
};

const EditReportsDialog: React.FC<{
  onClose: () => void;
  onSubmit: (value: { subjectUuid: Uuid; netPrice: Decimal }) => void;
  reports: PriceReportDto[] | undefined;
}> = ({ onClose, onSubmit, reports }) => {
  const { isMobile } = useDeviceType();

  const legacyMegaraxHttpService = useServiceClient("legacyMegarax");

  const taxRate = new Decimal("1.23");

  const [subjectUuid, setSubjectUuid] = useState<string | undefined>(undefined);
  const [netPrice, setNetPrice] = useState<string | undefined>();
  const [grossPrice, setGrossPrice] = useState<string | undefined>();

  const onNetPriceSet = (newValue: string | undefined) => {
    setNetPrice(newValue);
    setGrossPrice(newValue ? new Decimal(newValue).mul(taxRate).toFixed(2) : undefined);
  };

  const onGrossPriceSet = (newValue: string | undefined) => {
    setGrossPrice(newValue);
    setNetPrice(newValue ? new Decimal(newValue).div(taxRate).toFixed(2) : undefined);
  };

  const submit = () => {
    if (!netPrice || !subjectUuid) return;

    onSubmit({
      netPrice: new Decimal(netPrice),
      subjectUuid: subjectUuid as Uuid,
    });

    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      placement={isMobile ? "top" : "center"}
      css={{
        width: isMobile ? "100%" : "520px",
        height: isMobile ? "60%" : "unset",
      }}
      header="Ankiety cenowe"
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100%",
          overflow: "auto",
        }}
      >
        <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem", width: "100%" }}>
          <SelectDialog
            label="Produkt"
            search={async (searchText) => {
              const result = await legacyMegaraxHttpService.searchSubjects({ searchText, limit: 20, offset: 0 });

              return result.value?.items.map((subject) => ({ label: subject.varietyName, value: subject.uuid })) ?? [];
            }}
            onSelect={(option) => setSubjectUuid(option?.value)}
            variant="single-select"
          />
          <DecimalField
            label="Cena netto [PLN]"
            value={netPrice || ""}
            onChange={(value) => onNetPriceSet(value || undefined)}
          />
          <DecimalField
            label="Cena brutto [PLN]"
            value={grossPrice || ""}
            onChange={(value) => onGrossPriceSet(value || undefined)}
          />
          <Button onClick={submit} css={{ alignSelf: "flex-end" }}>
            Dodaj
          </Button>
        </div>
        <ReportsTable reports={reports ?? []} />
      </div>
    </Dialog>
  );
};

const ReportsTable: React.FC<{ reports: PriceReportDto[] }> = ({ reports }) => {
  const columns = ["Cena netto [PLN]", "Produkt", "Utworzono"];

  const tableColumns: TableHeadColumn<string>[] = [
    ...columns.map(
      (heading) =>
        ({
          isSortable: false,
          label: heading,
        } as const),
    ),
  ];

  const getTableRowCellsContent = (report: PriceReportDto): RowCellContent[] => {
    return [
      {
        isLink: false,
        element: formatCurrency(report.netPrice, "PLN"),
      },
      {
        isLink: false,
        cellCss: { textWrap: "nowrap" },
        element: report.subjectName,
      },

      {
        isLink: false,
        cellCss: { textWrap: "nowrap" },
        element: formatDistanceToNowStrict(report.time, {
          locale: pl,
          addSuffix: true,
        }),
      },
    ];
  };

  return (
    <div css={{ overflow: "auto", flexShrink: 0 }}>
      <Table columns={tableColumns}>
        <TableBody
          rows={reports.map((report) => ({
            uuid: report.uuid,
            cellsContent: getTableRowCellsContent(report),
            css: {
              "td:first-child": {
                padding: "0.75rem",
              },
            },
          }))}
        />
      </Table>
    </div>
  );
};
