import { useTheme } from "@emotion/react";

import { Avatar } from "@megaron/dash-avatar";
import { Page, PageHeader } from "@megaron/dash-page";
import { useProfiles } from "@megaron/megarax-webapps";
import { useClientManager } from "@megaron/react-clients";
import { useRegion } from "@megaron/region-context";

import { MyAffiliateCodes } from "./MyAffiliateCodes";

export const MyAffiliateCodesPage = () => {
  const { region } = useRegion();

  const theme = useTheme();

  const regionQuery = useClientManager("crm")
    .regionsQuery()
    .useQuery({ regionName: region ?? undefined }, { enabled: !!region });

  const { profile } = useProfiles();

  const ownerProfile = regionQuery.data?.items[0].ownerEmail ? profile(regionQuery.data?.items[0].ownerEmail) : null;

  if (!region) {
    return null;
  }

  return (
    <Page
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        maxWidth: "100%",
      }}
    >
      <PageHeader
        actions={
          ownerProfile && (
            <div
              css={{
                display: "flex",
                alignItems: "center",
                gap: "0.375rem",
                fontSize: "1rem",
                color: theme.colors.primary,
              }}
            >
              <Avatar src={ownerProfile.profilePictureUrl} />
              {region ?? "Brak regionu"}
            </div>
          )
        }
      >
        Moje zaproszenia
      </PageHeader>

      <MyAffiliateCodes region={region} />
    </Page>
  );
};
