import { DateString, fromDateString, toDateString, validateDateString } from "@megarax/common";
import { useProfile } from "@megarax/iam-webapp";
import { BreadcrumbMarkers, PageTitleManager } from "@megarax/ui-components";
import { isDefined } from "@megarax/utils";
import { useLocation, useNavigate, useParams } from "react-router-dom-v5-compat";

import { useCustomers } from "@megaron/megarax-v2-resource-hooks";

import { getVisitCalendarEvents } from "./getVisitCalendarEvents";
import { getWeekDates } from "./getWeekDates";
import { getWeekNumber } from "./getWeekNumber";
import { getWorkDayCalendarEvents } from "./getWorkDayCalendarEvents";
import { useUserScheduledTradeRoutes } from "./useUserScheduledTradeRoutes";
import { useOnVisitClose, useVisitNavigation } from "./useVisitNavigation";
import { useWorkWeekDays } from "./useWorkWeekDays";
import { ViewLayout } from "./ViewLayout";
import { WeekCalendar } from "./WeekCalendar";
import { WeekDaySummary } from "./WeekDaySummary";
import { WeekSelect } from "./WeekSelect";
import { WorkTimeReportDialogContainer } from "./WorkTimeReportDialog";
import { useWeekVisits } from "./useWeekVisits";

export const WeekViewContainer = () => {
  const params = useParams();
  const dateString = validateDateString(params.date || "").assertOk().value;
  const userId = Number(params.userId);

  const date = fromDateString(dateString);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onGoToDay = (dateStr: DateString) => navigate(`/crm/pos/handlowcy/${userId}/wizyty/dzien/${dateStr}`);
  const onChangeDateString = (dateStr: DateString) =>
    navigate(`/crm/pos/handlowcy/${userId}/wizyty/tydzien/${dateStr}`);
  const goToTradeRoute = (uuid: string) => navigate(`/crm/pos/trasy/${uuid}`);

  const weekDates = getWeekDates(date);

  const profile = useProfile({ id: userId });

  const { workDays, isLoading: workDaysIsLoading, refetch: reloadWorkDays } = useWorkWeekDays(weekDates, userId);

  const visitsQuery = useWeekVisits(weekDates.map(toDateString), userId, profile?.user.email);

  const { tradeRoutes, isLoading: tradeRoutesIsLoading } = useUserScheduledTradeRoutes(weekDates, userId);

  const customerMap = useCustomers(visitsQuery.visits.map((v) => v.customerUuid));

  const { closeVisit, openVisit } = useVisitNavigation();
  const visitEvents = getVisitCalendarEvents(visitsQuery.visits, customerMap, openVisit);

  const workTimeEvents = workDays.filter(isDefined).flatMap(getWorkDayCalendarEvents);

  const openWorkTimeReportDialog = () => navigate(`${pathname}/zestawienie_czasu_pracy`);
  const isWorkTimeReportDialogOpen = pathname.endsWith("zestawienie_czasu_pracy");

  useOnVisitClose(() => {
    reloadWorkDays();
    visitsQuery.reload();
  });

  const [year, weekNumber] = getWeekNumber(date);

  if (!userId) {
    return null;
  }

  return (
    <>
      <PageTitleManager title={"Kalendarz"} />
      <BreadcrumbMarkers
        breadcrumbs={[
          { title: "Handlowcy", to: "/crm/pos/handlowcy", id: "salespeople" },
          { title: profile?.displayedName ?? "?", to: `/crm/pos/handlowcy/${userId}`, id: "salesperson" },
          { title: `${year}-W${weekNumber}`, id: "visitWeek" },
        ]}
      />
      {isWorkTimeReportDialogOpen && <WorkTimeReportDialogContainer onClose={() => navigate(-1)} userId={userId} />}

      <ViewLayout
        dateSelect={<WeekSelect date={date} onChange={(date) => onChangeDateString(toDateString(date))} />}
        isLoading={workDaysIsLoading || visitsQuery.isLoading || tradeRoutesIsLoading}
        calendar={
          <WeekCalendar
            events={[...visitEvents, ...workTimeEvents]}
            date={date}
            onOpenReport={openWorkTimeReportDialog}
            renderDaySummary={(date) => (
              <WeekDaySummary
                onGoToDay={onGoToDay}
                goToTradeRoute={goToTradeRoute}
                workDay={workDays?.find((w) => w?.date === date)}
                date={date}
                tradeRoute={tradeRoutes.find((route) => route?.date === date) ?? undefined}
              />
            )}
          />
        }
        closeVisit={closeVisit}
      />
    </>
  );
};
