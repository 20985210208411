import { useTheme } from "@emotion/react";
import { formatCurrency } from "@megarax/react-utils";
import { Link } from "react-router-dom-v5-compat";

import { Tile, TileStripe } from "@megaron/dash-tile";
import { OrderDoc } from "@megaron/docs-contracts";

import { getBackgroundColor, getTextColor } from "./helpers";
import { OrderStatus } from "./OrderStatus";

type Props = {
  order: OrderDoc;
};

export const OrderTile: React.FC<Props> = ({ order }) => {
  const theme = useTheme();

  const stripeColor = order.type === "order" ? getBackgroundColor(order.status, theme) : theme.colors.danger;
  const textColor = order.type === "order" ? getTextColor(order.status, theme) : theme.colors.danger;

  return (
    <Link to={`/crm/orders/${order.id}`}>
      <Tile stripe={<TileStripe color={stripeColor} />}>
        <div css={{ display: "flex", flexDirection: "column", gap: "0.325rem", fontSize: "0.875rem" }}>
          <div css={{ display: "flex", alignItems: "center", gap: "0.5rem", justifyContent: "space-between" }}>
            <h3 css={{ color: textColor, margin: 0, fontSize: "0.9375rem" }}>
              <strong>{order.referenceNumber}</strong> - {order.sellToName}
            </h3>
            <span css={{ fontSize: "0.75rem", color: theme.colors.secondaryText }}>{order.orderDate}</span>
          </div>
          <div css={{ display: "flex", alignItems: "center", gap: "0.5rem", color: theme.colors.primary }}>
            {/* <span>{formatCurrency(order.profitMargin, order.currency ?? "PLN")}</span> */}
          </div>
          <div css={{ display: "flex", alignItems: "center", gap: "0.5rem", justifyContent: "space-between" }}>
            <strong css={{ color: theme.colors.primary }}>
              {formatCurrency(order.netTotal, order.currency ?? "PLN")}
            </strong>
            <span css={{ color: theme.colors.secondaryText }}>
              <OrderStatus status={order.status} />
            </span>
          </div>
        </div>
      </Tile>
    </Link>
  );
};
