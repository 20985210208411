import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { PayerDetail, ProposalDetail } from "../payer";

export const payerDetailSerializer = Serializers.object<PayerDetail>({
  baseDiscount: Serializers.decimal,
  customerUuid: Serializers.string,
  modifiedAt: Serializers.datetime,
  priceList: Serializers.object({
    uuid: Serializers.string,
  }).nullable(),
  otherTerms: Serializers.string,
  proposals: Serializers.array(
    Serializers.object<ProposalDetail>({
      baseDiscount: Serializers.decimal,
      priceList: Serializers.object({
        uuid: Serializers.string,
      }).nullable(),
      proposedBy: Serializers.object({ id: Serializers.integer }),
      uuid: Serializers.string,
      otherTerms: Serializers.string,
      proposedAt: Serializers.datetime,
    }),
  ),
});

export const payerActions = {
  payerBulkQuery: HttpAction({
    path: "/pricing/payers/payerBulkQuery",
    method: "get",
    qsSerializer: Serializers.object({
      customerUuids: Serializers.array(uuidSerializer).optional(),
    }),
    valueSerializer: payerDetailSerializer.array(),
    requiresAuth: true,
  }),
  retrievePayer: HttpAction({
    path: "/pricing/payers/:customerUuid",
    method: "get",
    requiresAuth: true,
    paramsSerializer: Serializers.object({ customerUuid: uuidSerializer }),
    valueSerializer: payerDetailSerializer,
  }),

  proposePayerRuleChange: HttpAction({
    path: "/pricing/payers/:customerUuid/proposals",
    method: "post",
    paramsSerializer: Serializers.object({ customerUuid: uuidSerializer }),
    bodySerializer: Serializers.object({
      baseDiscount: Serializers.decimal,
      otherTerms: Serializers.string,
      priceList: Serializers.object({ uuid: uuidSerializer }).nullable(),
    }),
    requiresAuth: true,
  }),

  approvePayerProposal: HttpAction({
    path: "/pricing/payers/:customerUuid/proposals/:proposalUuid/approve",
    method: "post",
    paramsSerializer: Serializers.object({ customerUuid: uuidSerializer, proposalUuid: uuidSerializer }),
  }),

  rejectPayerProposal: HttpAction({
    path: "/pricing/payers/:customerUuid/proposals/:proposalUuid/reject",
    method: "post",
    paramsSerializer: Serializers.object({ customerUuid: uuidSerializer, proposalUuid: uuidSerializer }),
  }),

  retractPayerProposal: HttpAction({
    path: "/pricing/payers/:customerUuid/proposals/:proposalUuid/retract",
    method: "delete",
    paramsSerializer: Serializers.object({ customerUuid: uuidSerializer, proposalUuid: uuidSerializer }),
  }),
};
