import QRCode from "react-qr-code";
import { useParams } from "react-router-dom-v5-compat";

import { useDeviceType } from "@megaron/dash-mq";
import { HeaderBar, Page } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useClientManager } from "@megaron/react-clients";
import { useRegion } from "@megaron/region-context";

export const MyAffiliateCodePage = () => {
  const { affiliateCodeId } = useParams<{ affiliateCodeId: string }>();

  const { isMobile } = useDeviceType();

  const { region } = useRegion();

  const myAffiliateCodesQuery = useClientManager("loyaltyPoints").myAffiliateCodesQuery().useQuery({ region });

  return (
    <>
      <QuerySkeleton query={myAffiliateCodesQuery} height="58px" width="100%">
        {(myAffiliateCodes) => {
          const affiliateCode = myAffiliateCodes.find((code) => code.id === affiliateCodeId);

          if (!affiliateCode) {
            return null;
          }

          return <HeaderBar header={`Moje zaproszenie - ${affiliateCode?.id}`} />;
        }}
      </QuerySkeleton>

      <Page
        css={{
          display: "flex",
          padding: isMobile ? "83px 1rem" : "83px 2rem",
          flexDirection: "column",
          gap: "1rem",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <QuerySkeleton query={myAffiliateCodesQuery} height="280px" width="280px">
          {(myAffiliateCodes) => {
            const affiliateCode = myAffiliateCodes.find((code) => code.id === affiliateCodeId);

            if (!affiliateCode) {
              return null;
            }

            return (
              <div css={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "32px" }}>
                <span css={{ fontSize: "2rem", fontWeight: 700 }}>24 punkty</span>
                <QRCode value={`https://kod.smig.pl/${affiliateCode.id}`} size={280} />
                <code css={{ fontSize: "2rem", fontWeight: 400, letterSpacing: 1.2 }}>{affiliateCode.id}</code>
              </div>
            );
          }}
        </QuerySkeleton>
      </Page>
    </>
  );
};
