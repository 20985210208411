import { useTheme } from "@emotion/react";

type Props = {
  variant: "full" | "half-full" | "half" | "empty";
  color?: "primary" | "danger";
};

export const ProgressDot: React.FC<Props> = ({ variant, color = "primary" }) => {
  const theme = useTheme();

  const isFull = variant === "full";
  const isHalfFull = variant === "half-full";
  const isHalf = variant === "half";

  return (
    <div
      css={{
        borderRadius: "50%",
        width: "0.75rem",
        height: "0.75rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background:
          color === "primary"
            ? isFull
              ? theme.colors.primary
              : isHalf
              ? "transparent"
              : theme.colors.primaryLight
            : isFull
            ? theme.colors.danger
            : isHalf
            ? "transparent"
            : theme.colors.dangerBackground,
      }}
    >
      {(isHalfFull || isHalf) && (
        <div
          css={{
            width: "50%",
            height: "50%",
            background: color === "primary" ? theme.colors.primary : theme.colors.danger,
            borderRadius: "50%",
          }}
        />
      )}
    </div>
  );
};
