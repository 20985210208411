import { faBox, faLayerGroup, faPallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PackSizeDefaultsDto, TradeItemDto } from "@megarax/crm-contracts";
import { SkeletonLoader } from "@megarax/ui-components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Decimal from "decimal.js";
import { Dictionary } from "lodash";
import React from "react";

import { PackSizeDialogLine } from "./PackSizeDialogContainer";

interface Props {
  packSizesDict: {
    [gtin: string]: PackSizeDefaultsDto | null;
  };
  lines: PackSizeDialogLine[];
  closeDialog: () => void;
  tradeItemsDictionary: Dictionary<TradeItemDto> | undefined;
}

const sumLineColumn =
  (
    lines: PackSizeDialogLine[],
    packSizesDict: {
      [gtin: string]: PackSizeDefaultsDto | null;
    },
  ) =>
  (field: "pallet" | "layer" | "box" | "item") =>
    lines
      .reduce<Decimal>((sum, line) => {
        if (field === "item") return sum.plus(line.quantity);
        const packSize = packSizesDict[line.gtin];
        if (!packSize) return sum;
        const row = packSize[field];
        const rowSize = row ? line.quantity.div(row) : new Decimal(0);
        return sum.plus(rowSize);
      }, new Decimal(0))
      .toDP(2)
      .toNumber();

export const PackSizeDialog: React.FC<Props> = ({ packSizesDict, lines, closeDialog, tradeItemsDictionary }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const sumColumn = sumLineColumn(lines, packSizesDict);

  return (
    <Dialog open={true} onClose={closeDialog} fullScreen={isMobile}>
      <DialogTitle>Współczynniki paletowe</DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  <FontAwesomeIcon icon={faPallet} />
                </TableCell>
                <TableCell>
                  <FontAwesomeIcon icon={faLayerGroup} />
                </TableCell>
                <TableCell>
                  <FontAwesomeIcon icon={faBox} />
                </TableCell>
                <TableCell>szt.</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lines.map((line) => {
                const packSize = packSizesDict[line.gtin];
                return (
                  <TableRow key={line.uuid}>
                    <TableCell>
                      <SkeletonLoader
                        asyncData={tradeItemsDictionary}
                        component={(tradeItemsDictionary) => (
                          <Typography variant="inherit">{tradeItemsDictionary[line.gtin].itemName}</Typography>
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      {packSize?.pallet ? line.quantity.div(packSize.pallet).toDP(2).toNumber() : "-"}
                    </TableCell>
                    <TableCell>
                      {packSize?.layer ? line.quantity.div(packSize.layer).toDP(2).toNumber() : "-"}
                    </TableCell>
                    <TableCell>{packSize?.box ? line.quantity.div(packSize.box).toDP(2).toNumber() : "-"}</TableCell>
                    <TableCell>{line.quantity.toNumber()}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell>Razem</TableCell>
                <TableCell>{sumColumn("pallet")}</TableCell>
                <TableCell>{sumColumn("layer")}</TableCell>
                <TableCell>{sumColumn("box")}</TableCell>
                <TableCell>{sumColumn("item")}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Zamknij</Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    content: {
      width: "400px",
      [theme.breakpoints.down("sm")]: {
        padding: "0",
        width: "100%",
        marginTop: theme.spacing(4),
      },
    },
    table: {
      overflow: "auto",
    },
  };
});
