import { ProgressDot } from "./ProgressDot";

type Props = {
  singleDotValue: number;
  goalValue?: number;
  currentValue: number;
  color?: "primary" | "danger";
  allowWrap?: boolean;
};

export const DottedProgress: React.FC<Props> = ({
  singleDotValue,
  goalValue,
  currentValue,
  color = "primary",
  allowWrap,
}) => {
  const remainder = currentValue % singleDotValue;
  const fullDotsCount =
    remainder > (singleDotValue / 4) * 3
      ? Math.ceil(currentValue / singleDotValue)
      : Math.floor(currentValue / singleDotValue);
  const emptyDotsCount = goalValue ? Math.floor((goalValue - currentValue) / singleDotValue) : 0;
  const isHalfFullDot = remainder > singleDotValue / 4 && remainder < (singleDotValue / 4) * 3;

  return (
    <div css={{ display: "flex", alignItems: "center", gap: "3px", flexWrap: allowWrap ? "wrap" : "nowrap" }}>
      {Array.from({ length: fullDotsCount }, (_, index) => index).map((index) => (
        <ProgressDot variant="full" key={`full-dot-${index}`} color={color} />
      ))}
      {isHalfFullDot && <ProgressDot variant={goalValue ? "half-full" : "half"} color={color} />}
      {Array.from({ length: emptyDotsCount }, (_, index) => index).map((index) => (
        <ProgressDot variant="empty" key={`empty-dot-${index}`} color={color} />
      ))}
    </div>
  );
};
