import { useTheme } from "@emotion/react";
import React from "react";

type ValueProps = {
  value: string;
  onChange: (value: string) => void;
  isNullable?: boolean;
};

type Props = ValueProps & {
  label?: string;
  className?: string;
  enableNegativeValues?: boolean;
};

export const DecimalField: React.FC<Props> = (props) => {
  const theme = useTheme();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(",", ".");
    const regex = props.enableNegativeValues ? /^-?\d*\.?\d{0,2}$/ : /^\d*\.?\d{0,2}$/;

    if (regex.test(sanitizedValue) || sanitizedValue === "") {
      props.onChange(sanitizedValue);
    }
  };

  return (
    <div className={props.className}>
      {props.label && (
        <label
          css={{
            color: theme.colors.primary,
            fontSize: "0.875rem",
            fontFamily: theme.displayFontFamily,
            marginBottom: "0.25rem",
          }}
        >
          {props.label}
        </label>
      )}
      <input
        css={{
          width: "100%",
          border: "none",
          padding: "0.5rem 0.75rem",
          fontSize: "1rem",
          borderRadius: "6px",
          backgroundColor: theme.colors.primaryLight,
          ":focus": {
            outline: `1px solid ${theme.colors.primary}`,
          },
          disabled: {
            cursor: "text",
          },
        }}
        type="text"
        inputMode="decimal"
        // eslint-disable-next-line no-useless-escape
        pattern={props.enableNegativeValues ? `-?\d*\,?\d{0,2}` : `\d*\,?\d{0,2}`}
        value={props.value.replace(".", ",")}
        onChange={handleChange}
      />
    </div>
  );
};
