import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const shoperStatuses = ["unplaced", "pending", "inProgress", "shipped", "cancelled"] as const;
export type ShoperStatus = (typeof shoperStatuses)[number];
export const ShoperStatusSerializer = Serializers.oneOf(...shoperStatuses);

export const deliveryAddressSerializer = Serializers.object({
  firstName: Serializers.string,
  lastName: Serializers.string,
  street: Serializers.string,
  locality: Serializers.string,
  postalCode: Serializers.string,
  phoneNumber: Serializers.string,
});
export type DeliveryAddress = SerializerValue<typeof deliveryAddressSerializer>;

export const shoperFulfillmentDtoSerializer = Serializers.object({
  type: Serializers.stringOneOf("shoper"),
  uuid: uuidSerializer,
  status: ShoperStatusSerializer,
  isFailed: Serializers.boolean,
  isFulfilled: Serializers.boolean,
  email: Serializers.string.nullable(),
  deliveryAddress: deliveryAddressSerializer.nullable(),
  productOption: Serializers.string.nullable(),
  shoperOrderId: Serializers.integer.nullable(),
  customizationText: Serializers.string.nullable(),
});

export type ShoperFulfillmentDto = SerializerValue<typeof shoperFulfillmentDtoSerializer>;

export const myShoperFulfillmentDtoSerializer = Serializers.object({
  type: Serializers.stringOneOf("shoper"),
  uuid: uuidSerializer,
  status: ShoperStatusSerializer,
  isFulfilled: Serializers.boolean,
  isFailed: Serializers.boolean,
  deliveryAddress: deliveryAddressSerializer.nullable(),
  productOption: Serializers.string.nullable(),
  shoperOrderId: Serializers.integer.nullable(),
  customizationText: Serializers.string.nullable(),
});

export type MyShoperFulfillmentDto = SerializerValue<typeof myShoperFulfillmentDtoSerializer>;

export const shoperActions = {
  placeShoperOrder: HttpAction({
    path: "/shoperFulfillments/uuid/:fulfillmentUuid/placeOrder",
    method: "post",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      fulfillmentUuid: uuidSerializer,
    }),
    bodySerializer: Serializers.object({
      deliveryAddress: deliveryAddressSerializer,
      productOption: Serializers.string.optional(),
      email: Serializers.string,
      customizationText: Serializers.string.nullable(),
    }),
    errorSerializer: Serializers.stringOneOf(
      "UnknownProductOption",
      "FulfillmentNotFound",
      "AlreadyPlaced",
      "FulfillmentCancelled",
    ),
  }),
  cancelShoperFulfillment: HttpAction({
    path: "/shoperFulfillments/uuid/:fulfillmentUuid/cancel",
    method: "post",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      fulfillmentUuid: uuidSerializer,
    }),
    errorSerializer: Serializers.stringOneOf("FulfillmentNotFound", "FulfillmentAlreadyCancelled"),
  }),
  refreshShoperStatuses: HttpAction({
    path: "/shoperFulfillments/refreshStatuses",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({}),
  }),
  editShoperFulfillment: HttpAction({
    path: "/shoperFulfillments/uuid/:fulfillmentUuid",
    method: "put",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      fulfillmentUuid: uuidSerializer,
    }),
    bodySerializer: Serializers.object<{
      deliveryAddress: DeliveryAddress;
      email: string;
      productOption?: string;
      customizationText: string | null;
      shoperOrderId?: number;
    }>({
      deliveryAddress: deliveryAddressSerializer,
      email: Serializers.string,
      productOption: Serializers.string.optional(),
      shoperOrderId: Serializers.integer.optional(),
      customizationText: Serializers.string.nullable(),
    }),
    errorSerializer: Serializers.stringOneOf(
      "FulfillmentNotFound",
      "FulfillmentCancelled",
      "FulfillmentCancelled",
      "UnknownProductOption",
    ),
  }),
  resendShoperOrder: HttpAction({
    path: "/shoperFulfillments/uuid/:fulfillmentUuid/resendOrder",
    method: "post",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      fulfillmentUuid: uuidSerializer,
    }),
    errorSerializer: Serializers.object({
      message: Serializers.string,
      error: Serializers.identity<any>(),
      fulfillmentUuid: uuidSerializer,
    }),
  }),
};
