import { Currency, OrderDraftLineDto, TradeItemDto } from "@megarax/crm-contracts";
import { formatCurrency } from "@megarax/react-utils";
import { SkeletonLoader } from "@megarax/ui-components";
import { Box, Chip, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Decimal from "decimal.js";
import { Dictionary } from "lodash";
import React from "react";

const getLinesNetTotal = (lines: OrderDraftLineDto[]) =>
  lines.reduce<Decimal>((total, line) => total.plus(line.lineNetTotal), new Decimal(0));

interface Props {
  tradeItemsDictionary: Dictionary<TradeItemDto> | undefined;
  lines: OrderDraftLineDto[];
  openEditItemDialog: (lineUuid: string) => void;
  currency: Currency;
}

export const LinesTable: React.FC<Props> = ({ tradeItemsDictionary, lines, openEditItemDialog, currency }) => {
  const classes = useStyles();

  return (
    <div className={classes.table}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Przedmiot</TableCell>
            <TableCell align="right">Ilość</TableCell>
            <TableCell align="right">Wartość netto</TableCell>
          </TableRow>
        </TableHead>
        {lines.length > 0 ? (
          <>
            <TableBody>
              {lines.map((line) => {
                const isRwPromo = Boolean(line.promoPrice?.eq(0));
                const is1PlnPromo = Boolean(line.promoPrice?.eq(1));
                return (
                  <TableRow
                    hover
                    onClick={() => openEditItemDialog(line.uuid)}
                    className={classes.pointer}
                    key={line.uuid}
                  >
                    <TableCell>
                      <SkeletonLoader
                        asyncData={tradeItemsDictionary}
                        component={(tradeItemsDictionary) => (
                          <Box display="flex" flexWrap="nowrap">
                            <Typography variant="inherit">{tradeItemsDictionary[line.item.gtin].itemName}</Typography>
                            {is1PlnPromo && (
                              <Chip
                                className={classes.promoChip}
                                label="1zł"
                                variant="outlined"
                                size="small"
                                color="primary"
                              />
                            )}
                            {isRwPromo && (
                              <Chip
                                className={classes.promoChip}
                                label="RW"
                                variant="outlined"
                                size="small"
                                color="primary"
                              />
                            )}
                          </Box>
                        )}
                      />
                    </TableCell>
                    <TableCell align="right">{line.quantity}</TableCell>
                    <TableCell align="right">{formatCurrency(line.lineNetTotal.toNumber(), currency)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2}>Razem</TableCell>
                <TableCell align="right">{formatCurrency(getLinesNetTotal(lines).toNumber(), currency)}</TableCell>
              </TableRow>
            </TableFooter>
          </>
        ) : (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={3} align="center">
                Brak
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    pointer: {
      cursor: "pointer",
    },
    table: {
      overflowX: "auto",
    },
    promoChip: {
      marginLeft: theme.spacing(1),
    },
  };
});
