import { useState } from "react";
import { MdOutlineCheck } from "react-icons/md";

import { InteractionType, interactionTypes } from "@megaron/crm-contracts";
import { Dialog } from "@megaron/dash-dialog";
import { Button, TextField } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { SelectDialog } from "@megaron/dash-select";

import { AnalyticsPreset } from "./analyticsPreset";
import { TimeRange, timeRangeOptions } from "./SalesAggDialog";

type Props = {
  onClose: () => void;
  aggName?: string;
  analyticsPreset?: AnalyticsPreset;
};

export const InteractionsAggDialog: React.FC<Props> = ({ onClose, aggName, analyticsPreset }) => {
  const { isMobile } = useDeviceType();

  const agg = analyticsPreset?.aggsOptions.find((agg) => agg.aggName === aggName);

  const [name, setName] = useState(agg?.name ?? "");
  const [timeRange, setTimeRange] = useState<TimeRange>("default");
  const [types, setTypes] = useState<InteractionType[]>(agg?.type === "interactions" ? agg?.interactionType || [] : []);

  return (
    <Dialog
      onClose={onClose}
      placement={isMobile ? "top" : "center"}
      css={{ width: isMobile ? "100%" : "386px" }}
      header="Nowy zestaw danych"
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
        <TextField label="Nazwa" value={name} onChange={setName} />
        <SelectDialog
          label="Okres"
          variant="single-select"
          options={timeRangeOptions}
          initiallySelectedValues={timeRange ? [timeRange] : []}
          onSelect={(option) => setTimeRange(option?.value as TimeRange)}
        />
        <SelectDialog
          label="Typy interakcji"
          variant="multi-select"
          options={interactionTypes.map((type) => ({ label: interactionTypeNameMap[type], value: type }))}
          initiallySelectedValues={types}
          onSelectedChange={(options) => setTypes(options.map((option) => option.value as InteractionType))}
        />
        <Button
          onClick={() => {
            onClose();
          }}
          icon={<MdOutlineCheck />}
          css={{ marginLeft: "auto", marginTop: "0.25rem" }}
        >
          Zatwierdź
        </Button>
      </div>
    </Dialog>
  );
};

const interactionTypeNameMap = {
  call: "telefon",
  visit: "wizyta",
  hotline: "infolinia",
  training: "szkolenie",
  other: "inna",
};
