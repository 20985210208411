import { Uuid, uuidSerializer } from "@megarax/common";
import { Serializers } from "@megarax/serializers";

import { Gtin, gtinSerializer } from "../common";

export interface TradeItemDto {
  gtin: Gtin;
  itemName: string;
}

export const tradeItemDtoSerializer = Serializers.object<TradeItemDto>({
  gtin: gtinSerializer,
  itemName: Serializers.string,
});
