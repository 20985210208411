import { useState } from "react";

import { OrderLineDto } from "@megaron/crm-contracts";
import { Dialog } from "@megaron/dash-dialog";
import { useDeviceType } from "@megaron/dash-mq";
import { DocsSelectDialog } from "@megaron/dash-select";
import { ItemDoc } from "@megaron/docs-contracts";

import { ItemDialogContent } from "./ItemDialogContent";

type Props = {
  onClose: () => void;
  onAddLine: (line: OrderLineDto) => void;
};

export const AddItemDialog: React.FC<Props> = ({ onClose, onAddLine }) => {
  const { isMobile } = useDeviceType();

  const [item, setItem] = useState<ItemDoc | undefined>(undefined);

  return (
    <Dialog
      onClose={onClose}
      placement={isMobile ? "top" : "center"}
      css={{ width: isMobile ? "100%" : "400px" }}
      header="Dodaj przedmiot"
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <DocsSelectDialog
          variant="single-select"
          label="Jednostka handlowa"
          docType={["item"]}
          onSelect={(item) => setItem(item as ItemDoc)}
          initiallySelectedDocIds={item?.docId ? [item.docId] : []}
        />

        <hr
          css={{
            margin: 0,
            width: "100%",
            height: "1px",
            background: "rgba(0, 0, 0, 0.1)",
            border: "none",
          }}
        />

        <ItemDialogContent item={item} onSave={onAddLine} />
      </div>
    </Dialog>
  );
};
