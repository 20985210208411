import { ediConnectorOrderResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { useAsyncLoad } from "@megarax/react-utils";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";
import { Container } from "@mui/material";

import { EdiErrorAlert } from "./EdiErrorAlert";

interface Props {
  onEdiChanged: () => void;
  children?: React.ReactNode;
  isVertical?: boolean;
}

export const EdiAlertsContainer: React.FunctionComponent<Props> = ({ children, onEdiChanged, isVertical }) => {
  const ediOrderProvider = useResourceProviderV2(ediConnectorOrderResource);
  const { value: documentErrors, reload } = useAsyncLoad(ediOrderProvider.documentErrors, []);
  const snackbarErrorHandler = useSnackbarErrorHandler({
    ...commonErrorsMap,
    DocumentNotFound: "Nie znaleziono dokumentu.",
    DocumentSkipped: "Dokument został już usunięty.",
    DocumentNotFailed: "Dokuement został już poprawnie zaimportowany.",
    RecipientGlnNotFound: "Nieznany odbiorca.",
    BuyerGlnNotFound: "Nieznany kupujący.",
    UnableToParseDocument: "Nie udało się odczytać dokumentu.",
  });

  const skipDocument = (orderUuid: string) => ediOrderProvider.byOrderUuid(orderUuid).skip().then(reload);

  const retryDocument = (orderUuid: string) =>
    ediOrderProvider.byOrderUuid(orderUuid).retry().map(reload).map(onEdiChanged).mapError(snackbarErrorHandler);

  if (documentErrors === undefined || documentErrors.isFailure) return null;

  return (
    <Container maxWidth="md">
      {documentErrors.value.map((docError) => (
        <EdiErrorAlert
          key={docError.orderUuid}
          documentError={docError}
          skipDocument={skipDocument}
          retryDocument={retryDocument}
          isVertical={isVertical}
        />
      ))}
    </Container>
  );
};
