import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

const priceListSimpleSerializer = Serializers.first(
  Serializers.object({
    name: Serializers.string,
    uuid: uuidSerializer,
    awaitingProposalCount: Serializers.integer,
    modifiedAt: Serializers.datetime,
  }),
);

export type PriceListSimple = SerializerValue<typeof priceListSimpleSerializer>;

export const currencies = ["PLN", "EUR", "USD", "GBP"] as const;

const priceListDetailSerializer = Serializers.object({
  name: Serializers.string,
  uuid: uuidSerializer,
  currentPrices: Serializers.array(
    Serializers.object({
      gtin: Serializers.string,
      activeFrom: Serializers.datetime,
      netPrice: Serializers.decimal,
    }),
  ),
  currency: Serializers.stringOneOf(...currencies),
});

export type PriceListDetail = SerializerValue<typeof priceListDetailSerializer>;

export const priceListActions = {
  priceLists: HttpAction({
    path: "/pricing/priceLists",
    method: "get",
    qsSerializer: Serializers.object({
      limit: Serializers.integer.optional(),
      offset: Serializers.integer.optional(),
      searchText: Serializers.string.optional(),
      modifiedSince: Serializers.datetime.optional(),
    }).partial(),
    valueSerializer: Serializers.paginatedList(priceListSimpleSerializer),
    requiresAuth: true,
  }),
  priceList: HttpAction({
    path: "/pricing/priceLists/:priceListUuid",
    method: "get",
    paramsSerializer: Serializers.object({
      priceListUuid: Serializers.string,
    }),
    valueSerializer: priceListDetailSerializer,
    requiresAuth: true,
  }),
};
