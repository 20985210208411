import { useTheme } from "@emotion/react";
import { useLayoutEffect, useState } from "react";
import { useNavigate, useParams as useParamsCompat, useSearchParams } from "react-router-dom-v5-compat";

import { CustomerDetails } from "@megaron/crm-contracts";
import { useDeviceType } from "@megaron/dash-mq";
import { HeaderBar, Page } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { ManagerUseQueryReturn, useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { InteractionHistory } from "../interactions/InteractionHistory";
import { CustomerProfile } from "./CustomerProfile";
import { CustomerRelations } from "./CustomerRelations";
import { CustomerTabs, getActiveTab, historyTab, profileTab, relationsTab, saleTab } from "./CustomerTabs";
import { SaleSection } from "./SaleSection";
import { UnsavedChangesAlert } from "./UnsavedChangesAlert";
import { UpdateStatusTile } from "./UpdateStatusTile";
import { useCustomerUpcomingVisits } from "./useCustomerUpcomingVisits";

export type UpdateStatus = "isLoading" | "success" | "error" | undefined;

export type CustomerQuery = ManagerUseQueryReturn<CustomerDetails, "CustomerNotFound" | "UserNotAuthorized" | unknown>;

export const CustomerPage: React.FC = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { customerId }: { customerId?: Uuid } = useParamsCompat();

  if (!customerId) {
    return <div>Error: Customer ID not provided.</div>;
  }

  return <CustomerPageContent customerId={customerId} key={customerId} />;
};

const CustomerPageContent: React.FC<{ customerId: Uuid }> = ({ customerId }) => {
  const { isMobile, isDesktop } = useDeviceType();

  const [showAlertOnTabChange, setShowAlertOnTabChange] = useState(false);
  const [isUnsavedChangesDialogOpen, setIsUnsavedChangesDialogOpen] = useState(false);

  const [updateStatus, setUpdateStatus] = useState<UpdateStatus>(undefined);

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const theme = useTheme();

  const { customerUpcomingVisits, user } = useCustomerUpcomingVisits(customerId);

  const customerQuery = useClientManager("crm").getCustomer().useQuery({ customerId });

  function getFullName(customer: { phoneNumber: string; firstName: string; lastName: string; name: string }) {
    if (customer.name) {
      return customer.name;
    }

    const fullName =
      customer?.firstName && customer.lastName
        ? `${customer?.firstName} ${customer?.lastName}`
        : customer?.phoneNumber || "";

    return fullName.trim();
  }

  const { activeTab } = getActiveTab(searchParams);

  return (
    <>
      <QuerySkeleton query={customerQuery} height="58px" width="100%">
        {(customer) => (
          <>
            <HeaderBar
              header={getFullName(customer)}
              css={isMobile ? { boxShadow: "none", background: theme.colors.primary } : {}}
              onGoBack={() => {
                if (showAlertOnTabChange) {
                  setIsUnsavedChangesDialogOpen(true);
                  return;
                }

                navigate(-1);
              }}
              actions={<UpdateStatusTile status={updateStatus} />}
            />
            {isMobile && (
              <CustomerTabs
                customerId={customerId}
                css={{ position: "fixed", top: "53px", left: 0, right: 0, zIndex: 1000 }}
                showAlertOnTabChange={showAlertOnTabChange}
                onTabChange={() => setShowAlertOnTabChange(false)}
              />
            )}
          </>
        )}
      </QuerySkeleton>

      <Page
        css={{
          display: "flex",
          gap: isMobile ? 0 : "2rem",
          flexDirection: isMobile ? "column" : "row",
          marginBottom: "2rem",
          marginTop: isMobile ? "134px" : "83px",
          padding: isMobile ? "0 1rem" : "0 2rem",
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: isMobile ? "100%" : `calc(100% - ${isDesktop ? "380px" : "20rem"} - 2rem)`,
          }}
        >
          {(!isMobile || (isMobile && activeTab === profileTab)) && (
            <CustomerProfile customerId={customerId} customerQuery={customerQuery} onStatusChange={setUpdateStatus} />
          )}
          {(!isMobile || (isMobile && activeTab === historyTab)) && (
            <InteractionHistory upcomingVisits={customerUpcomingVisits} user={user} />
          )}
        </div>

        <div
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            width: isDesktop ? "380px" : isMobile ? "100%" : "20rem",
            flexShrink: 0,
          }}
        >
          {(!isMobile || (isMobile && activeTab === saleTab)) && (
            <QuerySkeleton query={customerQuery}>{(customer) => <SaleSection customer={customer} />}</QuerySkeleton>
          )}
          {(!isMobile || (isMobile && activeTab === relationsTab)) && (
            <CustomerRelations customerQuery={customerQuery} />
          )}
        </div>
        {isUnsavedChangesDialogOpen && (
          <UnsavedChangesAlert onContinue={() => navigate(-1)} onClose={() => setIsUnsavedChangesDialogOpen(false)} />
        )}
      </Page>
    </>
  );
};
