import { useTheme } from "@emotion/react";

import { RowCellContent, Table, TableBody, TableHeadColumn } from "@megaron/dash-table";

import { ImportedCustomer } from "../addCustomer/CustomerDetails";

type Props = {
  importedCustomers: ImportedCustomer[];
};

export const ImportedCustomersTable: React.FC<Props> = ({ importedCustomers }) => {
  const theme = useTheme();

  const tableColumns: TableHeadColumn<string>[] = [
    {},
    ...tableHeadingsList.map(
      (heading) =>
        ({
          isSortable: false,
          label: heading,
        } as const),
    ),
  ];

  const getTableRowCellsContent = (customer: ImportedCustomer): RowCellContent[] => {
    return [
      {
        isLink: true,
        element: (
          <div
            css={{
              position: "absolute",
              inset: 0,
              background: customer.error ? theme.colors.dangerBackground : theme.colors.primaryLight,
            }}
          ></div>
        ),
      },
      {
        isLink: true,
        cellCss: { color: customer.error ? theme.colors.danger : theme.colors.primary, fontWeight: 700 },
        element: customer.phoneNumber,
      },
      {
        isLink: true,
        element: customer.firstName,
      },
      {
        isLink: true,
        element: customer.lastName,
      },
      {
        isLink: true,
        element: customer.email,
      },

      {
        isLink: true,
        element: customer.region,
      },
      {
        isLink: true,
        cellCss: { color: theme.colors.danger },
        element: customer.error ?? "",
      },
    ];
  };

  return (
    <div css={{ overflow: "auto", margin: "-1rem -2rem", padding: "1rem 2rem", height: "calc(100% + 2rem)" }}>
      <Table columns={tableColumns}>
        <TableBody
          rows={importedCustomers.map((customer) => ({
            uuid: customer.uuid,
            linkTo: `/crm/customers/id/${customer.uuid}`,
            cellsContent: getTableRowCellsContent(customer),
            css: {
              "&:hover": {
                "td:nth-child(1)": {
                  textDecoration: "underline",
                },
              },
            },
          }))}
        />
      </Table>
    </div>
  );
};

const tableHeadingsList = ["Numer telefonu", "Imię", "Nazwisko", "Email", "Region", "Błąd"];
