import { useTheme } from "@emotion/react";
import { useMemo, useState } from "react";
import { IconType } from "react-icons/lib";
import { MdOutline3P, MdOutlineFilter, MdOutlineShoppingCart } from "react-icons/md";
import tinycolor from "tinycolor2";

import { CustomerCategory, CustomerStatus } from "@megaron/crm-contracts";
import { Dialog } from "@megaron/dash-dialog";
import { useDeviceType } from "@megaron/dash-mq";
import { SearchBox } from "@megaron/dash-search";
import { SelectDialog } from "@megaron/dash-select";
import { useClientManager } from "@megaron/react-clients";

import { customerCategories } from "../addCustomer/CustomerCategorySection";
import { customerStatusList } from "../StatusField";
import { InteractionsAggDialog } from "./InteractionsAggDialog";
import { SalesAggDialog } from "./SalesAggDialog";
import { Filters } from "./useCustomerAnalyticsGlobalFilters";

type Props = {
  onClose: () => void;
  filters: Filters;
  handlers: {
    onRegionsChange: (regions: string[] | undefined) => void;
    onCategoriesChange: (categories: CustomerCategory[] | undefined) => void;
    onStatusChange: (status: CustomerStatus[] | undefined) => void;
  };
};

export const CustomerAnalyticsFiltersDialog: React.FC<Props> = ({ onClose, filters, handlers }) => {
  const { isMobile } = useDeviceType();

  const theme = useTheme();

  const [searchText, setSearchText] = useState("");

  const [isAddSalesAggDialogOpen, setAddSalesAggDialogOpen] = useState(false);
  const [isAddInteractionsAggDialogOpen, setAddInteractionsAggDialogOpen] = useState(false);

  const regionsQuery = useClientManager("crm").regionsQuery().useQuery({});

  const regionsOptions = useMemo(
    () =>
      regionsQuery.data
        ? regionsQuery.data.items.map((region) => ({
            label: region.name,
            value: region.name,
          }))
        : [],
    [regionsQuery.data],
  );

  const tiles = useMemo(() => {
    const tiles = [
      {
        label: "Sprzedaż",
        Icon: MdOutlineShoppingCart,
        onClick: () => setAddSalesAggDialogOpen(true),
        type: "tile",
      },
      {
        label: "Interakcje",
        Icon: MdOutline3P,
        onClick: () => setAddInteractionsAggDialogOpen(true),
        type: "tile",
      },
      {
        label: "Regiony",
        initiallySelectedValues: filters.region ?? [],
        options: regionsOptions ?? [],
        onSelectedChange: (options) => handlers.onRegionsChange(options.map((option) => option.value) ?? undefined),
        variant: "multi-select",
        renderTrigger: (_, __, ___, onOpen) => <EntryTile label="Region" Icon={MdOutlineFilter} onClick={onOpen} />,
        type: "select",
      } satisfies React.ComponentProps<typeof SelectDialog> & { type: "select" },
      {
        label: "Kategoria",
        variant: "multi-select",
        initiallySelectedValues: filters.category || [],
        options: customerCategories.map((category) => ({ label: category.label, value: category.value })),
        onSelectedChange: (options) =>
          handlers.onCategoriesChange(options.map((option) => option.value as CustomerCategory) ?? undefined),
        renderTrigger: (_, __, ___, onOpen) => <EntryTile label="Kategoria" Icon={MdOutlineFilter} onClick={onOpen} />,
        type: "select",
      } satisfies React.ComponentProps<typeof SelectDialog> & { type: "select" },
      {
        label: "Status",
        variant: "multi-select",
        initiallySelectedValues: filters.status || [],
        options: customerStatusList,
        onSelectedChange: (options) =>
          handlers.onStatusChange(options.map((option) => option.value as CustomerStatus) ?? undefined),
        renderTrigger: (_, __, ___, onOpen) => <EntryTile label="Status" Icon={MdOutlineFilter} onClick={onOpen} />,
        type: "select",
      } satisfies React.ComponentProps<typeof SelectDialog> & { type: "select" },
    ] as const;

    if (!searchText) {
      return tiles;
    }

    return tiles.filter((tile) => tile.label.toLowerCase().includes(searchText.toLowerCase()));
  }, [filters.category, filters.region, filters.status, handlers, regionsOptions, searchText]);

  return (
    <Dialog
      onClose={onClose}
      header="Wybierz filtr lub type danych"
      placement={isMobile ? "top" : "center"}
      css={{ width: isMobile ? "100%" : "400px" }}
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <SearchBox value={searchText} onChange={setSearchText} />
        <div
          css={{
            height: "1px",
            margin: "0 -1rem",
            background: `${tinycolor(theme.colors.primary).setAlpha(0.1)}`,
          }}
        />
        <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
          {tiles.map((tile) => {
            if (tile.type === "select") {
              return <SelectDialog {...tile} key={`filter-select-${tile.type}-${tile.label}`} />;
            }

            return <EntryTile {...tile} key={`filter-${tile.type}-${tile.label}`} />;
          })}
        </div>
        {isAddSalesAggDialogOpen && <SalesAggDialog onClose={() => setAddSalesAggDialogOpen(false)} />}
        {isAddInteractionsAggDialogOpen && (
          <InteractionsAggDialog onClose={() => setAddInteractionsAggDialogOpen(false)} />
        )}
      </div>
    </Dialog>
  );
};

const EntryTile: React.FC<{ label: string; Icon: IconType; onClick: () => void }> = ({ Icon, label, onClick }) => {
  const theme = useTheme();

  return (
    <button
      css={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        gap: "0.5rem",
        padding: "0.75rem",
        background: "white",
        borderRadius: theme.smallBorderRadius,
        color: theme.colors.primary,
        border: `1px solid rgba(0, 0, 0, 0.1)`,
        cursor: "pointer",
        transition: "all 0.1s",
        ":hover": {
          border: `1px solid ${theme.colors.primary}`,
          fontWeight: 700,
        },
      }}
      onClick={onClick}
    >
      <Icon size={14} />
      <span>{label}</span>
    </button>
  );
};
