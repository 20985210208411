import { MyDraftSimple, orderDraftResource } from "@megarax/crm-contracts";
import { HttpRequestError } from "@megarax/http-client";
import { useIndependentAsyncLoad, useResourceProviderV2 } from "@megarax/react-client";
import { useDepPagination } from "@megarax/react-utils";
import {
  BreadcrumbMarker,
  commonErrorsMap,
  LoadingBar,
  NewItemFab,
  PageTitleManager,
  SearchBar,
  useSnackbarErrorHandler,
} from "@megarax/ui-components";
import { Box, Container, LinearProgress } from "@mui/material";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom-v5-compat";

import { Failure, Ok } from "@megaron/result";

import { DraftsTabContainer } from "./DraftsTab";

interface Props {}

type MyOrderTab = "zlozone" | "wersje_robocze";

const perPage = 20;

export type OrderDraftsResult =
  | Ok<{
      count: number;
      items: MyDraftSimple[];
    }>
  | Failure<HttpRequestError>;

export const MyOrderHomeContainer: React.FC<Props> = (props) => {
  const params = useParams<{ tab: MyOrderTab }>();
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToNew = () => navigate("/crm/pos/moje_zamowienia/nowe");

  const orderDraftProvider = useResourceProviderV2(orderDraftResource);

  const [draftsResult, setDraftsResult] = useState<OrderDraftsResult>();

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
  });

  const { currentPage, searchTerm } = qs.parse(location.search.substring(1));

  const initialValues = {
    search: typeof searchTerm === "string" ? searchTerm : "",
    page: typeof currentPage === "string" && !isNaN(parseInt(currentPage)) ? parseInt(currentPage) : 0,
  };

  const [searchText, setSearchText] = useState<string>(initialValues.search);

  const { paginationProps, page } = useDepPagination(
    {
      perPage,
      allCount: draftsResult?.value?.count,
      initialPage: initialValues.page,
    },
    [searchText, params.tab],
  );

  const { loading: draftsLoading, reload: reloadDrafts } = useIndependentAsyncLoad(
    () =>
      orderDraftProvider
        .list({
          searchText: searchText,
          limit: perPage,
          offset: perPage * page,
        })
        .mapError(handleError),
    setDraftsResult,
    [searchText, page],
  );

  useEffect(() => {
    navigate(
      {
        pathname: location.pathname,

        search: qs.stringify({
          currentPage: page,
          searchTerm: searchText,
        }),
      },
      { replace: true },
    );
  }, [searchText, page]);

  if (draftsResult === undefined) return <LinearProgress />;
  if (draftsResult.isFailure) return null;

  return (
    <Container maxWidth="md" disableGutters>
      <PageTitleManager title="Zamówienia robocze" />
      <BreadcrumbMarker id="myOrders" title="Zamówienia robocze" to={location.pathname} />

      <Box display="flex" p={2} sx={{ gap: "8px" }} justifyContent="space-between" alignItems="center">
        <SearchBar onSearchChange={setSearchText} initialValue={initialValues.search} />
        <NewItemFab text="Nowe" onClick={navigateToNew} />
      </Box>
      <LoadingBar loading={draftsLoading} />
      <DraftsTabContainer pagination={paginationProps} draftsQuery={draftsResult.value} />
    </Container>
  );
};
