import { CSSObject, useTheme } from "@emotion/react";

import { SortableTableCell, SortField } from "./SortableTableCell";

export type TableHeadColumn<TSortField extends string> = {
  isHidden?: boolean;
  cellCss?: CSSObject;
} & ({ isSortable: true; sortFieldName: TSortField } | { isSortable?: false; sortFieldName?: undefined }) &
  ({ label?: string; element?: undefined } | { label: string; element: JSX.Element });

export type TableHeadGroupColumn = { columnsCount: number; cellCss?: CSSObject } & (
  | { label: string; element?: undefined }
  | { label: string; element: JSX.Element }
);

export type TableHeadProps<TSortField extends string> = {
  columns: TableHeadColumn<TSortField>[];
  groupColumns?: TableHeadGroupColumn[];
  onSort?: (value?: SortField<TSortField>) => void;
  activeSortValue?: SortField<TSortField> | undefined;
};

export const TableHead = <TSortField extends string>({
  columns,
  groupColumns,
  activeSortValue,
  onSort,
}: TableHeadProps<TSortField>) => {
  const theme = useTheme();

  return (
    <thead>
      {groupColumns && (
        <tr
          css={{
            textAlign: "left",
            padding: "0.375rem 0",
            fontFamily: theme.displayFontFamily,
          }}
        >
          {groupColumns.map((column, index) => (
            <th
              key={`table-group-heading-${index}-${column.label}`}
              colSpan={column.columnsCount}
              css={{
                color: theme.colors.primary,
                fontWeight: 400,
                whiteSpace: "nowrap",
                padding: "0.5rem 0.75rem",
                fontSize: "0.9375rem",
                ...column.cellCss,
              }}
            >
              {column.element || column.label}
            </th>
          ))}
        </tr>
      )}
      <tr
        css={{
          textAlign: "left",
          padding: "0.375rem 0",
          fontFamily: theme.displayFontFamily,
        }}
      >
        {columns.map((column, index) => {
          if (column.isHidden) {
            return null;
          }

          return column.isSortable ? (
            <SortableTableCell
              {...column}
              key={`table-heading-${index}-${column.label}`}
              activeSortValue={activeSortValue}
              onSortChange={onSort || (() => null)}
            />
          ) : (
            <th
              key={`table-heading-${index}-${column.label}`}
              css={{
                color: theme.colors.primary,
                fontWeight: 400,
                whiteSpace: "nowrap",
                padding: "0.5rem 0.75rem",
                fontSize: "0.9375rem",
                ...column.cellCss,
              }}
            >
              {column.element || column.label}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};
