import { useTheme } from "@emotion/react";
import * as Popover from "@radix-ui/react-popover";
import { useRef, useState } from "react";

import { SelectListElement } from "@megaron/dash-select";

import { FlagFilter } from "./FlagFilter";

type Props<TOptionValue> = {
  className?: string;
  label: string;
  value?: TOptionValue;
  options: {
    label: string;
    value: TOptionValue;
  }[];
  onChange: (value: TOptionValue | undefined) => void;
};

export const SingleSelectSimpleFilter = <TOptionValue,>(props: Props<TOptionValue>) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const selectedOption = props.options.find((o) => o.value === props.value);
  const isSelected = !!props.value;
  const label = isSelected && selectedOption ? `${props.label}: ${selectedOption.label}` : props.label;

  const handleOptionClick = (value: TOptionValue) => {
    if (props.value === value) {
      props.onChange(undefined);
    } else {
      props.onChange(value);
    }
    setIsOpen(false);
  };

  return (
    <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
      <Popover.Trigger css={{ border: "none", padding: 0, background: "none" }} ref={triggerRef}>
        <FlagFilter
          isActive={isSelected}
          label={label}
          onClick={() => {
            setIsOpen(true);
          }}
          onDelete={() => {
            props.onChange(undefined);
            setIsOpen(false);
          }}
          className={props.className}
        />
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content>
          <div
            css={{
              margin: "0.25rem",
              display: "flex",
              flexDirection: "column",
              gap: "0.25rem",
              minWidth: `max(${triggerRef.current?.clientWidth}px, 12rem)`,
              padding: "0.5rem",
              borderRadius: theme.smallBorderRadius,
              background: theme.colors.background,
              boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
            }}
          >
            {props.options.map((option) => (
              <SelectListElement
                label={option.label}
                value={option.value}
                onClick={handleOptionClick}
                isSelected={props.value === option.value}
                key={option.label}
                showBorder
              />
            ))}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
