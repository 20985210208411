import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const postSaleDiscountSerialzier = Serializers.object({
  customerId: Serializers.string,
  customerName: Serializers.string.nullable(),
  discount: Serializers.decimal.nullable(),
});

export const savePostSaleDiscountSerialzier = Serializers.object({
  addedDiscounts: postSaleDiscountSerialzier.array(),
  customersNotFound: Serializers.object({
    customerName: Serializers.string.nullable(),
    discount: Serializers.decimal.nullable(),
  }).array(),
  wrongDiscount: Serializers.object({
    customerName: Serializers.string.nullable(),
    discount: Serializers.decimal.nullable(),
  }).array(),
});

export type SavePostSaleDiscountResponseValue = SerializerValue<typeof savePostSaleDiscountSerialzier>;

export type PostSaleDiscount = SerializerValue<typeof postSaleDiscountSerialzier>;

export const regionTargetSerialzier = Serializers.object({
  regionName: Serializers.string,
  targets: Serializers.record(Serializers.decimal.nullable(), Serializers.month),
});

export const saveRegionTargetSerialzier = Serializers.object({
  addedTargets: regionTargetSerialzier.array(),
  regionNotFound: regionTargetSerialzier.array(),
});

export type SaveRegionTargetResponseValue = SerializerValue<typeof saveRegionTargetSerialzier>;

export type RegionTarget = SerializerValue<typeof regionTargetSerialzier>;

export const margins = {
  postSaleDiscountsQuery: HttpAction({
    path: "/postSaleDiscounts",
    method: "get",
    requiresAuth: true as const,
    valueSerializer: postSaleDiscountSerialzier.array(),
  }),

  savePostSaleDiscounts: HttpAction({
    path: "/postSaleDiscounts",
    method: "put",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({
      customerId: uuidSerializer.optional(),
      customerName: Serializers.string.optional(),
      discount: Serializers.decimal.nullable(),
    }).array(),
    valueSerializer: savePostSaleDiscountSerialzier,
  }),

  saveRegionTarget: HttpAction({
    path: "/regionTarget",
    method: "put",
    requiresAuth: true as const,
    bodySerializer: regionTargetSerialzier.array(),
    valueSerializer: saveRegionTargetSerialzier,
  }),

  regionTargetQuery: HttpAction({
    path: "/regionTarget",
    method: "get",
    requiresAuth: true as const,
    valueSerializer: regionTargetSerialzier.array(),
  }),
};
