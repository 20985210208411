import { useState } from "react";
import { MdFolder, MdInbox } from "react-icons/md";

import { GroupFilter } from "@megaron/dash-filters";
import { useDeviceType } from "@megaron/dash-mq";
import { SelectDialog } from "@megaron/dash-select";

import { tableHeadingsList } from "./tableHeadingsListConstants";
import { VisibleInvoicesTableColumnsConfigObject } from "./useTableVisibleColumns";
import { GroupFilter as GroupFilterType } from "./useThreadFilters";

type Props = {
  groupFilter: GroupFilterType;
  onGroupFilterChange: (value: GroupFilterType) => void;
  visibleTableColumnsConfig: VisibleInvoicesTableColumnsConfigObject | undefined;
  onVisibleTableColumnsConfigChange: (key: keyof VisibleInvoicesTableColumnsConfigObject, columns: string[]) => void;
};

export const AllThreadsGroupFilters: React.FC<Props> = ({
  groupFilter,
  onGroupFilterChange,
  visibleTableColumnsConfig,
  onVisibleTableColumnsConfigChange,
}) => {
  const { isMobile } = useDeviceType();

  const [isEditMineColumnsDialogOpen, setIsEditMineColumnsDialogOpen] = useState(false);
  const [isEditAllColumnsDialogOpen, setIsEditAllColumnsDialogOpen] = useState(false);

  return (
    <div
      css={{
        overflow: "scroll",
        width: isMobile ? "calc(100% + 2rem)" : "calc(100% + 4rem)",
        margin: isMobile ? "0 -1rem" : "0 -2rem",
      }}
    >
      <div css={{ display: "flex", gap: "0.625rem", padding: isMobile ? "0 1rem" : "0 2rem", width: "fit-content" }}>
        <GroupFilter
          isActive={groupFilter === "mine"}
          label="Moje faktury"
          onClick={(v) => onGroupFilterChange(v as GroupFilterType)}
          value="mine"
          onSettingsClick={() => setIsEditMineColumnsDialogOpen(true)}
          Icon={MdInbox}
        />
        <GroupFilter
          isActive={groupFilter === "all"}
          label="Wszystkie faktury"
          onClick={(v) => onGroupFilterChange(v as GroupFilterType)}
          value="all"
          onSettingsClick={() => setIsEditAllColumnsDialogOpen(true)}
          Icon={MdFolder}
        />
      </div>
      <SelectDialog
        onClose={() => setIsEditMineColumnsDialogOpen(false)}
        label={"Kolumny"}
        initiallySelectedValues={visibleTableColumnsConfig?.mine}
        options={tableHeadingsList.map(({ label }) => ({ label: label, value: label }))}
        onSelectedChange={(options) =>
          onVisibleTableColumnsConfigChange(
            "mine",
            options.map((option) => option.value),
          )
        }
        variant="multi-select"
        noTrigger
        isOpen={isEditMineColumnsDialogOpen}
      />
      <SelectDialog
        onClose={() => setIsEditAllColumnsDialogOpen(false)}
        label={"Kolumny"}
        initiallySelectedValues={visibleTableColumnsConfig?.all}
        options={tableHeadingsList.map(({ label }) => ({ label: label, value: label }))}
        onSelectedChange={(options) =>
          onVisibleTableColumnsConfigChange(
            "all",
            options.map((option) => option.value),
          )
        }
        variant="multi-select"
        noTrigger
        isOpen={isEditAllColumnsDialogOpen}
      />
    </div>
  );
};
