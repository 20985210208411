import { useTheme } from "@emotion/react";
import { formatCurrency } from "@megarax/react-utils";
import { Link } from "react-router-dom-v5-compat";

import { Tile, TileStripe } from "@megaron/dash-tile";
import { OrderDoc } from "@megaron/docs-contracts";

type Props = {
  order: OrderDoc;
};

export const OrderDraftTile: React.FC<Props> = ({ order }) => {
  const theme = useTheme();

  return (
    <Link to={`/crm/orders/${order.id}`}>
      <Tile stripe={<TileStripe color={theme.colors.primaryLight} />}>
        <div css={{ display: "flex", flexDirection: "column", gap: "0.325rem", fontSize: "0.875rem" }}>
          <div css={{ display: "flex", alignItems: "center", gap: "0.5rem", justifyContent: "space-between" }}>
            <h3 css={{ color: theme.colors.primary, margin: 0, fontSize: "0.9375rem", fontWeight: 700 }}>
              {order.sellToName}
            </h3>
            <span css={{ color: theme.colors.secondaryText }}>{order.createdAt.toLocaleDateString()}</span>
          </div>
          <div css={{ display: "flex", justifyContent: "flex-end", color: theme.colors.primary }}>
            {formatCurrency(order.netTotal, order.currency ?? "PLN")}
          </div>
        </div>
      </Tile>
    </Link>
  );
};
