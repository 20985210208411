export const Szczecin = [
  { lat: 53.9314866, lng: 14.2060348 },
  { lat: 53.8642147, lng: 14.2099682 },
  { lat: 53.6972611, lng: 14.2687514 },
  { lat: 53.6373622, lng: 14.2780191 },
  { lat: 53.6181598, lng: 14.3147096 },
  { lat: 53.5545143, lng: 14.3022541 },
  { lat: 53.4779712, lng: 14.3555305 },
  { lat: 53.4275692, lng: 14.364768 },
  { lat: 53.2673212, lng: 14.4449875 },
  { lat: 53.1873777, lng: 14.3738863 },
  { lat: 53.1177527, lng: 14.3782472 },
  { lat: 53.074498, lng: 14.3606528 },
  { lat: 53.0441949, lng: 14.3315844 },
  { lat: 53.0105708, lng: 14.26688 },
  { lat: 52.9763105, lng: 14.1733204 },
  { lat: 52.9491069, lng: 14.1401169 },
  { lat: 52.8973099, lng: 14.1545595 },
  { lat: 52.8736639, lng: 14.1552583 },
  { lat: 52.8483637, lng: 14.1271244 },
  { lat: 52.8280378, lng: 14.1264714 },
  { lat: 52.8163947, lng: 14.2198327 },
  { lat: 52.7782008, lng: 14.2704924 },
  { lat: 52.7432888, lng: 14.3594869 },
  { lat: 52.6601291, lng: 14.4629238 },
  { lat: 52.6268401, lng: 14.5570346 },
  { lat: 52.6035041, lng: 14.6026565 },
  { lat: 52.5734846, lng: 14.6290085 },
  { lat: 52.541971, lng: 14.7440047 },
  { lat: 52.5558574, lng: 15.1280656 },
  { lat: 52.5366681, lng: 15.4576731 },
  { lat: 52.5701461, lng: 15.7351944 },
  { lat: 52.9060585, lng: 16.1066696 },
  { lat: 53.1142787, lng: 16.1808273 },
  { lat: 53.3493764, lng: 16.1705277 },
  { lat: 53.462303, lng: 15.79461 },
  { lat: 53.5876764, lng: 15.7369728 },
  { lat: 53.7122269, lng: 15.714993 },
  { lat: 53.9403795, lng: 15.5202743 },
  { lat: 54.1484434, lng: 15.3282643 },
  { lat: 54.0646807, lng: 14.9531051 },
  { lat: 54.0347259, lng: 14.7839775 },
  { lat: 53.993242, lng: 14.6151403 },
  { lat: 53.9162442, lng: 14.3661123 },
  { lat: 53.9314866, lng: 14.2060348 },
];
export const Bydgoszcz = [
  { lat: 53.6710243, lng: 18.7212235 },
  { lat: 53.6981082, lng: 18.6581866 },
  { lat: 53.7107325, lng: 18.5573667 },
  { lat: 53.7514904, lng: 18.195253 },
  { lat: 53.7549841, lng: 17.9275828 },
  { lat: 53.830755, lng: 17.4432613 },
  { lat: 53.6045241, lng: 17.4884398 },
  { lat: 53.2795215, lng: 17.190004 },
  { lat: 53.1102812, lng: 17.2087141 },
  { lat: 53.0283496, lng: 17.3067337 },
  { lat: 52.9261999, lng: 17.3287328 },
  { lat: 52.8744539, lng: 17.3232133 },
  { lat: 52.7973006, lng: 17.4028641 },
  { lat: 52.7487143, lng: 17.4415284 },
  { lat: 52.7069102, lng: 17.4780008 },
  { lat: 52.662927, lng: 17.6366831 },
  { lat: 52.6542366, lng: 17.7619068 },
  { lat: 52.6338322, lng: 17.8773362 },
  { lat: 52.6020118, lng: 17.8980989 },
  { lat: 52.5323732, lng: 18.0956296 },
  { lat: 52.4834819, lng: 18.4022602 },
  { lat: 52.4010976, lng: 18.7040573 },
  { lat: 52.3902037, lng: 18.9862686 },
  { lat: 52.3692463, lng: 19.3034988 },
  { lat: 52.4477216, lng: 19.2601811 },
  { lat: 52.4727876, lng: 19.3264266 },
  { lat: 52.6031384, lng: 19.4204214 },
  { lat: 52.6407718, lng: 19.3784244 },
  { lat: 52.675069, lng: 19.4573074 },
  { lat: 52.7224549, lng: 19.4490623 },
  { lat: 52.7028946, lng: 19.528721 },
  { lat: 52.7548636, lng: 19.5012823 },
  { lat: 52.8313313, lng: 19.4243484 },
  { lat: 52.8667291, lng: 19.5076175 },
  { lat: 52.9070975, lng: 19.464579 },
  { lat: 52.9855552, lng: 19.5576195 },
  { lat: 52.9602562, lng: 19.6756669 },
  { lat: 53.0209156, lng: 19.6784073 },
  { lat: 53.3331551, lng: 19.662101 },
  { lat: 53.4106967, lng: 19.4037491 },
  { lat: 53.3803983, lng: 19.2650467 },
  { lat: 53.5798444, lng: 19.178112 },
  { lat: 53.5915127, lng: 19.1182778 },
  { lat: 53.5890192, lng: 18.9095047 },
  { lat: 53.6710243, lng: 18.7212235 },
];
export const Koszalin = [
  { lat: 54.1484434, lng: 15.3282643 },
  { lat: 53.9383586, lng: 15.5134078 },
  { lat: 53.7122269, lng: 15.714993 },
  { lat: 53.5876764, lng: 15.7369728 },
  { lat: 53.462303, lng: 15.79461 },
  { lat: 53.3493764, lng: 16.1705277 },
  { lat: 53.2783969, lng: 16.1731652 },
  { lat: 53.2399919, lng: 16.1745385 },
  { lat: 53.3403682, lng: 16.5563834 },
  { lat: 53.2761423, lng: 16.8703178 },
  { lat: 53.2487547, lng: 17.0516126 },
  { lat: 53.2037033, lng: 17.1495995 },
  { lat: 53.1836587, lng: 17.2071944 },
  { lat: 53.2795215, lng: 17.190004 },
  { lat: 53.6045241, lng: 17.4884398 },
  { lat: 53.830755, lng: 17.4432613 },
  { lat: 54.0577664, lng: 17.3985345 },
  { lat: 54.1274241, lng: 17.3813096 },
  { lat: 54.1692492, lng: 17.3524709 },
  { lat: 54.223585, lng: 17.35994 },
  { lat: 54.2833628, lng: 17.4402001 },
  { lat: 54.3656469, lng: 17.5203724 },
  { lat: 54.4485718, lng: 17.6628673 },
  { lat: 54.5202076, lng: 17.7508654 },
  { lat: 54.5312079, lng: 17.7784949 },
  { lat: 54.5414097, lng: 17.7786658 },
  { lat: 54.7773834, lng: 17.6359842 },
  { lat: 54.7229503, lng: 17.2347908 },
  { lat: 54.6478556, lng: 17.0408806 },
  { lat: 54.5877822, lng: 16.8637177 },
  { lat: 54.5280499, lng: 16.5131832 },
  { lat: 54.3173643, lng: 16.215309 },
  { lat: 54.2403031, lng: 15.8453536 },
  { lat: 54.1484434, lng: 15.3282643 },
];
export const Gdansk = [
  { lat: 54.3265365, lng: 19.49862 },
  { lat: 54.2657009, lng: 19.3708828 },
  { lat: 54.2883994, lng: 19.2562601 },
  { lat: 54.3113178, lng: 19.2541758 },
  { lat: 54.3361458, lng: 19.2335764 },
  { lat: 54.3598654, lng: 19.3899741 },
  { lat: 54.4089416, lng: 19.5508052 },
  { lat: 54.524669, lng: 19.7691587 },
  { lat: 54.4357397, lng: 19.5900616 },
  { lat: 54.3768505, lng: 19.3841936 },
  { lat: 54.3417501, lng: 19.0811419 },
  { lat: 54.36976, lng: 18.7597936 },
  { lat: 54.4313145, lng: 18.5867602 },
  { lat: 54.5198864, lng: 18.5428152 },
  { lat: 54.5703726, lng: 18.5504855 },
  { lat: 54.6347876, lng: 18.5134824 },
  { lat: 54.7313602, lng: 18.3945007 },
  { lat: 54.785251, lng: 18.4227927 },
  { lat: 54.6832933, lng: 18.6936824 },
  { lat: 54.5961011, lng: 18.8153426 },
  { lat: 54.6195557, lng: 18.8266678 },
  { lat: 54.7079615, lng: 18.665724 },
  { lat: 54.8327305, lng: 18.3285835 },
  { lat: 54.8332441, lng: 18.0807781 },
  { lat: 54.8247245, lng: 17.9263961 },
  { lat: 54.7763933, lng: 17.6359921 },
  { lat: 54.5404196, lng: 17.7786702 },
  { lat: 54.5312079, lng: 17.7784949 },
  { lat: 54.5202076, lng: 17.7508654 },
  { lat: 54.4485718, lng: 17.6628673 },
  { lat: 54.3646568, lng: 17.5203829 },
  { lat: 54.282964, lng: 17.4402033 },
  { lat: 54.2231861, lng: 17.359944 },
  { lat: 54.1742021, lng: 17.351743 },
  { lat: 54.1270252, lng: 17.3813134 },
  { lat: 54.0503271, lng: 17.3985484 },
  { lat: 53.8297649, lng: 17.4432735 },
  { lat: 53.753994, lng: 17.9275836 },
  { lat: 53.7505004, lng: 18.1938742 },
  { lat: 53.6971182, lng: 18.6581701 },
  { lat: 53.5880293, lng: 18.9094824 },
  { lat: 53.5905228, lng: 19.1182506 },
  { lat: 53.718279, lng: 19.3537911 },
  { lat: 53.8958906, lng: 19.5673059 },
  { lat: 54.3265365, lng: 19.49862 },
];
export const Olsztyn = [
  { lat: 54.3307502, lng: 19.4945373 },
  { lat: 54.1902403, lng: 19.5164517 },
  { lat: 54.0824301, lng: 19.5425442 },
  { lat: 53.8968803, lng: 19.5687173 },
  { lat: 53.7184771, lng: 19.3537972 },
  { lat: 53.5915127, lng: 19.1182778 },
  { lat: 53.5798444, lng: 19.178112 },
  { lat: 53.3803983, lng: 19.2650467 },
  { lat: 53.4106967, lng: 19.4037491 },
  { lat: 53.3331551, lng: 19.662101 },
  { lat: 53.0209156, lng: 19.6784073 },
  { lat: 52.9124425, lng: 19.8851344 },
  { lat: 52.9408666, lng: 20.9351614 },
  { lat: 53.1177421, lng: 21.0938221 },
  { lat: 53.3692232, lng: 21.1315224 },
  { lat: 53.46403, lng: 21.4930069 },
  { lat: 53.4830712, lng: 21.6990537 },
  { lat: 53.464627, lng: 21.8817595 },
  { lat: 53.5146385, lng: 21.9435426 },
  { lat: 53.5074416, lng: 22.0345761 },
  { lat: 53.5719457, lng: 22.2026894 },
  { lat: 53.7913804, lng: 22.1551801 },
  { lat: 54.0261515, lng: 22.2905801 },
  { lat: 54.3338957, lng: 22.0963713 },
  { lat: 54.3267616, lng: 21.8511269 },
  { lat: 54.3715848, lng: 20.5465005 },
  { lat: 54.4179573, lng: 20.1015542 },
  { lat: 54.4435197, lng: 19.7994301 },
  { lat: 54.3307502, lng: 19.4945373 },
];
export const Lodz = [
  { lat: 52.3921399, lng: 18.9769751 },
  { lat: 52.4010976, lng: 18.7040573 },
  { lat: 52.4240918, lng: 18.6266461 },
  { lat: 52.3570161, lng: 18.5752949 },
  { lat: 52.2636884, lng: 18.5119294 },
  { lat: 52.0860324, lng: 18.2326513 },
  { lat: 51.927459, lng: 18.3270329 },
  { lat: 51.8295869, lng: 18.3815198 },
  { lat: 51.6569956, lng: 18.363114 },
  { lat: 51.4760154, lng: 18.368236 },
  { lat: 51.4219541, lng: 18.3262592 },
  { lat: 51.3516349, lng: 18.3372872 },
  { lat: 51.3105363, lng: 18.3900044 },
  { lat: 51.2814466, lng: 18.9385229 },
  { lat: 51.2100954, lng: 19.1623693 },
  { lat: 51.1679204, lng: 19.5201116 },
  { lat: 51.1184914, lng: 19.9321028 },
  { lat: 51.074408, lng: 19.9863513 },
  { lat: 51.1866112, lng: 19.9942278 },
  { lat: 51.3218013, lng: 19.9259191 },
  { lat: 51.4811549, lng: 19.9039465 },
  { lat: 51.6147585, lng: 19.8638393 },
  { lat: 51.7951284, lng: 20.0275653 },
  { lat: 51.8565012, lng: 19.9714402 },
  { lat: 51.9305623, lng: 19.9150331 },
  { lat: 52.0449492, lng: 20.0415841 },
  { lat: 52.1491483, lng: 20.1127826 },
  { lat: 52.2354996, lng: 20.0766283 },
  { lat: 52.2629229, lng: 19.9812541 },
  { lat: 52.2739512, lng: 19.8507309 },
  { lat: 52.2603689, lng: 19.6416479 },
  { lat: 52.338262, lng: 19.4202047 },
  { lat: 52.3692463, lng: 19.3034988 },
  { lat: 52.3921399, lng: 18.9769751 },
];
export const Warszawa = [
  { lat: 52.2944081, lng: 20.9678582 },
  { lat: 52.2852169, lng: 20.9877577 },
  { lat: 52.2613225, lng: 20.9527314 },
  { lat: 52.2496094, lng: 20.9369433 },
  { lat: 52.2437746, lng: 20.8957605 },
  { lat: 52.2005013, lng: 20.8362012 },
  { lat: 52.1929366, lng: 20.8115535 },
  { lat: 52.1901018, lng: 20.7869116 },
  { lat: 52.1876024, lng: 20.7779208 },
  { lat: 52.1853433, lng: 20.7748563 },
  { lat: 52.1824653, lng: 20.7724742 },
  { lat: 52.1823397, lng: 20.7724064 },
  { lat: 52.182195, lng: 20.7723099 },
  { lat: 52.1818134, lng: 20.7720953 },
  { lat: 52.1815789, lng: 20.7719468 },
  { lat: 52.1814059, lng: 20.7717972 },
  { lat: 52.1811888, lng: 20.7717972 },
  { lat: 52.1808467, lng: 20.7717436 },
  { lat: 52.1793896, lng: 20.7707459 },
  { lat: 52.178304, lng: 20.7696047 },
  { lat: 52.1769356, lng: 20.7672014 },
  { lat: 52.1738793, lng: 20.7600679 },
  { lat: 52.1699445, lng: 20.7542101 },
  { lat: 52.1354665, lng: 20.7985035 },
  { lat: 52.1212893, lng: 20.8802274 },
  { lat: 52.1083569, lng: 20.947888 },
  { lat: 52.1085524, lng: 21.0192755 },
  { lat: 52.1086245, lng: 21.1165407 },
  { lat: 52.0309195, lng: 21.2294394 },
  { lat: 52.0121167, lng: 21.2236988 },
  { lat: 51.9941592, lng: 21.2316902 },
  { lat: 51.958686, lng: 21.2833649 },
  { lat: 51.9127604, lng: 21.2403933 },
  { lat: 51.8601376, lng: 21.2896282 },
  { lat: 51.8456415, lng: 21.3607459 },
  { lat: 51.8522253, lng: 21.5280933 },
  { lat: 51.8306979, lng: 21.740426 },
  { lat: 51.9773733, lng: 21.7932202 },
  { lat: 52.2064285, lng: 21.8746285 },
  { lat: 52.355977, lng: 21.9556527 },
  { lat: 52.6235603, lng: 21.8416695 },
  { lat: 52.7442734, lng: 21.7606454 },
  { lat: 52.819031, lng: 21.6411691 },
  { lat: 52.7758949, lng: 21.5315888 },
  { lat: 52.7229478, lng: 21.4730531 },
  { lat: 52.7015705, lng: 21.4091286 },
  { lat: 52.6135552, lng: 21.18249 },
  { lat: 52.5926829, lng: 21.1248036 },
  { lat: 52.5526422, lng: 21.0891061 },
  { lat: 52.5080031, lng: 21.0735614 },
  { lat: 52.4779843, lng: 21.0367752 },
  { lat: 52.4366858, lng: 20.9886632 },
  { lat: 52.3119368, lng: 20.963643 },
  { lat: 52.2944081, lng: 20.9678582 },
];
export const Radom = [
  { lat: 52.10856, lng: 21.0184525 },
  { lat: 52.1083645, lng: 20.9470651 },
  { lat: 52.124248, lng: 20.8711647 },
  { lat: 52.1354741, lng: 20.7976805 },
  { lat: 52.1699521, lng: 20.7533871 },
  { lat: 52.1655974, lng: 20.7333147 },
  { lat: 52.1536772, lng: 20.6787936 },
  { lat: 52.1463623, lng: 20.6167446 },
  { lat: 52.1385014, lng: 20.5792237 },
  { lat: 52.131894, lng: 20.5485799 },
  { lat: 52.1293328, lng: 20.3821603 },
  { lat: 52.1491483, lng: 20.1127826 },
  { lat: 52.0449492, lng: 20.0415841 },
  { lat: 51.9305623, lng: 19.9150331 },
  { lat: 51.8565012, lng: 19.9714402 },
  { lat: 51.7951284, lng: 20.0275653 },
  { lat: 51.6147585, lng: 19.8638393 },
  { lat: 51.4811549, lng: 19.9039465 },
  { lat: 51.3218013, lng: 19.9259191 },
  { lat: 51.1866112, lng: 19.9942278 },
  { lat: 51.2000607, lng: 20.192646 },
  { lat: 51.2152852, lng: 20.2579238 },
  { lat: 51.2442459, lng: 20.304029 },
  { lat: 51.2470868, lng: 20.374306 },
  { lat: 51.2725738, lng: 20.3940923 },
  { lat: 51.3389473, lng: 20.4352301 },
  { lat: 51.2819672, lng: 20.5168794 },
  { lat: 51.2378056, lng: 20.5626454 },
  { lat: 51.2097017, lng: 20.6750478 },
  { lat: 51.217878, lng: 20.7560764 },
  { lat: 51.2548228, lng: 20.8130725 },
  { lat: 51.2728299, lng: 20.8876366 },
  { lat: 51.2982064, lng: 20.9847338 },
  { lat: 51.3049232, lng: 21.1141243 },
  { lat: 51.284895, lng: 21.2360461 },
  { lat: 51.3037874, lng: 21.5931018 },
  { lat: 51.3726983, lng: 21.8653145 },
  { lat: 51.5641934, lng: 21.8151469 },
  { lat: 51.5694077, lng: 21.6929112 },
  { lat: 51.6240047, lng: 21.6269932 },
  { lat: 51.6847236, lng: 21.462586 },
  { lat: 51.7861423, lng: 21.4502915 },
  { lat: 51.845649, lng: 21.359923 },
  { lat: 51.8601451, lng: 21.2888053 },
  { lat: 51.9127679, lng: 21.2395704 },
  { lat: 51.9586935, lng: 21.282542 },
  { lat: 51.9941667, lng: 21.2308673 },
  { lat: 52.0093769, lng: 21.2231233 },
  { lat: 52.030927, lng: 21.2286165 },
  { lat: 52.108632, lng: 21.1157178 },
  { lat: 52.10856, lng: 21.0184525 },
];
export const Lublin = [
  { lat: 52.3559845, lng: 21.9548299 },
  { lat: 52.206436, lng: 21.8738057 },
  { lat: 51.8307054, lng: 21.7396031 },
  { lat: 51.8522253, lng: 21.5280933 },
  { lat: 51.8456415, lng: 21.3607459 },
  { lat: 51.7861423, lng: 21.4502915 },
  { lat: 51.7648994, lng: 21.4530381 },
  { lat: 51.6840852, lng: 21.4708908 },
  { lat: 51.6240047, lng: 21.6269932 },
  { lat: 51.5694077, lng: 21.6929112 },
  { lat: 51.5626297, lng: 21.8066945 },
  { lat: 51.3726983, lng: 21.8653145 },
  { lat: 51.1627659, lng: 21.8210681 },
  { lat: 50.8853103, lng: 21.8320663 },
  { lat: 50.7787833, lng: 21.8925078 },
  { lat: 50.812663, lng: 22.1065441 },
  { lat: 50.7587653, lng: 22.1967269 },
  { lat: 50.6682448, lng: 22.1407578 },
  { lat: 50.6560256, lng: 22.2303207 },
  { lat: 50.5952436, lng: 22.3891421 },
  { lat: 50.5838222, lng: 22.4848669 },
  { lat: 50.5746057, lng: 22.528579 },
  { lat: 50.5409616, lng: 22.5310714 },
  { lat: 50.4841598, lng: 22.5296097 },
  { lat: 50.4592642, lng: 22.4561306 },
  { lat: 50.399362, lng: 22.4129684 },
  { lat: 50.3478685, lng: 22.5097735 },
  { lat: 50.3640779, lng: 22.6670153 },
  { lat: 50.3942914, lng: 22.785805 },
  { lat: 50.3408574, lng: 22.8956683 },
  { lat: 50.2903181, lng: 23.0169044 },
  { lat: 50.3256534, lng: 23.0776866 },
  { lat: 50.3943921, lng: 23.1912071 },
  { lat: 50.3635824, lng: 23.2451304 },
  { lat: 50.4122003, lng: 23.3565757 },
  { lat: 50.3423944, lng: 23.4263483 },
  { lat: 50.3041291, lng: 23.4081715 },
  { lat: 50.2573382, lng: 23.572026 },
  { lat: 50.2981523, lng: 23.6092467 },
  { lat: 50.3371846, lng: 23.6850144 },
  { lat: 50.3766612, lng: 23.7102858 },
  { lat: 50.3972685, lng: 23.7614642 },
  { lat: 50.4108297, lng: 23.8511456 },
  { lat: 50.413366, lng: 23.9949997 },
  { lat: 50.4954757, lng: 24.0690605 },
  { lat: 50.5985287, lng: 24.0938766 },
  { lat: 50.6642318, lng: 24.0932592 },
  { lat: 50.6811733, lng: 24.0624017 },
  { lat: 50.718557, lng: 24.0693476 },
  { lat: 50.7272946, lng: 24.0240099 },
  { lat: 50.7672818, lng: 24.0150438 },
  { lat: 50.7812001, lng: 23.9624743 },
  { lat: 50.8202868, lng: 23.9661605 },
  { lat: 50.8402584, lng: 24.0107791 },
  { lat: 50.8376353, lng: 24.1103561 },
  { lat: 50.8569998, lng: 24.1339608 },
  { lat: 50.869428, lng: 24.1301063 },
  { lat: 50.9211197, lng: 24.0097008 },
  { lat: 51.0105661, lng: 23.922447 },
  { lat: 51.071043, lng: 23.9076875 },
  { lat: 51.0995707, lng: 23.8638654 },
  { lat: 51.1163942, lng: 23.8546157 },
  { lat: 51.1502272, lng: 23.8692125 },
  { lat: 51.1582129, lng: 23.8384834 },
  { lat: 51.1858338, lng: 23.7965591 },
  { lat: 51.2011014, lng: 23.7615934 },
  { lat: 51.2430307, lng: 23.7293325 },
  { lat: 51.2784858, lng: 23.7015077 },
  { lat: 51.2889043, lng: 23.6793403 },
  { lat: 51.2838575, lng: 23.6544235 },
  { lat: 51.3387661, lng: 23.6447841 },
  { lat: 51.3936706, lng: 23.6983688 },
  { lat: 51.4364468, lng: 23.6826825 },
  { lat: 51.4443661, lng: 23.6839754 },
  { lat: 51.4414781, lng: 23.6760382 },
  { lat: 51.4450093, lng: 23.6681008 },
  { lat: 51.4492894, lng: 23.6480211 },
  { lat: 51.4584886, lng: 23.6483368 },
  { lat: 51.4605478, lng: 23.6553618 },
  { lat: 51.464946, lng: 23.6600764 },
  { lat: 51.4661358, lng: 23.6635898 },
  { lat: 51.4712958, lng: 23.6673424 },
  { lat: 51.4700528, lng: 23.6735105 },
  { lat: 51.4716969, lng: 23.6755587 },
  { lat: 51.4792378, lng: 23.6711742 },
  { lat: 51.4898798, lng: 23.6592632 },
  { lat: 51.4868379, lng: 23.6422008 },
  { lat: 51.4922971, lng: 23.6307326 },
  { lat: 51.5030108, lng: 23.6156348 },
  { lat: 51.5175699, lng: 23.6228513 },
  { lat: 51.5342921, lng: 23.5940121 },
  { lat: 51.5391095, lng: 23.5692794 },
  { lat: 51.6192264, lng: 23.5445896 },
  { lat: 51.6908085, lng: 23.5541897 },
  { lat: 51.7334415, lng: 23.5418136 },
  { lat: 51.782744, lng: 23.6269577 },
  { lat: 51.8100773, lng: 23.6336452 },
  { lat: 51.8374096, lng: 23.6046135 },
  { lat: 51.905651, lng: 23.6234633 },
  { lat: 51.9878741, lng: 23.680516 },
  { lat: 52.0723317, lng: 23.6503615 },
  { lat: 52.1145512, lng: 23.5857589 },
  { lat: 52.1268155, lng: 23.5392594 },
  { lat: 52.1795204, lng: 23.492692 },
  { lat: 52.2240081, lng: 23.228898 },
  { lat: 52.2843095, lng: 23.1788772 },
  { lat: 52.3623496, lng: 22.918448 },
  { lat: 52.3559845, lng: 21.9548299 },
];
export const Czestochowa = [
  { lat: 50.2951332, lng: 19.0739066 },
  { lat: 50.2813542, lng: 19.0887763 },
  { lat: 50.2655946, lng: 19.1273186 },
  { lat: 50.2568609, lng: 19.1872348 },
  { lat: 50.2600008, lng: 19.3293617 },
  { lat: 50.2990808, lng: 19.4131112 },
  { lat: 50.3342577, lng: 19.4805908 },
  { lat: 50.4176071, lng: 19.5262389 },
  { lat: 50.4101951, lng: 19.5890657 },
  { lat: 50.4110674, lng: 19.6436447 },
  { lat: 50.5127697, lng: 19.9149402 },
  { lat: 50.5404763, lng: 19.8888447 },
  { lat: 50.5494334, lng: 19.8483356 },
  { lat: 50.548923, lng: 19.7826626 },
  { lat: 50.6363937, lng: 19.8861007 },
  { lat: 50.6594645, lng: 19.8356458 },
  { lat: 50.6912346, lng: 19.8703077 },
  { lat: 50.7240203, lng: 19.7807276 },
  { lat: 50.7664907, lng: 19.724897 },
  { lat: 50.8228519, lng: 19.8311688 },
  { lat: 50.8688089, lng: 19.7528908 },
  { lat: 50.9006424, lng: 19.7918275 },
  { lat: 50.9243612, lng: 19.8380229 },
  { lat: 50.9671185, lng: 19.8348102 },
  { lat: 50.9695261, lng: 19.9084706 },
  { lat: 50.9681275, lng: 20.0051281 },
  { lat: 50.9927965, lng: 20.0419696 },
  { lat: 51.0575792, lng: 20.0536426 },
  { lat: 51.0746239, lng: 19.9863514 },
  { lat: 51.1187073, lng: 19.9321026 },
  { lat: 51.1751318, lng: 19.4652963 },
  { lat: 51.2061165, lng: 19.2483152 },
  { lat: 51.2100954, lng: 19.1623693 },
  { lat: 51.2814466, lng: 18.9385229 },
  { lat: 51.301951, lng: 18.5781459 },
  { lat: 51.3105363, lng: 18.3900044 },
  { lat: 51.2005235, lng: 18.318593 },
  { lat: 50.9900819, lng: 18.3735249 },
  { lat: 50.8827689, lng: 18.3652852 },
  { lat: 50.6360538, lng: 18.2746476 },
  { lat: 50.5929192, lng: 18.2478686 },
  { lat: 50.5195952, lng: 18.230307 },
  { lat: 50.4654635, lng: 18.2169687 },
  { lat: 50.4508978, lng: 18.3148453 },
  { lat: 50.4717222, lng: 18.3759111 },
  { lat: 50.4811309, lng: 18.4713592 },
  { lat: 50.4513384, lng: 18.5843106 },
  { lat: 50.3921067, lng: 18.6528844 },
  { lat: 50.376215, lng: 18.7776178 },
  { lat: 50.4019044, lng: 18.8119767 },
  { lat: 50.4109973, lng: 18.86836 },
  { lat: 50.4150326, lng: 18.9206384 },
  { lat: 50.40794, lng: 18.9468765 },
  { lat: 50.3923104, lng: 18.9593724 },
  { lat: 50.3743997, lng: 18.9646126 },
  { lat: 50.3534384, lng: 19.0450675 },
  { lat: 50.2951332, lng: 19.0739066 },
];
export const BielskoBiala = [
  { lat: 50.4019044, lng: 18.8119767 },
  { lat: 50.376215, lng: 18.7776178 },
  { lat: 50.3921067, lng: 18.6528844 },
  { lat: 50.4513384, lng: 18.5843106 },
  { lat: 50.4811309, lng: 18.4713592 },
  { lat: 50.4717222, lng: 18.3759111 },
  { lat: 50.4508978, lng: 18.3148453 },
  { lat: 50.4600784, lng: 18.2702134 },
  { lat: 50.4654635, lng: 18.2169687 },
  { lat: 50.3524223, lng: 18.0937455 },
  { lat: 50.2880286, lng: 17.8545754 },
  { lat: 50.2612594, lng: 17.7241128 },
  { lat: 50.2361882, lng: 17.7621715 },
  { lat: 50.2102354, lng: 17.7631304 },
  { lat: 50.1622648, lng: 17.594131 },
  { lat: 50.114553, lng: 17.6493532 },
  { lat: 50.117987, lng: 17.7043769 },
  { lat: 50.0667874, lng: 17.7524827 },
  { lat: 50.0398939, lng: 17.775142 },
  { lat: 49.988528, lng: 17.8369112 },
  { lat: 49.9778247, lng: 17.9103761 },
  { lat: 50.0147503, lng: 17.9880021 },
  { lat: 50.0091388, lng: 18.0453236 },
  { lat: 50.0363386, lng: 18.0432316 },
  { lat: 50.0308932, lng: 18.0067986 },
  { lat: 50.0526443, lng: 18.0067082 },
  { lat: 50.0644006, lng: 18.0340043 },
  { lat: 50.030115, lng: 18.0879417 },
  { lat: 49.9939776, lng: 18.1251814 },
  { lat: 49.9849452, lng: 18.1589005 },
  { lat: 49.9997461, lng: 18.1733964 },
  { lat: 49.9698454, lng: 18.2215035 },
  { lat: 49.964652, lng: 18.2736939 },
  { lat: 49.9321691, lng: 18.2880662 },
  { lat: 49.9155271, lng: 18.3189809 },
  { lat: 49.9447694, lng: 18.3461036 },
  { lat: 49.9359835, lng: 18.4144453 },
  { lat: 49.9025467, lng: 18.5242723 },
  { lat: 49.9233313, lng: 18.5503631 },
  { lat: 49.908738, lng: 18.5764573 },
  { lat: 49.8786134, lng: 18.5706403 },
  { lat: 49.862235, lng: 18.6000029 },
  { lat: 49.833239, lng: 18.5700361 },
  { lat: 49.7750073, lng: 18.6046098 },
  { lat: 49.7195469, lng: 18.6327622 },
  { lat: 49.6871287, lng: 18.737819 },
  { lat: 49.6771344, lng: 18.7990046 },
  { lat: 49.6146648, lng: 18.8243363 },
  { lat: 49.5354092, lng: 18.8552354 },
  { lat: 49.5160668, lng: 18.9029876 },
  { lat: 49.4502221, lng: 18.976085 },
  { lat: 49.4069117, lng: 18.9706118 },
  { lat: 49.3986545, lng: 19.0255296 },
  { lat: 49.4136041, lng: 19.0832017 },
  { lat: 49.4093732, lng: 19.1724258 },
  { lat: 49.4694133, lng: 19.2232774 },
  { lat: 49.5160139, lng: 19.2435122 },
  { lat: 49.5358548, lng: 19.2857622 },
  { lat: 49.5365293, lng: 19.3369125 },
  { lat: 49.5478849, lng: 19.3633531 },
  { lat: 49.5925684, lng: 19.4127694 },
  { lat: 49.6147648, lng: 19.4613502 },
  { lat: 49.6253844, lng: 19.4769935 },
  { lat: 49.6609119, lng: 19.4752763 },
  { lat: 49.6745226, lng: 19.4585623 },
  { lat: 49.6792436, lng: 19.441842 },
  { lat: 49.6771211, lng: 19.3946613 },
  { lat: 49.6935308, lng: 19.3723811 },
  { lat: 49.6979719, lng: 19.3910023 },
  { lat: 49.7104055, lng: 19.3986417 },
  { lat: 49.7201683, lng: 19.4310981 },
  { lat: 49.7415857, lng: 19.4429663 },
  { lat: 49.7603407, lng: 19.4301148 },
  { lat: 49.7754461, lng: 19.3593584 },
  { lat: 49.7794383, lng: 19.315025 },
  { lat: 49.8242029, lng: 19.2885087 },
  { lat: 49.9202065, lng: 19.3082183 },
  { lat: 49.9626567, lng: 19.3709062 },
  { lat: 49.9997461, lng: 19.4670365 },
  { lat: 50.0517995, lng: 19.289882 },
  { lat: 50.0967493, lng: 19.3434403 },
  { lat: 50.2308938, lng: 19.4177805 },
  { lat: 50.2600008, lng: 19.3293617 },
  { lat: 50.2568609, lng: 19.1872348 },
  { lat: 50.2655946, lng: 19.1273186 },
  { lat: 50.2813542, lng: 19.0887763 },
  { lat: 50.2951332, lng: 19.0739066 },
  { lat: 50.3534384, lng: 19.0450675 },
  { lat: 50.3743997, lng: 18.9646126 },
  { lat: 50.3923104, lng: 18.9593724 },
  { lat: 50.40794, lng: 18.9468765 },
  { lat: 50.4150326, lng: 18.9206384 },
  { lat: 50.4109973, lng: 18.86836 },
  { lat: 50.4019044, lng: 18.8119767 },
];
export const Krakow = [
  { lat: 50.2308938, lng: 19.4177805 },
  { lat: 50.0967493, lng: 19.3434403 },
  { lat: 50.0517995, lng: 19.289882 },
  { lat: 49.9997461, lng: 19.4670365 },
  { lat: 49.9626567, lng: 19.3709062 },
  { lat: 49.9202065, lng: 19.3082183 },
  { lat: 49.8283836, lng: 19.2883057 },
  { lat: 49.7794383, lng: 19.315025 },
  { lat: 49.7763338, lng: 19.3564451 },
  { lat: 49.7603407, lng: 19.4301148 },
  { lat: 49.7415857, lng: 19.4429663 },
  { lat: 49.7201683, lng: 19.4310981 },
  { lat: 49.711861, lng: 19.398103 },
  { lat: 49.6979719, lng: 19.3910023 },
  { lat: 49.6935308, lng: 19.3723811 },
  { lat: 49.6767952, lng: 19.3924525 },
  { lat: 49.6792436, lng: 19.441842 },
  { lat: 49.6745226, lng: 19.4585623 },
  { lat: 49.6609119, lng: 19.4752763 },
  { lat: 49.6253844, lng: 19.4769935 },
  { lat: 49.6155979, lng: 19.4660072 },
  { lat: 49.4111193, lng: 19.6412415 },
  { lat: 49.3919286, lng: 19.7174599 },
  { lat: 49.4028699, lng: 19.7528152 },
  { lat: 49.4102258, lng: 19.7881837 },
  { lat: 49.3301548, lng: 19.8054262 },
  { lat: 49.2159498, lng: 19.7675842 },
  { lat: 49.2044704, lng: 19.8023663 },
  { lat: 49.2001474, lng: 19.8673449 },
  { lat: 49.2325761, lng: 19.9131072 },
  { lat: 49.2380696, lng: 19.9836333 },
  { lat: 49.1924881, lng: 20.0849417 },
  { lat: 49.2521596, lng: 20.1040376 },
  { lat: 49.3124478, lng: 20.1397142 },
  { lat: 49.3085634, lng: 20.1671919 },
  { lat: 49.3333233, lng: 20.1781983 },
  { lat: 49.3530075, lng: 20.2509827 },
  { lat: 49.3490597, lng: 20.3164964 },
  { lat: 49.4033982, lng: 20.3204274 },
  { lat: 49.3987189, lng: 20.4122975 },
  { lat: 49.4161157, lng: 20.4696699 },
  { lat: 49.381625, lng: 20.5737061 },
  { lat: 49.418292, lng: 20.6196801 },
  { lat: 49.3990831, lng: 20.6454444 },
  { lat: 49.419852, lng: 20.69265 },
  { lat: 49.4191602, lng: 20.7371277 },
  { lat: 49.345863, lng: 20.8004005 },
  { lat: 49.3384902, lng: 20.8319698 },
  { lat: 49.3490036, lng: 20.8635412 },
  { lat: 49.2966181, lng: 20.9276719 },
  { lat: 49.3095993, lng: 20.9900958 },
  { lat: 49.32952, lng: 20.9923187 },
  { lat: 49.3504427, lng: 21.0202213 },
  { lat: 49.3647002, lng: 21.0455178 },
  { lat: 49.3642236, lng: 21.0667568 },
  { lat: 49.3604056, lng: 21.0760044 },
  { lat: 49.3683336, lng: 21.0981378 },
  { lat: 49.3780487, lng: 21.1024231 },
  { lat: 49.5431793, lng: 20.8552307 },
  { lat: 49.6179738, lng: 20.8030457 },
  { lat: 49.757536, lng: 20.8216556 },
  { lat: 49.80735, lng: 20.7982165 },
  { lat: 49.8385702, lng: 20.7484574 },
  { lat: 49.8607379, lng: 20.7493034 },
  { lat: 49.9187337, lng: 20.831612 },
  { lat: 49.9673751, lng: 20.8629119 },
  { lat: 50.0109289, lng: 20.8571566 },
  { lat: 50.1036232, lng: 20.8096393 },
  { lat: 50.1432536, lng: 20.7902446 },
  { lat: 50.23637, lng: 20.7522339 },
  { lat: 50.2311734, lng: 20.7265552 },
  { lat: 50.1902323, lng: 20.5558533 },
  { lat: 50.2162369, lng: 20.3983386 },
  { lat: 50.3217302, lng: 20.3043363 },
  { lat: 50.3820567, lng: 20.3190913 },
  { lat: 50.4686482, lng: 20.2706226 },
  { lat: 50.4889194, lng: 20.1257849 },
  { lat: 50.5197555, lng: 19.9925311 },
  { lat: 50.5064428, lng: 19.9513266 },
  { lat: 50.5127697, lng: 19.9149402 },
  { lat: 50.442418, lng: 19.7261127 },
  { lat: 50.4386888, lng: 19.6643117 },
  { lat: 50.420037, lng: 19.6536011 },
  { lat: 50.4176071, lng: 19.5262389 },
  { lat: 50.3342577, lng: 19.4805908 },
  { lat: 50.2990808, lng: 19.4131112 },
  { lat: 50.2600008, lng: 19.3293617 },
  { lat: 50.2308938, lng: 19.4177805 },
];
export const Wroclaw = [
  { lat: 50.2880286, lng: 17.8545754 },
  { lat: 50.3524223, lng: 18.0937455 },
  { lat: 50.4654635, lng: 18.2169687 },
  { lat: 50.5887802, lng: 18.2495332 },
  { lat: 50.7268991, lng: 18.3098214 },
  { lat: 50.8827689, lng: 18.3652852 },
  { lat: 50.9103991, lng: 18.3688729 },
  { lat: 50.9900819, lng: 18.3735249 },
  { lat: 51.2005235, lng: 18.318593 },
  { lat: 51.3105363, lng: 18.3900044 },
  { lat: 51.3516349, lng: 18.3372872 },
  { lat: 51.4219541, lng: 18.3262592 },
  { lat: 51.4760154, lng: 18.368236 },
  { lat: 51.6241132, lng: 18.3622274 },
  { lat: 51.6193654, lng: 18.210042 },
  { lat: 51.6190222, lng: 18.0756211 },
  { lat: 51.5976685, lng: 17.9742419 },
  { lat: 51.7418029, lng: 17.8262293 },
  { lat: 51.8786158, lng: 17.4803536 },
  { lat: 51.9717742, lng: 17.2166818 },
  { lat: 51.9057364, lng: 16.9475167 },
  { lat: 51.7640208, lng: 16.7607491 },
  { lat: 51.6550508, lng: 16.6304459 },
  { lat: 51.6337937, lng: 16.486091 },
  { lat: 51.4589519, lng: 16.6675248 },
  { lat: 51.3293299, lng: 16.5163033 },
  { lat: 51.212481, lng: 16.3679879 },
  { lat: 51.1194774, lng: 16.5327828 },
  { lat: 50.9848076, lng: 16.6810983 },
  { lat: 50.8740168, lng: 16.6014474 },
  { lat: 50.8301667, lng: 16.8577404 },
  { lat: 50.6568668, lng: 17.2393411 },
  { lat: 50.6180966, lng: 17.2351802 },
  { lat: 50.6115033, lng: 17.1397441 },
  { lat: 50.5459698, lng: 17.069899 },
  { lat: 50.5024405, lng: 17.0643027 },
  { lat: 50.4790747, lng: 17.0581141 },
  { lat: 50.4661941, lng: 17.0423209 },
  { lat: 50.4873363, lng: 16.9616712 },
  { lat: 50.4596428, lng: 16.9381883 },
  { lat: 50.4441767, lng: 16.8886462 },
  { lat: 50.4430654, lng: 16.9214271 },
  { lat: 50.4185126, lng: 16.9749244 },
  { lat: 50.4201179, lng: 17.0118689 },
  { lat: 50.4063418, lng: 17.0586957 },
  { lat: 50.4047996, lng: 17.1055073 },
  { lat: 50.3820179, lng: 17.1517448 },
  { lat: 50.3845812, lng: 17.2016399 },
  { lat: 50.3556053, lng: 17.2113023 },
  { lat: 50.318139, lng: 17.2782698 },
  { lat: 50.327096, lng: 17.3479428 },
  { lat: 50.2810087, lng: 17.3512643 },
  { lat: 50.2727884, lng: 17.4627615 },
  { lat: 50.2773849, lng: 17.5762057 },
  { lat: 50.2787653, lng: 17.6466961 },
  { lat: 50.2987471, lng: 17.676467 },
  { lat: 50.3274969, lng: 17.6897805 },
  { lat: 50.2987658, lng: 17.746784 },
  { lat: 50.2612594, lng: 17.7241128 },
  { lat: 50.2880286, lng: 17.8545754 },
];
export const JeleniaGora = [
  { lat: 50.0983652, lng: 16.7008271 },
  { lat: 50.1034597, lng: 16.7206558 },
  { lat: 50.1340908, lng: 16.7446208 },
  { lat: 50.1347815, lng: 16.7552057 },
  { lat: 50.1451514, lng: 16.7822745 },
  { lat: 50.1645385, lng: 16.7961338 },
  { lat: 50.1786465, lng: 16.7996996 },
  { lat: 50.1914702, lng: 16.8137067 },
  { lat: 50.190503, lng: 16.8230925 },
  { lat: 50.2066769, lng: 16.8469032 },
  { lat: 50.1984556, lng: 16.8648781 },
  { lat: 50.20078, lng: 16.8828508 },
  { lat: 50.2152948, lng: 16.8865791 },
  { lat: 50.2232174, lng: 16.9061058 },
  { lat: 50.2232322, lng: 16.9541014 },
  { lat: 50.229224, lng: 17.0256104 },
  { lat: 50.2863207, lng: 17.0135987 },
  { lat: 50.3205921, lng: 16.9416166 },
  { lat: 50.4345731, lng: 16.8625321 },
  { lat: 50.4487657, lng: 16.8939583 },
  { lat: 50.4596428, lng: 16.9381883 },
  { lat: 50.4873363, lng: 16.9616712 },
  { lat: 50.4661941, lng: 17.0423209 },
  { lat: 50.4790747, lng: 17.0581141 },
  { lat: 50.5024405, lng: 17.0643027 },
  { lat: 50.5459698, lng: 17.069899 },
  { lat: 50.6115033, lng: 17.1397441 },
  { lat: 50.6180966, lng: 17.2351802 },
  { lat: 50.6568668, lng: 17.2393411 },
  { lat: 50.8301667, lng: 16.8577404 },
  { lat: 50.8742529, lng: 16.6098465 },
  { lat: 50.9848076, lng: 16.6810983 },
  { lat: 51.1194774, lng: 16.5327828 },
  { lat: 51.212481, lng: 16.3679879 },
  { lat: 51.4589519, lng: 16.6675248 },
  { lat: 51.6337937, lng: 16.486091 },
  { lat: 51.5832811, lng: 16.2249818 },
  { lat: 51.5854144, lng: 16.06671 },
  { lat: 51.5871932, lng: 15.9564934 },
  { lat: 51.5768808, lng: 15.8765091 },
  { lat: 51.5757048, lng: 15.8509812 },
  { lat: 51.5728935, lng: 15.8241297 },
  { lat: 51.5521813, lng: 15.7042784 },
  { lat: 51.5399252, lng: 15.6270706 },
  { lat: 51.5034247, lng: 15.5908646 },
  { lat: 51.5256448, lng: 15.4878678 },
  { lat: 51.528208, lng: 15.3917374 },
  { lat: 51.4486827, lng: 15.3381791 },
  { lat: 51.4107285, lng: 15.1610245 },
  { lat: 51.3927814, lng: 15.0196152 },
  { lat: 51.3544415, lng: 14.9646439 },
  { lat: 51.3252731, lng: 14.974257 },
  { lat: 51.2823447, lng: 15.0319352 },
  { lat: 51.1860392, lng: 15.0099625 },
  { lat: 51.0783173, lng: 14.9673905 },
  { lat: 50.8829179, lng: 14.8177018 },
  { lat: 50.8707864, lng: 14.8451676 },
  { lat: 50.8647195, lng: 15.0030961 },
  { lat: 50.9850432, lng: 15.0127091 },
  { lat: 50.9980092, lng: 14.9646439 },
  { lat: 51.0187472, lng: 15.0319352 },
  { lat: 50.9971449, lng: 15.1747574 },
  { lat: 50.9694792, lng: 15.2928605 },
  { lat: 50.9140985, lng: 15.2612748 },
  { lat: 50.8638528, lng: 15.3065934 },
  { lat: 50.8430455, lng: 15.3601517 },
  { lat: 50.7909869, lng: 15.3683915 },
  { lat: 50.7692787, lng: 15.3683915 },
  { lat: 50.8083462, lng: 15.4384293 },
  { lat: 50.7492982, lng: 15.6828751 },
  { lat: 50.7397393, lng: 15.7254471 },
  { lat: 50.7536425, lng: 15.8140244 },
  { lat: 50.6727724, lng: 15.8765092 },
  { lat: 50.6875655, lng: 15.979506 },
  { lat: 50.6322712, lng: 16.0159019 },
  { lat: 50.6152961, lng: 15.989119 },
  { lat: 50.6031022, lng: 16.0261673 },
  { lat: 50.6658093, lng: 16.1031022 },
  { lat: 50.6331951, lng: 16.2047266 },
  { lat: 50.6717098, lng: 16.2321769 },
  { lat: 50.6649389, lng: 16.2939896 },
  { lat: 50.6488431, lng: 16.3613447 },
  { lat: 50.5882753, lng: 16.4505448 },
  { lat: 50.4301993, lng: 16.196486 },
  { lat: 50.407194, lng: 16.2451823 },
  { lat: 50.3701663, lng: 16.2773453 },
  { lat: 50.3825942, lng: 16.3039948 },
  { lat: 50.3792449, lng: 16.3567445 },
  { lat: 50.3668902, lng: 16.368333 },
  { lat: 50.3492795, lng: 16.3627535 },
  { lat: 50.3333326, lng: 16.3804239 },
  { lat: 50.3199924, lng: 16.4022964 },
  { lat: 50.3249376, lng: 16.4173491 },
  { lat: 50.3233046, lng: 16.4344628 },
  { lat: 50.2966041, lng: 16.4634065 },
  { lat: 50.2603627, lng: 16.5031157 },
  { lat: 50.246145, lng: 16.5146577 },
  { lat: 50.231924, lng: 16.5426675 },
  { lat: 50.2209954, lng: 16.5547093 },
  { lat: 50.2064143, lng: 16.560212 },
  { lat: 50.1959375, lng: 16.5548944 },
  { lat: 50.1843618, lng: 16.5581603 },
  { lat: 50.1604548, lng: 16.5712146 },
  { lat: 50.1312627, lng: 16.611705 },
  { lat: 50.1117402, lng: 16.6353387 },
  { lat: 50.0983652, lng: 16.7008271 },
];
export const ZielonaGora = [
  { lat: 52.3650769, lng: 14.5375504 },
  { lat: 52.284368, lng: 14.5914077 },
  { lat: 52.2405563, lng: 14.7054538 },
  { lat: 52.1311177, lng: 14.6847116 },
  { lat: 52.0486484, lng: 14.7407975 },
  { lat: 51.9081352, lng: 14.6911883 },
  { lat: 51.8420995, lng: 14.5924821 },
  { lat: 51.8093892, lng: 14.5980566 },
  { lat: 51.7902567, lng: 14.6475448 },
  { lat: 51.7265083, lng: 14.6695498 },
  { lat: 51.6686805, lng: 14.7627701 },
  { lat: 51.5339078, lng: 14.6995988 },
  { lat: 51.5014791, lng: 14.8301833 },
  { lat: 51.478376, lng: 14.9228564 },
  { lat: 51.4449783, lng: 14.9495377 },
  { lat: 51.3544415, lng: 14.9646439 },
  { lat: 51.3927814, lng: 15.0196152 },
  { lat: 51.4107285, lng: 15.1610245 },
  { lat: 51.4486827, lng: 15.3381791 },
  { lat: 51.528208, lng: 15.3917374 },
  { lat: 51.5256448, lng: 15.4878678 },
  { lat: 51.5034247, lng: 15.5908646 },
  { lat: 51.5373247, lng: 15.6306901 },
  { lat: 51.5728935, lng: 15.8241297 },
  { lat: 51.5757048, lng: 15.8509812 },
  { lat: 51.5768808, lng: 15.8765091 },
  { lat: 51.5871932, lng: 15.9564934 },
  { lat: 51.5812944, lng: 15.9829392 },
  { lat: 51.5845611, lng: 16.0344376 },
  { lat: 51.5832811, lng: 16.2249818 },
  { lat: 51.6337937, lng: 16.486091 },
  { lat: 51.6550508, lng: 16.6304459 },
  { lat: 51.8929784, lng: 16.9298234 },
  { lat: 52.1026677, lng: 16.7073502 },
  { lat: 52.1802061, lng: 16.8144669 },
  { lat: 52.1798979, lng: 16.5440964 },
  { lat: 52.2643339, lng: 16.4601578 },
  { lat: 52.3835216, lng: 16.4134659 },
  { lat: 52.4141839, lng: 16.3811453 },
  { lat: 52.4655907, lng: 16.2761368 },
  { lat: 52.4952821, lng: 16.1305679 },
  { lat: 52.5701461, lng: 15.7351944 },
  { lat: 52.5366681, lng: 15.4576731 },
  { lat: 52.5558574, lng: 15.1280656 },
  { lat: 52.541971, lng: 14.7440047 },
  { lat: 52.5734846, lng: 14.6290085 },
  { lat: 52.5339339, lng: 14.603346 },
  { lat: 52.4977245, lng: 14.6353562 },
  { lat: 52.4288988, lng: 14.5479276 },
  { lat: 52.3650769, lng: 14.5375504 },
];
export const Kielce = [
  { lat: 50.5519779, lng: 19.7862758 },
  { lat: 50.5494334, lng: 19.8483356 },
  { lat: 50.5404763, lng: 19.8888447 },
  { lat: 50.5127697, lng: 19.9149402 },
  { lat: 50.5064428, lng: 19.9513266 },
  { lat: 50.5197555, lng: 19.9925311 },
  { lat: 50.4889194, lng: 20.1257849 },
  { lat: 50.4686482, lng: 20.2706226 },
  { lat: 50.3763407, lng: 20.3173144 },
  { lat: 50.3217302, lng: 20.3043363 },
  { lat: 50.2162369, lng: 20.3983386 },
  { lat: 50.1895423, lng: 20.5692876 },
  { lat: 50.2311734, lng: 20.7265552 },
  { lat: 50.2829757, lng: 21.0410388 },
  { lat: 50.4074216, lng: 21.2909778 },
  { lat: 50.4721445, lng: 21.4523394 },
  { lat: 50.5230339, lng: 21.6001687 },
  { lat: 50.6000174, lng: 21.6631396 },
  { lat: 50.6474994, lng: 21.7372974 },
  { lat: 50.6553359, lng: 21.7537769 },
  { lat: 50.6805778, lng: 21.7798694 },
  { lat: 50.7787833, lng: 21.8925078 },
  { lat: 50.8863566, lng: 21.8279345 },
  { lat: 51.1476924, lng: 21.8279345 },
  { lat: 51.1627659, lng: 21.8210681 },
  { lat: 51.3726983, lng: 21.8653145 },
  { lat: 51.3037874, lng: 21.5931018 },
  { lat: 51.284895, lng: 21.2360461 },
  { lat: 51.3049232, lng: 21.1141243 },
  { lat: 51.299065, lng: 20.9909136 },
  { lat: 51.2548228, lng: 20.8130725 },
  { lat: 51.217878, lng: 20.7560764 },
  { lat: 51.2097017, lng: 20.6750478 },
  { lat: 51.2378056, lng: 20.5626454 },
  { lat: 51.2819672, lng: 20.5168794 },
  { lat: 51.3389473, lng: 20.4352301 },
  { lat: 51.2470868, lng: 20.374306 },
  { lat: 51.2442459, lng: 20.304029 },
  { lat: 51.2152852, lng: 20.2579238 },
  { lat: 51.2000607, lng: 20.192646 },
  { lat: 51.192817, lng: 20.070222 },
  { lat: 51.1866112, lng: 19.9942278 },
  { lat: 51.1346946, lng: 19.991168 },
  { lat: 51.074408, lng: 19.9863513 },
  { lat: 51.0575792, lng: 20.0536426 },
  { lat: 50.9927965, lng: 20.0419696 },
  { lat: 50.9679116, lng: 20.0051279 },
  { lat: 50.9695261, lng: 19.9084706 },
  { lat: 50.9669026, lng: 19.8348108 },
  { lat: 50.9241453, lng: 19.8380235 },
  { lat: 50.9004265, lng: 19.7918284 },
  { lat: 50.868593, lng: 19.7528918 },
  { lat: 50.822636, lng: 19.8311694 },
  { lat: 50.7662748, lng: 19.7248982 },
  { lat: 50.7238044, lng: 19.7807285 },
  { lat: 50.6910187, lng: 19.8703082 },
  { lat: 50.6592486, lng: 19.8356464 },
  { lat: 50.6490221, lng: 19.8416539 },
  { lat: 50.6361778, lng: 19.8861011 },
  { lat: 50.5519779, lng: 19.7862758 },
];
export const Przemysl = [
  { lat: 50.6000174, lng: 21.6631396 },
  { lat: 50.5230339, lng: 21.6001687 },
  { lat: 50.4721445, lng: 21.4523394 },
  { lat: 50.2829757, lng: 21.0410388 },
  { lat: 50.23637, lng: 20.7522339 },
  { lat: 50.1036232, lng: 20.8096393 },
  { lat: 50.0036475, lng: 20.8585299 },
  { lat: 49.9673751, lng: 20.8629119 },
  { lat: 49.9187337, lng: 20.831612 },
  { lat: 49.8607379, lng: 20.7493034 },
  { lat: 49.8385702, lng: 20.7484574 },
  { lat: 49.80735, lng: 20.7982165 },
  { lat: 49.757536, lng: 20.8216556 },
  { lat: 49.6864933, lng: 20.8123632 },
  { lat: 49.6554032, lng: 20.808266 },
  { lat: 49.6179738, lng: 20.8030457 },
  { lat: 49.5762138, lng: 20.8309252 },
  { lat: 49.5594341, lng: 20.8433321 },
  { lat: 49.5431793, lng: 20.8552307 },
  { lat: 49.512502, lng: 20.9002765 },
  { lat: 49.4190552, lng: 21.0413319 },
  { lat: 49.4256151, lng: 21.1061216 },
  { lat: 49.4245566, lng: 21.1564973 },
  { lat: 49.4073897, lng: 21.1821754 },
  { lat: 49.4121986, lng: 21.2183568 },
  { lat: 49.4580809, lng: 21.2738116 },
  { lat: 49.4523012, lng: 21.3363287 },
  { lat: 49.4178955, lng: 21.4317401 },
  { lat: 49.417489, lng: 21.4797774 },
  { lat: 49.4351323, lng: 21.529895 },
  { lat: 49.436796, lng: 21.5879289 },
  { lat: 49.4455824, lng: 21.629494 },
  { lat: 49.3902318, lng: 21.7353028 },
  { lat: 49.3589286, lng: 21.7812783 },
  { lat: 49.3844002, lng: 21.8138683 },
  { lat: 49.3848294, lng: 21.8409808 },
  { lat: 49.3069917, lng: 21.9947894 },
  { lat: 49.2639726, lng: 22.0377643 },
  { lat: 49.2191578, lng: 22.0340124 },
  { lat: 49.1958377, lng: 22.1444781 },
  { lat: 49.1760908, lng: 22.2330554 },
  { lat: 49.1529715, lng: 22.2323853 },
  { lat: 49.1451079, lng: 22.3621448 },
  { lat: 49.1033247, lng: 22.4133216 },
  { lat: 49.1028684, lng: 22.4644549 },
  { lat: 49.0817351, lng: 22.6374896 },
  { lat: 49.0513291, lng: 22.7911571 },
  { lat: 49.0162529, lng: 22.8819888 },
  { lat: 49.0960682, lng: 22.8829304 },
  { lat: 49.1740437, lng: 22.7435762 },
  { lat: 49.3255267, lng: 22.7523559 },
  { lat: 49.3672448, lng: 22.7457471 },
  { lat: 49.4089609, lng: 22.7226336 },
  { lat: 49.4884214, lng: 22.6965411 },
  { lat: 49.5093784, lng: 22.65055 },
  { lat: 49.5287669, lng: 22.6385225 },
  { lat: 49.5499372, lng: 22.6704486 },
  { lat: 49.5941272, lng: 22.7055543 },
  { lat: 49.6569815, lng: 22.783356 },
  { lat: 49.686615, lng: 22.7989399 },
  { lat: 49.6956867, lng: 22.8250663 },
  { lat: 49.7136358, lng: 22.8484599 },
  { lat: 49.7637591, lng: 22.899657 },
  { lat: 49.8076471, lng: 22.9578265 },
  { lat: 49.8386794, lng: 22.9739713 },
  { lat: 49.8444593, lng: 22.9980185 },
  { lat: 50.052679, lng: 23.2355068 },
  { lat: 50.1569672, lng: 23.3701421 },
  { lat: 50.2504961, lng: 23.5411151 },
  { lat: 50.2573382, lng: 23.572026 },
  { lat: 50.3041291, lng: 23.4081715 },
  { lat: 50.3423944, lng: 23.4263483 },
  { lat: 50.4122003, lng: 23.3565757 },
  { lat: 50.3635824, lng: 23.2451304 },
  { lat: 50.3943921, lng: 23.1912071 },
  { lat: 50.2903181, lng: 23.0169044 },
  { lat: 50.3408574, lng: 22.8956683 },
  { lat: 50.3942914, lng: 22.785805 },
  { lat: 50.3640779, lng: 22.6670153 },
  { lat: 50.3478685, lng: 22.5097735 },
  { lat: 50.399362, lng: 22.4129684 },
  { lat: 50.4592642, lng: 22.4561306 },
  { lat: 50.4841598, lng: 22.5296097 },
  { lat: 50.5409616, lng: 22.5310714 },
  { lat: 50.5746057, lng: 22.528579 },
  { lat: 50.5838222, lng: 22.4848669 },
  { lat: 50.5952436, lng: 22.3891421 },
  { lat: 50.6560256, lng: 22.2303207 },
  { lat: 50.6753564, lng: 22.1444782 },
  { lat: 50.7544381, lng: 22.2065631 },
  { lat: 50.812663, lng: 22.1065441 },
  { lat: 50.7787833, lng: 21.8925078 },
  { lat: 50.6553359, lng: 21.7537769 },
  { lat: 50.6000174, lng: 21.6631396 },
];
export const Bialystok = [
  { lat: 54.3507334, lng: 22.3558527 },
  { lat: 54.3338957, lng: 22.0963713 },
  { lat: 54.0261515, lng: 22.2905801 },
  { lat: 53.7913804, lng: 22.1551801 },
  { lat: 53.5719457, lng: 22.2026894 },
  { lat: 53.5074416, lng: 22.0345761 },
  { lat: 53.5146385, lng: 21.9435426 },
  { lat: 53.464627, lng: 21.8817595 },
  { lat: 53.4830712, lng: 21.6990537 },
  { lat: 53.46403, lng: 21.4930069 },
  { lat: 53.4166073, lng: 21.2622354 },
  { lat: 53.3692232, lng: 21.1315224 },
  { lat: 53.1177421, lng: 21.0938221 },
  { lat: 53.0524895, lng: 21.3591046 },
  { lat: 52.9581542, lng: 21.417356 },
  { lat: 52.9076514, lng: 21.4818675 },
  { lat: 52.8252142, lng: 21.6304449 },
  { lat: 52.7442734, lng: 21.7606454 },
  { lat: 52.6235603, lng: 21.8416695 },
  { lat: 52.519443, lng: 21.8849541 },
  { lat: 52.3559845, lng: 21.9548299 },
  { lat: 52.3623496, lng: 22.918448 },
  { lat: 52.2901693, lng: 23.1766267 },
  { lat: 52.4044664, lng: 23.2859517 },
  { lat: 52.5531101, lng: 23.4733831 },
  { lat: 52.6266775, lng: 23.7710196 },
  { lat: 52.7164615, lng: 23.9376869 },
  { lat: 52.9604075, lng: 23.9405028 },
  { lat: 53.0991701, lng: 23.8911207 },
  { lat: 53.1774044, lng: 23.8980253 },
  { lat: 53.2424587, lng: 23.8224176 },
  { lat: 53.4588927, lng: 23.6989469 },
  { lat: 53.5177409, lng: 23.6687518 },
  { lat: 53.7734097, lng: 23.5506842 },
  { lat: 53.8788466, lng: 23.5231727 },
  { lat: 53.9576549, lng: 23.5143295 },
  { lat: 54.000091, lng: 23.4834559 },
  { lat: 54.0351893, lng: 23.5281099 },
  { lat: 54.0825306, lng: 23.5199014 },
  { lat: 54.1137471, lng: 23.4957859 },
  { lat: 54.1330878, lng: 23.4950011 },
  { lat: 54.1532461, lng: 23.4851058 },
  { lat: 54.1760152, lng: 23.4583687 },
  { lat: 54.1783318, lng: 23.4261618 },
  { lat: 54.2095795, lng: 23.3961435 },
  { lat: 54.229012, lng: 23.3790581 },
  { lat: 54.232787, lng: 23.3619631 },
  { lat: 54.2511743, lng: 23.338083 },
  { lat: 54.2571182, lng: 23.3114437 },
  { lat: 54.2605582, lng: 23.2423503 },
  { lat: 54.2872367, lng: 23.2020558 },
  { lat: 54.2994708, lng: 23.1603493 },
  { lat: 54.31132, lng: 23.1602029 },
  { lat: 54.3167585, lng: 23.1367055 },
  { lat: 54.3003846, lng: 23.0883418 },
  { lat: 54.3172953, lng: 23.0424013 },
  { lat: 54.3423075, lng: 23.0589831 },
  { lat: 54.3630743, lng: 22.9925121 },
  { lat: 54.381309, lng: 23.0074658 },
  { lat: 54.3959364, lng: 22.9393108 },
  { lat: 54.3994424, lng: 22.8884084 },
  { lat: 54.4101364, lng: 22.8828149 },
  { lat: 54.4083288, lng: 22.8372898 },
  { lat: 54.3727178, lng: 22.8128951 },
  { lat: 54.3650956, lng: 22.788526 },
  { lat: 54.3507334, lng: 22.3558527 },
];
export const Poznan = [
  { lat: 52.4828455, lng: 17.5573185 },
  { lat: 52.4516381, lng: 17.3724542 },
  { lat: 52.3955682, lng: 17.0908362 },
  { lat: 52.3945133, lng: 17.0390105 },
  { lat: 52.3733296, lng: 17.0105517 },
  { lat: 52.3514829, lng: 16.9550741 },
  { lat: 52.3571268, lng: 16.8815198 },
  { lat: 52.3520133, lng: 16.8432818 },
  { lat: 52.3520267, lng: 16.8095113 },
  { lat: 52.352072, lng: 16.7676879 },
  { lat: 52.3676542, lng: 16.7697107 },
  { lat: 52.3849264, lng: 16.7562646 },
  { lat: 52.3963661, lng: 16.7344247 },
  { lat: 52.4043899, lng: 16.7257356 },
  { lat: 52.4140893, lng: 16.7273447 },
  { lat: 52.4154375, lng: 16.7176241 },
  { lat: 52.4089606, lng: 16.4679797 },
  { lat: 52.38437, lng: 16.4134398 },
  { lat: 52.2651824, lng: 16.4601327 },
  { lat: 52.1807464, lng: 16.544073 },
  { lat: 52.1810547, lng: 16.8144487 },
  { lat: 52.1035162, lng: 16.70733 },
  { lat: 51.9749747, lng: 16.8225051 },
  { lat: 51.893827, lng: 16.9298075 },
  { lat: 51.906585, lng: 16.9475012 },
  { lat: 51.9726228, lng: 17.2166713 },
  { lat: 51.9191036, lng: 17.3677385 },
  { lat: 51.8799895, lng: 17.480185 },
  { lat: 51.8343175, lng: 17.5916141 },
  { lat: 51.7431765, lng: 17.8260713 },
  { lat: 51.7426516, lng: 17.8262304 },
  { lat: 51.5985171, lng: 17.9742457 },
  { lat: 51.6198709, lng: 18.0756268 },
  { lat: 51.620214, lng: 18.2100502 },
  { lat: 51.6249618, lng: 18.3622385 },
  { lat: 51.6578442, lng: 18.3631251 },
  { lat: 51.8304355, lng: 18.3815313 },
  { lat: 52.0874059, lng: 18.232506 },
  { lat: 52.1826153, lng: 18.3806304 },
  { lat: 52.2650618, lng: 18.5117928 },
  { lat: 52.4240918, lng: 18.6266461 },
  { lat: 52.4834819, lng: 18.4022602 },
  { lat: 52.4984194, lng: 18.3039675 },
  { lat: 52.5332219, lng: 18.0956359 },
  { lat: 52.6028605, lng: 17.8981014 },
  { lat: 52.5503364, lng: 17.8496246 },
  { lat: 52.526547, lng: 17.7481583 },
  { lat: 52.4828455, lng: 17.5573185 },
];

export const Warszawa2 = [
  { lat: 52.9124425, lng: 19.8851344 },
  { lat: 53.0209156, lng: 19.6784073 },
  { lat: 52.9602562, lng: 19.6756669 },
  { lat: 52.9855552, lng: 19.5576195 },
  { lat: 52.9070975, lng: 19.464579 },
  { lat: 52.8667291, lng: 19.5076175 },
  { lat: 52.8313313, lng: 19.4243484 },
  { lat: 52.7548636, lng: 19.5012823 },
  { lat: 52.7028946, lng: 19.528721 },
  { lat: 52.7224549, lng: 19.4490623 },
  { lat: 52.675069, lng: 19.4573074 },
  { lat: 52.6407718, lng: 19.3784244 },
  { lat: 52.6031384, lng: 19.4204214 },
  { lat: 52.4727876, lng: 19.3264266 },
  { lat: 52.4488562, lng: 19.2558053 },
  { lat: 52.3692463, lng: 19.3034988 },
  { lat: 52.338262, lng: 19.4202047 },
  { lat: 52.2603689, lng: 19.6416479 },
  { lat: 52.2739512, lng: 19.8507309 },
  { lat: 52.2629229, lng: 19.9812541 },
  { lat: 52.2354996, lng: 20.0766283 },
  { lat: 52.1491483, lng: 20.1127826 },
  { lat: 52.1293328, lng: 20.3821603 },
  { lat: 52.131894, lng: 20.5485799 },
  { lat: 52.1699445, lng: 20.7542101 },
  { lat: 52.1876024, lng: 20.7779208 },
  { lat: 52.2005013, lng: 20.8362012 },
  { lat: 52.2437746, lng: 20.8957605 },
  { lat: 52.2496094, lng: 20.9369433 },
  { lat: 52.2852169, lng: 20.9877577 },
  { lat: 52.2944081, lng: 20.9678582 },
  { lat: 52.3119368, lng: 20.963643 },
  { lat: 52.4366858, lng: 20.9886632 },
  { lat: 52.5080031, lng: 21.0735614 },
  { lat: 52.5526422, lng: 21.0891061 },
  { lat: 52.5926829, lng: 21.1248036 },
  { lat: 52.7229478, lng: 21.4730531 },
  { lat: 52.7758949, lng: 21.5315888 },
  { lat: 52.819031, lng: 21.6411691 },
  { lat: 52.9076514, lng: 21.4818675 },
  { lat: 52.9581542, lng: 21.417356 },
  { lat: 53.0524895, lng: 21.3591046 },
  { lat: 53.1177421, lng: 21.0938221 },
  { lat: 52.9408666, lng: 20.9351614 },
  { lat: 52.9124425, lng: 19.8851344 },
];
export const Poznan2 = [
  { lat: 53.2412481, lng: 16.1746901 },
  { lat: 53.2081062, lng: 16.1759228 },
  { lat: 53.1833939, lng: 16.1771497 },
  { lat: 53.1701143, lng: 16.1773694 },
  { lat: 53.1551375, lng: 16.1788362 },
  { lat: 53.1155349, lng: 16.1809793 },
  { lat: 53.0512058, lng: 16.1571318 },
  { lat: 52.9073147, lng: 16.1068195 },
  { lat: 52.8614075, lng: 16.0568068 },
  { lat: 52.7748161, lng: 15.9575817 },
  { lat: 52.6787837, lng: 15.8537214 },
  { lat: 52.5714021, lng: 15.735334 },
  { lat: 52.5240081, lng: 15.9523027 },
  { lat: 52.4806496, lng: 16.226852 },
  { lat: 52.4668469, lng: 16.2762919 },
  { lat: 52.4154401, lng: 16.3813035 },
  { lat: 52.3847778, lng: 16.413625 },
  { lat: 52.4093683, lng: 16.4681654 },
  { lat: 52.412253, lng: 16.5864563 },
  { lat: 52.4154249, lng: 16.7178109 },
  { lat: 52.4138797, lng: 16.7275159 },
  { lat: 52.4041803, lng: 16.7259068 },
  { lat: 52.3961565, lng: 16.7345959 },
  { lat: 52.3847168, lng: 16.7564357 },
  { lat: 52.3674446, lng: 16.7698817 },
  { lat: 52.3510108, lng: 16.7654717 },
  { lat: 52.352014, lng: 16.8096981 },
  { lat: 52.3520006, lng: 16.8434686 },
  { lat: 52.3571141, lng: 16.8817066 },
  { lat: 52.3514702, lng: 16.9552608 },
  { lat: 52.3733169, lng: 17.0107385 },
  { lat: 52.3945006, lng: 17.0391973 },
  { lat: 52.3955555, lng: 17.0910229 },
  { lat: 52.4516252, lng: 17.3726409 },
  { lat: 52.482728, lng: 17.5576763 },
  { lat: 52.5502188, lng: 17.8499816 },
  { lat: 52.6032678, lng: 17.8983004 },
  { lat: 52.6350882, lng: 17.8775372 },
  { lat: 52.6554927, lng: 17.7621045 },
  { lat: 52.6641831, lng: 17.6368772 },
  { lat: 52.7081664, lng: 17.4781904 },
  { lat: 52.7489314, lng: 17.4441203 },
  { lat: 52.7857138, lng: 17.4134279 },
  { lat: 52.8831211, lng: 17.3221505 },
  { lat: 52.9274561, lng: 17.3289182 },
  { lat: 53.0296058, lng: 17.3069185 },
  { lat: 53.1115374, lng: 17.2088961 },
  { lat: 53.1849149, lng: 17.2073764 },
  { lat: 53.250011, lng: 17.05179 },
  { lat: 53.2929904, lng: 16.7810324 },
  { lat: 53.3416245, lng: 16.5565463 },
  { lat: 53.2705078, lng: 16.2993549 },
  { lat: 53.2412481, lng: 16.1746901 },
];
