import { OrderLineDto } from "@megaron/crm-contracts";
import { Dialog } from "@megaron/dash-dialog";
import { useDeviceType } from "@megaron/dash-mq";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useClientManager } from "@megaron/react-clients";

import { ItemDialogContent } from "./ItemDialogContent";

type Props = {
  onClose: () => void;
  line: OrderLineDto;
  onEditLine: (line: OrderLineDto) => void;
};

export const EditItemDialog: React.FC<Props> = ({ onClose, onEditLine, line }) => {
  const { isMobile } = useDeviceType();

  const itemQuery = useClientManager("crm").itemQuery().useQuery({ itemId: line.itemId });

  return (
    <Dialog
      onClose={onClose}
      placement={isMobile ? "top" : "center"}
      css={{ width: isMobile ? "100%" : "400px" }}
      header={itemQuery.data?.itemName ?? ""}
    >
      <QuerySkeleton query={itemQuery} height="158px" width="100%">
        {(item) => <ItemDialogContent item={item} onSave={onEditLine} lineId={line.id} onDelete={() => {}} />}
      </QuerySkeleton>
    </Dialog>
  );
};
