import { useTheme } from "@emotion/react";

import { Avatar } from "@megaron/dash-avatar";
import { useProfiles } from "@megaron/megarax-webapps";

type Props = {
  regionOwnerEmail: string | null;
  region: string | null;
};

export const RegionCell: React.FC<Props> = ({ regionOwnerEmail, region }) => {
  const theme = useTheme();

  const { profile } = useProfiles();
  const ownerProfile = profile(regionOwnerEmail);

  if (!region) {
    return null;
  }

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        fontSize: "0.9375rem",
        color: theme.colors.primary,
      }}
    >
      <div
        css={{
          height: "27px",
          cursor: "pointer",
          "&:hover": {
            transform: "scale(1.1)",
            transformOrigin: "center",
            transition: "transform 0.15s",
          },
        }}
      >
        <Avatar src={ownerProfile ? ownerProfile?.profilePictureUrl : null} size="27px" />
      </div>
      {region}
    </div>
  );
};
