import { useTheme } from "@emotion/react";
import { IconType } from "react-icons/lib";
import {
  MdBarChart,
  MdFormatListBulleted,
  MdMap,
  MdOutlineAddChart,
  MdOutlineHome,
  MdOutlineSave,
} from "react-icons/md";
import { Link, useParams } from "react-router-dom-v5-compat";
import tinycolor from "tinycolor2";

import { useDialogRoute } from "@megaron/dash-dialog";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";

import { AddViewDialog } from "./AddViewDialog";
import { AllViewsDialog } from "./AllViewsDialog";

export const ViewsBar = () => {
  const theme = useTheme();

  const { isMobile } = useDeviceType();

  const { viewType } = useParams<{ viewType: CustomerViewName }>();

  const buttonStyles = {
    background: theme.colors.primaryLight,
    color: theme.colors.primary,
    padding: "0.75rem 1rem",
    fontFamily: theme.displayFontFamily,
    fontWeight: 400,
    fontSize: "0.9375rem",
    border: "none",
    borderTopLeftRadius: theme.extraSmallBorderRadius,
    borderTopRightRadius: theme.extraSmallBorderRadius,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    marginRight: "0.5rem",
  };

  const getViewTileStyles = (isActive?: boolean) =>
    ({
      display: "flex",
      alignItems: "center",
      gap: "0.625rem",
      padding: "0.75rem 1rem",
      border: isActive ? `1px solid ${tinycolor(theme.colors.primary).setAlpha(0.4)}` : "1px solid transparent",
      borderBottom: "1px solid transparent",
      borderTopLeftRadius: theme.smallBorderRadius,
      borderTopRightRadius: theme.smallBorderRadius,
      cursor: "pointer",
      zIndex: 2,
      background: isActive ? theme.colors.background : "transparent",
      color: theme.colors.primary,
      fontWeight: isActive ? 700 : 400,
      fontSize: "0.875rem",
      transition: "all 0.15s",
      textWrap: "nowrap",
    } as const);

  const addViewDialog = useDialogRoute("/add-view", ({ onClose }) => <AddViewDialog onClose={onClose} />);

  const allViewsDialog = useDialogRoute("/all-views", ({ onClose }) => <AllViewsDialog onClose={onClose} />);

  return (
    <div
      css={{
        display: "flex",
        padding: isMobile ? "1rem 1rem 0" : "1rem 2rem 0",
        width: "100%",
        background: "white",
        overflow: "auto",
        position: "relative",
      }}
    >
      <div
        css={{
          position: "absolute",
          height: "1px",
          width: "100%",
          bottom: 0,
          left: 0,
          background: theme.colors.primary,
          opacity: 0.4,
        }}
      />
      <Link to={`/crm/customers/analytics/all-customers`} css={buttonStyles}>
        <MdOutlineHome size={18} css={{ flexShrink: 0 }} />
      </Link>
      <button css={buttonStyles} onClick={addViewDialog.open}>
        <MdOutlineAddChart size={18} css={{ flexShrink: 0 }} />
      </button>

      {customersViews.map((view) => {
        const isActive = viewType === view.name;

        const styles = getViewTileStyles(isActive);

        return (
          <Link to={`/crm/customers/analytics/${view.name}`} key={view.name} css={styles}>
            <view.Icon size={14} css={{ flexShrink: 0 }} />
            {view.label}
          </Link>
        );
      })}

      <button css={getViewTileStyles()} onClick={allViewsDialog.open}>
        <MdFormatListBulleted size={14} css={{ flexShrink: 0 }} />
        Wszystkie widoki
      </button>

      <Button icon={<MdOutlineSave />} css={{ marginLeft: "auto", alignSelf: "flex-start" }}>
        Zapisz
      </Button>

      {addViewDialog.element}
      {allViewsDialog.element}
    </div>
  );
};

export type CustomerViewName =
  | "all-customers"
  | "loyalty-customers"
  | "pos-customers"
  | "all-customers-map"
  | "analytics-1"
  | "all-views";

type CustomerView = {
  label: string;
  name: CustomerViewName;
  Icon: IconType;
  type: "customerList" | "customerMap" | "analytics";
};

const customersViews: CustomerView[] = [
  { label: "Mapa klientów", name: "all-customers-map", Icon: MdMap, type: "customerMap" },
  { label: "Analiza 1", name: "analytics-1", Icon: MdBarChart, type: "analytics" },
] as const;
