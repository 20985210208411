import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { baseDocSerializer } from "../baseDoc";

export const savedViewTypes = ["customerList", "customerMap", "customerAnalytics"] as const;
export type SavedViewType = (typeof savedViewTypes)[number];

export const savedViewDocSerializer = Serializers.object({
  ...baseDocSerializer("saved-view").fields,

  id: uuidSerializer,
  title: Serializers.string,
  description: Serializers.string,
  viewType: Serializers.stringOneOf(...savedViewTypes),
  options: Serializers.identity(),
  isArchived: Serializers.boolean,
  owner: Serializers.string,
  createdAt: Serializers.datetime,
  updatedAt: Serializers.datetime,
  bookmarkedBy: Serializers.array(Serializers.string),
  isPrivate: Serializers.boolean,
});

export type SavedViewDoc = SerializerValue<typeof savedViewDocSerializer>;

export const savedViewActions = {
  saveView: HttpAction({
    path: "/savedViews",
    method: "put",
    requiresAuth: true,
    bodySerializer: Serializers.object({
      id: uuidSerializer,
      title: Serializers.string,
      description: Serializers.string,
      viewType: Serializers.stringOneOf(...savedViewTypes),
      options: Serializers.identity(),
      isArchived: Serializers.boolean,
      isPrivate: Serializers.boolean,
    }),
  }),

  searchSavedViews: HttpAction({
    path: "/savedViews",
    method: "get",
    requiresAuth: true,
    qsSerializer: Serializers.object({
      searchText: Serializers.string,
      limit: Serializers.integer,
      offset: Serializers.integer,
      viewType: Serializers.stringOneOf(...savedViewTypes),
      isArchived: Serializers.booleanString,
      isBookmarked: Serializers.booleanFieldFilter,
    }).partial(),
    valueSerializer: Serializers.paginatedList(savedViewDocSerializer),
  }),

  saveBookmark: HttpAction({
    path: "/savedViews/:savedViewId/bookmark",
    method: "put",
    requiresAuth: true,
    paramsSerializer: Serializers.object({
      savedViewId: uuidSerializer,
    }),
    bodySerializer: Serializers.object({
      isBookmarked: Serializers.boolean,
    }),
    errorSerializer: Serializers.stringOneOf("ViewNotFound"),
  }),
};
