import { useTheme } from "@emotion/react";
import { formatCurrency } from "@megarax/react-utils";
import { Link } from "react-router-dom-v5-compat";

import { DottedProgress } from "@megaron/dash-progress";
import { RowCellContent, Table, TableBody, TableHeadColumn } from "@megaron/dash-table";
import { OrderDoc, OrderSortField } from "@megaron/docs-contracts";

import { getBackgroundColor, getTextColor } from "./helpers";
import { OrderStatus } from "./OrderStatus";
import { RegionCell } from "./RegionCell";

type Props = {
  orders: OrderDoc[];
  isLoading: boolean;
  activeSortValue: { field: OrderSortField; order?: "ASC" | "DESC" | undefined } | undefined;
  onSortChange: (value: { field: OrderSortField; order?: "ASC" | "DESC" | undefined } | undefined) => void;
};

export const OrdersTable: React.FC<Props> = ({ orders, isLoading, activeSortValue, onSortChange }) => {
  const theme = useTheme();

  const tableColumns: TableHeadColumn<OrderSortField>[] = [
    {},
    ...tableHeadingList.map(({ heading, sortField }) =>
      sortField
        ? ({
            isSortable: true,
            sortFieldName: sortField,
            label: heading,
          } as const)
        : ({
            isSortable: false,
            label: heading,
          } as const),
    ),
  ];

  const getTableRowCellsContent = (order: OrderDoc): RowCellContent[] => {
    const backgroundColor = order.profit.greaterThanOrEqualTo(0)
      ? getBackgroundColor(order.status, theme)
      : theme.colors.danger;
    const textColor = order.profit.greaterThanOrEqualTo(0) ? getTextColor(order.status, theme) : theme.colors.danger;

    const isProfitNegative = order.profit.lessThan(0);

    return [
      {
        isLink: true,
        element: (
          <div
            css={{
              position: "absolute",
              inset: 0,
              background: backgroundColor,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        ),
      },
      {
        isLink: true,
        element: <span css={{ color: textColor, textWrap: "nowrap" }}>{order.orderDate}</span>,
      },
      {
        isLink: true,
        element: <span css={{ color: textColor, textWrap: "nowrap" }}>{order.referenceNumber}</span>,
      },
      {
        cellCss: { textWrap: "nowrap" },
        element: <Link to={`/crm/customers/id/${order.sellToId}`}>{order.sellToName}</Link>,
      },
      {
        cellCss: { textWrap: "nowrap" },
        element: <Link to={`/crm/customers/id/${order.billToId}`}>{order.billToName}</Link>,
      },
      {
        isLink: true,
        element: <OrderStatus status={order.status} css={{ color: textColor, fontSize: "0.9375rem" }} />,
      },
      {
        isLink: true,
        element: (
          <div
            css={{
              display: "flex",
              gap: "0.625rem",
              alignItems: "center",
            }}
          >
            <span
              css={{
                color: isProfitNegative ? theme.colors.danger : theme.colors.primary,
                fontWeight: 700,
                marginLeft: "auto",
                minWidth: "90px",
              }}
            >
              {order.profit.greaterThan(0) && "+"}
              {formatCurrency(order.profit, order.currency ?? "PLN")}
            </span>
            <DottedProgress
              singleDotValue={1000}
              color={isProfitNegative ? "danger" : "primary"}
              currentValue={isProfitNegative ? Number(-order.profit.toFixed(2)) : Number(order.profit.toFixed(2))}
            />
          </div>
        ),
      },
      {
        isLink: true,
        element: formatCurrency(order.netTotal.toNumber(), order.currency ?? "PLN"),
      },
      {
        isLink: true,
        element: <RegionCell region={order.region} regionOwnerEmail={order.regionOwnerEmail} />,
      },
    ];
  };

  return (
    <div css={{ overflow: "auto", margin: "0 -2rem 1rem" }}>
      <Table<OrderSortField>
        columns={tableColumns}
        activeSortValue={activeSortValue}
        onSort={onSortChange}
        css={{ padding: "0 2rem" }}
      >
        <TableBody
          isLoading={isLoading}
          rows={orders.map((order) => ({
            uuid: order.id,
            linkTo: `/crm/orders/${order.id}`,
            cellsContent: getTableRowCellsContent(order),
            css: {
              "&:hover": {
                "td:nth-child(1)": {
                  textDecoration: "underline",
                },
              },
            },
          }))}
        />
      </Table>
    </div>
  );
};

const tableHeadingList: { heading: string; sortField?: OrderSortField }[] = [
  { heading: "Data", sortField: "orderDate" },
  { heading: "Numer", sortField: "referenceNumber" },
  { heading: "Nabywca", sortField: "sellToName" },
  { heading: "Odbiorca faktury", sortField: "billToName" },
  { heading: "Status" },
  { heading: "Marża" },
  { heading: "Wartość netto" },
  { heading: "Region" },
];
