import { useTheme } from "@emotion/react";
import Decimal from "decimal.js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { CustomerCategory as CustomerCategoryType } from "@megaron/crm-contracts";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { HeaderBar, Page } from "@megaron/dash-page";
import { useToast } from "@megaron/dash-toast";
import { useClientManager } from "@megaron/react-clients";
import { useRegion } from "@megaron/region-context";
import { isPhoneNumber, PhoneNumber } from "@megaron/serializers";
import { sleep } from "@megaron/utils";
import { newUuid } from "@megaron/uuid";

import { getLatLng } from "../CustomerDetails";
import { CustomerCategorySection, CustomerType } from "./CustomerCategorySection";
import { CustomerDetails } from "./CustomerDetails";
import { CustomerTypeSection } from "./CustomerTypeSection";

export type CustomerCategory = Exclude<CustomerCategoryType, "loyaltyUser">;

export const AddCustomerPage = () => {
  const { isMobile } = useDeviceType();

  const toast = useToast();

  const navigate = useNavigate();

  const { region: userRegion } = useRegion();

  const [customerType, setCustomerType] = useState<CustomerType>("customer");
  const [customerCategories, setCustomerCategories] = useState<CustomerCategory[]>([]);

  const [phoneNumber, setPhoneNumber] = useState<PhoneNumber>("+48" as PhoneNumber);
  const [acronym, setAcronym] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [placeId, setPlaceId] = useState<string | null>(null);
  const [latitude, setLatitude] = useState<Decimal | null>(null);
  const [longitude, setLongitude] = useState<Decimal | null>(null);
  const [region, setRegion] = useState<string | null>(userRegion);

  useEffect(() => {
    setRegion(userRegion);
  }, [userRegion]);

  useEffect(() => {
    if (customerType === "firm") {
      setCustomerCategories(["pos"]);
    }

    if (customerType === "customer") {
      setCustomerCategories([]);
    }
  }, [customerType]);

  useEffect(() => {
    const getCoordinates = async () => {
      if (street || postalCode) {
        const [latitude, longitude] = await getLatLng(street, postalCode);

        setLatitude(latitude);
        setLongitude(longitude);
      }
    };

    getCoordinates();
  }, [street, postalCode]);

  const nearCustomersQuery = useClientManager("docs")
    .searchCustomers()
    .useQuery(
      {
        nearCoordinates: {
          latitude: latitude?.toNumber() as number,
          longitude: longitude?.toNumber() as number,
          rangeKm: 0.1,
        },
      },
      { enabled: !!latitude && !!longitude },
    );

  const handleCustomerCategoryChange = (value: CustomerCategory) => {
    if (customerCategories.includes(value)) {
      setCustomerCategories(customerCategories.filter((category) => category !== value));
      return;
    }

    setCustomerCategories([...customerCategories, value]);
  };

  const customerDetailsProps =
    customerType === "firm"
      ? ({
          isFirm: true,
          customerData: {
            acronym,
            street,
            postalCode,
            city,
            country,
            placeId,
            latitude,
            longitude,
            region,
          },
          handlers: {
            onAcronymChange: setAcronym,
            onStreetChange: setStreet,
            onPostalCodeChange: setPostalCode,
            onCityChange: setCity,
            onCountryChange: setCountry,
            onPlaceIdChange: setPlaceId,
            onLatitudeChange: setLatitude,
            onLongitudeChange: setLongitude,
            onRegionChange: setRegion,
          },
        } as const)
      : ({
          isFirm: false,
          customerData: {
            phoneNumber,
            region,
          },
          handlers: {
            onPhoneNumberChange: setPhoneNumber,
            onRegionChange: setRegion,
          },
        } as const);

  const preregisterMut = useClientManager("loyaltyAccounts").findAccount().useMutation();
  const addCustomerMut = useClientManager("crm").saveCustomer().useMutation();

  const getAccount = async (phoneNumber: PhoneNumber) => {
    const isValidPhoneNumber = isPhoneNumber(phoneNumber);

    if (!isValidPhoneNumber) {
      toast.error(`Nieprawidłowy format numeru telefonu. Upewnij się, że numer jest w formacie "+48000000000".`);
      return;
    }

    try {
      const account = await preregisterMut.mutateAsync({
        phoneNumber: phoneNumber,
        preregister: true,
      });

      // Wait for the preregistration to be handled by CRM
      if (account.isNew) await sleep(1500);

      return account;
    } catch (e) {
      toast.error(
        `Nie udało się odnaleźć konta dla numeru telefonu: ${phoneNumber}. Upewnij się, że numer jest w formacie "+48000000000".`,
      );
      return;
    }
  };

  const addLoyaltyCustomer = async () => {
    if (!region) {
      toast.error("Region jest wymagany");
      return;
    }

    const account = await getAccount(phoneNumber);

    if (!account) {
      return;
    }

    addCustomerMut.mutate(
      { region, uuid: account.uuid, categories: ["loyaltyUser"] },
      {
        onSuccess: (r) => {
          navigate(`/crm/customers/id/${account.uuid}`, { replace: true });
        },
        onError: (e) => {
          toast.error("Nie udało się dodać klienta");
        },
      },
    );
  };

  const addPosCustomer = async () => {
    if (!acronym) {
      toast.error("Akronim jest wymagany");
      return;
    }

    if (!region) {
      toast.error("Region jest wymagany");
      return;
    }

    if (street || postalCode) {
      const [latitude, longitude] = await getLatLng(street, postalCode);

      setLatitude(latitude);
      setLongitude(longitude);
    }

    addCustomerMut.mutate(
      {
        uuid: newUuid(),
        name: acronym,
        address: {
          street,
          postalCode,
          city,
          country,
          googlePlaceId: placeId,
          latitude: latitude,
          longitude: longitude,
        },
        region,
        phoneNumber,
        categories: customerCategories.includes("pos") ? customerCategories : [...customerCategories, "pos"],
      },
      {
        onSuccess: (r) => {
          navigate(`/crm/customers/id/${r.uuid}`, { replace: true });
        },
        onError: (e) => {
          if (e === "NameNotUnique") {
            toast.error("Klient o podanym akronimie już istnieje");
            return;
          }
          toast.error("Nie udało się dodać klienta");
        },
      },
    );
  };

  const handleSave = async () => {
    if (customerType === "firm") {
      await addPosCustomer();
      return;
    }

    await addLoyaltyCustomer();
  };

  return (
    <>
      <HeaderBar header="Nowy klient" />

      <Page
        css={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "2rem",
          padding: isMobile ? "0 1rem" : "0 2rem",
          marginTop: "83px",
          gap: "2rem",
        }}
      >
        <div
          css={{
            display: "flex",
            gap: "2rem",
            width: "100%",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              width: "100%",
            }}
          >
            <CustomerTypeSection customerType={customerType} onCustomerTypeChange={setCustomerType} />

            <CustomerCategorySection
              selectedCategories={customerCategories}
              onCategoryChange={handleCustomerCategoryChange}
              isDisabled={customerType !== "firm"}
            />
          </div>

          <CustomerDetails
            {...customerDetailsProps}
            isLoading={addCustomerMut.isLoading || preregisterMut.isLoading}
            getAccount={getAccount}
            nearCustomers={nearCustomersQuery.data?.items}
          />
        </div>

        <Button css={{ alignSelf: "flex-end" }} onClick={handleSave} isLoading={addCustomerMut.isLoading}>
          Dalej
        </Button>
      </Page>
    </>
  );
};

export const SectionHeader = ({ header }: { header: string }) => {
  const theme = useTheme();

  return <h4 css={{ fontSize: "1rem", fontWeight: 700, color: theme.colors.primary, margin: 0 }}>{header}</h4>;
};
