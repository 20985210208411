import { useTheme } from "@emotion/react";

type Props = {
  label: string;
};

export const TableHeadingCell: React.FC<Props> = ({ label }) => {
  const theme = useTheme();

  return (
    <div
      css={{
        background: theme.colors.primaryLight,
        color: theme.colors.primary,
        padding: "0.5rem 0.75rem",
        fontFamily: theme.displayFontFamily,
        fontWeight: 400,
        fontSize: "0.9375rem",
        width: "100%",
        textAlign: "left",
        borderTopLeftRadius: theme.extraSmallBorderRadius,
        borderTopRightRadius: theme.extraSmallBorderRadius,
      }}
    >
      {label}
    </div>
  );
};
