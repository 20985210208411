import { HttpAction, HttpService } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { CustomerDetail, customerStatuses, VisitLocation } from "../customer";
import { orderDraftActions } from "./orderDraft";
import { payerActions } from "./payer";
import { priceListActions } from "./priceList";
import { priceReportActions } from "./priceReport";
import { tradeRouteActions } from "./tradeRoute";
import { visitActions } from "./visit";
import { visitScheduleActions } from "./visitSchedule";

const visitLocationSerializer = Serializers.object<VisitLocation>({
  name: Serializers.string,
  locality: Serializers.string,
  street: Serializers.string,
  postalCode: Serializers.string,
  country: Serializers.string,
  lat: Serializers.decimal,
  lng: Serializers.decimal,
});

const customerDetailSerializer = Serializers.object<CustomerDetail>({
  uuid: uuidSerializer,
  name: Serializers.string,
  regionUuid: uuidSerializer,
  chainUuid: uuidSerializer.nullable(),
  createdAt: Serializers.datetime,
  createdBy: Serializers.integer,
  lastModificationAt: Serializers.datetime,
  lastModificationBy: Serializers.integer,
  tags: Serializers.array(Serializers.string),
  visitLocation: visitLocationSerializer.nullable(),
  status: Serializers.stringOneOf(...customerStatuses),
});

const getAllCrmCustomers = HttpAction({
  path: "/crm/customers/allCustomers",
  method: "get",
  valueSerializer: customerDetailSerializer.array(),
  requiresAuth: true,
});

export const legacyMegaraxHttpService = HttpService({
  getAllCrmCustomers,
  ...tradeRouteActions,
  ...visitActions,
  ...payerActions,
  ...priceListActions,
  ...visitScheduleActions,
  ...priceReportActions,
  ...orderDraftActions,
});

export type { NewVisitDto } from "./visit";
