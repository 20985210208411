import Decimal from "decimal.js";
import _ from "lodash";

import { Failure, Ok } from "@megaron/result";
import { isMonth, Month, Serializers } from "@megaron/serializers";

export type TargetRow = { region: string; targets: { month: string; target: Decimal | null }[] };

export type InputRow = {
  region: string;
  targets: {
    [month: Month]: Decimal | null;
  };
};

export const rawToInput = (raw: unknown) => {
  if (!Array.isArray(raw)) return Failure("InvalidInput");
  if (raw.length === 0) return Failure("InvalidInput");

  const mapped: TargetRow[] = [];

  for (const rawRow of raw) {
    if (typeof rawRow !== "object") return Failure("InvalidInput");
    const row = rawToInputRow(rawRow);
    if (row.isFailure) return row;
    mapped.push(row.value);
  }

  return Ok(mapped);
};

export const rawToInputRow = (raw: { [key: string]: unknown }) => {
  if (!("Nazwa regionu" in raw)) return Failure("MissingRegion");
  if (typeof raw["Nazwa regionu"] !== "string") return Failure("InvalidRegion");

  const allKeys = _.keys(raw);
  const months = allKeys.filter(isMonth);

  const targets: InputRow["targets"] = {};

  for (const month of months) {
    try {
      const monthDeserialized = Serializers.decimal.deserialize(raw[month]);

      if (monthDeserialized.isFailure) {
        return Failure("InvalidMonth");
      }

      targets[month] = monthDeserialized.value ? new Decimal(monthDeserialized.value) : null;
    } catch (err: any) {
      targets[month] = null;
    }
  }

  return Ok<TargetRow>({
    targets: Object.entries(targets).map(([month, target]) => ({ month, target })),
    region: raw["Nazwa regionu"],
  });
};
