import { useTheme } from "@emotion/react";
import { getMonth, getYear } from "date-fns";
import { useState } from "react";
import { MdOutlineCalendarToday } from "react-icons/md";

import { Dialog } from "@megaron/dash-dialog";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { Month } from "@megaron/serializers";

import { FlagFilter } from "./FlagFilter";
import { MonthSelectDialog } from "./MonthSelectDialog";

type Props = {
  startDate: Month | undefined;
  endDate: Month | undefined;
  onDatesChange: (startDate: Month | undefined, endDate: Month | undefined) => void;
};

export const MonthRangeFilter: React.FC<Props> = ({ startDate, endDate, onDatesChange }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const label = startDate && endDate ? `${startDate} - ${endDate}` : "Zakres czasowy";

  return (
    <>
      <FlagFilter
        isActive={!!startDate && !!endDate}
        label={label}
        onClick={() => setIsDialogOpen(true)}
        onDelete={() => onDatesChange(undefined, undefined)}
      />
      {isDialogOpen && (
        <MonthRangeDialog
          initialStartDate={startDate}
          initialEndDate={endDate}
          onDatesChange={onDatesChange}
          onClose={() => setIsDialogOpen(false)}
        />
      )}
    </>
  );
};

const MonthRangeDialog: React.FC<{
  onClose: () => void;
  initialStartDate: Month | undefined;
  initialEndDate: Month | undefined;
  onDatesChange: (startDate: Month | undefined, endDate: Month | undefined) => void;
}> = ({ initialStartDate, initialEndDate, onDatesChange, onClose }) => {
  const { isMobile } = useDeviceType();

  const [isDialogFromOpen, setIsDialogFromOpen] = useState(false);
  const [isDialogToOpen, setIsDialogToOpen] = useState(false);

  const [startMonth, setStartMonth] = useState<number>(
    Number(initialStartDate?.split("-")[1] ?? getMonth(new Date()) + 1),
  );
  const [startYear, setStartYear] = useState<number>(Number(initialStartDate?.split("-")[0] ?? getYear(new Date())));
  const [endMonth, setEndMonth] = useState<number>(Number(initialEndDate?.split("-")[1] ?? getMonth(new Date()) + 1));
  const [endYear, setEndYear] = useState<number>(Number(initialEndDate?.split("-")[0] ?? getYear(new Date())));

  const [startDate, setStartDate] = useState<Month | undefined>(initialStartDate);
  const [endDate, setEndDate] = useState<Month | undefined>(initialEndDate);

  const handleSave = () => {
    if (!startDate || !endDate) {
      return;
    }

    onDatesChange(startDate, endDate);
    onClose();
  };

  return (
    <Dialog
      header="Zakres czasowy"
      onClose={onClose}
      css={{ width: isMobile ? "100%" : "24rem" }}
      placement={isMobile ? "top" : "center"}
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <MonthRangeButton label="Od" month={startDate} onClick={() => setIsDialogFromOpen(true)} />
        <MonthRangeButton label="Do" month={endDate} onClick={() => setIsDialogToOpen(true)} />
        <Button
          onClick={handleSave}
          css={{ marginLeft: "auto", marginTop: "0.5rem" }}
          isDisabled={!startDate || !endDate}
        >
          Zastosuj zakres
        </Button>
      </div>
      {isDialogFromOpen && (
        <MonthSelectDialog
          header="Od"
          onClose={() => setIsDialogFromOpen(false)}
          year={startYear}
          month={startMonth}
          onDateChange={(year, month) => {
            setStartMonth(month);
            setStartYear(year);
            setStartDate(`${year}-${getMonthString(month)}` as Month);

            if (year > endYear || (year === endYear && month > endMonth)) {
              setEndDate(`${year}-${getMonthString(month)}` as Month);
              setEndMonth(month);
              setEndYear(year);
            }
          }}
          checkIsDisabled={(year, month) => month > getMonth(new Date()) + 1 && year >= getYear(new Date())}
        />
      )}
      {isDialogToOpen && (
        <MonthSelectDialog
          header="Do"
          onClose={() => setIsDialogToOpen(false)}
          year={endYear}
          month={endMonth}
          onDateChange={(year, month) => {
            setEndMonth(month);
            setEndYear(year);
            setEndDate(`${year}-${getMonthString(month)}` as Month);

            if (year < startYear || (year === startYear && month < startMonth)) {
              setStartMonth(month);
              setStartYear(year);
              setStartDate(`${year}-${getMonthString(month)}` as Month);
            }
          }}
          checkIsDisabled={(year, month) => month > getMonth(new Date()) + 1 && year >= getYear(new Date())}
        />
      )}
    </Dialog>
  );
};

const MonthRangeButton: React.FC<{ label: string; month: Month | undefined; onClick: () => void }> = ({
  label,
  month,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <div css={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <label
        css={{
          color: theme.colors.primary,
          fontSize: "0.875rem",
          fontFamily: theme.displayFontFamily,
          marginBottom: "0.25rem",
        }}
      >
        {label}
      </label>
      <button
        onClick={onClick}
        css={{
          border: "none",
          width: "100%",
          padding: "0.5rem 0.75rem",
          fontSize: "1rem",
          borderRadius: "6px",
          backgroundColor: theme.colors.primaryLight,
          display: "flex",
          alignItems: "center",
          gap: "0.625rem",
          minHeight: "38px",
        }}
      >
        <MdOutlineCalendarToday size={18} color={month ? theme.colors.primary : theme.colors.secondaryText} />
        <span css={{ color: month ? theme.colors.primary : theme.colors.secondaryText }}>{month ?? "Wybierz..."}</span>
      </button>
    </div>
  );
};

export const getMonthString = (month: number) => {
  return Number(month).toString().padStart(2, "0");
};
