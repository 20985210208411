import { useTheme } from "@emotion/react";
import { formatCurrency } from "@megarax/react-utils";
import Decimal from "decimal.js";
import { MdOutlineMonetizationOn } from "react-icons/md";

import { OrderLineDto } from "@megaron/crm-contracts";
import { Dialog, useDialogRoute } from "@megaron/dash-dialog";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { RowCellContent, Table, TableBody, TableHeadColumn } from "@megaron/dash-table";

type Props = {
  lines: OrderLineDto[];
};

export const MarginButton: React.FC<Props> = ({ lines }) => {
  const { isMobile } = useDeviceType();

  const theme = useTheme();

  const tableColumns: TableHeadColumn<string>[] = [
    ...tableHeadingList.map(
      ({ heading }) =>
        ({
          isSortable: false,
          label: heading,
        } as const),
    ),
  ];

  const getTableRowCellsContent = (line: OrderLineDto): RowCellContent[] => {
    return [
      {
        element: <span css={{ textWrap: "nowrap" }}>{line.itemName}</span>,
      },
      {
        element: line.quantity.toString(),
      },
      {
        element: formatCurrency(line.price?.toNumber(), "PLN"),
      },
    ];
  };

  const rows = [
    ...lines.map((line) => ({
      uuid: line.id,
      cellsContent: getTableRowCellsContent(line),
      css: {
        "td:first-child": {
          padding: "0.75rem",
        },
      },
    })),
    {
      uuid: "total",
      cellsContent: [
        {
          element: "Razem",
          cellCss: { fontWeight: 700, color: theme.colors.primary },
        },
        {
          element: "",
        },
        {
          element: formatCurrency(
            lines.map((line) => line.price).reduce((acc, curr) => acc.plus(curr), new Decimal(0)),
            "PLN",
          ),
          cellCss: { fontWeight: 700, color: theme.colors.primary },
        },
      ],
      css: {
        "td:first-child": {
          padding: "0.75rem",
        },
      },
    },
  ];

  const marginDialog = useDialogRoute("/margins", ({ onClose }) => (
    <Dialog onClose={onClose} header="Marże" placement={isMobile ? "top" : "center"} css={{ width: "400px" }}>
      <div css={{ overflow: "auto", margin: "0 -1rem", padding: "0 1rem" }}>
        <Table columns={tableColumns}>
          <TableBody rows={rows} />
        </Table>
      </div>
    </Dialog>
  ));

  return (
    <>
      <Button variant="outline" icon={<MdOutlineMonetizationOn />} onClick={marginDialog.open}>
        Marże
      </Button>
      {marginDialog.element}
    </>
  );
};

const tableHeadingList = [
  {
    heading: "Przedmiot",
  },
  {
    heading: "Ilość",
  },
  {
    heading: "Marża",
  },
];
