export const megaraxRoles = [
  "test.role",

  "docs.admin",

  "crm.user",
  "crm.supervisor",
  "crm.admin",
  "crm.salesperson-editor",
  "crm.pos-customer-editor",
  "crm.pricing-user",
  "crm.price-list-manager",
  "crm.docs-reader",
  "crm.payer-manager",
  "crm.milage-report-viewer",
  "crm.bi-reader",
  "crm.legacy-internal-client",
  "crm.scheduler",

  "iam.admin",
  "iam.users-admin",
  "iam.reader",

  "invoices.user",
  "invoices.coordinator",
  "invoices.docs-reader",
  "invoices.admin",
  "invoices.scheduler",

  "loyalty-points.admin",
  "loyalty-points.product-code-admin",
  "loyalty-points.viewer",
  "loyalty-points.account-viewer",
  "loyalty-points.code-group-user",
  "loyalty-points.account-reader",
  "loyalty-points.purchase-reader",

  "loyalty-orders.admin",
  "loyalty-orders.supervisor",
  "loyalty-orders.viewer",

  "loyalty-accounts.admin",
  "loyalty-accounts.viewer",

  "loyalty-qr-sync.user",

  "loyalty.bi-reader",
  "loyalty.scheduler",

  "plm.manager",
  "plm.user(komponent)",
  "plm.user(receptura)",
  "plm.user(produkt)",
  "plm.user(wyrob)",
  "plm.user(asortyment)",
  "plm.user(productionRecipe)",

  "dev.admin",
] as const;

export const megaraxRoleWildcard = [
  "docs.*",
  "crm.*",
  "iam.*",
  "invoices.*",
  "loyalty-accounts.*",
  "loyalty-orders.*",
  "loyalty-points.*",
  "loyalty-qr-sync.*",
  "loyalty.*",
  "plm.*",
  "dev.*",
] as const;

export type MegaraxRole = (typeof megaraxRoles)[number];
export type MegaraxRoleWildcard = (typeof megaraxRoleWildcard)[number];

export const pickMegaraxRoles = (...roles: MegaraxRole[]): MegaraxRole[] => roles;
