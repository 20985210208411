import { useTheme } from "@emotion/react";
import { userResource } from "@megarax/iam-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { getMonth, getYear } from "date-fns";
import _ from "lodash";
import { useContext } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom-v5-compat";

import { Avatar } from "@megaron/dash-avatar";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { HeaderBar, Page, SectionHeader } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { IamAuthContext } from "@megaron/iam-auth-react";
import { MyTasksViewContainer } from "@megaron/megarax-v2-feed-task";
import { useProfiles } from "@megaron/megarax-webapps";
import { useClientManager } from "@megaron/react-clients";
import { useRegion } from "@megaron/region-context";
import { sleep } from "@megaron/utils";

import { EdiAlertsContainer } from "../orders/ediAlerts";
import { RegionOrders } from "../regionProfitMetrics/RegionOrders";
import { RegionRealizationSection } from "../regionProfitMetrics/RegionRealizationSection";
import { LatestMentions } from "./LatestMentions";
import { UpcomingVisits } from "./UpcomingVisits";

export const UserHomepage = () => {
  const auth = useContext(IamAuthContext);

  const navigate = useNavigate();

  const theme = useTheme();

  const { isMobile } = useDeviceType();

  const { region } = useRegion();

  const regionQuery = useClientManager("crm")
    .regionsQuery()
    .useQuery({ regionName: region ?? undefined }, { enabled: !!region });

  const regionData = region ? regionQuery.data?.items[0] : undefined;

  const ordersQuery = useClientManager("docs")
    .searchOrders()
    .useQuery({
      region: region ? [region] : undefined,
      limit: region ? 10 : 1000,
    });

  const { profile } = useProfiles();

  const ownerProfile = regionData?.ownerEmail ? profile(regionData.ownerEmail) : null;

  const { list } = useResourceProviderV2(userResource);

  const { data: allUsersDictionary } = useQuery(["allUsers"], () =>
    list({ limit: 1000 })
      .map((result) => result.items)
      .then((res) => _.keyBy(res.value ?? [], "id")),
  );

  if (!auth || !auth.iamUser || auth.iamUser?.userType !== "megarax") {
    return;
  }

  const userEmail = auth.iamUser.email;

  const userId = Object.entries(allUsersDictionary ?? {}).find(([, user]) => user.email === userEmail)?.[0];

  return (
    <>
      <HeaderBar
        header={`${auth.iamUser.firstName} ${auth.iamUser.lastName}`}
        actions={
          <div css={{ display: "flex", alignItems: "center", gap: "0.375rem", fontSize: "1rem", color: "white" }}>
            <Avatar src={ownerProfile?.profilePictureUrl} />
            {region ?? "Brak regionu"}
          </div>
        }
      />

      <Page
        css={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "2rem",
          marginTop: "83px",
          padding: isMobile ? "0 1rem" : "0 2rem",
        }}
      >
        <div
          css={{
            display: "flex",
            gap: "2rem",
            width: isMobile ? "100%" : "360px",
            flexDirection: isMobile ? "column" : "row",
            flexGrow: 1,
            flexShrink: 1,
          }}
        >
          <div css={{ display: "flex", flexDirection: "column", gap: "1rem", flexGrow: 1 }}>
            <MyTasksViewContainer />
            <div css={{ flexGrow: 1, display: "flex", flexDirection: "column", gap: "1rem" }}>
              <SectionHeader
                isHr
                actions={
                  <Button variant="outline" onClick={() => navigate(`/crm/pos/handlowcy/${userId}/wizyty/dzien`)}>
                    Moje wizyty
                  </Button>
                }
              >
                Nadchodzące wizyty
              </SectionHeader>
              {userId && <UpcomingVisits userId={Number(userId)} />}
            </div>
            <div css={{ flexGrow: 1, display: "flex", flexDirection: "column", gap: "1rem" }}>
              <SectionHeader isHr>Ostatnie oznaczenia</SectionHeader>
              <LatestMentions />
            </div>
          </div>
          <div
            css={{
              flexShrink: 1,
              width: isMobile ? "100%" : "360px",
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            {region && (
              <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                <SectionHeader isHr css={{ marginTop: 0 }}>
                  Realizacja planu marży
                </SectionHeader>
                <span css={{ fontSize: "0.875rem", color: theme.colors.primary }}>{getTimeString()}</span>
                <RegionRealizationSection hideTimeRangeSelect region={region} />
                <Link
                  to="/crm/region-profit-metrics"
                  css={{
                    color: theme.colors.primary,
                    textDecoration: "underline",
                    cursor: "pointer",
                    "&:hover": {
                      opacity: 0.8,
                    },
                  }}
                >
                  Realizacja planu marży
                </Link>
              </div>
            )}

            {region && (
              <QuerySkeleton query={ordersQuery} height="158px" width="100%">
                {(orders) => (
                  <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                    <SectionHeader isHr>Ostatnie zamówienia</SectionHeader>
                    <RegionOrders orders={orders.items} region={region} />
                  </div>
                )}
              </QuerySkeleton>
            )}
            <EdiAlertsContainer onEdiChanged={() => sleep(1000).then()} isVertical />
          </div>
        </div>
      </Page>
    </>
  );
};

const getTimeString = () => {
  const today = new Date();

  return `Okres: ${getYear(today)}-${getMonth(today).toString().padStart(2, "0")} — ${getYear(today)}-${(
    getMonth(today) + 1
  )
    .toString()
    .padStart(2, "0")}`;
};
