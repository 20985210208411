import { dateStringSerializer, UuidResourceParam, uuidSerializer } from "@megarax/common";
import { BaseResource, NestedResourceNode, paginatedListSerializer, ResourceActionV2 } from "@megarax/rest-resource";
import { MSerializers } from "@megarax/serializers";

import { reportSubjectSerializer } from "./";
import { priceReportDtoSerializer } from "./myPriceReport";
import { PriceReportInput } from "./reportInput";
import { ReportSubjectFilters } from "./reportSubject";

const searchAction = ResourceActionV2({
  name: "search",
  method: "get",
  path: "search",
  querySerializer: MSerializers.object<ReportSubjectFilters>({
    limit: MSerializers.integer,
    offset: MSerializers.integer,
    searchText: MSerializers.string,
  }),
  responseValueSerializer: paginatedListSerializer(reportSubjectSerializer),
});
export const priceReportSubjectResource = BaseResource(["priceReport", "subjects"], {
  all: ResourceActionV2({
    name: "all",
    method: "get",
    path: "all",
    responseValueSerializer: MSerializers.array(reportSubjectSerializer),
  }),
  search: searchAction,
  update: ResourceActionV2({
    name: "update",
    method: "put",
    path: "",
    requestBodySerializer: MSerializers.array(reportSubjectSerializer),
  }),
});

const myPastReportsAction = ResourceActionV2({
  name: "myPastReports",
  method: "get",
  path: "",
  querySerializer: MSerializers.object({
    customerUuid: uuidSerializer,
  }),
  responseValueSerializer: MSerializers.array(priceReportDtoSerializer),
});

const customerReportsAction = ResourceActionV2({
  name: "customerReports",
  method: "get",
  path: "customerReports",
  querySerializer: MSerializers.object({
    customerUuid: uuidSerializer,
    date: dateStringSerializer,
  }),
  responseValueSerializer: MSerializers.array(priceReportDtoSerializer),
});

export const priceReportResource = BaseResource(["priceReport", "reports"], {
  maintenance: NestedResourceNode("maintenance", {
    rebuild: ResourceActionV2({
      name: "maintenance.rebuild",
      method: "post",
      path: "rebuild",
    }),
  }),
  myPastReports: myPastReportsAction,
  customerReports: customerReportsAction,
  bi: ResourceActionV2({
    name: "priceReportsBiQuery",
    method: "get",
    path: "bi",
    responseValueSerializer: MSerializers.array(
      MSerializers.object({
        articleVarietyName: MSerializers.string,
        articleName: MSerializers.string,
        articleSize: MSerializers.decimal,
        email: MSerializers.string,
        netPrice: MSerializers.decimal,
        region: MSerializers.string,
        time: MSerializers.datetime,
        productGroupName: MSerializers.string,
        customerUuid: uuidSerializer,
        customerName: MSerializers.string,
        latitude: MSerializers.identity<number>(),
        longitude: MSerializers.identity<number>(),
      }),
    ),
  }),
  byUuid: UuidResourceParam("uuid", "uuid", {
    add: ResourceActionV2({
      name: "create",
      path: "",
      method: "post",
      requestBodySerializer: MSerializers.object<PriceReportInput>({
        customerUuid: uuidSerializer,
        subjectUuid: uuidSerializer,
        netPrice: MSerializers.decimal,
      }),
      responseErrorSerializer: MSerializers.stringOneOf("ReportAlreadyExists"),
    }),
  }),
});
