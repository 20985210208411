import { useShallowEffect } from "@mantine/hooks";
import Decimal from "decimal.js";
import { useRef, useState } from "react";
import { useQueryClient } from "react-query";

import { Currency, DraftDto } from "@megaron/invoices-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { useDebouncedState } from "./useDebouncedState";

export type DraftData = {
  issuer: string;
  issuerTaxId: string;
  invoiceNumber: string;
  issueDate: Date;
  dueDate: Date | null;
  receivedDate: Date;
  total: string;
  currency: Currency;
  isDigital: boolean;
  isPayed: boolean;
  title: string;
};

type Props = {
  draft: DraftDto;
  draftQueryKey: string | string[];
};

export const useDraftForm = ({ draft, draftQueryKey }: Props) => {
  const queryClient = useQueryClient();

  const { mutate: saveDraftMutate, isLoading } = useClientManager("invoices").saveDraft().useMutation();

  const [isPayed, setIsPayed] = useState(!draft.invoice.dueDate);
  const [debouncedIssuer, issuer, setIssuer] = useDebouncedState<string>(draft.invoice.issuer);
  const [debouncedIssuerTaxId, issuerTaxId, setIssuerTaxId] = useDebouncedState<string>(draft.invoice.issuerTaxId);
  const [debouncedInvoiceNumber, invoiceNumber, setInvoiceNumber] = useDebouncedState<string>(
    draft.invoice.invoiceNumber,
  );
  const [debouncedIssueDate, issueDate, setIssueDate] = useDebouncedState<Date>(draft.invoice.issueDate);
  const [debouncedDueDate, dueDate, setDueDate] = useDebouncedState<Date | null>(draft.invoice.dueDate);
  const [debouncedReceivedDate, receivedDate, setReceivedDate] = useDebouncedState<Date>(draft.invoice.receivedDate);
  const [debouncedTotal, total, setTotal] = useDebouncedState<string>(draft.invoice.total.toFixed(2));
  const [debouncedCurrency, currency, setCurrency] = useDebouncedState<Currency>(draft.invoice.currency);
  const [debouncedIsDigital, isDigital, setIsDigital] = useDebouncedState<boolean>(draft.invoice.isDigital);
  const [debouncedTitle, title, setTitle] = useDebouncedState(draft.title);

  const wasDraftUpdated = useRef(false);

  useShallowEffect(() => {
    if (!wasDraftUpdated.current) {
      return;
    }

    const handleDraftChange = (draft: DraftDto) => {
      saveDraftMutate(
        { invoice: draft.invoice, title: draft.title, uuid: Uuid(draft.uuid) },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(draftQueryKey);
          },
        },
      );
    };

    const draftData = {
      ...draft,
      invoice: {
        ...draft.invoice,
        issuer: debouncedIssuer,
        issuerTaxId: debouncedIssuerTaxId,
        invoiceNumber: debouncedInvoiceNumber,
        issueDate: debouncedIssueDate,
        dueDate: debouncedDueDate,
        receivedDate: debouncedReceivedDate,
        total: new Decimal(Number(debouncedTotal || 0)),
        currency: debouncedCurrency,
        isDigital: debouncedIsDigital,
      },
      title: debouncedTitle,
    };

    handleDraftChange(draftData);
    // draft should be defined from the start so we don't need to include it in the dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedIssuer,
    debouncedIssuerTaxId,
    debouncedInvoiceNumber,
    debouncedIssueDate.toISOString(),
    debouncedDueDate?.toISOString(),
    debouncedReceivedDate.toISOString(),
    debouncedTotal,
    debouncedCurrency,
    debouncedIsDigital,
    debouncedTitle,
    draftQueryKey,
  ]);

  const draftData: DraftData = {
    issuer,
    issuerTaxId,
    invoiceNumber,
    issueDate,
    isPayed,
    dueDate,
    receivedDate,
    total,
    currency,
    isDigital,
    title,
  };

  const handlers = {
    issuer: setIssuer,
    issuerTaxId: setIssuerTaxId,
    invoiceNumber: setInvoiceNumber,
    issueDate: setIssueDate,
    receivedDate: setReceivedDate,
    total: setTotal,
    dueDate: setDueDate,
    currency: setCurrency,
    isDigital: setIsDigital,
    isPayed: setIsPayed,
    title: setTitle,
  } as const;

  const setDraft =
    <TKey extends keyof typeof handlers>(key: TKey) =>
    (value: Parameters<(typeof handlers)[TKey]>[0]) => {
      if (!wasDraftUpdated.current) {
        wasDraftUpdated.current = true;
      }

      const handler = handlers[key];

      // @ts-expect-error - For some reason Typescript incorrectly infers type of value
      handler(value);
    };

  return { draftData, setDraft, isLoading };
};
