import { Navigate, useParams } from "react-router-dom-v5-compat";

import { Uuid } from "@megaron/uuid";

export const CustomerPageRedirect = () => {
  const { uuid }: { uuid?: Uuid } = useParams();

  if (!uuid) {
    return <Navigate to="/crm/customers" replace />;
  }

  return <Navigate to={`/crm/customers/id/${uuid}`} replace />;
};
