import { TaskDto } from "@megarax/crm-contracts";
import _ from "lodash";
import React from "react";

import AnyTaskGroupCard from "./TaskGroupCard/AnyTaskGroupCard";

interface Props {
  tasks: TaskDto[];
}

export const MyTasksView: React.FunctionComponent<Props> = ({ tasks }) => {
  if (tasks.length === 0) return null;

  const taskGroups = _(tasks)
    .groupBy((task) => task.identifier.type)
    .entries()
    .map(([type, tasks]) => ({
      identifiers: tasks.map((t) => t.identifier),
      type: type,
      time: _.maxBy(tasks, (t) => t.addedAt)!.addedAt,
    }))
    .sortBy((g) => g.time)
    .reverse()
    .value();

  return (
    <div>
      {taskGroups.map((group) => (
        <AnyTaskGroupCard identifiers={group.identifiers} time={group.time} key={group.type} />
      ))}
    </div>
  );
};
