import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { MdShare } from "react-icons/md";

export const ShareButton: React.FC<{ content: string }> = ({ content }) => {
  const theme = useTheme();
  const [shareMethod, setShareMethod] = useState<{ canShare: boolean; link: string }>();

  const [copied, setCopied] = useState<number>(0);

  useEffect(() => {
    const canShare =
      window.navigator.canShare !== undefined &&
      window.navigator.canShare({ url: content }) &&
      window.navigator.share !== undefined;
    setShareMethod({ canShare: canShare, link: content });
  }, [content]);

  return (
    <button
      css={{
        background: theme.colors.primaryLight,
        width: "2rem",
        height: "2rem",
        borderRadius: "100%",
        border: "none",
        padding: 0,
        color: theme.colors.primary,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      disabled={shareMethod === undefined}
      onClick={async () => {
        try {
          if (shareMethod?.canShare) {
            await navigator.share({ url: content });
            return;
          }
          if (navigator.clipboard) {
            await navigator.clipboard.writeText(content);
            setCopied(copied + 1);
          }
        } catch {
          return;
        }
      }}
    >
      <MdShare size={16} />
    </button>
  );
};
