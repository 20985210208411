import { useDebouncedValue, useShallowEffect } from "@mantine/hooks";
import { useRef, useState } from "react";
import { useQueryClient } from "react-query";

import { CustomerAddress, CustomerCategory, CustomerDetails, CustomerStatus } from "@megaron/crm-contracts";
import { useClientManager } from "@megaron/react-clients";
import { Email, isValidEmail } from "@megaron/serializers";
import { Uuid } from "@megaron/uuid";

import { getLatLng } from "./CustomerDetails";
import { UpdateStatus } from "./CustomerPage";

type CustomerData = {
  uuid: Uuid;
  firstName: string;
  lastName: string;
  email: Email | null;
  phoneNumber: string;
  address: CustomerAddress;
  region: string | null;
  status: CustomerStatus;
  categories: CustomerCategory[];
  name: string;
};

type Props = {
  customer: CustomerDetails;
  customerQueryKey: string | string[];
  onStatusChange: (status: UpdateStatus) => void;
};

export const useCustomerDetailsForm = ({ customer, customerQueryKey, onStatusChange }: Props) => {
  const queryClient = useQueryClient();

  const { mutate: updateCustomerMutate, isLoading } = useClientManager("crm").saveCustomer().useMutation();

  const [debouncedFirstName, firstName, setFirstName] = useDebouncedState(customer.firstName);
  const [debouncedLastName, lastName, setLastName] = useDebouncedState(customer.lastName);
  const [debouncedPhoneNumber, phoneNumber, setPhoneNumber] = useDebouncedState(customer.phoneNumber.toString());
  const [debouncedEmail, email, setEmail] = useDebouncedState(customer.email?.toString());
  const [address, setAddress] = useState<CustomerAddress>(customer.address);
  const [region, setRegion] = useState(customer.region);
  const [status, setStatus] = useState<CustomerStatus>(customer.status);
  const [categories, setCategories] = useState<CustomerCategory[]>(customer.categories);
  const [debouncedName, name, setName] = useDebouncedState(customer.name);

  const [emailError, setEmailError] = useState<string | null>(null);
  const [nameError, setNameError] = useState<string | null>(null);

  const wasCustomerUpdated = useRef(false);

  useShallowEffect(() => {
    if (!wasCustomerUpdated.current) {
      return;
    }

    const handleDraftChange = async (customer: CustomerData) => {
      setNameError(null);

      let updatedAddress = { ...address };

      if (address.street || address.postalCode) {
        const [latitude, longitude] = await getLatLng(address.street, address.postalCode);

        updatedAddress = { ...address, latitude, longitude };
      }

      if (debouncedEmail && !isValidEmail(debouncedEmail)) {
        setEmailError("Niepoprawny adres email");
        return;
      }

      setEmailError(null);

      onStatusChange("isLoading");

      updateCustomerMutate(
        { ...customer, address: updatedAddress },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(customerQueryKey);
            onStatusChange("success");
          },
          onError: (error) => {
            onStatusChange("error");

            if (error === "NameNotUnique") {
              setNameError("Nazwa nie jest unikalna");
            }
          },
        },
      );
    };

    const customerData = {
      uuid: customer.uuid,
      firstName: debouncedFirstName,
      lastName: debouncedLastName,
      email: (debouncedEmail as Email) || null,
      phoneNumber: debouncedPhoneNumber,
      address,
      region: region ?? null,
      status,
      categories,
      name: debouncedName,
    };

    handleDraftChange(customerData);
  }, [
    categories,
    customerQueryKey,
    address,
    debouncedEmail,
    debouncedFirstName,
    debouncedLastName,
    debouncedName,
    debouncedPhoneNumber,
    region,
    status,
  ]);

  const customerData: CustomerData = {
    uuid: customer.uuid,
    firstName,
    lastName,
    email: email as Email,
    phoneNumber,
    address,
    region,
    status,
    categories,
    name,
  };

  const handlers = {
    firstName: setFirstName,
    lastName: setLastName,
    phoneNumber: setPhoneNumber,
    email: setEmail,
    address: setAddress,
    region: setRegion,
    status: setStatus,
    categories: setCategories,
    name: setName,
  } as const;

  const setCustomer =
    <TKey extends keyof typeof handlers>(key: TKey) =>
    (value: Parameters<(typeof handlers)[TKey]>[0]) => {
      if (!wasCustomerUpdated.current) {
        wasCustomerUpdated.current = true;
      }

      const handler = handlers[key];

      // @ts-expect-error - For some reason Typescript incorrectly infers type of value
      handler(value);
    };

  return { customerData, setCustomer, isLoading, emailError, nameError };
};

const useDebouncedState = <T>(inputValue: T) => {
  const [value, setValue] = useState(inputValue);
  const [debouncedValue] = useDebouncedValue(value, 200);

  return [debouncedValue, value, setValue] as const;
};
