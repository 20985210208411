import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";
import { Uuid, uuidSerializer } from "@megaron/uuid";

export const sms = {
  broadcastSmsNotification: HttpAction({
    path: "/sms/broadcast",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: Serializers.object<{
      broadcastUuid: Uuid;
      content: string;
      sendToAllUsers: boolean;
      userUuids?: Uuid[];
    }>({
      broadcastUuid: uuidSerializer,
      content: Serializers.string,
      userUuids: Serializers.array(uuidSerializer).optional(),
      sendToAllUsers: Serializers.boolean,
    }),
    valueSerializer: Serializers.object({
      successCount: Serializers.integer,
      failureCount: Serializers.integer,
      skippedCount: Serializers.integer,
    }),
    errorSerializer: Serializers.stringOneOf("ContentTooLong", "InvalidOptions"),
  }),
};
