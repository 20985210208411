import { useTheme } from "@emotion/react";
import { formatDistanceToNow } from "date-fns";
import { pl } from "date-fns/locale";
import { useContext } from "react";
import { MdOutlineBookmarkAdd, MdOutlineBookmarkAdded, MdOutlineViewList } from "react-icons/md";

import { Avatar } from "@megaron/dash-avatar";
import { DocsSelectDialog } from "@megaron/dash-select";
import { Tile } from "@megaron/dash-tile";
import { IamAuthContext } from "@megaron/iam-auth-react";

type Props = {
  onClose: () => void;
};

export const AllViewsDialog: React.FC<Props> = ({ onClose }) => {
  const theme = useTheme();

  const auth = useContext(IamAuthContext);

  const userEmail = auth.iamUser?.userType === "megarax" ? auth.iamUser.email : undefined;

  return (
    <DocsSelectDialog
      onClose={onClose}
      variant="single-select"
      docType={["saved-view"]}
      isOpen
      noTrigger
      label="Wybierz widok"
      renderItem={(view) => {
        if (view.docType !== "saved-view") {
          return null;
        }

        // const Icon = viewOptions.find((v) => v.type === view.viewType)?.Icon;

        const isBookmarked = userEmail && view.bookmarkedBy.includes(userEmail);

        return (
          <Tile key={view.docId} css={{ cursor: "pointer" }}>
            <div css={{ display: "flex", flexDirection: "column", gap: "0.5rem", padding: "0.25rem 0" }}>
              <div css={{ display: "flex", gap: "1rem", justifyContent: "space-between", color: theme.colors.primary }}>
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.75rem",
                    fontSize: "0.875rem",
                  }}
                >
                  {/* todo: add correct icon when view types fixed */}
                  <MdOutlineViewList size={14} />
                  {view.label}
                </div>
                {isBookmarked ? (
                  <MdOutlineBookmarkAdded />
                ) : (
                  <MdOutlineBookmarkAdd css={{ opacity: 0.3, transition: "opacity 0.15s", ":hover": { opacity: 1 } }} />
                )}
              </div>
              <p css={{ margin: 0, color: theme.colors.secondaryText, fontSize: "0.75rem" }}>{view.description}</p>
              <div css={{ display: "flex", alignItems: "center", gap: "0.75rem", alignSelf: "flex-end" }}>
                <span css={{ color: theme.colors.secondaryText, fontSize: "0.75rem" }}>
                  {formatDistanceToNow(view.createdAt, { addSuffix: true, locale: pl })}
                </span>
                <Avatar src={view.imageUrl} size="18px" />
              </div>
            </div>
          </Tile>
        );
      }}
    />
  );
};
