import { v4, v5 } from "uuid";

import { Failure, Ok } from "@megaron/result";
import { Serializer, serializerExtensions, SerializerExtensions } from "@megaron/serializers";

export type Uuid = string & { __brand: "uuid" };

export const newUuid = () => v4() as Uuid;
export const newUuidV5 = (name: string, namespace: string) => v5(name, namespace) as Uuid;

const uuidPattern = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/;

export const validateUuidFormat = (uuid: string) => uuidPattern.test(uuid);

export const validateUuid = (str: string) => {
  if (str.match(uuidPattern) === null) return Failure("InvalidUuid");
  return Ok<Uuid>(str as Uuid);
};

export const uuidSerializer: Serializer<Uuid> & SerializerExtensions<Uuid> = {
  serialize: (uuid) => {
    validateUuid(uuid).assertOk();
    return uuid;
  },
  deserialize: (raw: unknown) => {
    if (typeof raw !== "string") return Failure("NotAString");
    return validateUuid(raw.toLowerCase());
  },
  ...serializerExtensions(),
};

export const Uuid = (uuid: string) => {
  const result = validateUuid(uuid);
  if (result.isFailure) throw new Error(`Failed to create a UUID: ${result.error}`);
  return uuid as Uuid;
};

export const isUuid = (uuid: unknown): uuid is Uuid => {
  if (typeof uuid !== "string") return false;
  return validateUuid(uuid).isOk;
};
