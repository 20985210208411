import { useTheme } from "@emotion/react";
import { PropsWithChildren } from "react";
import { IconType } from "react-icons/lib";
import { MdOutlineSettings, MdOutlineViewList } from "react-icons/md";
import tinycolor from "tinycolor2";

import { useDeviceType } from "@megaron/dash-mq";

type Props = {
  isActive: boolean;
  onClick: (value: string) => void;
  label: string;
  value: string;
  className?: string;
  onSettingsClick?: () => void;
  Icon?: IconType;
} & PropsWithChildren;

export const GroupFilter: React.FC<Props> = ({
  isActive,
  onClick,
  value,
  label,
  className,
  children,
  onSettingsClick,
  Icon = MdOutlineViewList,
}) => {
  const theme = useTheme();

  const { isMobile } = useDeviceType();

  return (
    <button
      css={{
        display: "flex",
        flexDirection: "column",
        padding: "0.75rem",
        background: "white",
        borderRadius: theme.smallBorderRadius,
        gap: "2px",
        minWidth: "11rem",
        cursor: "pointer",
        border: isActive ? `1px solid ${theme.colors.primary}` : `1px solid ${tinycolor("rgb(0, 0, 0)").setAlpha(0.1)}`,
        flexShrink: 0,
      }}
      onClick={() => onClick(value)}
      className={className}
    >
      <div
        css={{
          display: "flex",
          alignItems: "center",
          gap: "0.625rem",
          color: theme.colors.primary,
          fontSize: "0.875rem",
          fontWeight: 700,
          whiteSpace: "nowrap",
          width: "100%",
        }}
      >
        <Icon size={16} />
        {label}
        {isActive && onSettingsClick && !isMobile && (
          <MdOutlineSettings
            css={{ color: theme.colors.secondaryText, marginLeft: "auto" }}
            size={16}
            onClick={(e) => {
              e.stopPropagation();
              onSettingsClick();
            }}
          />
        )}
      </div>
      {children}
    </button>
  );
};
