import { useTheme } from "@emotion/react";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";

export type SortField<TSortField extends string> = {
  field: TSortField;
  order: "ASC" | "DESC" | undefined;
  aggName: string | null;
};

type Props<TSortField extends string> = {
  sortFieldName: TSortField;
  activeSortValue: SortField<TSortField> | undefined;
  onSortChange: (value: SortField<TSortField> | undefined) => void;
  label: string;
  aggName: string | null;
};

export const SortableTableHeadingCell = <TSortField extends string>({
  label,
  sortFieldName,
  activeSortValue,
  onSortChange,
  aggName,
}: Props<TSortField>) => {
  const theme = useTheme();

  const isSortActive = sortFieldName ? activeSortValue?.field === sortFieldName : false;

  const getOrder = (activeOrder: "ASC" | "DESC" | undefined) => {
    if (activeOrder === "ASC") {
      return "DESC";
    }

    if (activeOrder === "DESC") {
      return undefined;
    }

    return "ASC";
  };

  const activeOrder = isSortActive ? activeSortValue?.order : undefined;

  const handleClick = () => {
    const newOrder = getOrder(activeOrder);

    onSortChange(newOrder === undefined ? undefined : { field: sortFieldName, order: newOrder, aggName });
  };

  return (
    <button
      key={label}
      css={{
        background: theme.colors.primaryLight,
        color: theme.colors.primary,
        padding: "0.5rem 0.75rem",
        fontFamily: theme.displayFontFamily,
        fontWeight: 400,
        fontSize: "0.9375rem",
        width: "100%",
        textAlign: "left",
        border: "none",
        display: "flex",
        alignItems: "center",
        gap: "0.25rem",
        whiteSpace: "nowrap",
        cursor: "pointer",
        borderTopLeftRadius: theme.extraSmallBorderRadius,
        borderTopRightRadius: theme.extraSmallBorderRadius,
      }}
      onClick={handleClick}
    >
      {label}
      {activeOrder === "ASC" && <MdArrowUpward size={14} />}
      {activeOrder === "DESC" && <MdArrowDownward size={14} />}
    </button>
  );
};
