import { customAlphabet } from "nanoid";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { Dialog } from "@megaron/dash-dialog";
import { Button } from "@megaron/dash-form";
import { useToast } from "@megaron/dash-toast";
import { toDateString } from "@megaron/date-string";
import { IamAuthContext } from "@megaron/iam-auth-react";
import { useClientManager } from "@megaron/react-clients";

type Props = {
  onClose: () => void;
};

export const NewLabelBatchDialog: React.FunctionComponent<Props> = (props) => {
  const auth = useContext(IamAuthContext);
  const toast = useToast();
  const navigate = useNavigate();

  const saveGroupProperties = useClientManager("loyaltyPoints")
    .saveGroupProperties()
    .useMutation({
      onError: (e) => toast.error("Error", e as any),
    });

  const createGroup = (size?: number) => async () => {
    const date = toDateString(new Date());

    const randomSuffix = customAlphabet("0123456789abcdefghijklmnopqrstuvwxyz")(8);
    const groupName = ["pokrywki", date, randomSuffix].join("_");
    const userEmail = auth.iamUser?.userType === "megarax" ? auth.iamUser?.email ?? "" : "";

    await saveGroupProperties.mutateAsync(
      {
        groupName,
        createdAt: new Date(),
        date: new Date(),
        type: "pokrywki",
        productId: null,
        createdBy: userEmail,
        notes: "",
      },
      {
        onSuccess: () => {
          const qs = new URLSearchParams();
          if (size) qs.set("maxSize", size.toString());

          navigate(`../label-batch/${groupName}?${qs.toString()}`);
        },
      },
    );
  };

  return (
    <Dialog header="Wybierz wielkość grupy" onClose={props.onClose}>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Button onClick={createGroup(50)} css={{ width: "100%" }}>
          50
        </Button>
        <Button onClick={createGroup(60)} css={{ width: "100%" }}>
          60
        </Button>
        <Button onClick={createGroup()} css={{ width: "100%" }}>
          Bez ograniczenia
        </Button>
      </div>
    </Dialog>
  );
};
