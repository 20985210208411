import { Theme, ThemeProvider } from "@emotion/react";
import { useContext, useEffect, useState } from "react";
import { MdOutlineComputer } from "react-icons/md";
import { Outlet, Route } from "react-router-dom-v5-compat";

import { createTheme } from "@megaron/dash-default-theme";
import { Nav, SiteContainer } from "@megaron/dash-nav";
import {
  ACCOUNTS_URL,
  API_URL,
  CRM_URL,
  DOCS_URL,
  DocsPage,
  EnvironmentPage,
  IAM_URL,
  INVOICES_URL,
  LOYALTY_ACCOUNTS_URL,
  ORDERS_URL,
  POINTS_URL,
  QR_SYNC_URL,
  useConfig,
} from "@megaron/dev";
import { LinkDoc } from "@megaron/docs-contracts";
import { IamAuthContext } from "@megaron/iam-auth-react";
import { appLinkTreeToNavEntries, MegaraxNavFooter } from "@megaron/megarax-webapps";
import { filterLinkTree } from "@megaron/megarax-webapps-home";

const devTheme: Theme = createTheme("205");

type Props = {
  linkDocs: LinkDoc[] | undefined;
};

export const useDevRouter: React.FC<Props> = ({ linkDocs }) => {
  return (
    <Route
      path="/dev"
      element={
        <ThemeProvider theme={devTheme}>
          <DevWebappContainer linkDocs={linkDocs} />
        </ThemeProvider>
      }
    >
      <Route path="environment" element={<EnvironmentPage />} />
      <Route path="docs/*" element={<DocsPage />} />
    </Route>
  );
};

const ENVIRONMENT_TEMPLATE = process.env.NX_PUBLIC_ENVIRONMENT_TEMPLATE;

const DevWebappContainer: React.FC<{
  linkDocs: LinkDoc[] | undefined;
}> = ({ linkDocs }) => {
  const fullLinkTree = filterLinkTree(linkDocs || []);
  const devLinkTree = fullLinkTree.find((appTree) => appTree.app === "dev");

  const { iamUser } = useContext(IamAuthContext);

  const { config } = useConfig();
  const {
    accountsUrl,
    loyaltyAccountsUrl,
    apiUrl,
    crmUrl,
    docsUrl,
    iamUrl,
    invoicesUrl,
    ordersUrl,
    pointsUrl,
    qrSyncUrl,
  } = config;

  const [isProductionUsed, setIsProductionUsed] = useState<boolean>(false);

  useEffect(() => {
    if (
      loyaltyAccountsUrl === LOYALTY_ACCOUNTS_URL ||
      apiUrl === API_URL ||
      crmUrl === CRM_URL ||
      docsUrl === DOCS_URL ||
      invoicesUrl === INVOICES_URL ||
      ordersUrl === ORDERS_URL ||
      pointsUrl === POINTS_URL ||
      iamUrl === IAM_URL ||
      accountsUrl === ACCOUNTS_URL ||
      qrSyncUrl === QR_SYNC_URL
    ) {
      setIsProductionUsed(true);
      return;
    }

    setIsProductionUsed(false);
  }, [accountsUrl, loyaltyAccountsUrl, apiUrl, crmUrl, docsUrl, invoicesUrl, ordersUrl, pointsUrl, qrSyncUrl, iamUrl]);
  return (
    <SiteContainer
      nav={
        <Nav
          app="dev"
          title="DEV"
          subtitle="Megarax.net"
          logo={<MdOutlineComputer width="1em" height="1em" />}
          footer={iamUser && <MegaraxNavFooter />}
          entries={appLinkTreeToNavEntries(devLinkTree)}
          showProductionEnvAlert={isProductionUsed && !ENVIRONMENT_TEMPLATE}
        />
      }
    >
      <Outlet />
    </SiteContainer>
  );
};
