import { stringify } from "csv/browser/esm/sync";
import { format } from "date-fns";
import fileDownload from "js-file-download";
import { useState } from "react";
import { MdOutlineFileDownload } from "react-icons/md";

import { CustomerStatus } from "@megaron/crm-contracts";
import { Button } from "@megaron/dash-form";
import { Spinner } from "@megaron/dash-spinner";
import { CustomerDoc } from "@megaron/docs-contracts";
import { useServiceClient } from "@megaron/react-clients";

import { Filters } from "./useCustomersFilters";

type Props = {
  filters: Filters;
};

export const DownloadCustomersCsvButton: React.FC<Props> = ({ filters }) => {
  const docs = useServiceClient("docs");

  const [isFetching, setIsFetching] = useState(false);

  const fetchCsvThreads = async () => {
    const customers = await docs.searchCustomers({
      limit: 10000,
      offset: 0,
      searchText: filters.searchText,
      sort: filters.sort,
      isPro: filters.isPro,
      isAffiliate: filters.isAffiliate,
      tags: filters.tags,
      scannedProducts: filters.scannedProducts,
      regions: filters.regions,
      isUnassigned: filters.isUnassigned,
      categories: filters.categories,
      status: filters.status,
      isActive: filters.isActive,
    });

    return customers.value?.items || [];
  };

  const handleDownload = async () => {
    setIsFetching(true);

    const customers = await fetchCsvThreads();
    setIsFetching(false);

    downloadCsv(customers);
  };

  return (
    <Button
      onClick={handleDownload}
      variant="outline"
      size="small"
      icon={
        isFetching ? (
          <div css={{ width: "20px" }}>
            <Spinner size="16px" />
          </div>
        ) : (
          <MdOutlineFileDownload size={20} />
        )
      }
    >
      Klienci CSV
    </Button>
  );
};

type CustomerFlat = {
  Nazwa: string;
  Status: string;
  "Kod pocztowy": string | undefined;
  Miejscowość: string | undefined;
  Ulica: string | undefined;
  Państwo: string | undefined;
  Region: string | null;
  "Ostatnia wizyta": string | undefined;
  "Ostatnie zamówienie": string | undefined;
};

const customerToCsvEntry = (customer: CustomerDoc): CustomerFlat => ({
  Nazwa: customer.name,
  Status: parseStatusName(customer.status),
  "Kod pocztowy": customer.address?.postalCode,
  Miejscowość: customer.address?.city,
  Ulica: customer.address?.street,
  Państwo: customer.address?.country,
  Region: customer.region,
  "Ostatnia wizyta": customer.lastInteraction?.createdAt
    ? format(customer.lastInteraction?.createdAt, "yyyy-MM-dd, h:mm:ss")
    : undefined,
  "Ostatnie zamówienie": customer.lastPurchaseTime
    ? format(customer.lastPurchaseTime, "yyyy-MM-dd, h:mm:ss")
    : undefined,
});

const downloadCsv = (customers: CustomerDoc[]) => {
  const entries = customers.slice(0, 10000).map(customerToCsvEntry);

  const columns: (keyof CustomerFlat)[] = [
    "Nazwa",
    "Status",
    "Kod pocztowy",
    "Miejscowość",
    "Ulica",
    "Państwo",
    "Region",
    "Ostatnia wizyta",
    "Ostatnie zamówienie",
  ];

  const csv = stringify(entries, {
    header: true,
    columns: columns,
  });
  fileDownload(csv, `klienci_${new Date().toISOString()}.csv`);
};

const parseStatusName = (status: CustomerStatus) => {
  if (status === "unverified") {
    return "Niezweryfikowany";
  }

  if (status === "verified") {
    return "Zweryfikowany";
  }

  if (status === "inactive") {
    return "Nieaktywny";
  }

  if (status === "vip") {
    return "VIP";
  }

  return "Brak";
};
