import { Currency, Gtin } from "@megarax/crm-contracts";
import { DialogLoading } from "@megarax/ui-components";
import Decimal from "decimal.js";
import React from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { MarginsDialog } from "./MarginsDialog";

interface Line {
  uuid: string;
  gtin: Gtin;
  quantity: Decimal;
  netPricePerUnit: Decimal;
}

interface Props {
  lines: Line[];
  payer: { uuid: string };
  currency: Currency;
}

export const MarginsDialogContainer: React.FC<Props> = ({ lines, payer, currency }) => {
  const query = useClientManager("crm")
    .orderTransactionsPreviewQuery()
    .useQuery({
      billToId: Uuid(payer.uuid),
      sellToId: Uuid(payer.uuid),
      lines: lines.map((line) => ({
        itemGtin: line.gtin,
        itemId: undefined,
        price: line.netPricePerUnit,
        quantity: line.quantity,
      })),
    });

  const params = useParams<{ uuid: string }>();
  const navigate = useNavigate();

  const closeDialog = () => navigate(`/crm/pos/moje_zamowienia/${params.uuid}`);

  return (
    <>
      {query.data && (
        <MarginsDialog
          transactions={query.data.transactions}
          totalProfit={query.data.totalProfit}
          closeDialog={closeDialog}
          currency={currency}
        />
      )}
      <DialogLoading loading={query.isLoading} error={query.isError} />
    </>
  );
};
