import { useTheme } from "@emotion/react";
import { useContext } from "react";
import { MdOutlineHome } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { LinkDoc } from "@megaron/docs-contracts";
import { IamAuthContext } from "@megaron/iam-auth-react";
import { filterLinkTree } from "@megaron/megarax-webapps-home";
import { useClientManager } from "@megaron/react-clients";

import { AccountHeader } from "./AccountHeader";
import { AppTile } from "./AppTile";
import { WebPushContext } from "./WebPushContext";

export const MegaraxNavFooter = () => {
  const auth = useContext(IamAuthContext);
  const webPushContext = useContext(WebPushContext);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const { isDesktop } = useDeviceType();

  const searchQuery = useClientManager("docs")
    .search()
    .useQuery({ docType: ["link"], limit: 10000 });

  const unsubscribe = useClientManager("notifications").removeUserWebPushSub().useMutation();

  const links = searchQuery.data?.items as LinkDoc[] | undefined;

  const linkTree = filterLinkTree(links || []);

  const onSignOut = () => {
    if (webPushContext?.endpoint) {
      unsubscribe.mutate({ endpoint: webPushContext.endpoint });
    }
    auth.signOut();
  };

  if (auth.iamUser?.userType !== "megarax") return null;

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        margin: "-1rem",
        marginTop: 0,
        padding: "1rem",
        gap: "0.75rem",
        background: `linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%)`,
      }}
    >
      {auth.iamUser && (
        <AccountHeader
          name={`${auth.iamUser.firstName} ${auth.iamUser?.lastName}`}
          groups={auth.iamUser.groups.map((g) => g.name)}
          profilePictureUrl={auth.iamUser.profilePictureUrl}
          onSignOut={onSignOut}
          css={{ marginBottom: "0.75rem" }}
        />
      )}
      {location.pathname !== "/" && isDesktop && (
        <Button
          icon={<MdOutlineHome css={{ width: "1rem", height: "1rem" }} />}
          css={{
            textAlign: "left",
            background: "rgba(255, 255, 255, 0.15)",
            fontSize: "12px",
            fontFamily: theme.displayFontFamily,
            height: "38px",
          }}
          onClick={() => navigate("/")}
        >
          Strona główna
        </Button>
      )}

      <div
        css={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
          gap: "10px 10px",
          paddingBottom: "1rem",
          alignContent: "space-between",
          justifyContent: "space-between",
          alignItems: "stretch",
        }}
      >
        {linkTree.map(
          (appTree) =>
            !appTree.hideAppLink && (
              <AppTile
                key={appTree.app}
                href={`${appTree.baseUrl}${appTree.appLinkUrl ?? ""}`}
                icon={<appTree.Icon css={{ width: "1rem", height: "1rem" }} />}
                color={appTree.color}
                label={appTree.name}
                isExternal={!appTree.baseUrl?.startsWith("/")}
              />
            ),
        )}
      </div>
    </div>
  );
};
