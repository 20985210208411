import { CustomerPricingRules } from "@megaron/docs-contracts";
import { useClientManager } from "@megaron/react-clients";

type Props = {
  pricingRules: CustomerPricingRules;
};

export const PricingListCell: React.FC<Props> = ({ pricingRules }) => {
  const priceListQuery = useClientManager("legacyMegarax")
    .priceList()
    .useQuery({ priceListUuid: pricingRules?.priceListUuid || "" }, { enabled: !!pricingRules?.priceListUuid });

  return priceListQuery.data?.name ?? "-";
};
