import { MdOutlineHub } from "react-icons/md";

import { useDeviceType } from "@megaron/dash-mq";
import { QuerySkeleton } from "@megaron/dash-skeleton";

import { ContactList } from "./ContactList";
import { CustomerQuery } from "./CustomerPage";
import { InvitedCustomers } from "./InvitedCustomers";
import { RelatedCustomersList } from "./RelatedCustomersList";
import { RelationList } from "./RelationList";
import { TabHeader } from "./TabHeader";

type Props = {
  customerQuery: CustomerQuery;
};

export const CustomerRelations: React.FC<Props> = ({ customerQuery }) => {
  const { isMobile } = useDeviceType();

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {!isMobile && <TabHeader icon={<MdOutlineHub size={18} />} text="Relacje" />}

      <QuerySkeleton height="41px" query={customerQuery}>
        {(customer) => <RelationList customerUuid={customer.uuid} customerCategories={customer.categories} />}
      </QuerySkeleton>
      <QuerySkeleton height="41px" query={customerQuery}>
        {(customer) => <RelatedCustomersList customerUuid={customer.uuid} />}
      </QuerySkeleton>
      <QuerySkeleton height="41px" query={customerQuery}>
        {(customer) =>
          customer.categories.some((category) => ["pos", "payer", "distributor", "chain"].includes(category)) ? (
            <ContactList customerUuid={customer.uuid} />
          ) : null
        }
      </QuerySkeleton>
    </div>
  );
};
