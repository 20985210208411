import { useState } from "react";
import { MdOutlineCheck } from "react-icons/md";

import { AggOptions } from "@megaron/crm-contracts";
import { Dialog } from "@megaron/dash-dialog";
import { Button, TextField } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { DocsSelectDialog, SelectDialog } from "@megaron/dash-select";
import { decodeDocId } from "@megaron/docs-contracts";
import { useClientManager, useServiceClient } from "@megaron/react-clients";

import { AnalyticsPreset } from "./analyticsPreset";

export type TimeRange = "last_30_days" | "last_90_days" | "default";

type Props = {
  onClose: () => void;
  aggName?: string;
  analyticsPreset?: AnalyticsPreset;
};

export const SalesAggDialog: React.FC<Props> = ({ onClose, aggName, analyticsPreset }) => {
  const { isMobile } = useDeviceType();

  const agg = analyticsPreset?.aggsOptions.find((agg) => agg.aggName === aggName);

  const productGroupsQuery = useClientManager("docs").searchProductGroups().useQuery({});

  const docs = useServiceClient("docs");

  const [name, setName] = useState(agg?.name ?? "");
  const [timeRange, setTimeRange] = useState<TimeRange>("default");
  const [productGroups, setProductGroups] = useState<string[]>(agg?.type === "sales" ? agg.productGroup || [] : []);
  const [itemIds, setItemIds] = useState<string[]>(agg?.type === "sales" ? agg.itemId || [] : []);
  const [productIds, setProductIds] = useState<string[]>(agg?.type === "sales" ? agg.productId || [] : []);

  return (
    <Dialog
      onClose={onClose}
      placement={isMobile ? "top" : "center"}
      css={{ width: isMobile ? "100%" : "386px" }}
      header="Nowy zestaw danych"
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
        <TextField label="Nazwa" value={name} onChange={setName} />
        <SelectDialog
          label="Okres"
          variant="single-select"
          options={timeRangeOptions}
          initiallySelectedValues={timeRange ? [timeRange] : []}
          onSelect={(option) => setTimeRange(option?.value as TimeRange)}
        />
        <SelectDialog
          label="Grupy produktowe"
          variant="multi-select"
          options={productGroupsQuery.data?.map((group) => ({ label: group, value: group })) ?? []}
          initiallySelectedValues={productGroups}
          onSelectedChange={(options) => setProductGroups(options?.map((option) => option.value))}
        />
        <SelectDialog
          label="Wyroby"
          variant="multi-select"
          search={async (search) => {
            const results = await docs.searchProductIds({ searchText: search, isArchived: false });
            return results.value ? results.value.map((r) => ({ label: r, value: r })) : [];
          }}
          initiallySelectedValues={productIds}
          onSelectedChange={(selected) => setProductIds(selected.map((product) => product.value))}
        />
        <DocsSelectDialog
          label="Jednostki handlowe"
          variant="multi-select"
          docType={["item"]}
          initiallySelectedDocIds={itemIds}
          onSelectedChange={(options) => setItemIds(options.map((option) => decodeDocId(option.docId).id))}
        />
        <Button
          onClick={() => {
            onClose();
          }}
          icon={<MdOutlineCheck />}
          css={{ marginLeft: "auto", marginTop: "0.25rem" }}
        >
          Zatwierdź
        </Button>
      </div>
    </Dialog>
  );
};

export const timeRangeOptions = [
  { label: "Ostatnie 30 dni", value: "last_30_days" },
  { label: "Ostatnie 90 dni", value: "last_90_days" },
  { label: "Domyślny", value: "default" },
] as { label: string; value: TimeRange }[];
