import { useTheme } from "@emotion/react";
import { useState } from "react";

import { CustomerAddress } from "@megaron/crm-contracts";
import { Dialog } from "@megaron/dash-dialog";
import { Button, Checkbox, DateField, TextField } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";

type Props = {
  onClose: () => void;
  customerAddress: CustomerAddress;
};

export const OrderSummaryDialog: React.FC<Props> = ({ onClose, customerAddress }) => {
  const { isMobile } = useDeviceType();

  const theme = useTheme();

  const [deliveryDate, setDeliveryDate] = useState<Date | null>(null);
  const [useCustomerAddress, setUseCustomerAddress] = useState(false);
  const [useOtherAddress, setUseOtherAddress] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");
  const [note, setNote] = useState("");

  const [addressName, setAddressName] = useState("");
  const [street, setStreet] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("Polska");

  return (
    <Dialog
      onClose={onClose}
      css={{
        width: isMobile ? "100%" : "400px",
        height: useOtherAddress ? "min(100dvh - 4rem, 752px)" : "min(100dvh - 4rem, 540px)",
      }}
      placement={isMobile ? "top" : "center"}
      header="Podsumowanie zamówienia"
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "1rem", height: "100%", overflow: "auto" }}>
        <DateField label="Data dostawy" value={deliveryDate} onChange={setDeliveryDate} />
        <div css={{ gap: "0.5rem", display: "flex", flexDirection: "column" }}>
          <span css={{ fontSize: "0.875rem", fontFamily: theme.displayFontFamily, color: theme.colors.primary }}>
            Użyj adresu nabywcy:
          </span>
          <Checkbox
            label={parseCustomerAddressToString(customerAddress)}
            value={useCustomerAddress}
            onChange={(v) => {
              if (v) {
                setUseOtherAddress(false);
              }

              setUseCustomerAddress(v);
            }}
          />
        </div>
        <div css={{ gap: "0.5rem", display: "flex", flexDirection: "column" }}>
          <span css={{ fontSize: "0.875rem", fontFamily: theme.displayFontFamily, color: theme.colors.primary }}>
            lub wpisz inny:
          </span>
          <div css={{ display: "flex", gap: "0.5rem", alignItems: "flex-start" }}>
            <Checkbox
              value={useOtherAddress}
              onChange={(v) => {
                if (v) {
                  setUseCustomerAddress(false);
                }

                setUseOtherAddress(v);
              }}
              css={{ marginTop: "1.5rem" }}
            />
            <div
              css={{ display: "flex", flexDirection: "column", gap: "0.675rem", width: "100%" }}
              onClick={() => {
                if (!useOtherAddress) {
                  setUseOtherAddress(true);
                  setUseCustomerAddress(false);
                }
              }}
            >
              <TextField label="Nazwa" value={addressName} onChange={setAddressName} />
              {useOtherAddress && (
                <>
                  <TextField label="Ulica" value={street} onChange={setStreet} />
                  <div css={{ display: "flex", gap: "0.675rem" }}>
                    <TextField
                      label="Kod pocztowy"
                      value={postalCode}
                      onChange={setPostalCode}
                      css={{ maxWidth: "100px" }}
                    />
                    <TextField label="Miasto" value={city} onChange={setCity} />
                  </div>
                  <TextField label="Kraj" value={country} onChange={setCountry} />
                </>
              )}
            </div>
          </div>

          <hr css={{ width: "100%", height: "1px", background: "rgba(0, 0, 0, 0.1)", border: "none" }} />

          <TextField label="Numer zamówienia klienta (opcjonalny)" value={orderNumber} onChange={setOrderNumber} />
          <TextField label="Notatka" value={note} onChange={setNote} multiline />
        </div>

        <div css={{ display: "flex", justifyContent: "space-between" }}>
          <Button variant="outline">Anuluj</Button>
          <Button>Wyślij</Button>
        </div>
      </div>
    </Dialog>
  );
};

const parseCustomerAddressToString = (address: CustomerAddress) => {
  if (!address || Object.values(address).every((value) => !value)) return "-";

  return `${address.street}${address.postalCode ? (address.street ? ", " + address.postalCode : address.city) : ""}${
    address.city
      ? address.street || address.postalCode
        ? address.postalCode
          ? " " + address.city
          : ", " + address.city
        : address.postalCode
      : ""
  }${address.country ? ", " + address.country : ""}`;
};
