import Decimal from "decimal.js";

import { Failure, Ok } from "@megaron/result";

export type InputRow = {
  itemId: string;
  cost: Decimal | null;
};

export const rawToInput = (raw: unknown) => {
  if (!Array.isArray(raw)) return Failure("InvalidInput");
  if (raw.length === 0) return Failure("InvalidInput");

  const mapped: InputRow[] = [];

  for (const rawRow of raw) {
    if (typeof rawRow !== "object") return Failure("InvalidInput");

    const row = rawToInputRow(rawRow);

    if (row.isFailure) return row;

    mapped.push(row.value);
  }

  return Ok(mapped);
};

export const rawToInputRow = (raw: { [key: string]: unknown }) => {
  if (!("ItemId" in raw)) return Failure("MissingItemId");

  if (!("Koszty standardowe" in raw)) return Failure("MissingItemCosts");

  if (typeof raw["ItemId"] !== "string") return Failure("InvalidItemId");

  const itemCosts = raw["Koszty standardowe"];

  const areCostsValid = !Number.isNaN(Number(itemCosts));

  if (!areCostsValid) return Failure("InvalidItemCosts");

  return Ok<InputRow>({
    cost: itemCosts ? new Decimal(itemCosts as string) : null,
    itemId: raw["ItemId"],
  });
};
