import { useTheme } from "@emotion/react";
import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

type DurationFieldProps = {
  value: number;
  onChange: (value: number) => void;
  label?: string;
  className?: string;
};

export const DurationField: React.FC<DurationFieldProps> = (props) => {
  const theme = useTheme();

  const padZero = (num: number) => (num < 10 ? `0${num}` : num.toString());

  const minutesToTime = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${padZero(hours)}:${padZero(remainingMinutes)}`;
  };

  const timeToMinutes = (time: string) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const enforceMinimumValue = (minutes: number) => {
    const minMinutes = 15;
    return Math.max(minutes, minMinutes);
  };

  const [inputValue, setInputValue] = useState(minutesToTime(props.value));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length <= 5) {
      setInputValue(value);
    }
  };

  const handleBlur = () => {
    const regex = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/;
    if (regex.test(inputValue)) {
      const minutes = timeToMinutes(inputValue);
      const sanitizedMinutes = enforceMinimumValue(minutes);
      props.onChange(sanitizedMinutes);
    } else {
      setInputValue(minutesToTime(props.value));
    }
  };

  const formatInputValue = (value: string) => {
    const parts = value.split(":");
    if (parts.length > 2) return "00:00";
    const hours = parts[0] ? Math.min(parseInt(parts[0]), 23) : 0;
    const minutes = parts[1] ? Math.min(parseInt(parts[1]), 59) : 0;
    return `${padZero(hours)}:${padZero(minutes)}`;
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const formattedValue = formatInputValue(inputValue);
      setInputValue(formattedValue);
    }
  };

  const handleStep = (stepMinutes: number) => {
    const newMinutes = enforceMinimumValue(props.value + stepMinutes);
    props.onChange(newMinutes);
    setInputValue(minutesToTime(newMinutes));
  };

  return (
    <div
      className={props.className}
      css={{ position: "relative", display: "flex", flexDirection: "column", minWidth: "100px", width: "120px" }}
    >
      {props.label && (
        <label
          css={{
            color: theme.colors.primary,
            fontSize: "0.875rem",
            fontFamily: theme.displayFontFamily,
            marginBottom: "0.25rem",
          }}
        >
          {props.label}
        </label>
      )}
      <div css={{ position: "relative", display: "flex", alignItems: "center" }}>
        <input
          css={{
            width: "100%",
            padding: "0.5rem 0.75rem",
            fontSize: "1rem",
            borderRadius: "6px",
            border: "none",
            backgroundColor: theme.colors.primaryLight,
            ":focus": {
              outline: `1px solid ${theme.colors.primary}`,
            },
          }}
          type="text"
          value={inputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyUp={handleKeyUp}
          placeholder="HH:MM"
        />
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            right: 0,
            padding: "4px 6px",
            gap: "2px",
          }}
        >
          <button
            type="button"
            css={{
              width: "30px",
              height: "15px",
              padding: "0",
              backgroundColor: theme.colors.primary,
              color: "#fff",
              border: "none",
              borderRadius: "6px 6px 2px 2px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => handleStep(15)}
          >
            <FaChevronUp style={{ fontSize: "10px" }} />
          </button>
          <button
            type="button"
            css={{
              width: "30px",
              height: "15px",
              padding: "0",
              backgroundColor: theme.colors.primary,
              color: "#fff",
              border: "none",
              borderRadius: "2px 2px 6px 6px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => handleStep(-15)}
          >
            <FaChevronDown style={{ fontSize: "10px" }} />
          </button>
        </div>
      </div>
    </div>
  );
};
