import { AuthAttribute, authAttributeSerializer } from "@megaron/access-control";
import { Serializers } from "@megaron/serializers";

export type BaseDoc<T extends string = string> = {
  docType: T;
  docId: string;
  authorizedAttributes: AuthAttribute[];
  name: string;
  isArchived: boolean;
  boostedFor: AuthAttribute[];
};

export const baseDocSerializer = <T extends string>(docType: T) =>
  Serializers.object({
    docType: Serializers.stringOneOf(docType),
    docId: Serializers.string,
    authorizedAttributes: authAttributeSerializer.array(),
    boostedFor: authAttributeSerializer.array(),
    isArchived: Serializers.boolean,
    name: Serializers.string,
  });
