import { Gtin, TradeItemDto } from "@megarax/crm-contracts";
import { useAsyncLoad } from "@megarax/react-utils";
import { DialogLoading, useSnackbarErrorHandler } from "@megarax/ui-components";
import Decimal from "decimal.js";
import _, { Dictionary } from "lodash";
import React from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

import { useServiceClient } from "@megaron/react-clients";

import { PackSizeDialog } from "./PackSizeDialog";

export interface PackSizeDialogLine {
  uuid: string;
  gtin: string;
  quantity: Decimal;
}

interface Props {
  lines: PackSizeDialogLine[];
  tradeItemsDictionary: Dictionary<TradeItemDto> | undefined;
}

export const PackSizeDialogContainer: React.FC<Props> = ({ lines, tradeItemsDictionary }) => {
  const crmClient = useServiceClient("crm");
  const navigate = useNavigate();
  const params = useParams<{ uuid: string }>();

  const handleError = useSnackbarErrorHandler({});

  const closeDialog = () => navigate(`/crm/pos/moje_zamowienia/${params.uuid}`);

  const {
    value: dict,
    loading,
    error,
  } = useAsyncLoad(() => {
    const gtins = _.uniq(lines.map((line) => line.gtin));
    return Promise.all(gtins.map((gtin) => crmClient.itemQuery({ gtin }).unwrap()))
      .then((items) => {
        const sizes = items
          .filter((i) => !!i.gtin)
          .map((i) => ({
            gtin: i.gtin as Gtin,
            box: i.box,
            layer: i.layer,
            pallet: i.pallet,
          }));
        const dict = _.keyBy(sizes, "gtin");
        return dict;
      })
      .catch((error) => {
        handleError(error);
        return Promise.reject(null);
      });
  }, [lines]);

  return (
    <>
      {dict && (
        <PackSizeDialog
          packSizesDict={dict}
          tradeItemsDictionary={tradeItemsDictionary}
          lines={lines}
          closeDialog={closeDialog}
        />
      )}
      <DialogLoading loading={loading} error={Boolean(error)} />
    </>
  );
};
