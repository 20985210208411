import { OrderDoc } from "@megaron/docs-contracts";

import { OrderTile } from "./OrderTile";

type Props = {
  orders: OrderDoc[];
};

export const OrdersList: React.FC<Props> = ({ orders }) => {
  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem", width: "100%", marginBottom: "1rem" }}>
      {orders.map((order) => (
        <OrderTile key={order.id} order={order} />
      ))}
    </div>
  );
};
