import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { invoiceSerializer } from "./invoice";
import { participantSerializer, threadStatuses } from "./thread";

export const invoiceThreadDocSerializer = Serializers.object({
  docType: Serializers.stringOneOf("invoice-thread"),
  docId: Serializers.string,
  authorizedAttributes: Serializers.array(Serializers.string),
  boostedFor: Serializers.array(Serializers.string),
  isArchived: Serializers.boolean,
  name: Serializers.string,
  version: Serializers.integer,
  commentCount: Serializers.integer,
  createdAt: Serializers.datetime,
  deadline: Serializers.dateOnly,
  invoice: invoiceSerializer,
  isReceived: Serializers.boolean,
  isPosted: Serializers.boolean,
  referenceNumber: Serializers.string,
  signedBudgetRelative: Serializers.decimal,
  signedValidityRelative: Serializers.decimal,
  status: Serializers.stringOneOf(...threadStatuses),
  threadUuid: uuidSerializer,
  title: Serializers.string,
  approvedAt: Serializers.datetime.nullable(),
  modifiedAt: Serializers.datetime,
  decreeNumber: Serializers.string.nullable(),
  participants: participantSerializer.array(),
  signedBy: Serializers.string.array(),
  budgetUuids: uuidSerializer.array(),
  attachmentFileNames: Serializers.string.array(),
  tags: Serializers.string.array(),
  comments: Serializers.string.array(),
  lastComment: Serializers.object({
    uuid: uuidSerializer,
    message: Serializers.string,
    postedBy: Serializers.string,
    postedAt: Serializers.datetime,
  }).nullable(),
});

export type InvoiceThreadDoc = SerializerValue<typeof invoiceThreadDocSerializer>;

export const threadDocsActions = {
  invoiceThreadDocsQuery: HttpAction({
    path: "/invoiceThreads",
    valueSerializer: invoiceThreadDocSerializer.array(),
    qsSerializer: Serializers.object({
      uuid: uuidSerializer,
    }).partial(),
    requiresAuth: true,
  }),
};
