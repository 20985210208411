import { useTheme } from "@emotion/react";
import { useState } from "react";
import { MdCalendarToday } from "react-icons/md";

import { useDeviceType } from "@megaron/dash-mq";

import { TimeRangeSelect } from "../TimeRangeSelect";
import { FiltersProps } from "./CustomerAnalyticsFilters";

type Props = FiltersProps;

export const TimeRangeButton: React.FC<Props> = (props) => {
  const theme = useTheme();

  const { isMobile } = useDeviceType();

  const [isTimeRangeSelectOpen, setIsTimeRangeSelectOpen] = useState(false);

  return (
    <button
      css={{
        position: "relative",
        border: "none",
        background: "none",
        color: theme.colors.primary,
        fontSize: "0.875rem",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        gap: "0.75rem",
        padding: "0.25rem 0.75rem",
        alignSelf: "stretch",
        textWrap: "nowrap",
        flexShrink: 0,
        borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
        fontWeight: 700,
      }}
      onClick={() => setIsTimeRangeSelectOpen(true)}
    >
      <MdCalendarToday size={16} />
      {!isMobile && (props.filters.selectedTimeName ?? "Brak")}
      {isTimeRangeSelectOpen && (
        <TimeRangeSelect
          onDateChange={props.handlers.onDateChange}
          activeOptionName={props.filters.selectedTimeName ?? null}
          startDate={props.filters.startTime ?? null}
          endDate={props.filters.endTime ?? null}
          onClose={() => setIsTimeRangeSelectOpen(false)}
          timeUnit={props.filters.timeUnit ?? null}
          unitCount={props.filters.unitCount ?? null}
        />
      )}
    </button>
  );
};
