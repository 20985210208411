import { useTheme } from "@emotion/react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

type Props = { onClick: () => void; isForward?: boolean; isDisabled?: boolean };

export const ChangeRangeButton: React.FC<Props> = ({ onClick, isForward, isDisabled }) => {
  const theme = useTheme();

  return (
    <button
      css={{
        padding: "0.375rem",
        borderRadius: "100%",
        border: "none",
        background: theme.colors.primaryLight,
        color: theme.colors.primary,
        cursor: isDisabled ? "not-allowed" : "pointer",
        display: "flex",
        opacity: isDisabled ? 0.5 : 1,
      }}
      disabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
    >
      {isForward ? <MdChevronRight size={16} /> : <MdChevronLeft size={16} />}
    </button>
  );
};
