import { MdOutlineCorporateFare } from "react-icons/md";

import { LoyaltyAdminLogo } from "@megaron/megarax-links";

import { SectionHeader } from "./AddCustomerPage";
import { CustomerType } from "./CustomerCategorySection";
import { CustomerTypeTile } from "./CustomerTypeTile";

type Props = {
  customerType: CustomerType | undefined;
  onCustomerTypeChange: (value: CustomerType) => void;
};

export const CustomerTypeSection: React.FC<Props> = ({ customerType, onCustomerTypeChange }) => {
  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem", width: "100%" }}>
      <SectionHeader header="Rodzaj klienta" />
      <div css={{ display: "flex", flexDirection: "column", width: "100%", gap: "0.375rem" }}>
        <CustomerTypeTile
          label="Firma"
          value="firm"
          isSelected={customerType === "firm"}
          icon={<MdOutlineCorporateFare size={16} />}
          onClick={(value) => onCustomerTypeChange(value as CustomerType)}
        />
        <CustomerTypeTile
          label="Wykonawca"
          value="customer"
          isSelected={customerType === "customer"}
          icon={
            <LoyaltyAdminLogo
              css={{
                height: "16px",
                width: "16px",
              }}
            />
          }
          onClick={(value) => onCustomerTypeChange(value as CustomerType)}
        />
      </div>
    </div>
  );
};
