import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const createCopyInputSerializer = Serializers.object({
  uuid: uuidSerializer,
  templateUuid: uuidSerializer,
});

export const orderDraftActions = {
  createOrderCopy: HttpAction({
    path: "/sales/orderDrafts/createCopy",
    method: "post",
    errorSerializer: Serializers.stringOneOf("DraftNotFound"),
    bodySerializer: createCopyInputSerializer,
  }),
};
