import { useTheme } from "@emotion/react";
import { MdArrowDownward, MdArrowUpward, MdSwapVert } from "react-icons/md";

import { Dialog } from "@megaron/dash-dialog";
import { useDeviceType } from "@megaron/dash-mq";

import { Option } from "./Sort";

type Props = {
  options: Option[];
  activeOption: string;
  sortOrder: "ASC" | "DESC" | "";
  onClose: () => void;
  onOptionClick: (option: string) => void;
};

export const SortDialog: React.FC<Props> = (props) => {
  const { isMobile } = useDeviceType();

  const theme = useTheme();

  const isSelected = props.activeOption !== "" && props.sortOrder !== "";

  const getIcon = (optionValue: string): React.ReactNode => {
    if (isSelected && optionValue === props.activeOption) {
      return props.sortOrder === "ASC" ? <MdArrowUpward /> : <MdArrowDownward />;
    } else {
      return <MdSwapVert />;
    }
  };

  return (
    <Dialog
      placement={isMobile ? "bottom" : "center"}
      onClose={props.onClose}
      header={"Sortuj"}
      css={{ minWidth: "400px" }}
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        {props.options.map((option) => (
          <button
            key={option.value}
            css={{
              fontWeight: "400",
              fontSize: "1rem",
              cursor: "pointer",
              borderRadius: theme.smallBorderRadius,
              border: "none",
              outline: "none",
              backgroundColor: isSelected
                ? option.value === props.activeOption
                  ? theme.colors.primary
                  : "transparent"
                : "transparent",
              color: isSelected
                ? option.value === props.activeOption
                  ? "#fff"
                  : theme.colors.secondaryText
                : theme.colors.secondaryText,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 12px",
            }}
            onClick={() => props.onOptionClick(option.value)}
          >
            <span>{option.label}</span>
            {getIcon(option.value)}
          </button>
        ))}
      </div>
    </Dialog>
  );
};
