import { LoadingBar } from "@megarax/ui-components";
import { Box, Container, Paper, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {
  dateSelect: React.ReactNode;
  alerts: React.ReactNode;
  buttons: React.ReactNode;
  workTime: React.ReactNode;
  activityList: React.ReactNode;
  isLoading: boolean;
  closeVisit: () => void;
  viewSwitchButton: React.ReactNode;
}

export const ViewLayout: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className={classes.view}>
      <Paper className={classes.toolbarPaper}>
        <Box display="flex" flexDirection="row" alignContent="space-between" alignItems="center" mx={2}>
          <Box>{props.viewSwitchButton}</Box>
          <div className={classes.daySelect}>{props.dateSelect}</div>
          <Box width="40px"></Box>
        </Box>
        <LoadingBar loading={props.isLoading} />
      </Paper>
      <Container maxWidth="md">{props.alerts}</Container>

      <Container className={isMobile ? classes.mobileContainer : classes.container} maxWidth="md">
        <div className={classes.leftSection}>
          {props.buttons}
          {props.workTime}
        </div>
        <Box className={isMobile ? classes.mobileActivityList : classes.activityList}>{props.activityList}</Box>
      </Container>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  view: {
    background: theme.palette.grey[50],
    flexGrow: 1,
  },
  daySelect: {
    margin: "0 auto",
  },
  container: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "row-reverse",
  },
  mobileContainer: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  activityList: {
    width: "100%",
    flex: 3,
    marginRight: theme.spacing(2),
  },
  mobileActivityList: {
    width: "100%",
    flex: 3,
  },
  leftSection: {
    flex: 2,
    marginBottom: theme.spacing(3),
  },
  toolbarPaper: {
    zIndex: 10,
    position: "sticky",
    top: 0,
    borderRadius: 0,
    marginBottom: theme.spacing(2),
  },
  unfinishedVisitsAlert: {
    marginTop: theme.spacing(2),
  },
}));
