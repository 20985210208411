import { Failure, Ok } from "@megaron/result";

import { SerializerExtensions, serializerExtensions } from "../extensions";
import { Serializer } from "../serializer";

export type Month = string & { __brand: "Month" };

export const isMonth = (str: string): str is Month => str.match(monthRegex) !== null;

export const monthRegex = /^\d{4}-(0[1-9]|1[012])$/;

export const month: Serializer<Month> & SerializerExtensions<Month> = {
  serialize: (month) => {
    if (!monthRegex.test(month)) throw new Error("InvalidMonthFormat");
    return month;
  },
  deserialize: (raw: unknown) => {
    if (typeof raw !== "string") return Failure("NotAString");
    if (!monthRegex.test(raw)) return Failure("InvalidMonthFormat");
    return Ok(raw as Month);
  },
  ...serializerExtensions(),
};

export const Month = (str: string): Month => {
  const result = month.deserialize(str);
  if (result.isFailure) throw new Error("Failed to construct Month string: " + result.error);

  return result.value;
};

export const dateToMonth = (date: Date): Month => {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}` as Month;
};

const getMonthString = (month: number) => {
  return Number(month).toString().padStart(2, "0");
};

export const getMonths = (startDate: Month, endDate: Month): Month[] => {
  const months: Month[] = [];

  const startMonth = startDate?.split("-")[1];
  const startYear = startDate?.split("-")[0];
  const endMonth = endDate?.split("-")[1];
  const endYear = endDate?.split("-")[0];

  if (startYear === endYear) {
    for (let i = Number(startMonth); i <= Number(endMonth); i++) {
      months.push(`${startYear}-${getMonthString(i)}` as Month);
    }
  }

  if (startYear !== endYear) {
    for (let i = Number(startMonth); i <= 12; i++) {
      months.push(`${startYear}-${getMonthString(i)}` as Month);
    }

    for (let i = Number(startYear) + 1; i < Number(endYear); i++) {
      for (let j = 1; j <= 12; j++) {
        months.push(`${i}-${getMonthString(j)}` as Month);
      }
    }

    for (let i = 1; i <= Number(endMonth); i++) {
      months.push(`${endYear}-${getMonthString(i)}` as Month);
    }
  }

  return months;
};
