import Decimal from "decimal.js";

import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";

export const itemSerializer = Serializers.object({
  itemId: Serializers.string,
  productId: Serializers.string,
  itemName: Serializers.string,
  gtin: Serializers.string.nullable(),
  box: Serializers.integer.nullable(),
  layer: Serializers.integer.nullable(),
  pallet: Serializers.integer.nullable(),
  size: Serializers.decimal,
  bcNumber: Serializers.string.nullable(),
  productGroup: Serializers.string,
  description: Serializers.string,
  cost: Serializers.decimal.nullable(),
  isArchived: Serializers.boolean,
  isLoyalty: Serializers.boolean,
  defaultProfit: Serializers.decimal.nullable(),
});

export type Item = SerializerValue<typeof itemSerializer>;
export const newEmptyItem = (): Item => ({
  itemId: "",
  productId: "",
  itemName: "",
  gtin: null,
  box: null,
  layer: null,
  pallet: null,
  size: new Decimal(1),
  bcNumber: null,
  productGroup: "",
  description: "",
  cost: null,
  isArchived: false,
  isLoyalty: false,
  defaultProfit: null,
});

export const items = {
  itemsQuery: HttpAction({
    path: "/items",
    method: "get",
    qsSerializer: Serializers.object({
      itemIds: Serializers.string.array().asJsonString(),
    }).partial(),
    requiresAuth: true as const,
    valueSerializer: Serializers.object({ items: itemSerializer.array(), count: Serializers.integer }),
  }),

  itemQuery: HttpAction({
    path: "/item",
    method: "get",
    qsSerializer: Serializers.first(
      Serializers.object({ itemId: Serializers.string }),
      Serializers.object({ gtin: Serializers.string }),
    ),
    requiresAuth: true as const,
    valueSerializer: itemSerializer,
  }),

  saveItem: HttpAction({
    path: "/items",
    method: "put",
    requiresAuth: true as const,
    bodySerializer: itemSerializer,
    valueSerializer: itemSerializer,
  }),

  saveItemCosts: HttpAction({
    path: "/items/costs",
    method: "put",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({
      itemId: Serializers.string,
      cost: Serializers.decimal.nullable(),
    }).array(),
    valueSerializer: itemSerializer.array(),
  }),

  refreshBcTradeItems: HttpAction({
    path: "/items/refresh",
    method: "post",
    requiresAuth: true as const,
    valueSerializer: itemSerializer.array(),
  }),
};
