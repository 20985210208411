import { AnyUser } from "@megaron/access-control";
import { HttpService } from "@megaron/http-service";
import { HandlerMap } from "@megaron/http-service-host";

import { notifications } from "./notifications";
import { sms } from "./sms";
import { webPush } from "./webPush";

export const notificationsHttpService = HttpService({
  ...notifications,
  ...webPush,
  ...sms,
});

export type NotificationsHandlers = HandlerMap<typeof notificationsHttpService, AnyUser>;
