import { userResource } from "@megarax/iam-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import _ from "lodash";
import { useContext, useMemo } from "react";
import { useQuery } from "react-query";

import { toDateString } from "@megaron/date-string";
import { IamAuthContext } from "@megaron/iam-auth-react";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

export const useCustomerUpcomingVisits = (customerId: Uuid) => {
  const { list } = useResourceProviderV2(userResource);

  const { data: allUsersDictionary } = useQuery(["allUsers"], () =>
    list({ limit: 1000 })
      .map((result) => result.items)
      .then((res) => _.keyBy(res.value ?? [], "id")),
  );

  const auth = useContext(IamAuthContext);

  const user = auth.iamUser?.userType === "megarax" ? auth.iamUser : undefined;

  const userId = allUsersDictionary
    ? Object.entries(allUsersDictionary).find(([, userData]) => userData.email === user?.email)?.[0]
    : undefined;

  const today = useMemo(() => getTodayWithoutTime(), []);

  const scheduleVisitsQuery = useClientManager("legacyMegarax")
    .visitScheduleQuery()
    .useQuery({ date: toDateString(today), userId: userId ? Number(userId) : 0 }, { enabled: !!userId });

  const scheduleVisits = scheduleVisitsQuery.data?.visits ?? [];

  const customerUpcomingVisits = scheduleVisits?.filter((visit) => visit.customerUuid === customerId) ?? [];

  return { customerUpcomingVisits, user };
};

const getTodayWithoutTime = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
};
