import { useState } from "react";

import { Dialog } from "@megaron/dash-dialog";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { SelectDialog, SelectListElement } from "@megaron/dash-select";

import { analyticsColumns, ColumnType } from "./analyticsColumns";
import { AnalyticsPreset } from "./analyticsPreset";

type Props = {
  onClose: () => void;
  preset: AnalyticsPreset;
  onAggCreate: () => void;
};

export const AddColumnDialog: React.FC<Props> = ({ onClose, preset, onAggCreate }) => {
  const { isMobile } = useDeviceType();

  const [selectedColumnType, setSelectedColumnType] = useState<ColumnType | null>(null);
  const [selectedAgg, setSelectedAgg] = useState<string | null>(null);

  if (!selectedColumnType) {
    return (
      <SelectDialog
        onClose={onClose}
        options={analyticsColumns
          .filter(
            (column) =>
              !preset.columns.find(
                (presetColumn) => presetColumn.aggName === null && presetColumn.type === column.columnType,
              ),
          )
          .map((column) => ({ label: column.columnName, value: column.columnType }))}
        onSelect={(option) => {
          if (analyticsColumns.find((column) => column.columnType === option?.value)?.aggType === null) {
            setSelectedColumnType(option?.value as ColumnType);
            onClose();
            return;
          }

          setSelectedColumnType(option?.value as ColumnType);
        }}
        variant="single-select"
        isOpen
        noTrigger
        shouldNotCloseOnSelect
      />
    );
  }

  const aggOptions = getAggOptionsForColumnType(selectedColumnType, preset);

  return (
    <Dialog
      onClose={onClose}
      header="Wybierz agregację"
      placement={isMobile ? "top" : "center"}
      css={{ width: isMobile ? "100%" : "400px" }}
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
        {aggOptions.map((agg) => (
          <SelectListElement
            label={agg.name}
            value={agg.aggName}
            onClick={(v) => {
              setSelectedAgg(v);
              onClose();
            }}
            isSelected={selectedAgg === agg.aggName}
            showBorder
          />
        ))}
        <Button
          css={{ marginRight: "auto", marginTop: "0.375rem" }}
          variant="outline"
          size="small"
          onClick={onAggCreate}
        >
          {aggOptions.length ? "Lub utwórz agregację" : "Utwórz agregację"}
        </Button>
      </div>
    </Dialog>
  );
};

const getAggOptionsForColumnType = (columnType: ColumnType, preset: AnalyticsPreset) => {
  const column = analyticsColumns.find((column) => column.columnType === columnType);

  if (!column || !column.aggType) {
    return [];
  }

  const aggOptions = preset.aggsOptions.filter((agg) => agg.type === column.aggType);

  return aggOptions.filter(
    (agg) =>
      !preset.columns.find((presetColumn) => presetColumn.aggName === agg.aggName && presetColumn.type === columnType),
  );
};
