import {
  Currency,
  OrderDraftLineDto,
  orderDraftResource,
  PackSizeDefaultsDto,
  TradeItemDto,
} from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { commonErrorsMap, DialogLoading, useSnackbarErrorHandler } from "@megarax/ui-components";
import Decimal from "decimal.js";
import { Dictionary } from "lodash";
import React, { useEffect, useState } from "react";
import { useMatch, useNavigate, useParams } from "react-router-dom-v5-compat";

import { useServiceClient } from "@megaron/react-clients";

import { EditItemDialog } from "./EditItemDialog";

interface Props {
  lines: OrderDraftLineDto[];
  reloadDraft: () => void;
  tradeItemsDictionary: Dictionary<TradeItemDto> | undefined;
  currency: Currency;
}

export interface EditLineInput {
  quantity: number;
  promoPrice: Decimal | null;
}

export const EditItemDialogContainer: React.FC<Props> = ({ lines, reloadDraft, tradeItemsDictionary, currency }) => {
  const params = useParams<{ uuid: string; lineUuid: string }>();
  const matchPath = useMatch("/crm/pos/moje_zamowienia/:uuid/:lineUuid");
  const lineUuid = matchPath?.params.lineUuid;

  const navigate = useNavigate();
  const lineProvider = useResourceProviderV2(orderDraftResource)
    .byUuid(params.uuid || "")
    .lines.byUuid(lineUuid || "");
  const crmClient = useServiceClient("crm");

  const [packSize, setPackSize] = useState<PackSizeDefaultsDto | null>();

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
    LineNotFound: "Taki przedmiot nie istnieje.",
    TradeItemNotFound: "Nie znaleziono jednostki handlowej.",
    VersionConflict: "Wystąpił konflikt wersji wydarzenia.",
    OrderNotFound: "Zamówienie nie istnieje.",
    DraftNotOpen: "Wersja robocza nie posiada statusu: otwarta.",
    UserNotOrderOwner: "Nie jesteś właścicielem tego zamówienia.",
    LineDoesNotExist: "Data dostawy jest w przeszłośći.",
  });

  const line = lines.find((line) => line.uuid === lineUuid);

  const closeDialog = () => navigate(`/crm/pos/moje_zamowienia/${params.uuid}`);

  const removeLine = () =>
    lineProvider
      .remove()
      .mapError(handleError)
      .map(() => {
        reloadDraft();
      })
      .then(() => {});

  const editLine = (input: EditLineInput) =>
    lineProvider
      .update(undefined, input)
      .mapError((error) => {
        handleError(error);
        return Promise.reject(error);
      })
      .map(() => {
        reloadDraft();
      })
      .then(() => {});

  useEffect(() => {
    if (line === undefined) {
      handleError("LineNotFound");
      return;
    }
    crmClient.itemQuery({ gtin: line.item.gtin }).unwrap().then(setPackSize);
  }, [line]);

  const ready = line && packSize !== undefined;

  return (
    <>
      {ready && (
        <EditItemDialog
          currency={currency}
          closeDialog={closeDialog}
          line={line}
          packSize={packSize}
          editLine={editLine}
          removeLine={removeLine}
          tradeItemsDictionary={tradeItemsDictionary}
        />
      )}
      <DialogLoading loading={!ready} error={!line} />
    </>
  );
};
