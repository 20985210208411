import { Failure, Ok } from "@megaron/result";

import { SerializerExtensions, serializerExtensions } from "../extensions";
import { Serializer } from "../serializer";

export type PostalCode = string & { __brand: "postalCode" };

export const isValidPostalCode = (postalCode: string): boolean => {
  const postalCodeRegex = /^\d{2}-\d{3}$/;
  return postalCodeRegex.test(postalCode);
};

export const postalCode: Serializer<PostalCode> & SerializerExtensions<PostalCode> = {
  serialize: (postalCode: string) => {
    if (typeof postalCode !== "string") throw new Error("NotAString");

    return postalCode;
  },
  deserialize: (raw: any) => {
    if (typeof raw !== "string") return Failure("NotAString");

    const isValid = isValidPostalCode(raw);

    if (!isValid) {
      return Failure("InvalidPostalCode");
    }

    return Ok(raw as PostalCode);
  },
  ...serializerExtensions(),
};
