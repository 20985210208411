import _ from "lodash";
import { useInfiniteQuery } from "react-query";

import {
  aggTypes,
  customerAnalyticsFiltersSerializer,
  customerAnalyticsSortFields,
  customerAnalyticsSortSerializer,
  interactionsAggOptionsSerializer,
  salesAggOptionsSerializer,
} from "@megaron/crm-contracts";
import { useServiceClient } from "@megaron/react-clients";
import { Serializers, SerializerValue } from "@megaron/serializers";

import { analyticsColumnsTypes } from "./analyticsColumns";

export const analyticsPresetColumnSerializer = Serializers.object({
  header: Serializers.string,
  aggName: Serializers.string.nullable(),
  aggType: Serializers.stringOneOf(...aggTypes).nullable(),
  type: Serializers.stringOneOf(...analyticsColumnsTypes),
  sortField: Serializers.stringOneOf(...customerAnalyticsSortFields).nullable(),
});

export type AnalyticsPresetColumn = SerializerValue<typeof analyticsPresetColumnSerializer>;

export const analyticsPresetSerializer = Serializers.object({
  filters: customerAnalyticsFiltersSerializer.array(),
  sortBy: customerAnalyticsSortSerializer,
  columns: analyticsPresetColumnSerializer.array(),
  aggsOptions: Serializers.first(
    Serializers.object({ aggName: Serializers.string, name: Serializers.string, ...salesAggOptionsSerializer.fields }),
    Serializers.object({
      aggName: Serializers.string,
      name: Serializers.string,
      ...interactionsAggOptionsSerializer.fields,
    }),
  ).array(),
});

export type AnalyticsPreset = SerializerValue<typeof analyticsPresetSerializer>;

export const newAnalyticsPreset = (): AnalyticsPreset => ({
  filters: [],
  sortBy: { agg: null, fieldName: "name", order: "ASC", customColumn: null },
  columns: [],
  aggsOptions: [],
});

export const usePresetAnalyticsQuery = (preset: AnalyticsPreset) => {
  const aggs = _(preset.aggsOptions)
    .keyBy((g) => g.aggName)
    .value();

  const crm = useServiceClient("crm");

  const queryKey = [
    "crm",
    "customerAnalyticsInfiniteQuery",
    JSON.stringify({
      aggs,
      sortBy: preset.sortBy,
      filters: preset.filters,
    }),
  ];

  const customerAnalyticsInfiniteQuery = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam = 0 }) => {
      const filters = preset.filters.map((filters) => ({ ...filters, offset: pageParam * 30, limit: 30 }));

      return crm.customerAnalyticsQuery({
        aggs,
        sortBy: preset.sortBy,
        filters,
      });
    },
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.value && lastPage.value.length < 30) {
        return undefined;
      }

      return allPages.length;
    },
    getPreviousPageParam: (lastPage, allPages) => {
      if (lastPage.value && lastPage.value.length < 30) {
        return undefined;
      }

      return allPages.length - 1;
    },
  });

  return { customerAnalyticsInfiniteQuery };
};
