import { useTheme } from "@emotion/react";
import { ReactNode } from "react";
import { MdCheck } from "react-icons/md";
import tinycolor from "tinycolor2";

type Props<TOptionValue> = {
  label: string;
  value: TOptionValue;
  icon?: ReactNode;
  isSelected: boolean;
  onClick?: (value: TOptionValue) => void;
  className?: string;
  isDisabled?: boolean;
};

export const CustomerTypeTile = <TOptionValue,>({
  label,
  value,
  isSelected,
  onClick,
  className,
  icon,
  isDisabled,
}: Props<TOptionValue>) => {
  const theme = useTheme();

  return (
    <div
      key={label}
      css={{
        cursor: isDisabled ? "not-allowed" : "pointer",
        justifyContent: "space-between",
        borderRadius: theme.smallBorderRadius,
        width: "100%",
        display: "flex",
        alignItems: "center",
        background: "white",
        fontWeight: isSelected ? 700 : 400,
        overflow: "hidden",
        fontSize: "0.875rem",
        color: theme.colors.primary,
        border: isSelected
          ? `1px solid ${theme.colors.primary}`
          : `1px solid ${tinycolor("rgb(0, 0, 0)").setAlpha(0.1)}`,
        "&:hover": {
          background: isDisabled ? "white" : theme.colors.background,
        },
      }}
      className={className}
      onClick={() => onClick?.(value)}
    >
      <div
        style={{
          marginRight: "0.5rem",
          padding: "4px 12px",
          minHeight: "36px",
          display: "flex",
          alignItems: "center",
          gap: "0.625rem",
          textAlign: "left",
          fontSize: "0.875rem",
        }}
      >
        {icon}
        {label}
      </div>
      {isSelected && (
        <div
          css={{
            display: "flex",
            background: theme.colors.primary,
            minHeight: "36px",
            color: "white",
            padding: "10px",
            alignSelf: "stretch",
            alignItems: "center",
          }}
        >
          <MdCheck size="0.875rem" />
        </div>
      )}
    </div>
  );
};
