import Decimal from "decimal.js";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { HeaderBar, Page } from "@megaron/dash-page";
import { useToast } from "@megaron/dash-toast";
import { useClientManager } from "@megaron/react-clients";

import { NewTradeItemDetails } from "./NewTradeItemDetails";
import { TradeItemSizes } from "./TradeItemSizes";

export const NewTradeItemPage = () => {
  const { isMobile } = useDeviceType();

  const [id, setId] = useState("");
  const [itemName, setitemName] = useState("");
  const [index, setIndex] = useState("");
  const [group, setGroup] = useState("");
  const [size, setSize] = useState(new Decimal(0));
  const [pallet, setPallet] = useState<number | null>(null);
  const [layer, setLayer] = useState<number | null>(null);
  const [box, setBox] = useState<number | null>(null);
  const [cost, setCost] = useState("");

  const navigate = useNavigate();

  const toast = useToast();

  const itemMutation = useClientManager("crm").saveItem().useMutation();

  const handleSave = useCallback(() => {
    if (!itemName) {
      toast.error("Jednostka handlowa jest wymagana");
      return;
    }

    if (!id) {
      toast.error("Id jest wymagane");
      return;
    }

    if (!index) {
      toast.error("Index jest wymagany");
      return;
    }

    if (!group) {
      toast.error("Grupa jest wymagana");
      return;
    }

    if (!size) {
      toast.error("Wielkość jest wymagana");
      return;
    }

    itemMutation.mutate(
      {
        itemId: id,
        itemName: itemName,
        productId: index,
        productGroup: group,
        size: size,
        pallet: pallet,
        layer: layer,
        box: box,
        bcNumber: null,
        gtin: null,
        description: "",
        isArchived: false,
        cost: cost ? new Decimal(cost) : null,
        isLoyalty: false,
        defaultProfit: null,
      },
      {
        onSuccess: () => {
          navigate(`/crm/items/${id}`, { replace: true });
        },
        onError: () => {
          toast.error("Wystąpił błąd podczas zapisywania danych");
        },
      },
    );
  }, [box, group, id, index, itemMutation, layer, pallet, size, toast, itemName, navigate, cost]);

  return (
    <>
      <HeaderBar header="Nowy" />

      <Page
        css={{
          display: "flex",
          gap: "1rem",
          flexDirection: "column",
          marginBottom: "2rem",
          padding: isMobile ? "0 1rem" : "0 2rem",
          marginTop: "72px",
        }}
      >
        <NewTradeItemDetails
          values={{ id, index, itemName, group, cost }}
          handlers={{
            onIdChange: setId,
            onIndexChange: setIndex,
            onitemNameChange: setitemName,
            onGroupChange: setGroup,
            onCostChange: setCost,
          }}
        />
        <TradeItemSizes
          values={{ size: size.toFixed(0), pallet: pallet ?? 0, layer: layer || 0, box: box || 0 }}
          handlers={{
            onBoxChange: setBox,
            onLayerChange: setLayer,
            onPalletChange: setPallet,
            onSizeChange: (v) => setSize(new Decimal(v || 0)),
          }}
        />
        <Button onClick={handleSave} css={{ alignSelf: "flex-end", marginTop: "1rem" }}>
          Zapisz
        </Button>
      </Page>
    </>
  );
};
