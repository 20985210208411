import { useQsFilters } from "@megaron/dash-filters";
import { ThreadSortField, threadsSortFields } from "@megaron/docs-contracts";
import { threadStatuses } from "@megaron/invoices-contracts";
import { Serializers, SerializerValue, SortFilter } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export type GroupFilter = "mine" | "all";

export const filtersSerializer = Serializers.object({
  page: Serializers.integer.optional(),
  sort: Serializers.sortFilter(threadsSortFields).optional(),
  searchText: Serializers.string.optional(),
  status: Serializers.stringOneOf(...threadStatuses)
    .array()
    .optional(),
  signedBy: Serializers.string.array().optional(),
  budgetUuid: uuidSerializer.array().optional(),
  isDigital: Serializers.booleanFieldFilter.optional(),
  isReceived: Serializers.booleanFieldFilter.optional(),
  isPosted: Serializers.booleanFieldFilter.optional(),
  participant: Serializers.string.array().optional(),
  isRead: Serializers.booleanFieldFilter.optional(),
  isOverdue: Serializers.booleanFieldFilter.optional(),
  isMine: Serializers.booleanFieldFilter.optional(),
  issuer: Serializers.string.optional(),
  tags: Serializers.string.array().optional(),
  groupFilter: Serializers.stringOneOf("mine", "all"),
});

export type SortField = SortFilter<ThreadSortField>;

export type Filters = SerializerValue<typeof filtersSerializer>;

export const useThreadFilters = () => {
  const defaultFilters: Filters = {
    page: 0,
    searchText: "",
    sort: undefined,
    status: ["open"],
    signedBy: undefined,
    budgetUuid: undefined,
    isDigital: "any",
    isReceived: "any",
    isPosted: "any",
    participant: undefined,
    isRead: undefined,
    isOverdue: "any",
    isMine: "any",
    issuer: undefined,
    tags: undefined,
    groupFilter: "all",
  };

  const { filters, setFilters } = useQsFilters(filtersSerializer, defaultFilters);

  const setFilter =
    <Tkey extends keyof Filters>(key: Tkey) =>
    (value: Filters[Tkey]) => {
      if (key === "groupFilter") {
        if (value === "mine") {
          setFilters({
            ...defaultFilters,
            groupFilter: "mine",
            isMine: "true",
            sort: { field: "invitedAt", order: "DESC" },
            status: ["open"],
          });
          return;
        }

        setFilters({
          ...defaultFilters,
          groupFilter: "all",
          sort: { field: "createdAt", order: "DESC" },
          status: ["open"],
        });
        return;
      }

      setFilters({ ...filters, [key]: value });
    };

  return { filters, setFilter, defaultFilters, setFilters };
};
