import { useTheme } from "@emotion/react";
import { useState } from "react";
import { MdAdd } from "react-icons/md";
import { useQueryClient } from "react-query";

import { CustomerCategory } from "@megaron/crm-contracts";
import { Dialog, useDialogRoute } from "@megaron/dash-dialog";
import { Button, Checkbox, TextField } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useToast } from "@megaron/dash-toast";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

type Props = {
  customerId: Uuid;
  customerCategories: CustomerCategory[];
};

export const CustomerGlns: React.FC<Props> = ({ customerCategories, customerId }) => {
  const theme = useTheme();

  const toast = useToast();

  const isPos = customerCategories.includes("pos");

  const customerGlns = useClientManager("crm").getCustomerGlns().useQuery({ customerId }, { enabled: isPos });

  const queryClient = useQueryClient();

  const saveGlnConfig = useClientManager("crm").saveGlnConfig().useMutation();

  const handleIgnorePayerOrders = (gln: string, ignorePayerOrders: boolean) => {
    saveGlnConfig.mutate(
      { customerId, gln, ignorePayerOrders },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(customerGlns.key);
        },
        onError: () => {
          toast.error("Nie udało się zaktualizować GLN");
        },
      },
    );
  };

  const addGlnDialog = useDialogRoute(`/add-gln`, ({ onClose }) => (
    <AddGlnDialog onClose={onClose} customerId={customerId} queryKey={customerGlns.key} />
  ));

  if (!isPos) {
    return null;
  }

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "0.25rem" }}>
      <span css={{ fontFamily: theme.displayFontFamily, fontSize: "0.875rem", color: theme.colors.primary }}>GLNs</span>
      <QuerySkeleton query={customerGlns} height="34px">
        {(glns) => (
          <div css={{ display: "flex", flexDirection: "column", gap: "0.25rem" }}>
            {glns.filter(Boolean).map((gln) => (
              <div css={{ display: "flex", alignItems: "center", gap: "0.625rem", justifyContent: "space-between" }}>
                <span>{gln.gln}</span>
                <Checkbox
                  value={gln.ignorePayerOrders}
                  onChange={() => handleIgnorePayerOrders(gln.gln as string, !gln.ignorePayerOrders)}
                  label="Ignoruj zamówienia"
                />
              </div>
            ))}
            <button
              css={{
                border: "none",
                background: "none",
                alignSelf: "flex-start",
                padding: 0,
                display: "flex",
                alignItems: "center",
                gap: "0.25rem",
                color: theme.colors.primary,
                fontSize: "1rem",
                cursor: "pointer",
              }}
              onClick={addGlnDialog.open}
            >
              <MdAdd />
              Dodaj
            </button>
          </div>
        )}
      </QuerySkeleton>
      {addGlnDialog.element}
    </div>
  );
};

const AddGlnDialog: React.FC<{ onClose: () => void; customerId: Uuid; queryKey: string | string[] }> = ({
  onClose,
  customerId,
  queryKey,
}) => {
  const { isMobile } = useDeviceType();

  const toast = useToast();

  const saveGlnConfig = useClientManager("crm").saveGlnConfig().useMutation();

  const queryClient = useQueryClient();

  const [gln, setGln] = useState("");
  const [ignorePayerOrders, setIgnorePayerOrders] = useState(false);

  const handleAddGln = () => {
    saveGlnConfig.mutate(
      {
        customerId,
        gln,
        ignorePayerOrders,
      },
      {
        onSuccess: () => {
          onClose();
          queryClient.invalidateQueries(queryKey);
        },
        onError: () => {
          toast.error("Nie udało się dodać GLN");
          onClose();
        },
      },
    );
  };

  return (
    <Dialog
      header="Dodaj GLN"
      placement={isMobile ? "top" : "center"}
      css={{ width: isMobile ? "100%" : "400px" }}
      onClose={onClose}
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
        <TextField label="GLN" value={gln} onChange={setGln} />
        <Checkbox value={ignorePayerOrders} onChange={setIgnorePayerOrders} label="Ignoruj zamówienia" />
        <Button css={{ marginLeft: "auto" }} onClick={handleAddGln}>
          Dodaj
        </Button>
      </div>
    </Dialog>
  );
};
