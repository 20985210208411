import { dateStringSerializer } from "@megaron/date-string";
import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const priceReportDtoSerializer = Serializers.object({
  uuid: uuidSerializer,
  createdById: Serializers.integer,
  customerUuid: uuidSerializer,
  netPrice: Serializers.decimal,
  subjectName: Serializers.string,
  subjectUuid: uuidSerializer,
  time: Serializers.datetime,
});

export type PriceReportDto = SerializerValue<typeof priceReportDtoSerializer>;

export const reportSubjectSerializer = Serializers.object({
  uuid: uuidSerializer,
  gtin: Serializers.string.nullable(),
  name: Serializers.string,
  varietyName: Serializers.string,
  productGroup: Serializers.string,
  varietySizeKg: Serializers.decimal,
});

export type ReportSubject = SerializerValue<typeof reportSubjectSerializer>;

export const priceReportActions = {
  customerReports: HttpAction({
    path: "/priceReport/reports/customerReports",
    method: "get",
    qsSerializer: Serializers.object({
      customerUuid: uuidSerializer,
      date: dateStringSerializer,
    }),
    valueSerializer: Serializers.array(priceReportDtoSerializer),
    requiresAuth: true,
  }),
  addReport: HttpAction({
    path: "/priceReport/reports/uuid/:reportUuid",
    method: "post",
    paramsSerializer: Serializers.object({
      reportUuid: uuidSerializer,
    }),
    bodySerializer: Serializers.object({
      customerUuid: uuidSerializer,
      subjectUuid: uuidSerializer,
      netPrice: Serializers.decimal,
    }),
    errorSerializer: Serializers.stringOneOf("ReportAlreadyExists"),
  }),
  searchSubjects: HttpAction({
    path: "/priceReport/subjects/search",
    method: "get",
    qsSerializer: Serializers.object({
      limit: Serializers.integer,
      offset: Serializers.integer,
      searchText: Serializers.string,
    }),
    valueSerializer: Serializers.paginatedList(reportSubjectSerializer),
    requiresAuth: true,
  }),
};
