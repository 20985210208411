import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const legacyEdiActions = {
  getCustomerGlns: HttpAction({
    path: "/legacyEdi/cutomerGlns/:customerId",
    method: "get",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      customerId: uuidSerializer,
    }),
    valueSerializer: Serializers.object({
      customerId: uuidSerializer,
      gln: Serializers.string.nullable(),
      ignorePayerOrders: Serializers.boolean,
    }).array(),
  }),
  saveGlnConfig: HttpAction({
    path: "/legacyEdi/gln/:gln",
    method: "post",
    requiresAuth: true as const,
    paramsSerializer: Serializers.object({
      gln: Serializers.string.nullable(),
    }),
    bodySerializer: Serializers.object({
      customerId: uuidSerializer.nullable(),
      ignorePayerOrders: Serializers.boolean,
    }),
  }),
};
