import Decimal from "decimal.js";

import { Uuid } from "@megarax/common";
import {
  CustomerDetail,
  CustomerListQueryFilters,
  customerListResource,
  customerResource,
  DesignateVisitLocationInput,
  RegisterCustomerInput,
  TagCustomerInput,
} from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { Failure, Ok, Result } from "@megaron/result";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";

type OnSuccessFunction = (() => void) | (() => Promise<void>);

const voidifyResult = <TValue, TError>(result: Result<TValue, TError>): Result<void, void> =>
  result.flatMapError(() => Failure()).flatMap(() => Ok());

const promisifyResult = <TValue, TError>(result: Result<TValue, TError>): Promise<TValue> =>
  new Promise((resolve, reject) => {
    if (result.isFailure) return reject(result.error);
    resolve(result.value);
  });

export const useCustomersResource = () => {
  const customersProvider = useResourceProviderV2(customerResource);
  const customersListProvider = useResourceProviderV2(customerListResource);

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
    CustomerNotFound: "Nie udało się odnaleźć takiego klienta",
    CustomerAlreadyRegistered: "Klient został już wcześniej utworzony",
    CustomerWithThatNameExists: "Klient o takiej nazwie już istnieje",
  });

  const retrieveCustomer = (uuid: Uuid) => customersProvider.byUuid(uuid).retrieve().mapError(handleError);

  const registerCustomer =
    ({ onSuccess }: { onSuccess: OnSuccessFunction }) =>
    (uuid: Uuid, input: RegisterCustomerInput) =>
      customersProvider
        .byUuid(uuid)
        .registerCustomer(undefined, input)
        .mapError(handleError)
        .map(onSuccess)
        .then(voidifyResult);

  const renameCustomer =
    ({ onSuccess }: { onSuccess: OnSuccessFunction }) =>
    (uuid: Uuid, name: string) =>
      customersProvider
        .byUuid(uuid)
        .renameCustomer(undefined, { name })
        .mapError(handleError)
        .map(onSuccess)
        .then(voidifyResult);

  const reassignRegion =
    ({ onSuccess }: { onSuccess: OnSuccessFunction }) =>
    (uuid: Uuid, regionUuid: Uuid) =>
      customersProvider
        .byUuid(uuid)
        .reassignRegion(undefined, { regionUuid })
        .mapError(handleError)
        .map(onSuccess)
        .then(voidifyResult);

  const designateVisitLocation =
    ({ onSuccess }: { onSuccess: OnSuccessFunction }) =>
    (uuid: Uuid, input: DesignateVisitLocationInput) =>
      customersProvider
        .byUuid(uuid)
        .designateVisitLocation(undefined, input)
        .mapError(handleError)
        .map(onSuccess)
        .then(voidifyResult);

  const assignToChain =
    ({ onSuccess }: { onSuccess: OnSuccessFunction }) =>
    (uuid: Uuid, chainUuid: Uuid | null) =>
      customersProvider
        .byUuid(uuid)
        .assignToChain(undefined, { chainUuid })
        .mapError(handleError)
        .map(onSuccess)
        .then(voidifyResult);

  const deleteCustomer =
    ({ onSuccess }: { onSuccess: OnSuccessFunction }) =>
    (uuid: Uuid) =>
      customersProvider.byUuid(uuid).retrieve().mapError(handleError).map(onSuccess).then(voidifyResult);

  const restoreCustomer =
    ({ onSuccess }: { onSuccess: OnSuccessFunction }) =>
    (uuid: Uuid) =>
      customersProvider.byUuid(uuid).retrieve().mapError(handleError).map(onSuccess).then(voidifyResult);

  const listCustomers = (query: CustomerListQueryFilters) => customersListProvider.list(query).mapError(handleError);

  const getCustomersItems = (searchText: string, hasPricingRules?: boolean) =>
    customersListProvider
      .list({ limit: 30, searchText, hasPricingRules })
      .mapError(handleError)
      .map((result) => result.items)
      .then(promisifyResult);

  const getNearbyCustomers = (input: { lat: Decimal; lng: Decimal }) =>
    customersListProvider.nearbyCustomers({ latitude: input.lat, longitude: input.lng }).mapError(handleError);

  const tagCustomer =
    ({ onSuccess }: { onSuccess: OnSuccessFunction }) =>
    (uuid: Uuid, input: TagCustomerInput) =>
      customersProvider
        .byUuid(uuid)
        .tagCustomer(undefined, input)
        .mapError(handleError)
        .map(onSuccess)
        .then(voidifyResult);

  const untagCustomer =
    ({ onSuccess }: { onSuccess: OnSuccessFunction }) =>
    (uuid: Uuid, input: TagCustomerInput) =>
      customersProvider
        .byUuid(uuid)
        .untagCustomer(undefined, input)
        .mapError(handleError)
        .map(onSuccess)
        .then(voidifyResult);

  return {
    designateVisitLocation,
    registerCustomer,
    renameCustomer,
    retrieveCustomer,
    reassignRegion,
    assignToChain,
    listCustomers,
    deleteCustomer,
    restoreCustomer,
    getCustomersItems,
    getNearbyCustomers,
    tagCustomer,
    untagCustomer,
  };
};
