import { HttpAction } from "@megaron/http-service";
import { Serializers } from "@megaron/serializers";
import { Uuid, uuidSerializer } from "@megaron/uuid";

import { webPushNotificationPaylodSerializer } from "./notifications";

export const webPush = {
  subscribeToWebPush: HttpAction({
    path: "/subscribeToWebPush",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({
      endpoint: Serializers.string,
      keys: Serializers.object({
        p256dh: Serializers.string,
        auth: Serializers.string,
      }),
    }),
    valueSerializer: Serializers.object({ subscriptionUuid: uuidSerializer }),
  }),

  unsubscribeToWebPush: HttpAction({
    path: "/unsubscribeToWebPush",
    method: "post",
    requiresAuth: true as const,
  }),

  updateWebPushSubscription: HttpAction({
    path: "/updateWebPushSubscription",
    method: "post",
    bodySerializer: Serializers.object({
      oldEndpoint: Serializers.string,
      newEndpoint: Serializers.string,
      keys: Serializers.object({
        p256dh: Serializers.string,
        auth: Serializers.string,
      }),
    }),
    errorSerializer: Serializers.stringOneOf("SubscriptionNotFound"),
  }),

  broadcastWebPushNotification: HttpAction({
    path: "/webPush/broadcast",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({
      broadcastUuid: uuidSerializer,
      ...webPushNotificationPaylodSerializer.fields,
    }),
    valueSerializer: Serializers.object({
      successCount: Serializers.integer,
      failureCount: Serializers.integer,
    }),
  }),

  wipeWebPushSubscriptions: HttpAction({
    path: "/webPush/wipe",
    method: "post",
    requiresAuth: true as const,
  }),

  reportWebPushOpened: HttpAction({
    path: "/webPush/reportWebPushOpened",
    method: "post",
    bodySerializer: Serializers.object({ broadcastUuid: uuidSerializer, subscriptionUuid: uuidSerializer }),
    errorSerializer: Serializers.stringOneOf("NotificationNotFound", "NotificationAlreadyOpened"),
  }),

  removeUserWebPushSub: HttpAction({
    path: "/removeUserWebPushSub",
    method: "delete",
    requiresAuth: true as const,
    bodySerializer: Serializers.object({ endpoint: Serializers.string }),
  }),

  reportNotificationOpened: HttpAction({
    path: "/webPush/reportNotificationOpened",
    method: "post",
    bodySerializer: Serializers.object({ broadcastUuid: uuidSerializer, subscriptionUuid: uuidSerializer }),
    errorSerializer: Serializers.stringOneOf("NotificationNotFound", "NotificationAlreadyOpened"),
  }),
};
