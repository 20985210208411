import { useParams } from "react-router-dom-v5-compat";

import { CustomerAnalyticsView } from "./customerAnalytics/CustomerAnalyticsView";
import { CustomerMapView } from "./CustomerMapView";
import { CustomersHome } from "./CustomersHome";
import { CustomerViewName, ViewsBar } from "./ViewsBar";

export const CustomersViewPage = () => {
  const { viewType } = useParams<{ viewType: CustomerViewName }>();

  return (
    <div css={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <ViewsBar />
      <CustomerView viewType={viewType ?? "all-customers"} />
    </div>
  );
};

const CustomerView: React.FC<{ viewType: CustomerViewName }> = ({ viewType }) => {
  switch (viewType) {
    case "all-customers":
      return <CustomersHome />;
    case "loyalty-customers":
      return <CustomersHome />;
    case "pos-customers":
      return <CustomersHome />;
    case "all-customers-map":
      return <CustomerMapView />;
    case "analytics-1":
      return <CustomerAnalyticsView />;
    default:
      return <div>Not found</div>;
  }
};
