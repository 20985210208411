import Decimal from "decimal.js";

import { DottedProgress } from "@megaron/dash-progress";

type Props = {
  potentialRealization: Decimal | undefined;
  potential: Decimal | undefined;
};

export const PotentialRealization: React.FC<Props> = ({ potential, potentialRealization }) => {
  return (
    <div css={{ display: "flex", alignItems: "center", gap: "0.375rem", flexWrap: "nowrap" }}>
      <span css={{ fontSize: "0.875rem" }}>{(potentialRealization ?? new Decimal(0)).mul(100).toFixed()}%</span>
      <DottedProgress
        currentValue={potentialRealization && potential ? Number(potentialRealization.mul(potential).toFixed(2)) : 0}
        goalValue={Number(potential ?? 0)}
        singleDotValue={10000}
      />
    </div>
  );
};
