import { useTheme } from "@emotion/react";
import axios from "axios";
import { addDays } from "date-fns";
import { useContext, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams as useParamsCompat, useSearchParams } from "react-router-dom-v5-compat";

import { Interaction, InteractionType } from "@megaron/crm-contracts";
import { Dialog } from "@megaron/dash-dialog";
import { Button, CommentInput, DateTimeField, DurationField, Mention } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { SelectDialog } from "@megaron/dash-select";
import { CustomerInteractionDoc, getDocId } from "@megaron/docs-contracts";
import { IamAuthContext } from "@megaron/iam-auth-react";
import { useClientManager, useServiceClient } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { InteractionTypeSelect } from "./InteractionTypeSelect";

type Props = {
  header: React.ReactNode;
  onClose: () => void;
  interaction?: Interaction | CustomerInteractionDoc;
  queryKey: string | string[];
};

export const InteractionPage: React.FC<Props> = (props) => {
  const { customerId }: { customerId?: Uuid } = useParamsCompat();
  if (!customerId) {
    return <div>Error: Customer ID not provided.</div>;
  }
  return <InteractionPageContent customerId={customerId} {...props} />;
};

const InteractionPageContent: React.FC<Props & { customerId: Uuid }> = (props) => {
  const auth = useContext(IamAuthContext);
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const interactionUuid = searchParams.get("interactionUuid");
  const { isMobile } = useDeviceType();
  const theme = useTheme();
  const docs = useServiceClient("docs");
  const saveInteraction = useClientManager("crm").saveInteraction().useMutation();
  const [comment, setComment] = useState<string>(props.interaction?.message ?? "");
  const [mentions, setMentions] = useState<Mention[]>(props.interaction?.mentions ?? []);
  const [interactionDate, setInteractionDate] = useState<Date>(props.interaction?.interactionDate ?? new Date());
  const [selectedType, setSelectedType] = useState<InteractionType | null | undefined>(props.interaction?.type ?? null);
  const [samples, setSamples] = useState<string[]>(props.interaction?.samples ?? []);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(props.interaction?.durationInMinutes ?? 15);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  const handleCommentChange = (comment: string, mentions?: Mention[]) => {
    setComment(comment);
    setMentions(mentions || []);
  };

  const handleTypeSelect = (type: InteractionType) => {
    setSelectedType(type);
  };

  const typeNameMap = {
    call: "telefon",
    visit: "wizyta",
    hotline: "infolinia",
    training: "szkolenie",
    other: "inna",
  };

  const customer = useClientManager("docs")
    .retrieveDocs()
    .useQuery({ docIds: [getDocId("customer", props.customerId)] });

  const saveInteractionMutation = async () => {
    if (!selectedType) {
      setShowWarning(true);
      return;
    }

    setIsButtonLoading(true);

    const createEventPayload = () => ({
      summary: `${customer.data?.[0]?.name} (${typeNameMap[selectedType]})`,
      description: comment,
      start: {
        dateTime: interactionDate.toISOString(),
        timeZone: "Europe/Warsaw",
      },
      end: {
        dateTime: addDays(interactionDate, duration / 1440).toISOString(),
        timeZone: "Europe/Warsaw",
      },
      colorId: 3,
      attendees: mentions.map((m) => ({ email: m.user })),
    });

    const saveEventToCalendar = async (accessToken: string): Promise<string> => {
      setIsButtonLoading(true);
      const eventPayload = createEventPayload();
      const url = props.interaction?.googleCalendarEventId
        ? `https://www.googleapis.com/calendar/v3/calendars/primary/events/${props.interaction.googleCalendarEventId}`
        : `https://www.googleapis.com/calendar/v3/calendars/primary/events`;

      const method = props.interaction?.googleCalendarEventId ? "put" : "post";

      const response = await axios({
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: eventPayload,
      });

      return response.data.id;
    };

    try {
      setIsButtonLoading(true);
      const accessToken = await auth.getGoogleAccessToken();
      if (!accessToken) {
        throw new Error("Unable to retrieve access token.");
      }

      const googleCalendarEventId = await saveEventToCalendar(accessToken);
      setIsButtonLoading(true);

      await saveInteraction.mutateAsync({
        customerId: props.customerId,
        type: selectedType ?? "other",
        message: comment,
        interactionId: (interactionUuid as Uuid) || props.interaction?.uuid,
        samples,
        interactionDate,
        mentions,
        durationInMinutes: duration,
        googleCalendarEventId,
      });

      await new Promise((resolve) => setTimeout(resolve, 1500));
      queryClient.invalidateQueries(props.queryKey);
      props.onClose();
    } catch (error: any) {
      if (error.response && (error.response.status === 403 || error.response.status === 401)) {
        await auth.signInWithGoogle();
        setIsButtonLoading(true);

        const interval = setInterval(async () => {
          setIsButtonLoading(true);
          try {
            const accessToken = await auth.getGoogleAccessToken();
            if (!accessToken) {
              throw new Error("Unable to retrieve access token during retry.");
            }

            const googleCalendarEventId = await saveEventToCalendar(accessToken);

            await saveInteraction.mutateAsync({
              customerId: props.customerId,
              type: selectedType ?? "other",
              message: comment,
              interactionId: (interactionUuid as Uuid) || props.interaction?.uuid,
              samples,
              interactionDate,
              mentions,
              durationInMinutes: duration,
              googleCalendarEventId,
            });

            clearInterval(interval);
            setIsButtonLoading(true);
            await new Promise((resolve) => setTimeout(resolve, 1500));
            queryClient.invalidateQueries(props.queryKey);
            props.onClose();
            setIsButtonLoading(false);
          } catch {
            // silent catch
          }
        }, 2000);
      }
    }
  };

  const canUserEdit =
    auth.iamUser?.userType === "megarax" &&
    !(props.interaction?.ownerEmail !== auth.iamUser?.email && !!props.interaction?.ownerEmail);

  return (
    <Dialog
      header={props.header}
      onClose={props.onClose}
      placement={isMobile ? "top" : "center"}
      css={{ width: "600px", height: isMobile ? "min(100dvh - 4rem, 680px)" : "auto" }}
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "1rem", height: "100%", overflow: "auto" }}>
        <div css={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
          <DateTimeField
            value={interactionDate}
            onChange={setInteractionDate}
            showTime={true}
            label="Data"
            isDisabled={!canUserEdit}
            disableFuture
          />
          <DurationField value={duration} onChange={(value) => setDuration(value)} label="Czas trwania" />
        </div>
        <CommentInput
          onChange={handleCommentChange}
          enableMentions={true}
          enableTags={false}
          commentValue={comment}
          mentionsValues={mentions}
          userDropdownPlacement="bottom"
        />
        <InteractionTypeSelect
          isDisabled={!canUserEdit}
          onSelect={(type) => handleTypeSelect(type as InteractionType)}
          selectedType={selectedType ?? undefined}
        />
        {showWarning && !selectedType && (
          <div style={{ color: "red", textAlign: "left", fontFamily: theme.primaryFontFamily, fontSize: "14px" }}>
            Wybierz rodzaj interakcji
          </div>
        )}
        <SelectDialog
          label="Próbki i gadżety"
          variant="multi-select"
          search={async (search) => {
            const results = await docs.searchProductIds({ searchText: search, isArchived: false });
            return results.value ? results.value.map((r) => ({ label: r, value: r })) : [];
          }}
          initiallySelectedValues={samples}
          onSelectedChange={(selected) => setSamples(selected.map((s) => s.value))}
          isDisabled={!canUserEdit}
        />
        <Button
          isDisabled={!canUserEdit}
          isLoading={isButtonLoading}
          spinnerColor={"white"}
          css={{
            alignSelf: "flex-end",
            marginBottom: "1rem",
          }}
          onClick={saveInteractionMutation}
        >
          Zapisz
        </Button>
      </div>
    </Dialog>
  );
};
