import { useTheme } from "@emotion/react";

type Props = {
  label?: string;
  className?: string;
  isDisabled?: boolean;
  value: number;
  onChange: (value: number) => void;
};

export const IntegerFieldWithButtons: React.FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  const handleChange = (value: string) => {
    const int = parseInt(value);
    if (isNaN(int)) {
      props.onChange(0);
      return;
    }
    props.onChange(parseInt(value));
  };

  const buttonStyles = {
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.875rem",
    color: theme.colors.primary,
    backgroundColor: "white",
    flexShrink: 0,
    width: "2rem",
    height: "2rem",
    borderRadius: "100%",
    cursor: "pointer",
    ":disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
  };

  return (
    <div css={{ display: "flex", flexDirection: "column", width: "100%" }} className={props.className}>
      <label
        css={{
          color: theme.colors.primary,
          fontSize: "0.875rem",
          fontFamily: theme.displayFontFamily,
          marginBottom: "0.25rem",
        }}
      >
        {props.label}
      </label>
      <div
        css={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          justifyContent: "space-between",
          width: "100%",
          backgroundColor: theme.colors.primaryLight,
          padding: "0.5rem 0.75rem",
          borderRadius: "6px",
          opacity: props.isDisabled ? 0.7 : 1,
        }}
      >
        <button
          disabled={props.value < 1 || props.isDisabled}
          css={buttonStyles}
          onClick={() => handleChange((props.value - 1).toString())}
        >
          -1
        </button>
        <input
          value={props.value?.toString() ?? ""}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange(e.target.value)}
          css={{
            width: "100%",
            textAlign: "center",
            border: "none",
            fontSize: "1rem",
            backgroundColor: "transparent",
            ":focus": {
              outline: `none`,
            },
            disabled: {
              cursor: "text",
            },
          }}
          disabled={props.isDisabled ? true : false}
          type="text"
          inputMode="numeric"
        />
        <button
          css={buttonStyles}
          onClick={() => handleChange((props.value + 1).toString())}
          disabled={props.isDisabled}
        >
          +1
        </button>
      </div>
    </div>
  );
};
