import { useTheme } from "@emotion/react";
import { useState } from "react";
import { MdOutlineCall, MdOutlineMail } from "react-icons/md";
import { useQueryClient } from "react-query";
import tinycolor from "tinycolor2";

import { Contact } from "@megaron/crm-contracts";
import { Dialog, useDialogRoute } from "@megaron/dash-dialog";
import { Button, TextField } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { Tile } from "@megaron/dash-tile";
import { useToast } from "@megaron/dash-toast";
import { useClientManager } from "@megaron/react-clients";

type Props = {
  contact: Contact;
  queryKey: string | string[];
};

export const ContactTile: React.FC<Props> = ({ contact, queryKey }) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { isMobile } = useDeviceType();
  const { jobTitle, name, email, phoneNumber, uuid, customerId } = contact;

  const linkStyles = {
    border: "none",
    padding: "0.5rem",
    textDecoration: "none",
    background: theme.colors.primaryLight,
    color: theme.colors.primary,
    borderRadius: "50%",
    display: "flex",
  };

  const [fullName, setFullName] = useState<string>(name);
  const [newJobTitle, setJobTitle] = useState<string>(jobTitle);
  const [newEmail, setEmail] = useState<string>(email);
  const [newPhoneNumber, setPhoneNumber] = useState<string>(phoneNumber);

  const addContactMutation = useClientManager("crm").saveCustomerContact().useMutation();
  const removeContactMutation = useClientManager("crm").removeCustomerContact().useMutation();

  const addContact = (onSuccess: () => void) => {
    addContactMutation.mutate(
      {
        uuid: uuid,
        customerId: customerId,
        name: fullName,
        email: newEmail,
        phoneNumber: newPhoneNumber,
        jobTitle: newJobTitle,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKey);
          onSuccess();
        },
        onError: () => toast.error(`Nie zaktualizować kontaktu`),
      },
    );
  };

  const removeContact = (onSuccess: () => void) => {
    removeContactMutation.mutate(
      {
        contactUuid: uuid,
      },
      {
        onSuccess: () => {
          toast.info("Usunięto kontakt");
          queryClient.invalidateQueries(queryKey);
          onSuccess();
        },
        onError: () => toast.error(`Nie zaktualizować kontaktu`),
      },
    );
  };

  const dialog = useDialogRoute(`/edit-contact/${uuid}`, ({ onClose }) => (
    <Dialog onClose={onClose} header="Edytuj kontakt" placement={isMobile ? "top" : "center"} css={{ width: "400px" }}>
      <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <TextField
          css={{ width: "100%", input: { height: "46px" }, padding: 0 }}
          label={"Imie i Nazwisko"}
          value={fullName}
          onChange={(value) => setFullName(value)}
          autoFocus
        />
        <TextField
          css={{ width: "100%", input: { height: "46px" }, padding: 0 }}
          label={"Stanowisko"}
          value={newJobTitle}
          onChange={(value) => setJobTitle(value)}
        />
        <TextField
          css={{ width: "100%", input: { height: "46px" }, padding: 0 }}
          label={"Email"}
          value={newEmail}
          onChange={(value) => setEmail(value)}
        />
        <TextField
          css={{ width: "100%", input: { height: "46px" }, padding: 0 }}
          label={"Nr telefonu"}
          value={newPhoneNumber}
          onChange={(value) => setPhoneNumber(value)}
        />
      </div>
      <div css={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem", gap: "1rem" }}>
        <Button
          variant="outline"
          color="danger"
          css={{ width: "auto", padding: "0.5rem 1rem" }}
          onClick={() => {
            removeContact(onClose);
          }}
        >
          Usuń
        </Button>
        <Button
          css={{ width: "auto", padding: "0.5rem 1rem" }}
          onClick={() => {
            addContact(onClose);
          }}
        >
          Zapisz
        </Button>
      </div>
    </Dialog>
  ));

  return (
    <>
      <Tile
        css={{
          background: "white",
          borderRadius: "6px",
          minHeight: "53.5px",
          border: `1px solid ${`${tinycolor(theme.colors.primary).setAlpha(0.1)}`}`,
        }}
        onClick={dialog.open}
      >
        <div css={{ display: "flex", alignItems: "center", gap: "0.5rem", justifyContent: "space-between" }}>
          <div css={{ display: "flex", flexDirection: "column" }}>
            <span css={{ fontSize: "0.75rem", color: theme.colors.secondaryText }}>{jobTitle}</span>
            <span
              css={{
                fontSize: "0.875rem",
                color: theme.colors.primary,
                fontWeight: 700,
                opacity: 1,
              }}
            >
              {name}
            </span>
          </div>

          <div css={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            {email && (
              <a href={`mailto:${email}`} css={linkStyles} onClick={(e) => e.stopPropagation()}>
                <MdOutlineMail size={14} />
              </a>
            )}
            {phoneNumber && (
              <a href={`tel:${phoneNumber}`} css={linkStyles} onClick={(e) => e.stopPropagation()}>
                <MdOutlineCall size={14} />
              </a>
            )}
          </div>
        </div>
      </Tile>
      {dialog.element}
    </>
  );
};
