import { useTheme } from "@emotion/react";
import Decimal from "decimal.js";

import { SaveRegionTargetResponseValue } from "@megaron/crm-contracts";
import { Dialog } from "@megaron/dash-dialog";
import { useDeviceType } from "@megaron/dash-mq";
import { RowCellContent, Table, TableBody, TableHeadColumn } from "@megaron/dash-table";
import { Month } from "@megaron/serializers";

type Props = {
  onClose: () => void;
  responseValue: SaveRegionTargetResponseValue;
};

export const ImportedProfitTargetsDialog: React.FC<Props> = ({ onClose, responseValue }) => {
  const { isMobile } = useDeviceType();

  const theme = useTheme();

  const importedProfitTargets = parseResponseToImportedProfitTargets(responseValue);

  const months = Object.keys(importedProfitTargets[0].targets);

  const tableColumns: TableHeadColumn<string>[] = [
    {},
    {
      isSortable: false,
      label: "Nazwa regionu",
    },
    { isSortable: false, label: "Error" },
    ...months.map(
      (heading) =>
        ({
          isSortable: false,
          label: heading,
        } as const),
    ),
  ];

  const getTableRowCellsContent = (regionTargets: ImportedRegionTarget): RowCellContent[] => {
    return [
      {
        element: (
          <div
            css={{
              position: "absolute",
              inset: 0,
              background: regionTargets.error ? theme.colors.dangerBackground : theme.colors.primaryLight,
            }}
          ></div>
        ),
      },
      {
        cellCss: { color: regionTargets.error ? theme.colors.danger : theme.colors.primary, fontWeight: 700 },
        element: regionTargets.regionName,
      },
      {
        cellCss: { color: theme.colors.danger },
        element: <span css={{ textWrap: "nowrap" }}>{regionTargets.error}</span>,
      },
      ...months.map((month) => ({
        cellCss: { color: "black" },
        element: regionTargets.targets[month as Month]?.toFixed(2) ?? "-",
      })),
    ];
  };

  return (
    <Dialog
      placement={isMobile ? "top" : "center"}
      onClose={onClose}
      css={{ width: isMobile ? "100%" : "90vw", height: "min(700px, 90vh)" }}
      header="Zaimportowane cele regionów"
    >
      <div css={{ overflow: "auto", margin: "-1rem -2rem", padding: "1rem 2rem", height: "calc(100% + 2rem)" }}>
        <Table columns={tableColumns}>
          <TableBody
            rows={importedProfitTargets.map((regionTarget) => ({
              cellsContent: getTableRowCellsContent(regionTarget),
            }))}
          />
        </Table>
      </div>
    </Dialog>
  );
};

type ImportedRegionTarget = {
  regionName: string;
  targets: Partial<Record<Month, Decimal | null>>;
  error?: string;
};

const parseResponseToImportedProfitTargets = (responseValue: SaveRegionTargetResponseValue) => {
  const importedTargets: ImportedRegionTarget[] = [];

  for (const addedTarget of responseValue.addedTargets) {
    importedTargets.push({
      regionName: addedTarget.regionName,
      targets: addedTarget.targets,
    });
  }

  for (const regionNotFoundTarget of responseValue.regionNotFound) {
    importedTargets.push({
      regionName: regionNotFoundTarget.regionName,
      targets: regionNotFoundTarget.targets,
      error: "Region nie istnieje",
    });
  }

  return importedTargets;
};
