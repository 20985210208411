import { useParams } from "react-router-dom-v5-compat";

import { useDeviceType } from "@megaron/dash-mq";
import { HeaderBar, Page } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { OrderDetails } from "./OrderDetails";
import { OrderItems } from "./OrderItems";
import { OrderMenu } from "./OrderMenu";
import { OrderStatusTile } from "./OrderStatusTile";

export const OrderPage = () => {
  const { orderId } = useParams<{ orderId: string }>();

  const { isMobile } = useDeviceType();

  const orderQuery = useClientManager("crm")
    .orderQuery()
    .useQuery({ orderId: orderId as Uuid });

  return (
    <>
      <QuerySkeleton query={orderQuery} height="58px" width="100%">
        {(order) => (
          <HeaderBar
            header={`${order.referenceNumber} - ${order.sellToName}`}
            actions={<OrderMenu orderUuid={order.id} />}
          />
        )}
      </QuerySkeleton>

      <Page
        css={{
          display: "flex",
          marginBottom: "2rem",
          marginTop: "83px",
          padding: isMobile ? "0 1rem" : "0 2rem",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <QuerySkeleton query={orderQuery} height="40px" width="100%">
          {(order) => <OrderStatusTile status={order.status} />}
        </QuerySkeleton>

        <QuerySkeleton query={orderQuery} height="160px" width="100%">
          {(order) => <OrderDetails order={order} />}
        </QuerySkeleton>

        <QuerySkeleton query={orderQuery} height="160px" width="100%">
          {(order) => <OrderItems orderItems={order.lines} />}
        </QuerySkeleton>
      </Page>
    </>
  );
};
