import { useTheme } from "@emotion/react";
import React, { useContext, useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom-v5-compat";

import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { Page, PageHeader } from "@megaron/dash-page";
import { SearchWithPaginationSticky } from "@megaron/dash-search";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { IamAuthContext } from "@megaron/iam-auth-react";
import { InvoiceThreadDoc } from "@megaron/invoices-contracts";
import { useProfiles } from "@megaron/megarax-webapps";
import { useClientManager } from "@megaron/react-clients";

import { ThreadStateContext } from "../threadStateContext";
import { AllThreadsFilters } from "./AllThreadsFilters";
import { AllThreadsGroupFilters } from "./AllThreadsGroupFilters";
import { AllThreadsMobileList } from "./AllThreadsMobileList";
import { AllThreadsTable } from "./AllThreadsTable";
import { DownloadCsvButton } from "./DownloadCsvButton";
import { Settings } from "./Settings";
import { useTableVisibleColumns } from "./useTableVisibleColumns";
import { useThreadFilters } from "./useThreadFilters";

export const AllThreadsHome = () => {
  const navigate = useNavigate();
  const auth = useContext(IamAuthContext);
  const profilesQuery = useProfiles();
  const { integrateLocalState } = React.useContext(ThreadStateContext);

  const theme = useTheme();

  const { isMobile } = useDeviceType();

  const { visibleTableColumns, handleVisibleColumnsChange } = useTableVisibleColumns();

  const profiles = profilesQuery.allProfiles;
  const userEmail = auth.iamUser?.userType === "megarax" ? auth.iamUser?.email : undefined;
  const userRoles = auth.iamUser?.userType === "megarax" ? auth.iamUser?.roles : undefined;

  const { filters, setFilter } = useThreadFilters();

  const getActiveVisibleTableColumns = () => {
    if (filters.groupFilter === "mine") {
      return visibleTableColumns?.mine || [];
    }

    return visibleTableColumns?.all || [];
  };

  const pageSize = 20;

  const invoicesQuery = useClientManager("docs")
    .searchInvoiceThreads()
    .useQuery(
      {
        offset: filters.page ? filters.page * pageSize : 0,
        limit: pageSize,
        searchText: filters.searchText,
        sort: filters.sort,
        status: filters.status,
        signedBy: filters.signedBy,
        budgetUuid: filters.budgetUuid,
        isDigital: filters.isDigital,
        isReceived: filters.isReceived,
        isPosted: filters.isPosted,
        participant: filters.participant,
        isRead: filters.isRead,
        isOverdue: filters.isOverdue,
        isMine: filters.isMine,
        issuer: filters.issuer,
        tags: filters.tags,
      },
      { refetchInterval: 3000 },
    );

  const [definedInvoicesData, setDefinedInvoicesData] = useState<(typeof invoicesQuery)["data"]>();

  useEffect(() => {
    if (invoicesQuery.data) {
      setDefinedInvoicesData(invoicesQuery.data);
    }
  }, [invoicesQuery.data]);

  const parsedInvoicesQuery = { ...invoicesQuery, data: definedInvoicesData };

  const onNewClick = () => navigate("/faktury/wersje-robocze/nowa");

  if (userEmail === undefined) return null;

  return (
    <Page
      css={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        gap: "1rem",
      }}
    >
      <PageHeader
        actions={
          <div css={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            <Button icon={<MdAdd />} onClick={onNewClick}>
              Dodaj
            </Button>
          </div>
        }
      >
        Faktury
      </PageHeader>

      <AllThreadsGroupFilters
        groupFilter={filters.groupFilter}
        onGroupFilterChange={setFilter("groupFilter")}
        visibleTableColumnsConfig={visibleTableColumns}
        onVisibleTableColumnsConfigChange={handleVisibleColumnsChange}
      />

      <SearchWithPaginationSticky
        onSearchChange={setFilter("searchText")}
        searchValue={filters.searchText || ""}
        page={filters.page || 0}
        onPageChange={setFilter("page")}
        pageSize={pageSize}
        itemCount={parsedInvoicesQuery.data?.count}
        children={
          <div css={{ display: "flex", alignItems: "center" }}>
            <DownloadCsvButton filters={filters} />
            <div css={{ width: "1px", alignSelf: "stretch", backgroundColor: theme.colors.primaryLight }} />
            <Settings />
          </div>
        }
      />

      <AllThreadsFilters
        filters={filters}
        handlers={{
          onBudgetUuidChange: setFilter("budgetUuid"),
          onDigitalChange: setFilter("isDigital"),
          onReceivedChange: setFilter("isReceived"),
          onPostedChange: setFilter("isPosted"),
          onParticipantChange: setFilter("participant"),
          onReadChange: setFilter("isRead"),
          onOverdueChange: setFilter("isOverdue"),
          onStatusChange: setFilter("status"),
          onSignedByChange: setFilter("signedBy"),
          onIssuerChange: setFilter("issuer"),
          onSortChange: setFilter("sort"),
          onIsMineChange: setFilter("isMine"),
          onTagsChange: setFilter("tags"),
        }}
      />

      <QuerySkeleton query={parsedInvoicesQuery}>
        {(result) =>
          isMobile ? (
            <AllThreadsMobileList threads={result.items.map(integrateLocalState)} userEmail={userEmail ?? ""} />
          ) : (
            <AllThreadsTable
              threads={result.items.map(integrateLocalState)}
              onRefetch={invoicesQuery.refetch}
              userEmail={userEmail ?? ""}
              userRoles={userRoles}
              profiles={profiles}
              sortValue={filters.sort}
              onSortChange={setFilter("sort")}
              isLoading={!invoicesQuery.data}
              visibleTableColumns={getActiveVisibleTableColumns()}
            />
          )
        }
      </QuerySkeleton>
    </Page>
  );
};

export type InvoiceThreadFlat = Pick<InvoiceThreadDoc, "referenceNumber" | "title" | "decreeNumber"> &
  Pick<InvoiceThreadDoc["invoice"], "invoiceNumber" | "issuer" | "currency"> & {
    approvedAt?: string;
    createdAt: string;
    deadline: string;
    issueDate: string;
    dueDate?: string;
    total: number;
    status?: string;
    receivedDate?: string;
  };
