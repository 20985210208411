import { getMonth, getYear } from "date-fns";

import { useQsFilters } from "@megaron/dash-filters";
import { Month, Serializers } from "@megaron/serializers";

export const getStartMonth = (date: Date) => {
  const month = getMonth(date);
  return month > 1 ? month : 12;
};

export const getStartYear = (date: Date) => {
  const month = getMonth(date);
  const year = getYear(date);
  return month > 0 ? year : year - 1;
};

export const getDateString = (month: number, year: number) => {
  return `${year}-${month.toString().padStart(2, "0")}` as Month;
};

export const filterSerializer = Serializers.object({
  startDate: Serializers.month,
  endDate: Serializers.month,
});

export const useRegionProfitMetricsFilters = () => {
  const { filters, setFilters } = useQsFilters(filterSerializer, {
    startDate: getDateString(getStartMonth(new Date()), getStartYear(new Date())),
    endDate: getDateString(getMonth(new Date()) + 1, getYear(new Date())),
  });

  return {
    filters,
    setFilters,
  };
};
