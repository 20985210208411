import { MdPersonAdd } from "react-icons/md";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom-v5-compat";

import { orderStatuses } from "@megaron/crm-contracts";
import { useQsFilters } from "@megaron/dash-filters";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { Page, PageHeader } from "@megaron/dash-page";
import { SearchWithPaginationSticky } from "@megaron/dash-search";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { orderSortFields } from "@megaron/docs-contracts";
import { useClientManager } from "@megaron/react-clients";
import { getMonths, Serializers, SerializerValue } from "@megaron/serializers";
import { sleep } from "@megaron/utils";

import { EdiAlertsContainer } from "./ediAlerts";
import { OrdersFilters } from "./OrdersFilters";
import { OrdersList } from "./OrdersList";
import { OrdersTable } from "./OrdersTable";

const pageSize = 30;

const filterSerializer = Serializers.object({
  page: Serializers.integer,
  searchText: Serializers.string,
  regions: Serializers.array(Serializers.string).optional(),
  status: Serializers.stringOneOf(...orderStatuses)
    .array()
    .optional(),
  sort: Serializers.sortFilter(orderSortFields).asJsonString().optional(),
  type: Serializers.stringOneOf("order", "creditMemo").array().asJsonString().optional(),
  startDate: Serializers.month.optional(),
  endDate: Serializers.month.optional(),
});

export type Filters = SerializerValue<typeof filterSerializer>;

export const OrdersPage = () => {
  const { isMobile } = useDeviceType();

  const navigate = useNavigate();

  const { filters, setFilter, setFilters } = useQsFilters(filterSerializer, {
    page: 0,
    searchText: "",
    regions: undefined,
    status: undefined,
    sort: undefined,
    type: undefined,
    startDate: undefined,
    endDate: undefined,
  });

  const ordersQuery = useClientManager("docs")
    .searchOrders()
    .useQuery({
      offset: filters.page * pageSize,
      limit: pageSize,
      searchText: filters.searchText,
      status: filters.status,
      type: filters.type,
      region: filters.regions,
      month: filters.startDate && filters.endDate ? getMonths(filters.startDate, filters.endDate) : undefined,
      sort: filters.sort,
    });

  const queryClient = useQueryClient();

  return (
    <Page
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        maxWidth: "100%",
      }}
    >
      <PageHeader
        actions={
          <Button icon={<MdPersonAdd />} onClick={() => navigate("/crm/pos/moje_zamowienia/nowe")}>
            Dodaj
          </Button>
        }
      >
        Zamówienia
      </PageHeader>

      <SearchWithPaginationSticky
        onSearchChange={setFilter("searchText")}
        searchValue={filters.searchText}
        page={filters.page}
        onPageChange={setFilter("page")}
        pageSize={pageSize}
        itemCount={ordersQuery.data?.count}
      ></SearchWithPaginationSticky>

      <OrdersFilters
        filters={filters}
        handlers={{
          onRegionsChange: setFilter("regions"),
          onStatusChange: setFilter("status"),
          onTypeChange: setFilter("type"),
          onSortChange: setFilter("sort"),
          onDatesChange: (startDate, endDate) => setFilters({ ...filters, startDate, endDate }),
        }}
      />

      <EdiAlertsContainer
        onEdiChanged={() => sleep(1000).then(() => queryClient.invalidateQueries(ordersQuery.key))}
        isVertical
      />

      <QuerySkeleton query={ordersQuery}>
        {(orders) =>
          isMobile ? (
            <OrdersList orders={orders.items} />
          ) : (
            <OrdersTable
              isLoading={false}
              orders={orders.items}
              activeSortValue={filters.sort}
              onSortChange={setFilter("sort")}
            />
          )
        }
      </QuerySkeleton>
    </Page>
  );
};
