import { useTheme } from "@emotion/react";
import Decimal from "decimal.js";
import { useState } from "react";

import { Item, OrderLineDto } from "@megaron/crm-contracts";
import { Button, IntegerFieldWithButtons } from "@megaron/dash-form";
import { ItemDoc } from "@megaron/docs-contracts";
import { newUuid, Uuid } from "@megaron/uuid";

type Props = {
  item: ItemDoc | Item | undefined;
  lineId?: Uuid;
  onSave: (line: OrderLineDto) => void;
  onDelete?: () => void;
};

export const ItemDialogContent: React.FC<Props> = ({ item, onSave, lineId, onDelete }) => {
  const theme = useTheme();

  const [paletteQuantity, setPaletteQuantity] = useState(0);
  const [layerQuantity, setLayerQuantity] = useState(0);
  const [boxQuantity, setBoxQuantity] = useState(0);
  const [unitQuantity, setUnitQuantity] = useState(0);

  const [isOneZloty, setIsOneZloty] = useState(false);
  const [isRW, setIsRW] = useState(false);

  const areQuantityFieldsDisabled = !item;

  const getQuantity = () => {
    if (!item) {
      return new Decimal(0);
    }

    return new Decimal(
      paletteQuantity * (item?.pallet || 0) +
        layerQuantity * (item?.layer || 0) +
        boxQuantity * (item?.box || 0) +
        unitQuantity,
    );
  };

  const handleSave = () => {
    if (!item || !item.pallet || !item.layer || !item.box) {
      return;
    }

    onSave({
      id: lineId || newUuid(),
      itemId: item.itemId,
      lineNumber: 0,
      price: new Decimal(0),
      productGroup: item.productGroup,
      quantity: getQuantity(),
      productId: item.productId,
      itemName: item.itemName,
    });
  };

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
        {item?.pallet && (
          <IntegerFieldWithButtons
            value={paletteQuantity}
            onChange={setPaletteQuantity}
            label={`Palety [${item.pallet} szt. na palecie]`}
            isDisabled={areQuantityFieldsDisabled}
          />
        )}
        {item?.layer && (
          <IntegerFieldWithButtons
            value={layerQuantity}
            onChange={setLayerQuantity}
            label={`Warstwy [${item.layer} szt. na warstwie]`}
            isDisabled={areQuantityFieldsDisabled}
          />
        )}
        {item?.box && (
          <IntegerFieldWithButtons
            value={boxQuantity}
            onChange={setBoxQuantity}
            label={`Kartony [${item.box} szt. w kartonie]`}
            isDisabled={areQuantityFieldsDisabled}
          />
        )}
        <IntegerFieldWithButtons
          value={unitQuantity}
          onChange={setUnitQuantity}
          label="Sztuki"
          isDisabled={areQuantityFieldsDisabled}
        />
      </div>
      <span css={{ fontWeight: 700, color: theme.colors.primary, fontSize: "0.875rem" }}>
        Sztuk razem: {getQuantity().toFixed()}
      </span>
      <div css={{ display: "flex", gap: "0.5rem" }}>
        <Button
          variant={isOneZloty ? "primary" : "outline"}
          size="small"
          onClick={() => {
            setIsOneZloty(!isOneZloty);
          }}
        >
          za złotówkę
        </Button>
        <Button
          variant={isRW ? "primary" : "outline"}
          size="small"
          onClick={() => {
            setIsRW(!isRW);
          }}
        >
          RW
        </Button>
      </div>
      {/* todo: count total */}
      <span css={{ fontWeight: 700, color: theme.colors.primary, fontSize: "1rem" }}>Wartość netto: </span>
      <div css={{ display: "flex", justifyContent: "space-between", gap: "0.625rem" }}>
        {onDelete && (
          <Button onClick={onDelete} color="danger">
            Usuń
          </Button>
        )}
        <Button onClick={handleSave} css={{ marginLeft: "auto" }}>
          Zapisz
        </Button>
      </div>
    </div>
  );
};
