import { useTheme } from "@emotion/react";

import { CustomerStatus } from "@megaron/crm-contracts";
import { useDialogRoute } from "@megaron/dash-dialog";
import { SelectDialog } from "@megaron/dash-select";

type Props = {
  onStatusChange: (status: CustomerStatus) => void;
  currentStatus: CustomerStatus;
};

export const StatusField: React.FC<Props> = ({ onStatusChange, currentStatus }) => {
  const theme = useTheme();

  const editStatusDialog = useDialogRoute("/status", ({ onClose }) => (
    <SelectDialog
      noTrigger
      variant="single-select"
      initiallySelectedValues={currentStatus ? [currentStatus] : []}
      onSelect={(option) => onStatusChange(option?.value as CustomerStatus)}
      options={customerStatusList}
      isOpen
      onClose={onClose}
    />
  ));

  return (
    <div css={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <label
        css={{
          color: theme.colors.primary,
          fontSize: "0.875rem",
          fontFamily: theme.displayFontFamily,
          marginBottom: "0.25rem",
        }}
      >
        Status
      </label>
      <button
        onClick={editStatusDialog.open}
        css={{
          border: "none",
          width: "100%",
          padding: "0.5rem 0.75rem",
          fontSize: "1rem",
          borderRadius: "6px",
          backgroundColor: theme.colors.primaryLight,
          display: "flex",
          alignItems: "center",
          gap: "0.625rem",
          minHeight: "38px",
          color: theme.colors.primary,
        }}
      >
        {customerStatusList.find((status) => status.value === currentStatus)?.label}
      </button>
      {editStatusDialog.element}
    </div>
  );
};

export const customerStatusList: { label: string; value: CustomerStatus }[] = [
  { label: "VIP", value: "vip" },
  { label: "Zweryfikowany", value: "verified" },
  { label: "Niezweryfikowany", value: "unverified" },
  { label: "Nieaktywny", value: "inactive" },
];
