import { useTheme } from "@emotion/react";

import { Item } from "@megaron/crm-contracts";
import { RowCellContent, Table, TableBody, TableHeadColumn } from "@megaron/dash-table";
import { ItemSortField } from "@megaron/docs-contracts";

type Props = {
  items: Item[];
  isLoading: boolean;
  activeSortValue: { field: ItemSortField; order?: "ASC" | "DESC" | undefined } | undefined;
  onSortChange: (value: { field: ItemSortField; order?: "ASC" | "DESC" | undefined } | undefined) => void;
};

export const TradeItemsTable: React.FC<Props> = ({ items, isLoading, activeSortValue, onSortChange }) => {
  const theme = useTheme();

  const tableColumns: TableHeadColumn<ItemSortField>[] = [
    ...tableHeadingsList.map(({ heading, sortField }) =>
      sortField
        ? ({
            isSortable: true,
            sortFieldName: sortField,
            label: heading,
          } as const)
        : ({
            isSortable: false,
            label: heading,
          } as const),
    ),
  ];

  const getTableRowCellsContent = (item: Item): RowCellContent[] => {
    return [
      {
        isLink: true,
        cellCss: {
          color: item.isArchived ? theme.colors.secondaryText : theme.colors.primary,
          opacity: item.isArchived ? 0.5 : 1,
        },
        element: item.productId,
      },
      {
        isLink: true,
        cellCss: {
          color: item.isArchived ? theme.colors.secondaryText : theme.colors.primary,
          opacity: item.isArchived ? 0.5 : 1,
        },
        element: item.productGroup,
      },
      {
        isLink: true,
        cellCss: { color: theme.colors.secondaryText, opacity: item.isArchived ? 0.5 : 1 },
        element: item.itemId,
      },
      {
        isLink: true,
        cellCss: { color: theme.colors.secondaryText, opacity: item.isArchived ? 0.5 : 1 },
        element: item.gtin,
      },
      {
        isLink: true,
        cellCss: { color: theme.colors.secondaryText, opacity: item.isArchived ? 0.5 : 1 },
        element: item.bcNumber,
      },
      {
        isLink: true,
        cellCss: { color: theme.colors.secondaryText, opacity: item.isArchived ? 0.5 : 1 },
        element: item.itemName,
      },
      {
        isLink: true,
        cellCss: { color: theme.colors.secondaryText, opacity: item.isArchived ? 0.5 : 1 },
        element: `${item.size.toFixed(0)} kg`,
      },
      {
        isLink: true,
        cellCss: { color: theme.colors.secondaryText, opacity: item.isArchived ? 0.5 : 1 },
        element: item.pallet ? `${item.pallet} szt.` : "",
      },
      {
        isLink: true,
        cellCss: { color: theme.colors.secondaryText, opacity: item.isArchived ? 0.5 : 1 },
        element: item.layer ? `${item.layer} szt.` : "",
      },
      {
        isLink: true,
        cellCss: { color: theme.colors.secondaryText, opacity: item.isArchived ? 0.5 : 1 },
        element: item.box ? `${item.box} szt.` : "",
      },
      {
        isLink: true,
        cellCss: { color: theme.colors.secondaryText, opacity: item.isArchived ? 0.5 : 1 },
        element: item.cost?.toFixed(2) || "-",
      },
    ];
  };

  return (
    <div css={{ overflow: "auto", margin: "0 -2rem 1rem" }}>
      <Table<ItemSortField>
        columns={tableColumns}
        activeSortValue={activeSortValue}
        onSort={onSortChange}
        css={{ padding: "0 2rem" }}
      >
        <TableBody
          isLoading={isLoading}
          rows={items.map((item) => ({
            uuid: item.itemId,
            linkTo: `/crm/items/${item.itemId}`,
            cellsContent: getTableRowCellsContent(item),
            css: {
              "&:hover": {
                "td:nth-child(1)": {
                  textDecoration: "underline",
                },
              },
            },
          }))}
        />
      </Table>
    </div>
  );
};

export const tableHeadingsList: { heading: string; sortField?: ItemSortField }[] = [
  { heading: "Indeks" },
  { heading: "Grupa" },
  { heading: "Id" },
  { heading: "EAN", sortField: "gtin" },
  { heading: "Numer BC" },
  { heading: "Jednostka handlowa" },
  { heading: "Wielkość", sortField: "size" },
  { heading: "Paleta", sortField: "pallet" },
  { heading: "Warstwa", sortField: "layer" },
  { heading: "Karton", sortField: "box" },
  { heading: "Koszty standardowe" },
];
