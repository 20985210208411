import { useTheme } from "@emotion/react";
import { useState } from "react";
import { IconType } from "react-icons/lib";
import { MdBarChart, MdOutlineMap, MdOutlineViewList } from "react-icons/md";

import { Dialog } from "@megaron/dash-dialog";
import { Button, TextField } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { Tile } from "@megaron/dash-tile";
import { SavedViewType } from "@megaron/docs-contracts";

type Props = {
  onClose: () => void;
};

export const AddViewDialog: React.FC<Props> = ({ onClose }) => {
  const { isMobile } = useDeviceType();

  const [selectedViewType, setSelectedViewType] = useState<SavedViewType | null>(null);

  return (
    <Dialog
      placement={isMobile ? "top" : "center"}
      onClose={onClose}
      css={{ width: isMobile ? "100%" : "400px" }}
      header={selectedViewType ? "Uzupełnij dane widoku" : "Wybierz rodzaj widoku"}
    >
      {selectedViewType ? <ViewSettings viewType={selectedViewType} /> : <ViewOptions onSelect={setSelectedViewType} />}
    </Dialog>
  );
};

const ViewOptions: React.FC<{ onSelect: (value: SavedViewType) => void }> = ({ onSelect }) => {
  const theme = useTheme();

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
      {viewOptions.map((view) => (
        <Tile key={`new-view-${view.type}`} onClick={() => onSelect(view.type)} css={{ cursor: "pointer" }}>
          <div css={{ display: "flex", flexDirection: "column", gap: "0.5rem", padding: "0.25rem 0" }}>
            <div
              css={{
                display: "flex",
                alignItems: "center",
                gap: "0.75rem",
                color: theme.colors.primary,
                fontSize: "0.875rem",
              }}
            >
              <view.Icon size={14} />
              {view.label}
            </div>
            <p css={{ margin: 0, color: theme.colors.secondaryText, fontSize: "0.75rem" }}>{view.description}</p>
          </div>
        </Tile>
      ))}
    </div>
  );
};

const ViewSettings: React.FC<{ viewType: SavedViewType }> = ({ viewType }) => {
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem" }}>
      <TextField label="Nazwa widoku" value={title} onChange={setTitle} />
      <TextField label="Opis widoku" value={description} onChange={setDescription} />
      <Button css={{ alignSelf: "flex-end", marginTop: "0.25rem" }}>Utwórz widok</Button>
    </div>
  );
};

type View = {
  label: string;
  type: SavedViewType;
  description: string;
  Icon: IconType;
};

export const viewOptions: View[] = [
  { label: "Lista", type: "customerList", description: "Filtruj i przeglądaj bazę klientów.", Icon: MdOutlineViewList },
  {
    label: "Analiza",
    type: "customerAnalytics",
    description: "Analizuj sprzedaż, interakcje i relacje klientów.",
    Icon: MdBarChart,
  },
  { label: "Mapa", type: "customerMap", description: "Przeglądaj bazę klientów na mapie.", Icon: MdOutlineMap },
];
