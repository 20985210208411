import { interactionMentionSerializer, interactionTypes } from "@megaron/crm-contracts";
import { dateStringSerializer } from "@megaron/date-string";
import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { baseDocSerializer } from "../baseDoc";

export const customerInteractionDocSerializer = Serializers.object({
  ...baseDocSerializer("customer-interaction").fields,

  customerUuid: uuidSerializer,
  uuid: uuidSerializer,
  ownerEmail: Serializers.string,
  type: Serializers.stringOneOf(...interactionTypes).nullable(),
  message: Serializers.string,
  interactionDate: Serializers.datetime,
  dateString: dateStringSerializer,
  samples: Serializers.array(Serializers.string),
  mentions: interactionMentionSerializer.array(),
  durationInMinutes: Serializers.integer,
  googleCalendarEventId: Serializers.string.nullable(),
});

export type CustomerInteractionDoc = SerializerValue<typeof customerInteractionDocSerializer>;

export const customerInteractionActions = {
  customerInteractionQuery: HttpAction({
    path: "/customer/interactions",
    qsSerializer: Serializers.object({
      customerUuid: uuidSerializer,
      ownerEmail: Serializers.string,
      dates: dateStringSerializer.array().asJsonString(),
      limit: Serializers.integer,
    }).partial(),
    valueSerializer: Serializers.paginatedList(customerInteractionDocSerializer),
    requiresAuth: true,
  }),

  customerMentionedInteractionsQuery: HttpAction({
    path: "/customer/mentionedInteractions",
    qsSerializer: Serializers.object({
      limit: Serializers.integer,
    }).partial(),
    valueSerializer: Serializers.paginatedList(customerInteractionDocSerializer),
    requiresAuth: true,
  }),
};
