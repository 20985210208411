import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom-v5-compat";

import { OrderDoc } from "@megaron/docs-contracts";
import { Month } from "@megaron/serializers";

import { OrderTile } from "./OrderTile";

type Props = {
  orders: OrderDoc[];
  region?: string;
  startDate?: Month;
  endDate?: Month;
};

export const RegionOrders: React.FC<Props> = ({ orders, region, startDate, endDate }) => {
  const theme = useTheme();

  const ordersUrl = createUrl("/crm/orders", {
    regions: region ? [region] : undefined,
    page: 0,
    searchText: "",
    startDate,
    endDate,
  });

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem", width: "100%" }}>
      {orders.map((order) => (
        <OrderTile order={order} key={order.id} />
      ))}
      {orders.length >= 10 && !!region && (
        <Link
          to={ordersUrl}
          css={{
            color: theme.colors.primary,
            textDecoration: "underline",
            cursor: "pointer",
            "&:hover": {
              opacity: 0.8,
            },
          }}
        >
          Zobacz wszystkie zamówienia
        </Link>
      )}
    </div>
  );
};

const createUrl = (pathname: string, params: Record<string, any>) => {
  const searchText = JSON.stringify(params);

  return `${pathname}?filters=${encodeURIComponent(searchText)}`;
};
