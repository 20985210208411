import { useTheme } from "@emotion/react";
import { useLayoutEffect, useRef } from "react";

import { CustomerAnalyticsEntry, CustomerAnalyticsSortField } from "@megaron/crm-contracts";
import { useDialogRoute } from "@megaron/dash-dialog";
import { useDeviceType } from "@megaron/dash-mq";
import { RowCellContent, Table, TableBody, TableHeadColumn } from "@megaron/dash-table";

import { AddColumnButton } from "./AddColumnButton";
import { AddColumnDialog } from "./AddColumnDialog";
import { analyticsColumns, CellContent } from "./analyticsColumns";
import { AnalyticsPreset } from "./analyticsPreset";
import { SortableTableHeadingCell } from "./SortableTableHeadingCell";
import { TableHeadingCell } from "./TableHeadingCell";

type Props = {
  customersAnalytics: CustomerAnalyticsEntry[];
  isLoading: boolean;
  preset: AnalyticsPreset;
  activeSortValue: { field: CustomerAnalyticsSortField; order?: "ASC" | "DESC" | undefined } | undefined;
  onSortChange: (
    value:
      | { field: CustomerAnalyticsSortField; order?: "ASC" | "DESC" | undefined; aggName: string | null }
      | undefined,
  ) => void;
  onNextPageReach: () => void;
  onAggCreate: () => void;
};

export const CustomerAnalyticsTable: React.FC<Props> = ({
  customersAnalytics,
  isLoading,
  preset,
  activeSortValue,
  onSortChange,
  onNextPageReach,
  onAggCreate,
}) => {
  const theme = useTheme();

  const { isDesktop } = useDeviceType();

  const tableRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const currentTableRef = tableRef.current;

    if (!currentTableRef) {
      return;
    }

    const callback = () => {
      if (currentTableRef?.scrollHeight === (currentTableRef?.scrollTop ?? 0) + (currentTableRef?.clientHeight ?? 0)) {
        onNextPageReach();
      }
    };

    currentTableRef.addEventListener("scroll", callback);

    return () => {
      currentTableRef?.removeEventListener("scroll", callback);
    };
  }, [onNextPageReach]);

  const addColumnDialog = useDialogRoute("/add-column", ({ onClose }) => (
    <AddColumnDialog onClose={onClose} preset={preset} onAggCreate={onAggCreate} />
  ));

  const tableColumns: TableHeadColumn<CustomerAnalyticsSortField>[] = [
    {
      label: "Klient",
      cellCss: {
        padding: "0.5rem 0.75rem",
        position: "sticky",
        zIndex: 12,
        top: 0,
        background: theme.colors.background,
        left: 0,
      },
    },
    ...preset.columns.map(({ header, sortField, aggName }, index) =>
      sortField
        ? ({
            isSortable: false,
            label: header,
            cellCss: {
              padding: index === preset.columns.length - 1 ? 0 : "0 8px 0 0",
              position: "sticky",
              zIndex: 11,
              top: 0,
              background: theme.colors.background,
            },
            element: (
              <SortableTableHeadingCell
                label={header}
                sortFieldName={sortField}
                activeSortValue={
                  activeSortValue?.field
                    ? { field: activeSortValue.field, order: activeSortValue.order, aggName }
                    : undefined
                }
                onSortChange={onSortChange}
                aggName={aggName}
              />
            ),
          } as const)
        : ({
            isSortable: false,
            label: header,
            cellCss: {
              padding: index === preset.columns.length - 1 ? 0 : "0 8px 0 0",
              position: "sticky",
              zIndex: 11,
              top: 0,
              background: theme.colors.background,
            },
            element: <TableHeadingCell label={header} />,
          } as const),
    ),
    {
      label: "Dodaj kolumnę",
      element: <AddColumnButton onClick={addColumnDialog.open} />,
      cellCss: {
        padding: "0 0.5rem",
        position: "sticky",
        zIndex: 11,
        top: 0,
        width: "100%",
        background: theme.colors.background,
      },
    },
  ];

  const visibleTableColumnsIndexes = tableColumns
    .map((column, index) => (!column.label === undefined || !column.isHidden ? index : null))
    .filter((index) => index !== null) as number[];

  const getTableRowCellsContent = (
    analyticsConfig: AnalyticsPreset,
    analyticsEntry: CustomerAnalyticsEntry,
  ): RowCellContent[] => {
    return [
      {
        isLink: true,
        element: (
          <span css={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
            {analyticsEntry.name}
          </span>
        ),
        cellCss: { overflow: "hidden", width: "10rem" },
      },
      ...analyticsConfig.columns.map((column) => {
        const columnType = analyticsColumns.find(
          (cellConfig) => cellConfig.aggType === column.aggType && cellConfig.columnType === column.type,
        )?.columnType;

        if (!columnType) {
          return { isLink: true, element: null };
        }

        return {
          isLink: false,
          element: <CellContent componentType={columnType} analyticsEntry={analyticsEntry} aggName={column.aggName} />,
        };
      }),
      { isLink: false, element: null },
    ];
  };

  return (
    <div
      css={{
        overflow: "auto",
        margin: isDesktop ? "0 -2rem 0 0" : "0 -1rem 2.75rem",
      }}
      ref={tableRef}
    >
      <Table<CustomerAnalyticsSortField> columns={tableColumns} css={{ paddingRight: isDesktop ? "2rem" : "0" }}>
        <TableBody
          visibleColumnIndexes={visibleTableColumnsIndexes}
          isLoading={isLoading}
          rows={customersAnalytics.map((analytics) => ({
            uuid: analytics.uuid,
            linkTo: `/crm/customers/id/${analytics.uuid}`,
            cellsContent: getTableRowCellsContent(preset, analytics),
            css: {
              "td:first-of-type": {
                position: "sticky",
                left: 0,
                zIndex: 10,
                borderRightStyle: "solid",
              },
              "&:first-child td:last-child": {
                borderTopRightRadius: 0,
              },
              "&:first-child td:first-child": {
                borderTopLeftRadius: isDesktop ? theme.smallBorderRadius : 0,
              },
            },
          }))}
        />
      </Table>
      {addColumnDialog.element}
    </div>
  );
};
