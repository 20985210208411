import { useTheme } from "@emotion/react";
import { MdQrCode2 } from "react-icons/md";
import { Link } from "react-router-dom-v5-compat";

import { AffiliateCodeDto } from "@megaron/loyalty-points-contracts";

import { ShareButton } from "./ShareButton";

type Props = {
  affiliateCode: AffiliateCodeDto;
};

export const MyAffiliateCodeTile: React.FC<Props> = ({ affiliateCode }) => {
  const theme = useTheme();

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        justifyContent: "space-between",
        padding: "0.75rem",
        width: "100%",
      }}
    >
      <span css={{ fontSize: "1rem", fontWeight: "700", color: theme.colors.primary }}>{affiliateCode.id}</span>
      <div css={{ display: "flex", gap: "0.5rem" }}>
        <ShareButton content={`https://kod.smig.pl/${affiliateCode.id}`} />
        <Link
          css={{
            background: theme.colors.primaryLight,
            width: "2rem",
            height: "2rem",
            borderRadius: "100%",
            border: "none",
            padding: 0,
            color: theme.colors.primary,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          to={`/crm/my-affiliate-codes/${affiliateCode.id}`}
        >
          <MdQrCode2 size={16} />
        </Link>
      </div>
    </div>
  );
};
