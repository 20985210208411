import { AggType, CustomerAnalyticsEntry } from "@megaron/crm-contracts";

import { customerStatusList } from "../StatusField";
import { AnalyticsPreset } from "./analyticsPreset";
import { GroupPotentialRealization } from "./GroupPotentialRealization";
import { PotentialRealization } from "./PotentialRealization";
import { SalesHistory } from "./SalesHistory";

export type ColumnType =
  | "profit"
  | "potential"
  | "potential-realization"
  | "groups-potential-realization"
  | "sales-history"
  | "interaction-count"
  | "potential-per-interaction"
  | "child-payer"
  | "child-pos"
  | "child-distributor"
  | "child-loyaltyUser"
  | "parent-chain"
  | "parent-payer"
  | "parent-pos"
  | "parent-distributor"
  | "region"
  | "status";

export const analyticsColumns: { columnType: ColumnType; aggType: AggType | null; columnName: string }[] = [
  { aggType: "sales", columnType: "profit", columnName: "Marża" },
  { aggType: "sales", columnType: "potential", columnName: "Potencjał" },
  { aggType: "sales", columnType: "potential-realization", columnName: "Realizacja potencjału" },
  // { aggType: "sales", columnType: "groups-potential-realization" },
  // { aggType: "sales", columnType: "sales-history" },
  { aggType: "interactions", columnType: "interaction-count", columnName: "Ilość interakcji" },
  // { aggType: "interactions", columnType: "potential-per-interaction" },
  // { aggType: "childCustomers", columnType: "child-payer" },
  // { aggType: "childCustomers", columnType: "child-loyaltyUser" },
  // { aggType: "childCustomers", columnType: "child-distributor" },
  // { aggType: "childCustomers", columnType: "child-pos" },
  // { aggType: "parentCustomers", columnType: "parent-payer" },
  // { aggType: "parentCustomers", columnType: "parent-pos" },
  // { aggType: "parentCustomers", columnType: "parent-distributor" },
  // { aggType: "parentCustomers", columnType: "parent-payer" },
  { aggType: null, columnType: "region", columnName: "Region" },
  { aggType: null, columnType: "status", columnName: "Status" },
];

export const analyticsColumnsTypes = analyticsColumns.map((column) => column.columnType);

export const getRowContent = (analyticsConfig: AnalyticsPreset, analyticsEntry: CustomerAnalyticsEntry) => {
  return analyticsConfig.columns.map((column) => {
    const columnType = analyticsColumns.find(
      (cellConfig) => cellConfig.aggType === column.aggType && cellConfig.columnType === column.type,
    )?.columnType;

    if (!columnType) {
      return null;
    }

    return <CellContent componentType={columnType} analyticsEntry={analyticsEntry} aggName={column.aggName} />;
  });
};

export const CellContent: React.FC<{
  componentType: ColumnType;
  analyticsEntry: CustomerAnalyticsEntry;
  aggName: string | null;
}> = ({ componentType, analyticsEntry, aggName }) => {
  const agg = aggName ? analyticsEntry.aggs[aggName] : null;

  if (componentType === "profit") {
    if (!agg || agg.type !== "sales") {
      return null;
    }

    return (
      <span css={{ textWrap: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
        {agg.profit?.toFixed(2)} zł
      </span>
    );
  }

  if (componentType === "potential") {
    if (!agg || agg.type !== "sales") {
      return null;
    }

    return <span css={{ textWrap: "nowrap" }}>{agg.profit?.toFixed(2)} zł</span>;
  }

  if (componentType === "potential-realization") {
    if (!agg || agg.type !== "sales") {
      return null;
    }

    return <PotentialRealization potential={agg.absolutePotential} potentialRealization={agg.potentialRealization} />;
  }

  // if (componentType === "groups-potential-realization") {
  //   if (!agg) {
  //     return null;
  //   }

  //   return (
  //     <GroupPotentialRealization groupRealization={agg.salesByGroup?.map((group) => group.potentialRealization)} />
  //   );
  // }

  // if (componentType === "sales-history") {
  //   if (!agg) {
  //     return null;
  //   }

  //   return <SalesHistory salesHistory={agg.salesHistory} />;
  // }

  if (componentType === "interaction-count") {
    if (!agg || agg.type !== "interactions") {
      return null;
    }

    return <span>{agg.interactionCount}</span>;
  }

  // if (componentType === "potential-per-interaction") {
  //   if (!agg || !agg || !agg.absolutePotential || !agg.interactionCount) {
  //     return null;
  //   }

  //   return <span>{agg.absolutePotential.div(agg.interactionCount).toFixed(2)} zł</span>;
  // }

  if (componentType === "region") {
    return <span css={{ textWrap: "nowrap" }}>{analyticsEntry.region}</span>;
  }

  if (componentType === "status") {
    return (
      <span css={{ textWrap: "nowrap" }}>
        {customerStatusList.find((status) => status.value === analyticsEntry.status)?.label}
      </span>
    );
  }

  // if (componentType === "child-payer") {
  //   if (!childCustomersAgg) {
  //     return null;
  //   }

  //   return <span>{childCustomersAgg.payerCount}</span>;
  // }

  // if (componentType === "child-loyaltyUser") {
  //   if (!childCustomersAgg) {
  //     return null;
  //   }

  //   return <span>{childCustomersAgg.loyaltyUserCount}</span>;
  // }

  // if (componentType === "child-distributor") {
  //   if (!childCustomersAgg) {
  //     return null;
  //   }

  //   return <span>{childCustomersAgg.distributorCount}</span>;
  // }

  // if (componentType === "child-pos") {
  //   if (!childCustomersAgg) {
  //     return null;
  //   }

  //   return <span>{childCustomersAgg.posCount}</span>;
  // }

  // if (componentType === "parent-payer") {
  //   if (!parentCustomersAgg) {
  //     return null;
  //   }

  //   return <span>{parentCustomersAgg.payerCount}</span>;
  // }

  // if (componentType === "parent-pos") {
  //   if (!parentCustomersAgg) {
  //     return null;
  //   }

  //   return <span>{parentCustomersAgg.posCount}</span>;
  // }

  // if (componentType === "parent-distributor") {
  //   if (!parentCustomersAgg) {
  //     return null;
  //   }

  //   return <span>{parentCustomersAgg.distributorCount}</span>;
  // }

  return null;
};
