import { Currency, Gtin, PackSizeDefaultsDto, PricedItemDto } from "@megarax/crm-contracts";
import {
  DenominatedQuantity,
  denominatedQuantityToNumber,
  DenominationDefinitions,
  formatCurrency,
  numberToDenominatedQuantity,
} from "@megarax/react-utils";
import { AsyncButton, IntegerInput, NewRouterResponsiveSelect } from "@megarax/ui-components";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Decimal from "decimal.js";
import React, { useEffect, useState } from "react";
import { v4 } from "uuid";

import { PromoPriceInput } from "../PromoPriceInput";
import { AddLineInput } from "./NewItemDialogContainer";

interface Props {
  closeDialog: () => void;
  getOrderableItems: (searchText: string) => Promise<PricedItemDto[]>;
  getPackSize: (gtin: Gtin) => Promise<PackSizeDefaultsDto | null>;
  addLine: (input: AddLineInput) => Promise<void>;
  currency: Currency;
}

type Quantities = DenominatedQuantity<string>;

const initialQuantities = {
  item: 0,
  box: 0,
  layer: 0,
  pallet: 0,
};

const getDefinitionsFromPackSize = (packSize: PackSizeDefaultsDto | null): DenominationDefinitions<string> => {
  return {
    item: 1,
    ...(packSize?.box && { box: packSize.box }),
    ...(packSize?.layer && { layer: packSize.layer }),
    ...(packSize?.pallet && { pallet: packSize.pallet }),
  };
};

const sumNetPriceText = ({
  netPricePerUnit,
  promoPrice,
  quantity,
  currency,
}: {
  netPricePerUnit: Decimal;
  promoPrice: Decimal | null;
  quantity: number;
  currency: Currency;
}) => {
  const unitPrice = promoPrice ?? netPricePerUnit;
  const total = unitPrice.mul(quantity);
  return `Wartość netto: ${formatCurrency(total.toNumber(), currency)} (${formatCurrency(
    unitPrice.toNumber(),
    currency,
  )}/szt.)`;
};

export const NewItemDialog: React.FC<Props> = ({ closeDialog, getOrderableItems, addLine, getPackSize, currency }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState<PricedItemDto | null>(null);
  const [packSize, setPackSize] = useState<PackSizeDefaultsDto | null>();
  const [quantities, setQuantities] = useState<Quantities>(initialQuantities);
  const [promoPrice, setPromoPrice] = useState<Decimal | null>(null);
  const [quantity, setQuantity] = useState<number>(0);

  const onQuantityChange = (fieldName: keyof Quantities) => (value: number) =>
    setQuantities({ ...quantities, [fieldName]: value });

  const recount = () => {
    if (packSize === undefined) return initialQuantities;
    const defs = getDefinitionsFromPackSize(packSize);
    setQuantities(numberToDenominatedQuantity(defs)(quantity));
    return;
  };

  const onSubmit = () => {
    if (!selectedItem) return;
    return addLine({
      lineUuid: v4(),
      item: { gtin: selectedItem.gtin },
      quantity,
      promoPrice,
    }).then(closeDialog);
  };

  useEffect(() => {
    if (!selectedItem) return setPackSize(undefined);
    getPackSize(selectedItem.gtin).then(setPackSize);
  }, [selectedItem]);

  useEffect(() => {
    setQuantities(initialQuantities);
  }, [packSize]);

  useEffect(() => {
    if (packSize === undefined) return setQuantity(0);
    setQuantity(denominatedQuantityToNumber(getDefinitionsFromPackSize(packSize))(quantities));
  }, [quantities]);

  return (
    <Dialog open={true} onClose={closeDialog} fullScreen={isMobile} sx={{ zIndex: 1010 }}>
      <DialogTitle>Nowy przedmiot</DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.orderableItem}>
          <NewRouterResponsiveSelect
            label="Jednostka handlowa"
            value={selectedItem}
            getOptions={getOrderableItems}
            getKey={(i) => i.gtin}
            getValue={(i) => i}
            getLabel={(i) => i.name ?? "?"}
            onChange={setSelectedItem}
            renderOption={{
              desktop: (item) => renderPricedItemOption(item, currency),
              mobile: (item) => renderPricedItemOption(item, currency),
            }}
          />
        </div>
        {packSize !== undefined && selectedItem && (
          <>
            <Divider />
            <div className={classes.quantities}>
              {packSize?.pallet && (
                <IntegerInput
                  label="Palety"
                  helperText={`Sztuk na palecie: ${packSize.pallet}`}
                  variant="outlined"
                  value={quantities.pallet}
                  onChange={onQuantityChange("pallet")}
                />
              )}
              {packSize?.layer && (
                <IntegerInput
                  label="Warstwy"
                  helperText={`Sztuk na warstwie: ${packSize.layer}`}
                  variant="outlined"
                  value={quantities.layer}
                  onChange={onQuantityChange("layer")}
                />
              )}
              {packSize?.box && (
                <IntegerInput
                  label="Kartony"
                  helperText={`Sztuk w pudle: ${packSize.box}`}
                  variant="outlined"
                  value={quantities.box}
                  onChange={onQuantityChange("box")}
                />
              )}

              <IntegerInput
                label="Sztuki"
                variant="outlined"
                value={quantities.item}
                onChange={onQuantityChange("item")}
              />
              <div className={classes.row}>
                <Typography>{`Sztuk razem: ${quantity}`}</Typography>
                <Button onClick={recount}>Przelicz</Button>
              </div>
            </div>
            <div className={classes.priceSummary}>
              <PromoPriceInput value={promoPrice} onChange={setPromoPrice} />
              <Typography className={classes.sumText}>
                {sumNetPriceText({
                  netPricePerUnit: selectedItem.finalPrice ?? new Decimal(0),
                  promoPrice,
                  quantity,
                  currency,
                })}
              </Typography>
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Anuluj</Button>
        <AsyncButton
          color="primary"
          asyncAction={onSubmit}
          disabled={packSize === undefined || !selectedItem || quantity <= 0}
        >
          Dodaj
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
};

const getProfitMarginTag = (relativeMargin: Decimal) =>
  `${relativeMargin.isNeg() ? "" : "+"}${relativeMargin.mul(100).toFixed(0)}%`;

const renderPricedItemOption = (item: PricedItemDto, currency: Currency) => (
  <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
    <Typography variant="inherit" display="inline">
      {item.name}
    </Typography>
  </Box>
);

const useStyles = makeStyles((theme: Theme) => {
  return {
    content: {
      width: "300px",
      [theme.breakpoints.down("sm")]: {
        boxSizing: "border-box",
        padding: theme.spacing(2),
        width: "100%",
        display: "flex",
        flexDirection: "column",
      },
    },
    priceSummary: {
      marginTop: "auto",
    },
    orderableItem: {
      marginBottom: theme.spacing(2),
    },
    quantities: {
      margin: theme.spacing(2, 0),
      "& > .MuiFormControl-root": {
        margin: theme.spacing(1, 0),
      },
    },
    row: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    sumText: {
      marginTop: theme.spacing(2),
      fontWeight: theme.typography.fontWeightMedium,
    },
  };
});
