import { useTheme } from "@emotion/react";
import { MdAdd, MdLan, MdOutline3P, MdOutlineFilterAlt, MdShoppingCart } from "react-icons/md";

import { AggType, CustomerAnalyticsSortField, CustomerCategory, CustomerStatus } from "@megaron/crm-contracts";
import { useDialogRoute } from "@megaron/dash-dialog";
import { MultiSelectSimpleFilter, SelectFilter } from "@megaron/dash-filters";
import { useDeviceType } from "@megaron/dash-mq";
import { useClientManager } from "@megaron/react-clients";

import { customerCategories } from "../addCustomer/CustomerCategorySection";
import { customerStatusList } from "../StatusField";
import { TimeUnit } from "../TimeRangeSelect";
import { CustomerAnalyticsFiltersDialog } from "./CustomerAnalyticsFiltersDialog";
import { Filters } from "./useCustomerAnalyticsGlobalFilters";

export type CustomerAnalyticsSort = { field: CustomerAnalyticsSortField; order: "ASC" | "DESC" | undefined };

export type FiltersProps = {
  filters: Filters;
  handlers: {
    onRegionsChange: (regions: string[] | undefined) => void;
    onCategoriesChange: (categories: CustomerCategory[] | undefined) => void;
    onStatusChange: (status: CustomerStatus[] | undefined) => void;
    onDateChange: (
      startDate: Date | null,
      endDate: Date | null,
      timeUnit: TimeUnit | null,
      unitCount: number | null,
      activeOptionName: string | null,
    ) => void;
    onProductGroupsChange: (productGroups: string[]) => void;
  };
  aggs: { type: AggType; name: string; onClick: () => void }[];
};

export const CustomerAnalyticsFilters: React.FC<FiltersProps> = ({ filters, handlers, aggs }) => {
  const theme = useTheme();

  const { isMobile } = useDeviceType();

  const regionsQuery = useClientManager("crm").regionsQuery().useQuery({});

  const regionsOptions = regionsQuery.data
    ? regionsQuery.data.items.map((region) => ({
        label: region.name,
        value: region.name,
      }))
    : [];

  const filtersDialog = useDialogRoute("/add-filter", ({ onClose }) => (
    <CustomerAnalyticsFiltersDialog onClose={onClose} filters={filters} handlers={handlers} />
  ));

  const activeGlobalFilters = [filters.status, filters.category, filters.region].filter(
    (filter) => filter && filter.length > 0,
  );

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
      }}
    >
      <MdOutlineFilterAlt size={20} css={{ color: theme.colors.primary, flexShrink: 0 }} />
      {isMobile ? (
        <button
          css={{
            fontSize: "0.875rem",
            fontWeight: 700,
            color: theme.colors.primary,
            border: "none",
            background: "none",
            padding: "0.125rem 0.25rem",
          }}
          onClick={filtersDialog.open}
        >
          Filtry ({activeGlobalFilters.length})
        </button>
      ) : (
        <>
          {aggs.map((agg) => (
            <AggFilterTile key={agg.name} name={agg.name} type={agg.type} onClick={agg.onClick} />
          ))}
          {filters.status && filters.status.length > 0 && (
            <MultiSelectSimpleFilter
              label={"Status"}
              values={filters.status || []}
              options={customerStatusList}
              color="light"
              onChange={(values: CustomerStatus[]) => handlers.onStatusChange(values.length > 0 ? values : undefined)}
            />
          )}
          {filters.category && filters.category.length > 0 && (
            <MultiSelectSimpleFilter
              label={"Kategoria"}
              color="light"
              values={filters.category || []}
              options={customerCategories.map((category) => ({ label: category.label, value: category.value }))}
              onChange={(values) => handlers.onCategoriesChange(values.length > 0 ? values : undefined)}
            />
          )}
          {filters.region && filters.region.length > 0 && (
            <SelectFilter
              key={filters.region.join("-")}
              label="Regiony"
              initiallySelectedValues={filters.region ?? []}
              options={regionsOptions ?? []}
              onSelectedChange={(options) =>
                handlers.onRegionsChange(options.length ? options.map((option) => option.value) : undefined)
              }
              variant="multi-select"
              color="light"
            />
          )}
          <button
            css={{
              padding: "0.25rem 0.5rem",
              borderRadius: theme.smallBorderRadius,
              background: theme.colors.primaryLight,
              color: theme.colors.primary,
              border: "none",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              minHeight: "24px",
              alignSelf: "stretch",
            }}
            onClick={filtersDialog.open}
          >
            <MdAdd size={16} />
          </button>
        </>
      )}
      {filtersDialog.element}
    </div>
  );
};

const AggFilterTile: React.FC<{
  name: string;
  type: AggType;
  onClick: () => void;
}> = ({ name, type, onClick }) => {
  const theme = useTheme();

  return (
    <button
      css={{
        display: "flex",
        alignItems: "center",
        gap: "0.375rem",
        borderRadius: theme.smallBorderRadius,
        padding: "5px 10px",
        background: theme.colors.primary,
        color: "white",
        cursor: "pointer",
        fontSize: "0.875rem",
        whiteSpace: "nowrap",
        border: "none",
      }}
      onClick={onClick}
    >
      <AggIcon type={type} />
      {name}
    </button>
  );
};

const AggIcon: React.FC<{ type: AggType }> = ({ type }) => {
  if (type === "interactions") {
    return <MdOutline3P size={12} />;
  }

  if (type === "sales") {
    return <MdShoppingCart size={12} />;
  }

  return <MdLan size={12} />;
};

export const sortOptions: { label: string; value: CustomerAnalyticsSortField }[] = [
  { label: "Całkowity potencjał", value: "absolutePotential" },
  { label: "Koszt", value: "cost" },
  { label: "Pierwsza interakcja", value: "firstInteractionTime" },
  { label: "Pierwsza sprzedaż", value: "firstSaleTime" },
  { label: "Ilość grup produktowych", value: "groupCount" },
  { label: "Realizacja potencjału grup", value: "groupRealization" },
  { label: "Ilość interakcji", value: "interactionCount" },
  { label: "Ilość Jednostek handlowych", value: "itemCount" },
  { label: "Ostatnia interakcja", value: "lastInteractionTime" },
  { label: "Ostatnia sprzedaż", value: "lastSaleTime" },
  { label: "Nazwa", value: "name" },
  { label: "Ilość zamówień", value: "orderCount" },
  { label: "Realizacja potencjału", value: "potentialRealization" },
  { label: "Ilość produktów", value: "productCount" },
  { label: "Marża", value: "profit" },
  { label: "Ilość grup ze zrealizowanym potencjałem", value: "realizedGroupCount" },
  { label: "Wielkość", value: "size" },
  { label: "Ilość grup z niezrealizowanym potencjałem", value: "unrealizedGroupCount" },
  { label: "Wartość", value: "value" },
];
