import { BoolFilter, FilterList, MultiSelectSimpleFilter, SelectFilter, SelectFilterDocs } from "@megaron/dash-filters";
import { getDocId } from "@megaron/docs-contracts";
import { ThreadStatus, threadTags } from "@megaron/invoices-contracts";
import { useServiceClient } from "@megaron/react-clients";
import { BooleanFieldFilterOption } from "@megaron/serializers";
import { Uuid } from "@megaron/uuid";

import { AllThreadsSortFilter } from "./AllThreadsSortFilter";
import { Filters, SortField } from "./useThreadFilters";

type Props = {
  filters: Filters;
  handlers: {
    onStatusChange: (status: ThreadStatus[] | undefined) => void;
    onBudgetUuidChange: (budgetUuids: Uuid[] | undefined) => void;
    onDigitalChange: (isDigital: BooleanFieldFilterOption) => void;
    onReceivedChange: (isReceived: BooleanFieldFilterOption) => void;
    onPostedChange: (isPosted: BooleanFieldFilterOption) => void;
    onParticipantChange: (participants: string[] | undefined) => void;
    onReadChange: (isRead: BooleanFieldFilterOption | undefined) => void;
    onIsMineChange: (isRead: BooleanFieldFilterOption | undefined) => void;
    onOverdueChange: (isOverdue: BooleanFieldFilterOption) => void;
    onSignedByChange: (signedBy: string[] | undefined) => void;
    onIssuerChange: (issuer: string | undefined) => void;
    onSortChange: (sort: SortField | undefined) => void;
    onTagsChange: (status: string[] | undefined) => void;
  };
};

export const AllThreadsFilters: React.FC<Props> = ({ filters, handlers }) => {
  const docs = useServiceClient("docs");

  return (
    <FilterList key={`threads-filters-${filters.groupFilter}`}>
      <BoolFilter
        label={"Moje faktury?"}
        value={parseBooleanFieldOptionToBoolean(filters.isMine, false)}
        onChange={(value) => handlers.onIsMineChange(parseBooleanToBooleanFieldOption(value, false))}
        falseLabel="Nie"
        trueLabel="Tak"
      />
      <MultiSelectSimpleFilter
        label={"Status"}
        values={filters.status || []}
        options={[
          { label: "Otwarte", value: "open" },
          { label: "Zatwierdzone", value: "approved" },
          { label: "Odrzucone", value: "closed" },
        ]}
        onChange={(values: ThreadStatus[]) => handlers.onStatusChange(values.length > 0 ? values : undefined)}
      />
      <BoolFilter
        label={"Faktura papierowa?"}
        value={parseBooleanFieldOptionToBoolean(filters.isDigital, true)}
        onChange={(value) => handlers.onDigitalChange(parseBooleanToBooleanFieldOption(value, true))}
        falseLabel="Nie"
        trueLabel="Tak"
      />
      <BoolFilter
        label={"Dotarła?"}
        value={parseBooleanFieldOptionToBoolean(filters.isReceived)}
        onChange={(value) => handlers.onReceivedChange(parseBooleanToBooleanFieldOption(value))}
        falseLabel="Nie"
        trueLabel="Tak"
      />
      <BoolFilter
        label={"Przekazana?"}
        value={parseBooleanFieldOptionToBoolean(filters.isPosted)}
        onChange={(value) => handlers.onPostedChange(parseBooleanToBooleanFieldOption(value))}
        falseLabel="Nie"
        trueLabel="Tak"
      />
      <SelectFilterDocs
        label={"Budżet"}
        initiallySelectedDocIds={(filters.budgetUuid?.map((id) => getDocId("budget", id)) || []) as string[]}
        docType={["budget"]}
        onSelectedChange={(options) =>
          handlers.onBudgetUuidChange(options.length > 0 ? options.map((option) => Uuid(option.value)) : undefined)
        }
        variant="multi-select"
      />
      <SelectFilterDocs
        label={"Podpisujący"}
        initiallySelectedDocIds={(filters.signedBy?.map((user) => getDocId("user", user)) || []) as string[]}
        onSelectedChange={(options) =>
          handlers.onSignedByChange(options.length > 0 ? options.map((option) => option.value) : undefined)
        }
        docType={["user"]}
        variant="multi-select"
      />
      <SelectFilterDocs
        label={"Uczestnik"}
        initiallySelectedDocIds={(filters.participant?.map((user) => getDocId("user", user)) || []) as string[]}
        onSelectedChange={(options) =>
          handlers.onParticipantChange(options.length > 0 ? options.map((option) => option.value) : undefined)
        }
        docType={["user"]}
        variant="multi-select"
      />
      <SelectFilter
        label={"Wystawca"}
        optionalNoResultsText={"Wpisz nazwę wystawcy..."}
        onSelect={(option) => handlers.onIssuerChange(option?.value)}
        initiallySelectedValues={filters.issuer ? [filters.issuer] : []}
        variant="single-select"
        search={async (searchText) => {
          const results = searchText
            ? await docs.autocomplete({
                text: searchText,
                doctype: "invoice-thread",
                field: "invoiceIssuer",
              })
            : null;

          return results ? results.value?.map((result) => ({ label: result, value: result })) ?? [] : [];
        }}
      />
      <BoolFilter
        label={"Przeczytane?"}
        value={parseBooleanFieldOptionToBoolean(filters.isRead)}
        onChange={(value) => handlers.onReadChange(parseBooleanToBooleanFieldOption(value))}
        falseLabel="Nie"
        trueLabel="Tak"
      />
      <AllThreadsSortFilter onSortChange={handlers.onSortChange} filters={filters} />
      <MultiSelectSimpleFilter
        label={"Tagi"}
        values={filters.tags || []}
        options={threadTags.map((t) => ({ label: `#${t}`, value: t }))}
        onChange={(values: string[]) => handlers.onTagsChange(values.length > 0 ? values : undefined)}
      />
    </FilterList>
  );
};

export const parseBooleanFieldOptionToBoolean = (
  option: BooleanFieldFilterOption | undefined,
  reverse?: boolean,
): boolean | undefined => {
  if (reverse) {
    if (option === "true") return false;

    if (option === "false") return true;

    return undefined;
  }

  if (option === "true") return true;

  if (option === "false") return false;

  return undefined;
};

export const parseBooleanToBooleanFieldOption = (
  value: boolean | undefined,
  reverse?: boolean,
): BooleanFieldFilterOption => {
  if (reverse) {
    if (value === true) return "false";

    if (value === false) return "true";

    return "any";
  }

  if (value === true) return "true";

  if (value === false) return "false";

  return "any";
};
