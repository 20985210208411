import { CSSObject, useTheme } from "@emotion/react";
import React, { useState } from "react";
import tinycolor from "tinycolor2";

type SliderProps = {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  className?: string;
  color?: "normal" | "secondary" | "danger" | "inverse";
  isDisabled?: boolean;
  showSteps?: boolean;
  showTooltip?: boolean;
};

export const Slider: React.FunctionComponent<SliderProps> = ({
  value,
  onChange,
  min = 0,
  max = 100,
  step = 1,
  className,
  color = "normal",
  isDisabled = false,
  showSteps = false,
  showTooltip = false,
}) => {
  const theme = useTheme();
  const [draggingValue, setDraggingValue] = useState(value);
  const [showTooltipState, setShowTooltipState] = useState(false);

  const foregroundColor = {
    normal: theme.colors.primary,
    danger: theme.colors.danger,
    secondary: theme.colors.primaryLight,
    inverse: theme.colors.background,
  }[color];

  const trackColor = tinycolor(foregroundColor).setAlpha(0.2).toString();
  const stepMarkerColor = "white";

  const styles: { slider: CSSObject; thumb: CSSObject; stepMarker?: CSSObject } = {
    slider: {
      width: "100%",
      height: "0.4em",
      background: trackColor,
      borderRadius: "0.2em",
      position: "relative",
      cursor: isDisabled ? "not-allowed" : "pointer",
      opacity: isDisabled ? 0.4 : 1,
      transition: "opacity 0.2s ease-in-out",
    },
    thumb: {
      width: "1em",
      height: "1em",
      background: foregroundColor,
      borderRadius: "50%",
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      cursor: isDisabled ? "not-allowed" : "pointer",
      transition: "background 0.2s ease-in-out",
    },
    stepMarker: {
      position: "absolute",
      top: "50%",
      width: "0.1em",
      height: "0.5em",
      backgroundColor: stepMarkerColor,
      borderRadius: "0.1em",
      transform: "translate(-50%, -50%)",
    },
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isDisabled) {
      setDraggingValue(Number(e.target.value));
      if (showTooltip) {
        setShowTooltipState(true);
      }
    }
  };

  const handleMouseUp = () => {
    if (!isDisabled) {
      onChange(draggingValue);
    }
    setShowTooltipState(false);
  };

  const stepMarkers = showSteps
    ? Array.from({ length: Math.floor((max - min) / step) - 1 }, (_, i) => min + (i + 1) * step).map((markerValue) => (
        <div
          key={markerValue}
          css={{
            ...styles.stepMarker,
            left: `${((markerValue - min) / (max - min)) * 100}%`,
          }}
        />
      ))
    : null;

  const tooltipLeft = `${((draggingValue - min) / (max - min)) * 100}%`;

  return (
    <div className={className} css={styles.slider}>
      {stepMarkers}
      {showTooltipState && showTooltip && (
        <div
          style={{
            position: "absolute",
            left: tooltipLeft,
            transform: "translateX(-50%)",
            bottom: "20px",
            padding: "6px 12px",
            backgroundColor: theme.colors.primaryLight,
            color: theme.colors.primary,
            borderRadius: "4px",
            fontSize: "0.875rem",
            whiteSpace: "nowrap",
            zIndex: 999999,
          }}
        >
          {draggingValue}
        </div>
      )}
      <input
        type="range"
        value={draggingValue}
        min={min}
        max={max}
        step={step}
        onChange={handleChange}
        onMouseUp={handleMouseUp}
        disabled={isDisabled}
        css={{
          borderRadius: "0.2em",
          width: "100%",
          height: "100%",
          background: `linear-gradient(to right, ${foregroundColor} 0%, ${foregroundColor} ${
            ((draggingValue - min) / (max - min)) * 100
          }%, ${trackColor} ${((draggingValue - min) / (max - min)) * 100}%, ${trackColor} 100%)`,
          appearance: "none",
          position: "absolute",
          top: 0,
          left: 0,
          margin: 0,
          padding: 0,
          cursor: isDisabled ? "not-allowed" : "pointer",
          "&::-webkit-slider-thumb": {
            appearance: "none",
            width: "1em",
            height: "1em",
            background: foregroundColor,
            borderRadius: "50%",
            cursor: isDisabled ? "not-allowed" : "pointer",
          },
          "&::-moz-range-thumb": {
            width: "1em",
            height: "1em",
            background: foregroundColor,
            borderRadius: "50%",
            cursor: isDisabled ? "not-allowed" : "pointer",
          },
        }}
      />
    </div>
  );
};
