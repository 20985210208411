import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { customerCategories } from "./categories";
import { interactionTypes } from "./history";

const biCustomerSerialzier = Serializers.object({
  id: uuidSerializer,
  firstName: Serializers.string,
  lastName: Serializers.string,
  region: Serializers.string.nullable(),
  regionOwnerEmail: Serializers.string.nullable(),
  isAffiliate: Serializers.boolean,
  isPro: Serializers.boolean,
  isArchived: Serializers.boolean,
  categories: Serializers.string.array(),
  pointsOfSale: Serializers.object({
    uuid: uuidSerializer,
    name: Serializers.string.nullable(),
  }).array(),
});

export type BiCustomer = SerializerValue<typeof biCustomerSerialzier>;

const biCustomerPurchaseSerializer = Serializers.object({
  time: Serializers.datetime,
  massKg: Serializers.decimal,
  productId: Serializers.string,
  customerId: uuidSerializer,
  customerCategories: Serializers.string.array(),
  region: Serializers.string.nullable(),
  regionOwnerEmail: Serializers.string.nullable(),
});

export type BiCustomerPurchase = SerializerValue<typeof biCustomerPurchaseSerializer>;

const biCustomerInteractionsSerializer = Serializers.object({
  time: Serializers.datetime,
  interactionType: Serializers.stringOneOf(...interactionTypes),
  customerId: uuidSerializer,
  customerCategories: Serializers.string.array(),
  interactionOwnerEmail: Serializers.string,
  region: Serializers.string.nullable(),
  regionOwnerEmail: Serializers.string.nullable(),
  samples: Serializers.string.nullable(),
});

export type BiCustomerInteractions = SerializerValue<typeof biCustomerInteractionsSerializer>;

export const bi = {
  biCustomersQuery: HttpAction({
    path: "/bi/customers",
    requiresAuth: true,
    valueSerializer: biCustomerSerialzier.array(),
  }),

  biCustomerPurchasesQuery: HttpAction({
    path: "/bi/customerPurchases",
    requiresAuth: true,
    valueSerializer: biCustomerPurchaseSerializer.array(),
  }),

  biInteractionsQuery: HttpAction({
    path: "/bi/interactions",
    requiresAuth: true,
    valueSerializer: biCustomerInteractionsSerializer.array(),
  }),
};
