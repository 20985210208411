import { Theme, useTheme } from "@emotion/react";
import { formatCurrency } from "@megarax/react-utils";
import { Link } from "react-router-dom-v5-compat";

import { DottedProgress } from "@megaron/dash-progress";
import { Tile, TileStripe } from "@megaron/dash-tile";
import { OrderDoc } from "@megaron/docs-contracts";

type Props = {
  order: OrderDoc;
};

export const OrderTile: React.FC<Props> = ({ order }) => {
  const theme = useTheme();

  const { backgroundColor, color } = getTileColors(order, theme);

  const currentProfit = order.profit.lessThan(0) ? Number(-order.profit.toFixed(2)) : Number(order.profit.toFixed(2));

  return (
    <Link to={`/crm/orders/${order.id}`}>
      <Tile stripe={<TileStripe color={backgroundColor} />}>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "0.375rem",
            color: "black",
          }}
        >
          <div css={{ display: "flex", gap: "0.5rem", flexWrap: "wrap", fontSize: "0.875rem" }}>
            {order.sellToName}
            <span css={{ opacity: 0.7 }}>#{order.referenceNumber}</span>
            <span css={{ color: theme.colors.secondaryText, fontSize: "0.75rem" }}>{order.orderDate}</span>
          </div>
          <div
            css={{
              display: "flex",
              gap: "0.625rem",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <DottedProgress
              singleDotValue={1000}
              color={order.profit.lessThan(0) ? "danger" : "primary"}
              currentValue={currentProfit}
              allowWrap
            />
            <span css={{ color, fontWeight: 700, marginLeft: "auto" }}>
              {order.profit.greaterThan(0) && "+"}
              {formatCurrency(order.profit, order.currency ?? "PLN")}
            </span>
          </div>
        </div>
      </Tile>
    </Link>
  );
};

const getTileColors = (order: OrderDoc, theme: Theme) => {
  if (order.profit.lessThan(0)) {
    return { backgroundColor: theme.colors.danger, color: theme.colors.danger };
  }

  if (order.status === "fulfilled") {
    return { backgroundColor: theme.colors.primary, color: theme.colors.primary };
  }

  if (order.status === "cancelled") {
    return { backgroundColor: "rgba(0, 0, 0, 0.1)", color: theme.colors.secondaryText };
  }

  return { backgroundColor: theme.colors.primaryLight, color: theme.colors.primary };
};
