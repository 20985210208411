import { MarkerClusterer, MarkerClustererOptions } from "@googlemaps/markerclusterer";

export class ExtendedMarkerClusterer extends MarkerClusterer {
  constructor(options: MarkerClustererOptions) {
    super(options);
  }

  public updateVisibility(): void {
    const map = this.getMap() as google.maps.Map;
    const bounds = map.getBounds();

    this.clusters.forEach((cluster) => {
      if (!cluster.marker) {
        return;
      }

      if (isMarker(cluster.marker)) {
        const position = cluster.marker?.getPosition();
        cluster.marker?.setVisible(bounds && position ? bounds.contains(position) : true);
        return;
      }
    });
  }
}

const isMarker = (
  marker: google.maps.Marker | google.maps.marker.AdvancedMarkerElement,
): marker is google.maps.Marker => {
  return !!(marker as google.maps.Marker).setPosition;
};
