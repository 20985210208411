import { useTheme } from "@emotion/react";
import { TbColumnInsertRight } from "react-icons/tb";

type Props = {
  onClick: () => void;
};

export const AddColumnButton: React.FC<Props> = ({ onClick }) => {
  const theme = useTheme();

  return (
    <button
      css={{
        background: theme.colors.primaryLight,
        color: theme.colors.primary,
        padding: "0.625rem 0.75rem",
        fontFamily: theme.displayFontFamily,
        fontWeight: 400,
        fontSize: "0.9375rem",
        width: "2.5rem",
        textAlign: "left",
        border: "none",
        borderTopLeftRadius: theme.extraSmallBorderRadius,
        borderTopRightRadius: theme.extraSmallBorderRadius,
        cursor: "pointer",
        display: "flex",
        height: "2.25rem",
      }}
      onClick={onClick}
    >
      <TbColumnInsertRight />
    </button>
  );
};
