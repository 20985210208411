import { useTheme } from "@emotion/react";
import { useState } from "react";
import { BiExpandVertical } from "react-icons/bi";
import { FaTruck } from "react-icons/fa";
import { IconType } from "react-icons/lib";
import { MdAddBusiness, MdCorporateFare, MdEngineering, MdOutlineViewList, MdPayments, MdPerson } from "react-icons/md";

import { GroupFilter } from "@megaron/dash-filters";
import { useDeviceType } from "@megaron/dash-mq";
import { SelectDialog } from "@megaron/dash-select";
import { VisibleCustomersTableColumnsKey } from "@megaron/megarax-links";

import { tableHeadingsList } from "./CustomerTable";
import { VisibleCustomersTableColumnsConfigObject } from "./useCustomerVisibleTableColumns";

type Props = {
  groupFilter: VisibleCustomersTableColumnsKey;
  onGroupFilterChange: (value: VisibleCustomersTableColumnsKey) => void;
  visibleTableColumnsConfig: VisibleCustomersTableColumnsConfigObject | undefined;
  onVisibleTableColumnsConfigChange: (key: keyof VisibleCustomersTableColumnsConfigObject, columns: string[]) => void;
};

export const CustomersGroupFilters: React.FC<Props> = ({
  groupFilter,
  onGroupFilterChange,
  onVisibleTableColumnsConfigChange,
  visibleTableColumnsConfig,
}) => {
  const { isMobile } = useDeviceType();

  const theme = useTheme();

  if (isMobile) {
    return (
      <SelectDialog
        variant="single-select"
        initiallySelectedValues={[groupFilter]}
        options={groupFiltersConfig}
        onSelect={(option) => onGroupFilterChange(option?.value as VisibleCustomersTableColumnsKey)}
        renderTrigger={(_, __, ___, onOpen) => (
          <button
            onClick={onOpen}
            css={{
              border: `1px solid rgba(0, 0, 0, 0.1)`,
              background: "white",
              color: theme.colors.primary,
              fontWeight: 700,
              display: "flex",
              gap: "0.625rem",
              borderRadius: theme.smallBorderRadius,
              padding: "0.75rem",
              alignItems: "center",
            }}
          >
            <MdOutlineViewList size={16} />
            {groupFiltersConfig.find((option) => option.value === groupFilter)?.label ?? ""}
            <BiExpandVertical size={20} css={{ marginLeft: "auto" }} />
          </button>
        )}
      />
    );
  }

  return (
    <div
      css={{
        overflow: "auto",
        width: isMobile ? "calc(100% + 2rem)" : "calc(100% + 4rem)",
        margin: isMobile ? "0 -1rem" : "0 -2rem",
      }}
    >
      <div
        css={{
          display: "flex",
          gap: "0.625rem",
          padding: isMobile ? "0 1rem" : "0 2rem",
          width: "fit-content",
          paddingBottom: "1rem",
        }}
      >
        {groupFiltersConfig.map(({ value, label, Icon }) => (
          <CustomerGroupFilter
            key={`group-filter-${value}`}
            isActive={groupFilter === value}
            groupFilterValue={value}
            label={label}
            onGroupFilterChange={(v) => onGroupFilterChange(v as VisibleCustomersTableColumnsKey)}
            initialSelectedValues={visibleTableColumnsConfig?.[value] || []}
            onColumnsChange={(columns) => onVisibleTableColumnsConfigChange(value, columns)}
            Icon={Icon}
          />
        ))}
      </div>
    </div>
  );
};

const groupFiltersConfig: { value: VisibleCustomersTableColumnsKey; label: string; Icon: IconType }[] = [
  { value: "all", label: "Wszyscy klienci", Icon: MdPerson },
  { value: "loyalty", label: "Wykonawcy", Icon: MdEngineering },
  { value: "pos", label: "Punkty sprzedaży", Icon: MdAddBusiness },
  { value: "payers", label: "Płatnicy", Icon: MdPayments },
  { value: "distributors", label: "Dystrybutorzy", Icon: FaTruck },
  { value: "chains", label: "Sieci", Icon: MdCorporateFare },
] as const;

type CustomerGroupFilterProps = {
  isActive: boolean;
  label: string;
  onGroupFilterChange: (value: string) => void;
  groupFilterValue: string;
  initialSelectedValues: string[];
  onColumnsChange: (columns: string[]) => void;
  Icon: IconType;
};

const CustomerGroupFilter: React.FC<CustomerGroupFilterProps> = ({
  isActive,
  label,
  onGroupFilterChange,
  groupFilterValue,
  initialSelectedValues,
  onColumnsChange,
  Icon,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <GroupFilter
        isActive={isActive}
        label={label}
        onClick={(v) => onGroupFilterChange(v)}
        value={groupFilterValue}
        onSettingsClick={() => setIsDialogOpen(true)}
        Icon={Icon}
      ></GroupFilter>
      <SelectDialog
        onClose={() => setIsDialogOpen(false)}
        label={"Kolumny"}
        initiallySelectedValues={initialSelectedValues}
        options={tableHeadingsList.map(({ heading }) => ({ label: heading, value: heading }))}
        onSelectedChange={(options) => onColumnsChange(options.map((option) => option.value))}
        variant="multi-select"
        noTrigger
        isOpen={isDialogOpen}
      />
    </>
  );
};
