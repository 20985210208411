import { Uuid } from "@megarax/common";
import { Gtin, orderDraftResource, tradeItemResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { useAsyncLoad } from "@megarax/react-utils";
import {
  BreadcrumbMarkers,
  commonErrorsMap,
  FeedbackSnackbarContext,
  LoadingBar,
  PageTitleManager,
  useSnackbarErrorHandler,
} from "@megarax/ui-components";
import { LinearProgress } from "@mui/material";
import Decimal from "decimal.js";
import _ from "lodash";
import React, { useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom-v5-compat";

import { useCustomersResource } from "@megaron/megarax-v2-resource-hooks";

import { MarginsDialogContainer } from "../MarginsDialog";
import { PackSizeDialogContainer } from "../PackSizeDialog";
import { DraftDetails } from "./DraftDetails";
import { EditCustomersContainer } from "./EditCustomers";
import { EditItemDialogContainer } from "./EditItemDialog";
import { NewItemDialogContainer } from "./NewItemDialog";
import { SendDraftDialogContainer } from "./SendDraftDialog";

interface Props {}

export const DraftDetailsContainer: React.FC<Props> = (props) => {
  const params = useParams<{ uuid: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  const { retrieveCustomer } = useCustomersResource();
  const draftOrderProvider = useResourceProviderV2(orderDraftResource);
  const tradeItemProvider = useResourceProviderV2(tradeItemResource);

  const snackbarContext = useContext(FeedbackSnackbarContext);

  const isNewItemDialogOpen = location.hash === "#nowy_przedmiot";
  const isSendDraftDialogOpen = location.hash === "#wyslij";
  const isMarginsDialogOpen = location.hash === "#marze";
  const isPackSizeDialogOpen = location.hash === "#wspolczynniki";
  const isEditItemDialogOpen = location.hash === "#edycja_przedmiotu";
  const isEditCustomersDialogOpen = location.pathname.endsWith("edytuj_klientow");

  const openNewItemDialog = () => navigate({ hash: "nowy_przedmiot" });
  const openSendDraftDialog = () => navigate({ hash: "wyslij" });
  const openEditItemDialog = (lineUuid: string) => navigate(`${location.pathname}/${lineUuid}#edycja_przedmiotu`);
  const openMarginsDialog = () => navigate({ hash: "marze" });
  const openPackSizeDialog = () => navigate({ hash: "wspolczynniki" });
  const openEditCustomersDialog = () => navigate(`${location.pathname}/edytuj_klientow`);

  const getClientUrl = (uuid: string) => `/crm/pos/klienci/${uuid}`;

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
    PriceListNotFound: "Nie znaleziono cennika.",
    DraftDiscarded: "Ta wersja robocza zamówienia została już odrzucona.",
    DraftFinalized: "Ta wersja robocza zamówienia została już złożona.",
    TradeItemNotFound: "Jednostka handlowa nie istnieje.",
    CustomerNotFound: "Klient nie istnieje.",
  });

  const {
    value: draftOrder,
    loading: draftOrderLoading,
    reload,
  } = useAsyncLoad(
    () =>
      draftOrderProvider
        .byUuid(params.uuid || "")
        .retrieve()
        .mapError(handleError),
    [],
  );

  const { value: buyer, loading: buyerLoading } = useAsyncLoad(() => {
    const uuid = draftOrder?.value?.buyer.uuid;
    if (!uuid) return Promise.resolve(undefined);
    return retrieveCustomer(uuid as Uuid);
  }, [draftOrder?.value?.buyer.uuid]);

  const { value: payer, loading: payerLoading } = useAsyncLoad(() => {
    const uuid = draftOrder?.value?.payer.uuid;
    if (!uuid) return Promise.resolve(undefined);
    return retrieveCustomer(uuid as Uuid);
  }, [draftOrder?.value?.payer.uuid]);

  const { value: recipient, loading: recipientLoading } = useAsyncLoad(() => {
    const uuid = draftOrder?.value?.recipient.uuid;
    if (!uuid) return Promise.resolve(undefined);
    return retrieveCustomer(uuid as Uuid);
  }, [draftOrder?.value?.recipient.uuid]);

  const { value: tradeItemsDictionary, loading: tradeItemsDictionaryLoading } = useAsyncLoad(
    () =>
      tradeItemProvider
        .list({ limit: 1000 })
        .mapError(handleError)
        .map((result) => _.keyBy(result.items, "gtin")),
    [],
  );

  const deleteDraft = () =>
    draftOrderProvider
      .byUuid(params.uuid || "")
      .discard()
      .then(() => {
        snackbarContext.pushMessage({
          severity: "success",
          content: "Pomyślnie usunięto wersję roboczą",
        });
        navigate("/crm/pos/moje_zamowienia");
      });

  if (draftOrder === undefined) return <LinearProgress />;
  if (draftOrder.isFailure) {
    if (draftOrder.error === "DraftNotFound") return <span>"Nie znaleziono wersji roboczej"</span>;
    return null;
  }

  const loading = draftOrderLoading || buyerLoading || payerLoading || recipientLoading || tradeItemsDictionaryLoading;

  return (
    <>
      {isEditCustomersDialogOpen && draftOrder?.value && (
        <EditCustomersContainer
          initialBillToUuid={draftOrder.value.payer.uuid as Uuid}
          initialPayerDepartmentUuid={draftOrder.value.buyer.uuid as Uuid}
          initialSellToUuid={draftOrder.value.recipient.uuid as Uuid}
          draftUuid={params.uuid as Uuid}
          onChange={reload}
          onClose={() => navigate(-1)}
        />
      )}
      <LoadingBar loading={loading} />
      <PageTitleManager title={`Wersja robocza ${buyer?.value?.name ?? ""}`} />
      <BreadcrumbMarkers
        breadcrumbs={[
          { id: "myOrders", title: "Zamówienia robocze", to: "/crm/pos/moje_zamowienia" },
          { id: "orderDraft", title: `Wersja robocza ${buyer?.value?.name ?? ""}` },
        ]}
      />
      {isEditItemDialogOpen && (
        <EditItemDialogContainer
          currency={draftOrder.value.currency}
          tradeItemsDictionary={tradeItemsDictionary?.value}
          lines={draftOrder.value.lines}
          reloadDraft={reload}
        />
      )}
      {isNewItemDialogOpen && (
        <NewItemDialogContainer
          currency={draftOrder.value.currency}
          baseDiscountRate={draftOrder.value.payerDiscountRate}
          priceListUuid={draftOrder.value.priceList.uuid}
          reload={reload}
          postSaleDiscountRate={new Decimal(0)}
        />
      )}
      {isMarginsDialogOpen && (
        <MarginsDialogContainer
          currency={draftOrder.value.currency}
          lines={draftOrder.value.lines.map((line) => ({
            ...line,
            gtin: line.item.gtin as Gtin,
            netPricePerUnit: line.finalPrice,
            quantity: new Decimal(line.quantity),
          }))}
          payer={draftOrder.value.payer}
        />
      )}
      {isSendDraftDialogOpen && <SendDraftDialogContainer recipient={recipient} />}
      {isPackSizeDialogOpen && (
        <PackSizeDialogContainer
          lines={draftOrder.value.lines.map((line) => ({
            ...line,
            gtin: line.item.gtin,
            quantity: new Decimal(line.quantity),
          }))}
          tradeItemsDictionary={tradeItemsDictionary?.value}
        />
      )}
      <DraftDetails
        draftDetails={draftOrder.value}
        openNewItemDialog={openNewItemDialog}
        openSendDraftDialog={openSendDraftDialog}
        openEditItemDialog={openEditItemDialog}
        openMarginsDialog={openMarginsDialog}
        openPackSizeDialog={openPackSizeDialog}
        deleteDraft={deleteDraft}
        getClientUrl={getClientUrl}
        buyer={buyer?.value}
        payer={payer?.value}
        recipient={recipient?.value}
        tradeItemsDictionary={tradeItemsDictionary?.value}
        onEditCustomers={openEditCustomersDialog}
      />
    </>
  );
};
