import { CSSObject, useTheme } from "@emotion/react";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";

import { SortFilter } from "@megaron/serializers";

export type SortField<TSortField extends string> = SortFilter<TSortField>;

type Props<TSortField extends string> = {
  sortFieldName: TSortField;
  activeSortValue: SortField<TSortField> | undefined;
  onSortChange: (value: SortField<TSortField> | undefined) => void;
  cellCss?: CSSObject;
} & ({ label?: string; element?: undefined } | { label: string; element: JSX.Element });

export const SortableTableCell = <TSortField extends string>({
  label,
  sortFieldName,
  activeSortValue,
  onSortChange,
  element,
  cellCss,
}: Props<TSortField>) => {
  const theme = useTheme();

  const isSortActive = sortFieldName ? activeSortValue?.field === sortFieldName : false;

  const getOrder = (activeOrder: "ASC" | "DESC" | undefined) => {
    if (activeOrder === "ASC") {
      return "DESC";
    }

    if (activeOrder === "DESC") {
      return undefined;
    }

    return "ASC";
  };

  const activeOrder = isSortActive ? activeSortValue?.order : undefined;

  const handleClick = () => {
    const newOrder = getOrder(activeOrder);

    onSortChange(newOrder === undefined ? undefined : { field: sortFieldName, order: newOrder });
  };

  return (
    <th
      key={label}
      css={{
        padding: 0,
      }}
    >
      <button
        onClick={handleClick}
        css={{
          fontSize: "0.9375rem",
          color: theme.colors.primary,
          background: "none",
          fontWeight: isSortActive ? 700 : 400,
          padding: "0.5rem 0.75rem",
          border: "none",
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: "0.25rem",
          whiteSpace: "nowrap",
          cursor: "pointer",
          ...cellCss,
        }}
      >
        {element || label}
        {activeOrder === "ASC" && <MdArrowUpward size={14} />}
        {activeOrder === "DESC" && <MdArrowDownward size={14} />}
      </button>
    </th>
  );
};
