import { Currency, PriceListProposalDto } from "@megarax/crm-contracts";
import { ProfilesDictionary } from "@megarax/iam-webapp";
import { formatCurrency } from "@megarax/react-utils";
import { AreUSureWrapper } from "@megarax/ui-components";
import { Avatar, Box, Button, Paper, Table, TableCell, TableRow, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

import { TradeItemDictionary } from "../PriceListDetailsContainer";
import { ProposalChat } from "./ProposalChat";
import { strikeThrough } from "./utils";

interface Props {
  isMyProposal: (userId: number) => boolean;
  data: {
    priceListUuid: string;
    currency: Currency;
    tradeItems: TradeItemDictionary | null;
    profiles: ProfilesDictionary;
    proposals: PriceListProposalDto[];
  };
  proposalActions: {
    retractProposal: (proposalUuid: string) => Promise<void>;
    rejectProposal: (proposalUuid: string) => Promise<void>;
    approveProposal: (proposalUuid: string) => Promise<void>;
  };
  permissions: {
    create: boolean;
    approve: boolean;
    reject: boolean;
  };
  proposal: PriceListProposalDto;
}

export const ProposalItem: React.FC<Props> = ({ isMyProposal, data, proposalActions, permissions, proposal }) => {
  const classes = useStyles();

  const activeFromString = `Aktywna - ${getActiveFromToString(proposal.activeFrom)}`;

  return (
    <Paper key={proposal.uuid}>
      <div className={classes.paper}>
        <Box display="flex" justifyContent="space-between" className={classes.gap}>
          <div>
            <Typography className={classes.text} variant="body2">
              {activeFromString}
            </Typography>
            <Typography align="justify">{proposal.comment}</Typography>
          </div>
          <Box display="flex">
            <div className={classes.userData}>
              <Typography variant="body2" className={classes.text}>
                {data.profiles[proposal.proposedBy.id]?.displayedName ?? "Nieznany użytkownik"}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {proposal.proposedAt.toLocaleDateString()}
              </Typography>
            </div>
            <Avatar className={classes.avatar} src={data.profiles[proposal.proposedBy.id]?.pictureUrl ?? ""}>
              {proposal.proposedBy.id}
            </Avatar>
          </Box>
        </Box>

        <Box width="100%" display="flex">
          {isMyProposal(proposal.proposedBy.id) ? (
            <AreUSureWrapper action={() => proposalActions.retractProposal(proposal.uuid)}>
              <Button variant="text" color="secondary">
                Wycofaj
              </Button>
            </AreUSureWrapper>
          ) : (
            <>
              {permissions.reject && (
                <AreUSureWrapper action={() => proposalActions.rejectProposal(proposal.uuid)}>
                  <Button variant="text" color="secondary">
                    Odrzuć
                  </Button>
                </AreUSureWrapper>
              )}
            </>
          )}
          {permissions.approve && (
            <AreUSureWrapper action={() => proposalActions.approveProposal(proposal.uuid)} color="primary">
              <Button variant="text" color="primary">
                Zatwierdź
              </Button>
            </AreUSureWrapper>
          )}
        </Box>

        <Box width="100%" maxWidth={300} display="flex" justifyContent="space-between" alignItems="center">
          <Table size="small">
            {proposal.changes.map((change) => (
              <TableRow>
                <TableCell>{data.tradeItems?.[change.gtin]?.itemName ?? change.gtin}</TableCell>
                <TableCell className={classes.price}>
                  {change.netPrice ? formatCurrency(change.netPrice.toNumber(), data.currency) : "Do usunięcia"}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Box>
        <Box width="100%" maxHeight="300px">
          <ProposalChat proposalUuid={proposal.uuid} priceListUuid={data.priceListUuid} />
        </Box>
      </div>
    </Paper>
  );
};

const getActiveFromToString = (activeFrom: Date | null) => {
  if (!activeFrom) return "natychmiast";
  const parsed = `${activeFrom.toLocaleDateString()} ${activeFrom.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  })}`;
  if (activeFrom.getTime() < new Date().getTime()) return `${strikeThrough(parsed)}\xa0\xa0natychmiast`;
  return parsed;
};

const useStyles = makeStyles((theme) => {
  return {
    userData: {
      marginRight: theme.spacing(1),
      textAlign: "right",
    },
    deletion: {
      backgroundColor: theme.palette.secondary.main,
      paddingLeft: theme.spacing(1),
    },
    other: {
      backgroundColor: theme.palette.primary.main,
      paddingLeft: theme.spacing(1),
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      borderRadius: "0 4px 4px 0",
      backgroundColor: "white",
      padding: theme.spacing(1, 2),
      margin: theme.spacing(2, 0),
      gap: theme.spacing(1),
    },
    text: {
      whiteSpace: "nowrap",
    },
    comment: {
      marginTop: theme.spacing(1),
      textAlign: "justify",
      textJustify: "inter-word",
      display: "block",
      maxHeight: "300px",
      overflowY: "auto",
    },
    avatar: {
      width: "35px",
      height: "35px",
    },
    gap: {
      gap: theme.spacing(1),
    },
    price: {
      fontWeight: theme.typography.fontWeightMedium as any,
    },
  };
});
