import { Dialog } from "@megaron/dash-dialog";
import { useDeviceType } from "@megaron/dash-mq";

import { ImportedCustomer } from "../addCustomer/CustomerDetails";
import { ImportedCustomersTable } from "./ImportedCustomersTable";

type Props = {
  onClose: () => void;
  importedCustomers: ImportedCustomer[];
};

export const ImportedCustomersDialog: React.FC<Props> = ({ onClose, importedCustomers }) => {
  const { isMobile } = useDeviceType();

  return (
    <Dialog
      placement={isMobile ? "top" : "center"}
      onClose={onClose}
      css={{ width: isMobile ? "100%" : "90vw", height: "min(700px, 90vh)" }}
      header="Zaimportowani wykonawcy"
    >
      <ImportedCustomersTable importedCustomers={importedCustomers} />
    </Dialog>
  );
};
