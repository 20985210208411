import { Currency, Gtin, orderDraftResource, pricingPricedItemResource } from "@megarax/crm-contracts";
import { useResourceProviderV2 } from "@megarax/react-client";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";
import Decimal from "decimal.js";
import React from "react";
import { useNavigate, useParams } from "react-router-dom-v5-compat";

import { useServiceClient } from "@megaron/react-clients";

import { NewItemDialog } from "./NewItemDialog";

interface Props {
  reload: () => void;
  currency: Currency;
  priceListUuid: string;
  baseDiscountRate: Decimal;
  postSaleDiscountRate: Decimal;
}

export interface AddLineInput {
  lineUuid: string;
  item: { gtin: Gtin };
  quantity: number;
  promoPrice: Decimal | null;
}

export const NewItemDialogContainer: React.FC<Props> = ({
  priceListUuid,
  baseDiscountRate,
  reload,
  currency,
  postSaleDiscountRate,
}) => {
  const params = useParams<{ uuid: string }>();
  const navigate = useNavigate();
  const orderableItemProvider = useResourceProviderV2(pricingPricedItemResource);
  const draftProvider = useResourceProviderV2(orderDraftResource);
  const crmClient = useServiceClient("crm");

  const handleError = useSnackbarErrorHandler({
    ...commonErrorsMap,
  });

  const closeDialog = () => navigate(`/crm/pos/moje_zamowienia/${params.uuid}`);

  const getOrderableItems = (searchText: string) => {
    return orderableItemProvider
      .search({
        limit: 1000,
        searchText,
        priceListUuid,
        discountRate: baseDiscountRate,
        postSaleDiscountRate,
      })
      .mapError((error) => {
        handleError(error);
        return Promise.reject(error);
      })
      .map((result) => result.items)
      .then((result) => result.value);
  };
  const addLine = (input: AddLineInput) =>
    draftProvider
      .byUuid(params.uuid || "")
      .lines.add(undefined, input)
      .mapError((error) => {
        handleError(error);
        return Promise.reject(error);
      })
      .map(() => {
        reload();
      })
      .then(() => {});

  const getPackSize = (gtin: Gtin) => crmClient.itemQuery({ gtin }).unwrap();

  return (
    <NewItemDialog
      closeDialog={closeDialog}
      getOrderableItems={getOrderableItems}
      getPackSize={getPackSize}
      addLine={addLine}
      currency={currency}
    />
  );
};
