import { LoadingBar } from "@megarax/ui-components";
import { Paper } from "@mui/material";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

interface Props {
  dateSelect: React.ReactNode;
  isLoading: boolean;
  calendar: React.ReactNode;
  closeVisit: () => void;
}

export const ViewLayout: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div>
      <Paper className={classes.toolbarPaper}>
        <div className={classes.toolbar}>{props.dateSelect}</div>
        <LoadingBar loading={props.isLoading} />
      </Paper>
      {props.calendar}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  calendarSection: {
    display: "flex",
    flexDirection: "row",
  },
  view: {
    flexGrow: "1",
  },
  toolbarPaper: {
    zIndex: 10,
    position: "sticky",
    top: 0,
    borderRadius: 0,
  },
  toolbar: {
    display: "flex",
    alignContent: "space-around",
    "& > *": {
      margin: "0 auto",
    },
  },
}));
