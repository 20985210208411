import _ from "lodash";

import { Failure, Ok } from "@megaron/result";

import { optionalExtension, serializerExtensions, SerializerExtensions } from "../extensions";
import { Serializer } from "../serializer";

export class ObjectSerializerError extends Error {
  public fieldName: string | number | symbol;
  public fieldValue: unknown;
  public fieldError: unknown;

  constructor(fieldName: string | number | symbol, fieldValue: unknown, fieldError: unknown) {
    super(`Failed to serialize object field "${fieldName.toString()}". ${fieldError} `);
    this.fieldName = fieldName;
    this.fieldValue = fieldValue;
    this.fieldError = fieldError;
  }
}

type ObjectSerializer<T extends { [key: string]: any }> = Serializer<T> &
  SerializerExtensions<T> & { fields: FieldSerializers<T>; partial: () => ObjectSerializer<Partial<T>> };

export type FieldSerializers<
  T extends {
    [key: string]: any;
  },
> = {
  [field in keyof T]: Serializer<T[field]>;
};

export const object = <T extends { [key: string]: any }>(
  fieldSerializers: FieldSerializers<T>,
): ObjectSerializer<T> => ({
  serialize: (item) =>
    _.mapValues(fieldSerializers, (fieldSerializer, fieldName: keyof T) => {
      try {
        return fieldSerializer.serialize(item[fieldName]);
      } catch (err) {
        throw new ObjectSerializerError(fieldName, item[fieldName], err);
      }
    }),

  deserialize: (raw) => {
    if (typeof raw !== "object") return Failure(`Object deserialization failure: incorrect input type (${typeof raw})`);
    if (raw === null) return Failure(`object is null`);

    const result: Partial<T> = {};
    const fieldErrors: { [field in keyof T]?: unknown } = {};
    let hasErrors = false;

    for (const fieldName in fieldSerializers) {
      const fieldResult = fieldSerializers[fieldName].deserialize((raw as any)[fieldName]);
      if (fieldResult.isFailure) {
        hasErrors = true;
        fieldErrors[fieldName] = fieldResult.error;
      }
      result[fieldName] = fieldResult.value;
    }

    if (hasErrors) return Failure(fieldErrors);

    return Ok(result as T);
  },

  fields: fieldSerializers,
  ...serializerExtensions(),
  partial: () => object<Partial<T>>(_.mapValues(fieldSerializers, (s) => optionalExtension(s))),
});

/**
 * @deprecated TODO
 */
export const identity = <T>(): Serializer<T> => ({
  deserialize: Ok,
  serialize: _.identity,
});
