import { AnyUser } from "@megaron/access-control";
import { HttpAction, HttpService } from "@megaron/http-service";
import { HandlerMap } from "@megaron/http-service-host";
import { Serializers } from "@megaron/serializers";

import { anyDocActions, anyDocSerializer } from "./anyDoc";
import { bcVendorActions } from "./docTypes/bcVendor";
import { customerEventActions } from "./docTypes/customerEvents";
import { customerInteractionActions } from "./docTypes/customerInteractions";
import { customerActions } from "./docTypes/customers";
import { invoiceThreadActions } from "./docTypes/invoiceThread";
import { itemActions } from "./docTypes/items";
import { orderActions } from "./docTypes/order";
import { savedViewActions } from "./docTypes/savedView";
import { userActions } from "./docTypes/users";
import { indexModuleActions } from "./indexModuleActions";

const addFakeDocs = HttpAction({
  path: "/addFakeDocs",
  method: "put",
  requiresAuth: true as const,
  bodySerializer: Serializers.object({ docs: anyDocSerializer.array() }),
});

export const docsHttpService = HttpService({
  ...customerActions,
  ...userActions,
  ...anyDocActions,
  ...invoiceThreadActions,
  ...indexModuleActions,
  ...bcVendorActions,
  ...itemActions,
  ...customerEventActions,
  ...customerInteractionActions,
  ...orderActions,
  ...savedViewActions,
  addFakeDocs,
});

export type DocsHandlers = HandlerMap<typeof docsHttpService, AnyUser>;
