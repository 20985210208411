import { authAttributeSerializer } from "@megaron/access-control";
import {
  customerAddressSerializer,
  customerCategories,
  customerProductSerializer,
  customerStatuses,
  relationTypes,
} from "@megaron/crm-contracts";
import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { baseDocSerializer } from "../baseDoc";
import { customerEventDocSerializer } from "./customerEvents";
import { customerInteractionDocSerializer } from "./customerInteractions";

const customerPricingRulesSerializer = Serializers.object({
  priceListUuid: uuidSerializer.nullable(),
  baseDiscount: Serializers.decimal,
  otherTerms: Serializers.string,
  awaitingProposalCount: Serializers.integer,
}).nullable();

export type CustomerPricingRules = SerializerValue<typeof customerPricingRulesSerializer>;

export const customerDocSerializer = Serializers.object({
  ...baseDocSerializer("customer").fields,

  uuid: uuidSerializer,
  name: Serializers.string,
  phoneNumber: Serializers.string,
  phoneNumberWithoutCountryCode: Serializers.string,
  firstName: Serializers.string.nullable(),
  lastName: Serializers.string.nullable(),
  postalCode: Serializers.string,
  interactionCount: Serializers.integer,
  lastInteraction: Serializers.object({
    uuid: uuidSerializer,
    createdAt: Serializers.datetime,
    ownerEmail: Serializers.string,
    message: Serializers.string,
  }).nullable(),
  visitCount: Serializers.integer,
  lastVisit: Serializers.object({
    uuid: uuidSerializer,
    createdAt: Serializers.datetime,
    ownerEmail: Serializers.string,
    message: Serializers.string,
  }).nullable(),
  email: Serializers.email.nullable(),
  lastPurchaseTime: Serializers.datetime.nullable(),
  totalMassKg: Serializers.decimal,
  products: customerProductSerializer.array(),
  region: Serializers.string.nullable(),
  regionOwnerEmail: Serializers.string.nullable(),
  isArchived: Serializers.boolean,
  isPro: Serializers.boolean,
  isAffiliate: Serializers.boolean,
  tags: Serializers.array(Serializers.string),
  tagsNested: Serializers.array(Serializers.object({ tag: Serializers.string })),
  categories: Serializers.stringOneOf(...customerCategories).array(),
  status: Serializers.stringOneOf(...customerStatuses),
  address: customerAddressSerializer,
  location: Serializers.object({ lat: Serializers.float, lon: Serializers.float }).nullable(),

  pricingRules: customerPricingRulesSerializer,

  fixedTradeRouteCount: Serializers.integer,

  parents: Serializers.object({
    uuid: Serializers.string,
    relationType: Serializers.stringOneOf(...relationTypes),
  }).array(),
  invitedChildrenCount: Serializers.integer,
  supplyPointChildrenCount: Serializers.integer,
  payerChildrenCount: Serializers.integer,
  distributorChildrenCount: Serializers.integer,
  chainChildrenCount: Serializers.integer,
  postSaleDiscount: Serializers.decimal.nullable(),
});

export type CustomerDoc = SerializerValue<typeof customerDocSerializer>;

export const customerSortFields = [
  "name",
  "totalMassKg",
  "lastInteractionTime",
  "lastVisitTime",
  "lastPurchaseTime",
  "invitedChildrenCount",
  "supplyPointChildrenCount",
  "payerChildrenCount",
  "distributorChildrenCount",
  "chainChildrenCount",
] as const;
export type CustomerSortField = (typeof customerSortFields)[number];

export const customerActions = {
  searchCustomers: HttpAction({
    path: "/customers",
    qsSerializer: Serializers.object({
      searchText: Serializers.string,
      sort: Serializers.sortFilter(customerSortFields).asJsonString(),
      isPro: Serializers.boolean.asJsonString(),
      isAffiliate: Serializers.boolean.asJsonString(),
      tags: Serializers.string.array().asJsonString(),
      regions: Serializers.string.array().asJsonString(),
      isArchived: Serializers.boolean.asJsonString(),
      isUnassigned: Serializers.boolean.asJsonString(),
      isActive: Serializers.boolean.asJsonString(),
      status: Serializers.stringOneOf(...customerStatuses)
        .array()
        .asJsonString(),
      hasPricingRules: Serializers.booleanFieldFilter,
      isOnTradeRoute: Serializers.booleanFieldFilter,
      lastPurchaseTimeBefore: Serializers.datetime,
      lastPurchaseTimeAfter: Serializers.datetime,
      lastInteractionTimeBefore: Serializers.datetime,
      lastInteractionTimeAfter: Serializers.datetime,
      lastVisitTimeBefore: Serializers.datetime,
      lastVisitTimeAfter: Serializers.datetime,
      limit: Serializers.integer,
      offset: Serializers.integer,
      categories: Serializers.stringOneOf(...customerCategories)
        .array()
        .asJsonString(),
      scannedProducts: Serializers.string.array().asJsonString(),
      relation: Serializers.object({
        relationType: Serializers.stringOneOf(...relationTypes),
        parents: Serializers.string.array(),
      }).asJsonString(),
      nearCoordinates: Serializers.object({
        latitude: Serializers.float,
        longitude: Serializers.float,
        rangeKm: Serializers.float,
      }).asJsonString(),
    }).partial(),
    valueSerializer: Serializers.paginatedList(customerDocSerializer),
    requiresAuth: true,
  }),

  customerHistoryQuery: HttpAction({
    path: "/customer/:customerUuid/history",
    paramsSerializer: Serializers.object({
      customerUuid: uuidSerializer,
    }),
    valueSerializer: Serializers.paginatedList(
      Serializers.first(customerEventDocSerializer, customerInteractionDocSerializer),
    ),
    requiresAuth: true,
  }),
};
