import { createContext, PropsWithChildren, useContext, useState } from "react";

import { IamAuthContext } from "@megaron/iam-auth-react";

import { useUserRegion } from "./useUserRegion";

type RegionContext = {
  region: string | null;
  setRegion: (region: string) => void;
  isLoaded: boolean;
};

const RegionContext = createContext<RegionContext>({ region: null, setRegion: () => null, isLoaded: false });

export const RegionContextProvider: React.FC<
  PropsWithChildren & {
    auth: IamAuthContext;
  }
> = ({ children, auth }) => {
  const [region, setRegion] = useState<string | null>(null);

  const { region: userRegion, isLoaded } = useUserRegion({ enabled: auth.isLoaded });

  return (
    <RegionContext.Provider value={{ region: region || userRegion || null, setRegion, isLoaded }}>
      {children}
    </RegionContext.Provider>
  );
};

export const useRegion = () => useContext(RegionContext);
