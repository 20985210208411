import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const accountDtoSerializer = Serializers.object({
  uuid: uuidSerializer,
  phoneNumber: Serializers.string,
  email: Serializers.email.nullable(),
  firstName: Serializers.string.nullable(),
  lastName: Serializers.string.nullable(),
  unverifiedEmail: Serializers.string.nullable(),
  deletedAt: Serializers.datetime.nullable(),
  scheduledDeletionTime: Serializers.datetime.nullable(),
  joinedAt: Serializers.datetime.nullable(),
  registeredAt: Serializers.datetime.nullable(),
  postalCode: Serializers.postalCode.nullable(),
});

export type AccountDto = SerializerValue<typeof accountDtoSerializer>;

export const accountsAdminActions = {
  account: HttpAction({
    path: "/account/uuid/:uuid",
    method: "get",
    requiresAuth: true as const,
    valueSerializer: accountDtoSerializer,
    errorSerializer: Serializers.stringOneOf("AccountNotFound"),
    paramsSerializer: Serializers.object({ uuid: uuidSerializer }),
  }),
  searchAccounts: HttpAction({
    path: "/account/search",
    method: "get",
    requiresAuth: true as const,
    valueSerializer: Serializers.array(accountDtoSerializer),
    qsSerializer: Serializers.object<{
      searchText?: string;
      limit?: number;
      offset?: number;
    }>({
      searchText: Serializers.string.optional(),
      limit: Serializers.integer.optional(),
      offset: Serializers.integer.optional(),
    }),
  }),

  bulkAccountsQuery: HttpAction({
    path: "/accounts",
    method: "get",
    requiresAuth: true as const,
    qsSerializer: Serializers.object({
      customerUuids: uuidSerializer.array().asJsonString(),
    }).partial(),
    valueSerializer: Serializers.array(accountDtoSerializer),
    errorSerializer: Serializers.stringOneOf(),
  }),
};
