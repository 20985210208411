type Props = {
  onClick: () => void;
  text: string;
};

export const ChildrenCountButton: React.FC<Props> = ({ onClick, text }) => {
  return (
    <button
      onClick={onClick}
      css={{
        width: "100%",
        minHeight: "2rem",
        alignSelf: "stretch",
        background: "none",
        border: "none",
        padding: "0",
        textAlign: "left",
        cursor: "pointer",
      }}
    >
      {text}
    </button>
  );
};
