import { useTheme } from "@emotion/react";

import { OrderStatus as Status } from "@megaron/crm-contracts";

import { parseStatusToDisplayData } from "./helpers";

type Props = {
  status: Status;
  className?: string;
};

export const OrderStatus: React.FC<Props> = ({ status, className }) => {
  const theme = useTheme();

  const { label, Icon } = parseStatusToDisplayData(status);

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        gap: "0.25rem",
        color: theme.colors.secondaryText,
        fontSize: "0.875rem",
        textWrap: "nowrap",
      }}
      className={className}
    >
      <Icon size={16} />
      {label}
    </div>
  );
};
