import { useState } from "react";

import { CustomerAnalyticsEntry } from "@megaron/crm-contracts";
import { useDialogRoute } from "@megaron/dash-dialog";
import { Page } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";

import { TimeUnit } from "../TimeRangeSelect";
import { AnalyticsPreset, usePresetAnalyticsQuery } from "./analyticsPreset";
import { CustomerAnalyticsFiltersBar } from "./CustomerAnalyticsFiltersBar";
import { CustomerAnalyticsFiltersDialog } from "./CustomerAnalyticsFiltersDialog";
import { CustomerAnalyticsTable } from "./CustomerAnalyticsTable";
import { InteractionsAggDialog } from "./InteractionsAggDialog";
import { SalesAggDialog } from "./SalesAggDialog";
import { useCustomerAnalyticsGlobalFilters } from "./useCustomerAnalyticsGlobalFilters";

export const CustomerAnalyticsView = () => {
  // const [preset, setPreset] = useState(newAnalyticsPreset());

  const [isSalesAggDialogOpen, setIsSalesAggDialogOpen] = useState(false);
  const [activeSalesAggName, setActiveSalesAggName] = useState<string | undefined>(undefined);

  const [isInteractionsAggDialogOpen, setIsInteractionsAggDialogOpen] = useState(false);
  const [activeInteractionsAggName, setActiveInteractionsAggName] = useState<string | undefined>(undefined);

  const { filters, setFilter, setFilters } = useCustomerAnalyticsGlobalFilters();

  const preset: AnalyticsPreset = {
    filters: [filters],
    sortBy: {
      agg: filters.sort?.aggName ?? null,
      customColumn: null,
      fieldName: filters.sort?.field ?? "name",
      order: filters.sort?.order ?? "ASC",
    },
    columns: [
      // { aggName: "sprzedaz_1", type: "profit", header: "Marża", aggType: "sales", sortField: "profit" },
      {
        aggName: "sprzedaz_1",
        type: "potential",
        header: "Potencjał",
        aggType: "sales",
        sortField: "absolutePotential",
      },
      {
        aggName: "sprzedaz_1",
        type: "potential-realization",
        header: "Realizacja potencjału",
        aggType: "sales",
        sortField: "potentialRealization",
      },
      {
        aggName: "wizyty_1",
        type: "interaction-count",
        header: "#",
        aggType: "interactions",
        sortField: "interactionCount",
      },
      {
        aggName: null,
        type: "region",
        header: "Region",
        aggType: null,
        sortField: null,
      },
      // {
      //   aggName: null,
      //   type: "status",
      //   header: "Status",
      //   aggType: null,
      //   sortField: null,
      // },
    ],
    aggsOptions: [
      { aggName: "sprzedaz_1", name: "Sprzedaz 1", type: "sales", productGroup: ["Gładzie", "Test"] },
      { aggName: "wizyty_1", name: "wizyty 1", type: "interactions", interactionType: ["visit"] },
    ],
  };

  const filterHandlers = {
    onCategoriesChange: setFilter("category"),
    onRegionsChange: setFilter("region"),
    onStatusChange: setFilter("status"),
    onDateChange: (
      startDate: Date | null,
      endDate: Date | null,
      timeUnit: TimeUnit | null,
      unitCount: number | null,
      activeOptionName: string | null,
    ) => {
      setFilters({
        ...filters,
        startTime: startDate ?? undefined,
        endTime: endDate ?? undefined,
        timeUnit: timeUnit ?? undefined,
        unitCount: unitCount ?? undefined,
        selectedTimeName: activeOptionName ?? undefined,
      });
    },
    onProductGroupsChange: setFilter("productGroups"),
  };

  const filtersDialog = useDialogRoute("/add-filter", ({ onClose }) => (
    <CustomerAnalyticsFiltersDialog onClose={onClose} filters={filters} handlers={filterHandlers} />
  ));

  const { customerAnalyticsInfiniteQuery } = usePresetAnalyticsQuery(preset);

  return (
    <Page
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        maxWidth: "100%",
        maxHeight: "100dvh",
        marginTop: "1rem",
      }}
    >
      <CustomerAnalyticsFiltersBar
        filters={filters}
        handlers={filterHandlers}
        aggs={[
          {
            type: "sales",
            name: "Sprzedaż 1",
            onClick: () => {
              setIsSalesAggDialogOpen(true);
              setActiveSalesAggName("sprzedaz_1");
            },
          },
          {
            type: "interactions",
            name: "Interakcje 1",
            onClick: () => {
              setIsInteractionsAggDialogOpen(true);
              setActiveInteractionsAggName("wizyty_1");
            },
          },
        ]}
      />

      <QuerySkeleton query={customerAnalyticsInfiniteQuery}>
        {(data) => (
          <CustomerAnalyticsTable
            customersAnalytics={data.pages.flatMap((page) => page.value).filter(Boolean) as CustomerAnalyticsEntry[]}
            isLoading={customerAnalyticsInfiniteQuery.isFetching}
            preset={preset}
            activeSortValue={filters.sort}
            onSortChange={setFilter("sort")}
            onNextPageReach={() => {
              customerAnalyticsInfiniteQuery.fetchNextPage();
            }}
            onAggCreate={filtersDialog.open}
          />
        )}
      </QuerySkeleton>

      {isSalesAggDialogOpen && (
        <SalesAggDialog
          onClose={() => setIsSalesAggDialogOpen(false)}
          aggName={activeSalesAggName}
          analyticsPreset={preset}
        />
      )}
      {isInteractionsAggDialogOpen && (
        <InteractionsAggDialog
          onClose={() => setIsInteractionsAggDialogOpen(false)}
          aggName={activeInteractionsAggName}
          analyticsPreset={preset}
        />
      )}
      {filtersDialog.element}
    </Page>
  );
};
