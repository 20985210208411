import { useEffect } from "react";

import { customerAnalyticsSortFields, customerCategories, customerStatuses } from "@megaron/crm-contracts";
import { useQsFilters } from "@megaron/dash-filters";
import { useRegion } from "@megaron/region-context";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export const filtersSerializer = Serializers.object({
  customerId: uuidSerializer.array().optional(),
  region: Serializers.string.array().optional(),
  category: Serializers.stringOneOf(...customerCategories)
    .array()
    .optional(),
  status: Serializers.stringOneOf(...customerStatuses)
    .array()
    .optional(),
  sort: Serializers.object({
    ...Serializers.sortFilter(customerAnalyticsSortFields).fields,
    aggName: Serializers.string.nullable(),
  }).optional(),
  startTime: Serializers.datetime.optional(),
  endTime: Serializers.datetime.optional(),
  timeUnit: Serializers.stringOneOf("hours", "days", "months", "years").optional(),
  unitCount: Serializers.integer.optional(),
  selectedTimeName: Serializers.string.optional(),
  productGroups: Serializers.string.array().optional(),
});

export type Filters = SerializerValue<typeof filtersSerializer>;

export const useCustomerAnalyticsGlobalFilters = () => {
  const { region, isLoaded } = useRegion();

  const defaultFilters: Filters = {
    sort: undefined,
    region: region ? [region] : undefined,
    status: ["unverified", "verified", "vip"],
    category: undefined,
    customerId: undefined,
    startTime: undefined,
    endTime: undefined,
    timeUnit: undefined,
    unitCount: undefined,
    selectedTimeName: undefined,
    productGroups: undefined,
  };

  const { filters, setFilters, setFilter } = useQsFilters(filtersSerializer, defaultFilters);

  // useEffect(() => {
  //   if (
  //     (filters.regions && filters.regions.length > 0) ||
  //     (filters.groupFilter !== "all" && filters.groupFilter !== "pos")
  //   ) {
  //     return;
  //   }

  //   if (isLoaded && region) {
  //     setFilter("regions")([region]);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isLoaded]);

  return { filters, setFilter, defaultFilters, setFilters };
};
