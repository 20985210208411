import { Loader } from "@googlemaps/js-api-loader";
import { useEffect, useState } from "react";

export const usePlaceDetails = (placeId: string | null) => {
  const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env["NX_PUBLIC_GOOGLE_MAPS_API_KEY"]!,
      version: "weekly",
      libraries: ["places"],
      language: "pl",
    });

    loader.load().then(() => {
      setIsGoogleLoaded(true);
    });
  }, []);

  const [place, setPlace] = useState<google.maps.places.PlaceResult | null>(null);

  useEffect(() => {
    if (!isGoogleLoaded || !placeId) {
      setPlace(null);
      return;
    }

    const map = new google.maps.Map(document.createElement("div"));

    const service = new google.maps.places.PlacesService(map);

    const request: google.maps.places.PlaceDetailsRequest = {
      placeId: placeId,
      fields: ["name", "formatted_address", "geometry", "photos"],
    };

    service.getDetails(
      request,
      (place: google.maps.places.PlaceResult | null, status: google.maps.places.PlacesServiceStatus) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          setPlace(place);
        }
      },
    );
  }, [isGoogleLoaded, placeId]);

  return place;
};
