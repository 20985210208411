import { useTheme } from "@emotion/react";
import React from "react";

type Props<T> = {
  elementsList: T[];
  renderElement: (element: T) => JSX.Element;
};

export const TileList = <T,>({ elementsList, renderElement }: Props<T>) => {
  const theme = useTheme();

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        borderRadius: theme.smallBorderRadius,
        background: "white",
        border: "1px solid rgba(0, 0, 0, 0.1)",
      }}
    >
      {elementsList.map((props, i) => (
        <React.Fragment key={i}>
          {renderElement(props)}
          <hr
            css={{
              border: "none",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              width: "100%",
              height: 0,
              margin: 0,
              "&:last-child": {
                display: "none",
              },
            }}
          />
        </React.Fragment>
      ))}
    </div>
  );
};
