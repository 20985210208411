import { useShallowEffect } from "@mantine/hooks";
import { useState } from "react";

type SingleConfigObject = {
  added: string[];
  removed: string[];
};

type Options<T extends string> = {
  localStorageKey: string;
  defaultColumns: Record<T, string[]>;
  configKeys: T[];
  useDefaultConfigOnUpdate?: boolean;
};

export const useVisibleTableColumns = <T extends string>({
  defaultColumns,
  localStorageKey,
  configKeys,
  useDefaultConfigOnUpdate,
}: Options<T>) => {
  const [visibleTableColumns, setVisibleTableColumns] = useState<Record<T, string[]> | undefined>(undefined);
  const [localStorageColumnsConfig, setLocalStorageColumnsConfig] = useState<Record<T, SingleConfigObject> | undefined>(
    undefined,
  );

  const defaultVisibleTableColumnsConfig = Object.fromEntries(
    configKeys.map((key) => {
      return [key, { added: [] as string[], removed: [] as string[] }];
    }),
  ) as Record<T, SingleConfigObject>;

  useShallowEffect(() => {
    const visibleTableColumns = localStorage.getItem(localStorageKey);

    if (visibleTableColumns) {
      const parsedVisibleTableColumns = JSON.parse(visibleTableColumns);

      if (Array.isArray(parsedVisibleTableColumns)) {
        localStorage.setItem(localStorageKey, JSON.stringify(defaultColumns));
        setVisibleTableColumns(parseConfigToVisibleColumns(defaultVisibleTableColumnsConfig, defaultColumns));
        setLocalStorageColumnsConfig(defaultVisibleTableColumnsConfig);

        return;
      }

      if (Array.isArray(Object.values(parsedVisibleTableColumns)[0])) {
        if (useDefaultConfigOnUpdate) {
          localStorage.setItem(localStorageKey, JSON.stringify(defaultColumns));
          setVisibleTableColumns(parseConfigToVisibleColumns(defaultVisibleTableColumnsConfig, defaultColumns));
          setLocalStorageColumnsConfig(defaultVisibleTableColumnsConfig);

          return;
        }

        const newConfig: Record<T, SingleConfigObject> = Object.fromEntries(
          configKeys.map((key) => [
            key,
            {
              added: getAddedColumns(parsedVisibleTableColumns[key], defaultColumns[key]),
              removed: getRemovedColumns(parsedVisibleTableColumns[key], defaultColumns[key]),
            },
          ]),
        ) as Record<T, SingleConfigObject>;

        localStorage.setItem(localStorageKey, JSON.stringify(newConfig));
        setVisibleTableColumns(parseConfigToVisibleColumns(newConfig, defaultColumns));
        setLocalStorageColumnsConfig(newConfig);
        return;
      }

      setVisibleTableColumns(parseConfigToVisibleColumns(parsedVisibleTableColumns, defaultColumns));
      setLocalStorageColumnsConfig(parsedVisibleTableColumns);
      return;
    }

    setVisibleTableColumns(parseConfigToVisibleColumns(defaultVisibleTableColumnsConfig, defaultColumns));
    setLocalStorageColumnsConfig(defaultVisibleTableColumnsConfig);
    localStorage.setItem(localStorageKey, JSON.stringify(defaultVisibleTableColumnsConfig));
  }, []);

  const handleVisibleColumnsChange = (key: T, columns: string[]) => {
    if (!localStorageColumnsConfig) {
      return;
    }

    if (columns.length === 0) {
      const newConfig: Record<T, SingleConfigObject> = {
        ...localStorageColumnsConfig,
        [key]: { added: [], removed: [] },
      };

      setVisibleTableColumns(parseConfigToVisibleColumns(newConfig, defaultColumns));
      setLocalStorageColumnsConfig(newConfig);
      localStorage.setItem(localStorageKey, JSON.stringify(newConfig));
      return;
    }

    const newConfig: Record<T, SingleConfigObject> = {
      ...localStorageColumnsConfig,
      [key]: {
        added: getAddedColumns(columns, defaultColumns[key]),
        removed: getRemovedColumns(columns, defaultColumns[key]),
      },
    };

    setVisibleTableColumns(parseConfigToVisibleColumns(newConfig, defaultColumns));
    setLocalStorageColumnsConfig(newConfig);
    localStorage.setItem(localStorageKey, JSON.stringify(newConfig));
  };

  return { visibleTableColumns, handleVisibleColumnsChange };
};

const getAddedColumns = (columns: string[], defaultColumns: string[]) => {
  return columns.filter((column) => !defaultColumns.includes(column));
};

const getRemovedColumns = (columns: string[], defaultColumns: string[]) => {
  return defaultColumns.filter((column) => !columns.includes(column));
};

const parseConfigToVisibleColumns = <T extends string>(
  config: Record<T, SingleConfigObject>,
  defaultColumns: Record<T, string[]>,
): Record<T, string[]> => {
  return Object.fromEntries(
    Object.entries(config).map(([key, value]) => {
      const { added, removed } = value as SingleConfigObject;

      return [key, [...defaultColumns[key as T].filter((column) => !removed.includes(column)), ...added]];
    }),
  ) as Record<T, string[]>;
};
