import Decimal from "decimal.js";
import { MdArrowForward, MdOutlineDelete } from "react-icons/md";
import { useParams } from "react-router-dom-v5-compat";

import { useDialogRoute } from "@megaron/dash-dialog";
import { Button } from "@megaron/dash-form";
import { useDeviceType } from "@megaron/dash-mq";
import { HeaderBar, Page } from "@megaron/dash-page";
import { QuerySkeleton } from "@megaron/dash-skeleton";
import { useClientManager } from "@megaron/react-clients";
import { Uuid } from "@megaron/uuid";

import { MarginButton } from "./MarginButton";
import { OrderDraftPageContent } from "./OrderDraftPageContent";
import { OrderSummaryDialog } from "./OrderSummaryDialog";
import { PackSizeButton } from "./PackSizeButton";

type Props = {
  isNew?: boolean;
};

export const OrderDraftPage: React.FC<Props> = ({ isNew }) => {
  const { orderId } = useParams<{ orderId: string }>();

  const { isMobile } = useDeviceType();

  const orderQuery = useClientManager("crm")
    .orderQuery()
    .useQuery({ orderId: orderId as Uuid }, { enabled: !isNew });

  const orderSummaryDialog = useDialogRoute("/summary", ({ onClose }) => (
    <OrderSummaryDialog
      onClose={onClose}
      customerAddress={{
        city: "Warszawa",
        country: "Polska",
        googlePlaceId: null,
        latitude: null,
        longitude: null,
        postalCode: "00-000",
        street: "Testowa 1",
      }}
    />
  ));

  return (
    <>
      {isNew ? (
        <HeaderBar header={`Nowa wersja robocza`} />
      ) : (
        <QuerySkeleton query={orderQuery} height="58px" width="100%">
          {(order) => (
            <HeaderBar
              header={`${order.referenceNumber} - ${order.sellToName}`}
              actions={<MdOutlineDelete size={24} color="white" />}
            />
          )}
        </QuerySkeleton>
      )}

      <Page
        css={{
          display: "flex",
          marginBottom: "2rem",
          marginTop: "83px",
          padding: isMobile ? "0 1rem" : "0 2rem",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {isNew ? (
          <OrderDraftPageContent
            initialBillToId={undefined}
            initialRecipientId={undefined}
            initialSellToId={undefined}
            lines={[]}
          />
        ) : (
          <QuerySkeleton query={orderQuery} height="58px" width="100%">
            {(order) => (
              <OrderDraftPageContent
                initialBillToId={order.billToId ?? undefined}
                initialRecipientId={undefined}
                initialSellToId={order.sellToId ?? undefined}
                lines={order.lines}
              />
            )}
          </QuerySkeleton>
        )}

        <div
          css={{
            display: "flex",
            gap: isMobile ? "1rem" : "0.625rem",
            justifyContent: "space-between",
            flexDirection: isMobile ? "column" : "row",
            alignItems: isMobile ? "flex-end" : "center",
          }}
        >
          <div css={{ display: "flex", gap: "0.625rem", alignSelf: "stretch" }}>
            <PackSizeButton
              itemPackSizes={[
                {
                  itemName: "Dv-20_5",
                  palette: new Decimal(1.2),
                  layer: new Decimal(12.34),
                  box: new Decimal(34.2),
                  piece: new Decimal(288),
                },
                {
                  itemName: "Dv-20_10",
                  palette: new Decimal(1.1),
                  layer: new Decimal(15.4),
                  box: new Decimal(30.23),
                  piece: new Decimal(240),
                },
              ]}
            />
            {isNew ? (
              <MarginButton
                lines={[
                  {
                    id: "1234" as Uuid,
                    itemId: "C-50_10",
                    lineNumber: 1,
                    price: new Decimal(15),
                    productGroup: "Gładzie",
                    productId: "1234",
                    quantity: new Decimal(10),
                    itemName: "C-50_10",
                  },
                ]}
              />
            ) : (
              <QuerySkeleton query={orderQuery} height="40px">
                {(order) => <MarginButton lines={order.lines} />}
              </QuerySkeleton>
            )}
          </div>
          {isNew ? (
            <Button>Zapisz</Button>
          ) : (
            <Button onClick={orderSummaryDialog.open}>
              <div css={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                Wyślij
                <MdArrowForward size={16} />
              </div>
            </Button>
          )}
        </div>
        {orderSummaryDialog.element}
      </Page>
    </>
  );
};
