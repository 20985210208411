import Decimal from "decimal.js";
import { before } from "lodash";

import { dateStringSerializer, toDateString } from "@megaron/date-string";
import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { newUuid, uuidSerializer } from "@megaron/uuid";

export const transactionTypes = ["order", "creditMemo", "loyaltyScan"] as const;
export type TransactionType = (typeof transactionTypes)[number];

const saveTransactionSerializer = Serializers.object({
  id: uuidSerializer,
  type: Serializers.stringOneOf(...transactionTypes),
  date: dateStringSerializer,
  createdAt: Serializers.datetime,
  customerId: uuidSerializer,
  itemId: Serializers.string,
  orderId: uuidSerializer.nullable(),
  quantity: Serializers.decimal,

  unitSize: Serializers.decimal,
  unitValue: Serializers.decimal.nullable(),
  unitCost: Serializers.decimal.nullable(),

  loyaltyBasePointValue: Serializers.decimal.nullable(),
  loyaltyRealPointValue: Serializers.decimal.nullable(),

  region: Serializers.string.nullable(),
});

export type SaveTransactionDto = SerializerValue<typeof saveTransactionSerializer>;

export const newTransaction = (options: Partial<SaveTransactionDto> = {}): SaveTransactionDto => ({
  id: newUuid(),
  customerId: newUuid(),
  orderId: null,
  itemId: "someProduct",
  quantity: new Decimal(1),
  date: toDateString(new Date()),
  unitValue: new Decimal(0),
  type: "order",
  unitCost: new Decimal(0),
  loyaltyBasePointValue: new Decimal(0),
  loyaltyRealPointValue: new Decimal(0),
  unitSize: new Decimal(0),
  region: null,
  createdAt: new Date(),
  ...options,
});

export const transactionDtoSerializer = Serializers.object({
  id: uuidSerializer,
  type: Serializers.stringOneOf(...transactionTypes),
  createdAt: Serializers.datetime,
  date: dateStringSerializer,
  customerId: uuidSerializer,
  region: Serializers.string.nullable(),
  itemId: Serializers.string,
  itemName: Serializers.string.nullable(),
  itemGtin: Serializers.string.nullable(),
  orderId: uuidSerializer.nullable(),
  quantity: Serializers.decimal,

  /** Item size */
  unitSize: Serializers.decimal.nullable(),
  /** Net price minus discount */
  unitValue: Serializers.decimal.nullable(),
  /** Item cost */
  unitCost: Serializers.decimal.nullable(),
  /** Unit value minus cost */
  unitProfit: Serializers.decimal.nullable(),

  /** unitSize * quantity */
  totalSize: Serializers.decimal.nullable(),
  /** unitValue * quantity */
  totalValue: Serializers.decimal.nullable(),
  /** unitCost * quantity */
  totalCost: Serializers.decimal.nullable(),
  /** unitProfit * quantity */
  totalProfit: Serializers.decimal.nullable(),

  loyaltyBasePointValue: Serializers.decimal.nullable(),
  loyaltyRealPointValue: Serializers.decimal.nullable(),
});

export type TransactionDto = SerializerValue<typeof transactionDtoSerializer>;

export const transactionActions = {
  saveTransaction: HttpAction({
    path: "/transactions",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: saveTransactionSerializer,
  }),

  transactionsQuery: HttpAction({
    path: "/transactions",
    method: "get",
    requiresAuth: true as const,
    qsSerializer: Serializers.object({
      orderId: uuidSerializer.array().asJsonString(),
      customerId: uuidSerializer.array().asJsonString(),
      limit: Serializers.integer,
      offset: Serializers.integer,
    }).partial(),
    valueSerializer: transactionDtoSerializer.array(),
  }),

  regionProfitMetricsQuery: HttpAction({
    path: "/transactions/regionProfitMetrics",
    requiresAuth: true as const,
    qsSerializer: Serializers.object({
      months: Serializers.month.array().asJsonString(),
    }),
    valueSerializer: Serializers.object({
      profit: Serializers.decimal,
      target: Serializers.decimal,
      byRegion: Serializers.object({
        region: Serializers.string.nullable(),
        regionOwner: Serializers.string.nullable(),
        profit: Serializers.decimal,
        target: Serializers.decimal,
      }).array(),
      byMacroregion: Serializers.object({
        macroregion: Serializers.string,
        profit: Serializers.decimal,
        target: Serializers.decimal,
      }).array(),
    }),
  }),
};
