import { dateStringSerializer } from "@megaron/date-string";
import { Serializers, SerializerValue } from "@megaron/serializers";

export const salesHistoryColumnOptionsSerializer = Serializers.object({
  type: Serializers.stringOneOf("salesHistory"),
  salesAgg: Serializers.string,
  bucketSizeDays: Serializers.integer,
  bucketCount: Serializers.integer,
  endDate: dateStringSerializer.nullable(),
});

export type SalesHistoryColumnOptions = SerializerValue<typeof salesHistoryColumnOptionsSerializer>;

const salesPerInteractionOptionsSerializer = Serializers.object({
  type: Serializers.stringOneOf("salesPerInteraction"),
  salesAgg: Serializers.string,
  interactionAgg: Serializers.string,
});

export type SalesPerInteractionOptions = SerializerValue<typeof salesPerInteractionOptionsSerializer>;

export const customColumnOptionsSerializer = Serializers.first(
  salesHistoryColumnOptionsSerializer,
  salesPerInteractionOptionsSerializer,
);

export type CustomColumnOptions = SerializerValue<typeof customColumnOptionsSerializer>;

export const customColumnValueSerializer = Serializers.first(
  Serializers.object({
    type: Serializers.stringOneOf("salesHistory"),
    profitHistory: Serializers.decimal.array(),
  }),
  Serializers.object({
    type: Serializers.stringOneOf("salesPerInteraction"),
    profit: Serializers.decimal.nullable(),
    orderCount: Serializers.decimal.nullable(),
    potential: Serializers.decimal.nullable(),
  }),
);

export type CustomColumnValue = SerializerValue<typeof customColumnValueSerializer>;
