import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom-v5-compat";

import { Item } from "@megaron/crm-contracts";
import { Chip } from "@megaron/dash-form";
import { Tile, TileStripe } from "@megaron/dash-tile";

type Props = {
  items: Item[];
};

export const TradeItemsMobileList: React.FC<Props> = ({ items }) => {
  const theme = useTheme();

  return (
    <div css={{ display: "flex", flexDirection: "column", gap: "0.625rem", width: "100%", marginBottom: "1rem" }}>
      {items.map((item) => (
        <Link to={`/crm/items/${item.itemId}`}>
          <Tile stripe={<TileStripe color={item.isArchived ? theme.colors.background : theme.colors.primaryLight} />}>
            <div
              css={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                width: "100%",
                fontSize: "0.875rem",
                opacity: item.isArchived ? 0.5 : 1,
              }}
            >
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.25rem",
                  justifyContent: "space-between",
                  color: theme.colors.primary,
                  fontWeight: 700,
                  fontSize: "1rem",
                }}
              >
                <div css={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
                  {item.productId}
                  <span css={{ fontSize: "0.875rem", fontWeight: 400 }}>{`(${item.itemId})`}</span>
                </div>
                <span css={{ fontWeight: 400, fontSize: "0.875rem" }}>{item.productGroup}</span>
              </div>
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.25rem",
                  justifyContent: "space-between",
                  color: theme.colors.secondaryText,
                }}
              >
                {item.itemName}
                <Chip>{`${item.size.toFixed(0)} kg`}</Chip>
              </div>
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.25rem",
                  justifyContent: "space-between",
                }}
              >
                {[
                  { label: "Paleta", value: item.pallet },
                  { label: "Warstwa", value: item.layer },
                  { label: "Karton", value: item.box },
                ].map(({ label, value }) => (
                  <div
                    css={{
                      display: "flex",
                      flexDirection: "column",
                      fontSize: "0.75rem",
                      color: theme.colors.primary,
                    }}
                  >
                    {label}
                    <span css={{ color: theme.colors.secondaryText }}>{value ? `${value} szt.` : "-"}</span>
                  </div>
                ))}
              </div>
            </div>
          </Tile>
        </Link>
      ))}
    </div>
  );
};
