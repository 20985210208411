import { AmqpTopic } from "@megarax/amqp";
import { Uuid, uuidSerializer as mUuidSerializer } from "@megarax/common";
import { MSerializers } from "@megarax/serializers";

import { PubsubTopic } from "@megaron/pubsub-contracts";
import { Serializers } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

export type TradeRouteCreatedMessage = {
  uuid: Uuid;
};

export const tradeRouteCreatedEventMessageSerializer = MSerializers.object<TradeRouteCreatedMessage>({
  uuid: mUuidSerializer,
});

export const tradeRouteCreatedEventMessageAmqpTopic: AmqpTopic<TradeRouteCreatedMessage> = {
  key: "crm.tradeRoute.created",
  serializer: tradeRouteCreatedEventMessageSerializer,
};

export type TradeRouteScheduledMessage = {
  uuid: Uuid;
  salespersonId: number;
  date: Date;
};

export const tradeRouteScheduledEventMessageSerializer = MSerializers.object<TradeRouteScheduledMessage>({
  uuid: mUuidSerializer,
  salespersonId: MSerializers.integer,
  date: MSerializers.dateOnly,
});
export const tradeRouteScheduledEventMessageAmqpTopic: AmqpTopic<TradeRouteScheduledMessage> = {
  key: "crm.tradeRoute.routeScheduled",
  serializer: tradeRouteScheduledEventMessageSerializer,
};

export type CustomerTradeRouteChanged = {
  uuid: Uuid;
};

export const customerTradeRouteChangedEventMessageSerializer = MSerializers.object<CustomerTradeRouteChanged>({
  uuid: mUuidSerializer,
});

export const customerTradeRouteChangedEventMessageAmqpTopic: AmqpTopic<CustomerTradeRouteChanged> = {
  key: "crm.tradeRoute.customerTradeRouteChanged",
  serializer: customerTradeRouteChangedEventMessageSerializer,
};

export type TradeRouteChagned = {
  tradeRouteUuid: Uuid;
  customerUuids: Uuid[];
};

export const tradeRouteChangedTopic = PubsubTopic({
  name: "legacy-crm-trade-route-changed",
  serializer: Serializers.object<TradeRouteChagned>({
    tradeRouteUuid: uuidSerializer,
    customerUuids: uuidSerializer.array(),
  }),
});
