import { Uuid } from "@megarax/common";
import { chatThreadResource } from "@megarax/crm-contracts";
import { useProfilesDictionary } from "@megarax/iam-webapp";
import { useResourceProviderV2 } from "@megarax/react-client";
import { commonErrorsMap, useSnackbarErrorHandler } from "@megarax/ui-components";
import { isNotNull } from "@megarax/utils";
import { Paper, Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import _ from "lodash";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom-v5-compat";

import { useCustomers } from "@megaron/megarax-v2-resource-hooks";
import { assertOk } from "@megaron/result";

import { Feed, ProfileMap } from "./Feed";

export const MyChatFeedContainer = () => {
  const classes = useStyles();

  const navigate = useNavigate();

  const provider = useResourceProviderV2(chatThreadResource);

  const errorHandler = useSnackbarErrorHandler({ ...commonErrorsMap });

  const { data: myThreads } = useQuery(["myChatFeed"], () =>
    provider
      .myFeed({ limit: 10, offset: 0 })
      .mapError(errorHandler)
      .then(assertOk)
      .then((r) => r.value),
  );

  const profilesDictionary = useProfilesDictionary(myThreads?.map((sub) => ({ id: sub.lastMessage.userId }))) ?? {};

  const profiles: ProfileMap = _.mapValues(
    profilesDictionary,
    (p) =>
      p && {
        userId: p.user.id,
        name: p.fullName ?? "?",
        pictureUrl: p.pictureUrl ?? "",
      },
  );

  const customerDictionary = useCustomers(
    myThreads
      ?.map((t) => (t.threadIdentifier.type === "customer" ? t.threadIdentifier.customerUuid : null))
      .filter(isNotNull) ?? [],
  );

  const customerNames = _.mapValues(customerDictionary, (c) => c?.name);

  const goToCustomer = (uuid: Uuid) => navigate(`/crm/pos/klienci/${uuid}`);

  return (
    <Paper className={classes.paper}>
      {myThreads ? (
        <Feed customerNames={customerNames} profiles={profiles} threads={myThreads} onCustomerClick={goToCustomer} />
      ) : (
        <Skeleton height={250} variant="rectangular" />
      )}
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(3),
  },
}));
