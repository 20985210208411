import { useTheme } from "@emotion/react";

import { RealizationProgressBar } from "./RealizationProgressBar";

type Props = {
  currentRealization: number;
  goalRealization: number;
  regionName: string;
  regionOwnerEmail: string | null;
  hideDetails?: boolean;
};

export const RegionRealizationCard: React.FC<Props> = ({
  regionName,
  currentRealization,
  goalRealization,
  regionOwnerEmail,
  hideDetails,
}) => {
  const theme = useTheme();

  const marginThresholdsDetails = marginThresholds.map(({ percentage, maxValue, bonus }) => {
    return {
      valueAboveGoal: maxValue
        ? Number(Math.min(((percentage - 100) / 100) * goalRealization, maxValue).toFixed(2))
        : Number((((percentage - 100) / 100) * goalRealization).toFixed(2)),
      bonus,
    };
  });

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem",
        borderRadius: theme.smallBorderRadius,
        background: "white",
        border: "1px solid rgba(0, 0, 0, 0.1)",
      }}
    >
      <RealizationProgressBar
        label={regionName}
        regionOwnerEmail={regionOwnerEmail}
        currentRealization={currentRealization}
        goalRealization={goalRealization}
      />
      {!hideDetails &&
        marginThresholdsDetails.map(({ valueAboveGoal, bonus }, index) => {
          const previousThreshold = marginThresholdsDetails[index - 1];

          const goalRealizationForThreshold = valueAboveGoal - (previousThreshold?.valueAboveGoal || 0);

          const currentRealizationForThreshold =
            currentRealization - goalRealization - (previousThreshold?.valueAboveGoal || 0);

          const isActive = currentRealizationForThreshold >= 0;

          return (
            <RealizationProgressBar
              key={bonus}
              label={`Premia: ${bonus} zł`}
              currentRealization={currentRealizationForThreshold < 0 ? 0 : currentRealizationForThreshold}
              goalRealization={goalRealizationForThreshold}
              variant={"secondary"}
              isDisabled={!isActive}
            />
          );
        })}
    </div>
  );
};

const marginThresholds = [
  { percentage: 102, maxValue: 4000, bonus: 800 },
  { percentage: 105, maxValue: 8000, bonus: 1400 },
  { percentage: 108, maxValue: 12000, bonus: 2000 },
  { percentage: 125, maxValue: undefined, bonus: 3000 },
];
