import { HttpAction } from "@megaron/http-service";
import { Serializers, SerializerValue } from "@megaron/serializers";
import { uuidSerializer } from "@megaron/uuid";

import { CustomerCategory } from "./categories";

export const relationTypes = ["invitedBy", "supplyPoint", "chain", "payer", "distributor"] as const;
export type RelationType = (typeof relationTypes)[number];

type RelationConfig = {
  isRestricted?: boolean;
  parentCategory: CustomerCategory;
  childCategory?: CustomerCategory;
};

export const relationConstraints: Record<RelationType, RelationConfig> = {
  invitedBy: {
    isRestricted: true,
    parentCategory: "loyaltyUser",
    childCategory: "loyaltyUser",
  },
  supplyPoint: {
    parentCategory: "pos",
    childCategory: "loyaltyUser",
  },
  chain: {
    parentCategory: "chain",
  },
  payer: {
    parentCategory: "payer",
  },
  distributor: {
    parentCategory: "distributor",
    childCategory: "pos",
  },
};

export const customerRelationSerialzier = Serializers.object({
  uuid: uuidSerializer,
  parentId: uuidSerializer,
  childId: uuidSerializer,
  type: Serializers.stringOneOf(...relationTypes),
});

export type Relation = SerializerValue<typeof customerRelationSerialzier>;

export const relations = {
  customerRelationsQuery: HttpAction({
    path: "/customer/:customerId/relations",
    method: "get",
    paramsSerializer: Serializers.object({
      customerId: uuidSerializer,
    }),
    requiresAuth: true as const,
    valueSerializer: customerRelationSerialzier.array(),
  }),

  addCustomerRelation: HttpAction({
    path: "/customers/relations",
    method: "post",
    requiresAuth: true as const,
    bodySerializer: customerRelationSerialzier,
    valueSerializer: customerRelationSerialzier,
  }),

  removeCustomerRelation: HttpAction({
    path: "/customers/relations/:relationUuid",
    method: "delete",
    paramsSerializer: Serializers.object({
      relationUuid: uuidSerializer,
    }),
    requiresAuth: true as const,
  }),
};
