import { HttpAction } from "@megaron/http-service";
import { budgetDocSerializer, invoiceThreadDocSerializer } from "@megaron/invoices-contracts";
import { Serializers, SerializerValue } from "@megaron/serializers";

import { bcVendorSerializer } from "./docTypes/bcVendor";
import { customerEventDocSerializer } from "./docTypes/customerEvents";
import { customerInteractionDocSerializer } from "./docTypes/customerInteractions";
import { customerDocSerializer } from "./docTypes/customers";
import { itemDocSerializer } from "./docTypes/items";
import { linkDocSerializer } from "./docTypes/link";
import { orderDocSerializer } from "./docTypes/order";
import { savedViewDocSerializer } from "./docTypes/savedView";
import { testDocSerializer } from "./docTypes/testDoc";
import { userGroupDocSerializer } from "./docTypes/userGroups";
import { userDocSerializer } from "./docTypes/users";

const docTypeSerializers = [
  customerDocSerializer,
  userDocSerializer,
  testDocSerializer,
  linkDocSerializer,
  budgetDocSerializer,
  invoiceThreadDocSerializer,
  userGroupDocSerializer,
  bcVendorSerializer,
  itemDocSerializer,
  customerEventDocSerializer,
  customerInteractionDocSerializer,
  orderDocSerializer,
  savedViewDocSerializer,
];

export const anyDocSerializer = Serializers.first(...docTypeSerializers);
export type AnyDoc = SerializerValue<typeof anyDocSerializer>;

export const docTypeSerializer = Serializers.first(...docTypeSerializers.map((s) => s.fields["docType"]));
export type DocType = SerializerValue<typeof docTypeSerializer>;

export const autocompleteParamsSerializer = Serializers.first(
  Serializers.object({
    doctype: Serializers.stringOneOf("invoice-thread"),
    field: Serializers.stringOneOf("title", "invoiceIssuer"),
  }),
  Serializers.object({
    doctype: Serializers.stringOneOf("item"),
    field: Serializers.stringOneOf("productId", "productGroup"),
  }),
  Serializers.object({
    doctype: Serializers.stringOneOf("customer"),
    field: Serializers.stringOneOf("tags"),
  }),
);

export const anyDocActions = {
  search: HttpAction({
    path: "/search",
    qsSerializer: Serializers.object({
      searchText: Serializers.string,
      docType: docTypeSerializer.array().asJsonString(),
      limit: Serializers.integer,
      offset: Serializers.integer,
      isArchived: Serializers.booleanFieldFilter,
    }).partial(),
    valueSerializer: Serializers.paginatedList(anyDocSerializer),
    requiresAuth: true,
  }),

  retrieveDocs: HttpAction({
    path: "/retrieve",
    qsSerializer: Serializers.object({
      docIds: Serializers.string.array().asJsonString(),
    }),
    valueSerializer: anyDocSerializer.nullable().array(),
    requiresAuth: true,
  }),

  autocomplete: HttpAction({
    path: "/autocomplete/:doctype/:field",
    paramsSerializer: autocompleteParamsSerializer,
    qsSerializer: Serializers.object<{
      text: string;
      limit?: number;
    }>({
      text: Serializers.string,
      limit: Serializers.integer.optional(),
    }),
    valueSerializer: Serializers.string.array(),
    requiresAuth: true,
  }),
};

export const isDocOfType =
  <T extends DocType>(docType: T) =>
  (doc: AnyDoc): doc is Extract<AnyDoc, { docType: T }> =>
    doc.docType === docType;
